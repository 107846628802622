import { capitalize } from 'lodash'
import { CSSProperties } from 'react'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import { MountType, DevicesType } from '../../../types/DoorsAndWindows'
import { IconContainer } from './DoorsAndWindowsStyle'

type IconColors = {
  containerColor: string
  iconColor?: string
  style?: CSSProperties
}

const DoorsAndWindowsTileFooter = (props: { props: DevicesType[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    const doorsAndWindows = devices.filter(
      (device) => device.MountType === MountType.DOOR || device.MountType === MountType.WINDOW,
    )
    return (
      <>
        {doorsAndWindows.length > 4 ? (
          <span>{`+${doorsAndWindows.length - doorsAndWindows.slice(0, 4).length}`}</span>
        ) : null}
      </>
    )
  }

  const getIconColors = (device: DevicesType, isClosed: boolean): IconColors => {
    let iconColors: IconColors = { containerColor: '', iconColor: '' }
    if (device.MountType === MountType.DOOR) {
      iconColors = { containerColor: colors.lightRed, iconColor: colors.white }
    } else {
      iconColors = {
        containerColor: colors.white,
        iconColor: colors.red,
        style: { border: `1px solid ${colors.red}` },
      }
    }
    if (isClosed) {
      iconColors = {
        containerColor: device.LowBattery ? colors.lightYellow : colors.black100,
        iconColor: undefined,
        style: undefined,
      }
    }
    return iconColors
  }

  const CountDevices = () => {
    const locksAndMagnets = ([] as Array<DevicesType>)
      .concat(devices)
      .sort((a, b) =>
        b.Closed === a.Closed ? a.Type - b.Type : Number(b.Closed) - Number(a.Closed),
      )
      .reverse()
      .slice(0, 4)

    const renderItems = (type: MountType, isClosed?: boolean) => {
      return locksAndMagnets!
        .filter(
          (device) =>
            device.MountType === type &&
            ((isClosed && device.Closed) || (!isClosed && !device.Closed)),
        )
        .map((device) => {
          const iconColors = getIconColors(device, isClosed ? isClosed : false)
          return (
            <IconContainer
              role="listitem"
              className="w-6 h-6 mx-1"
              color={iconColors.containerColor}
              key={device.Serial}
              style={iconColors.style}
            >
              <Icon name={capitalize(MountType[type]) + 's'} color={iconColors.iconColor} />
            </IconContainer>
          )
        })
    }

    return (
      <div className="flex" role={'list'}>
        {renderItems(MountType.DOOR)}
        {renderItems(MountType.WINDOW)}
        {renderItems(MountType.DOOR, true)}
        {renderItems(MountType.WINDOW, true)}
      </div>
    )
  }

  return (
    <>
      <CountDevices />
      <CountRemainderDevices />
    </>
  )
}

export default DoorsAndWindowsTileFooter
