import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import SettingsMenu from './components/SettingsMenu'

const SettingsScreen = () => {
  const { data: panelInfo } = usePanelInfo()

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <SettingsMenu />
    </TcOrCrowContainer>
  )
}

export default SettingsScreen
