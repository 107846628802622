export enum SoundLevel {
  Unknown = -1,
  Disabled = 0,
  Low = 1,
  High = 2,
}

export type Lock = {
  Id?: number
  Label: string
  PanelId: string
  Serial: string
  AutoLockEnabled?: boolean
  Languages?: {
    Languages?: [
      {
        Id?: string
        Language?: string
      },
    ]
    Selected?: {
      Id?: string
      Language?: string
    }
  } | null
  BatteryLow?: boolean
  Status?: 'lock' | 'unlock'
  SoundLevel?: SoundLevel | null
  LastChanged?: string
}

export interface LockCommandPayload {
  PanelId: string
  PanelCode: string
  Platform: string
  LockSerial: string
}

export interface LockSettingsPayload {
  Password?: string
  Serial?: string
  Label?: string
  PanelId?: string
  SoundLevel?: SoundLevel
  AutoLock?: boolean
  Language?: string | undefined
  ValidationCode: string
}
