import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import SpeechBubbleField from './SpeechbubbleField'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import { SectorAlarmAppCustomerSupportIcon } from '../../../../../assets/icons'
import FormButtons from '../../../../../components/forms/FormButtons'
import { useIrishCheck } from '../../../../../i18n/useI18n'

export type EditQuestionFormValues = {
  alarmCenterCodeword: string
  customerCodeword: string
}

const baseValidator = yup
  .string()
  .required('Required')
  .min(2, 'At least 2 characters is required')
  .max(30, 'SECURITY_QUESTION_TEXT_MAX_30')
  .matches(
    /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/,
    'Special characters are not allowed',
  )

const resolver = yup.object().shape({
  alarmCenterCodeword: baseValidator,
  customerCodeword: baseValidator.test('equal', 'The code words can not be equal', function(
    value,
    context,
  ) {
    return value?.toLowerCase() !== context.parent.alarmCenterCodeword?.toLowerCase()
  }),
})

type Props = {
  isLoading: boolean
  onSubmit: (formValues: EditQuestionFormValues) => void
  cancelUrl: string
  saveText?: TranslationKey
  cancelText?: TranslationKey
}

const EditQuestionForm: FC<Props> = ({ isLoading, onSubmit, cancelUrl, saveText, cancelText }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<EditQuestionFormValues>({
    resolver: yupResolver(resolver) as any,
    mode: 'onChange',
  })

  const { checkIrish } = useIrishCheck()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SpeechBubbleField
        {...register('alarmCenterCodeword')}
        icon={SectorAlarmAppCustomerSupportIcon}
        labelKey={checkIrish('Sector Alarm password', 'PW_WE_SAY')}
        placeholder="Example: fish"
        errorKey={errors.alarmCenterCodeword?.message as TranslationKey}
      />
      <SpeechBubbleField
        {...register('customerCodeword')}
        icon={SectorAlarmAppCustomerSupportIcon}
        labelKey={checkIrish('Your password', 'PW_YOU_SAY')}
        placeholder="Example: tomato"
        iconRight
        errorKey={errors.customerCodeword?.message as TranslationKey}
      />
      <div>
        <FormButtons
          cancelUrl={cancelUrl}
          isLoading={isLoading}
          isValid={isValid}
          submitText={saveText}
          cancelText={cancelText}
        />
      </div>
    </form>
  )
}

export default EditQuestionForm
