import React from 'react'

const LeftSecondaryS = () => {
  return (
    <svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5052 5.96988C2.16768 7.97904 2 9.98992 2 12C2 14.0101 2.16768 16.021 2.5052 18.0301C2.73552 19.4013 3.19791 20.2409 3.74783 20.7665C4.29209 21.2867 5.11078 21.6799 6.39941 21.8073C6.93864 21.8609 7.47268 21.9067 8 21.9402L10 21.9402L10 24L9.64286 24C9.09564 23.9972 8.54756 23.9768 8 23.944C7.39964 23.9079 6.79991 23.8569 6.20262 23.7976C2.97012 23.478 1.09395 21.7019 0.532832 18.3615C0.177045 16.2435 8.63578e-07 14.1218 1.04907e-06 12C1.23457e-06 9.87818 0.177046 7.75648 0.532835 5.63855C1.09395 2.29807 2.97013 0.522033 6.20263 0.202427C6.79992 0.14307 7.39965 0.0920503 8 0.0560358C8.54756 0.0231894 9.09564 0.0028247 9.64286 -3.12224e-08L10 0L10 2.05984L8 2.05984C7.47268 2.09331 6.93964 2.13903 6.40041 2.19262L6.39941 2.19272C5.11078 2.32013 4.2921 2.71331 3.74784 3.23349C3.19791 3.75909 2.73552 4.59871 2.5052 5.96988Z" />
    </svg>
  )
}

export default LeftSecondaryS
