import { OrderResponse } from '../types/Order'
import { PanelEvent } from '../types/PanelEvent'
import { WifiDetails } from '../types/WifiDetails'
import createHTTPClient from './createHTTPClient'
import { LockCommandPayload } from '../types/Lock'
import { PanelModel, PanelStatus } from '../types/PanelModel'
import { SmartPlug } from '../types/SmartPlug'

interface PanelStatusResponse {
  error: boolean
  Status: PanelStatus | undefined
  IsOnline: boolean
  AnnexStatus: PanelStatus | undefined
  StatusTime: string
  StatusTimeUtc: string
  TimeZoneName: string
}

const HTTP = createHTTPClient('/Panel')

export const panelTemperature = (panelId: string | number) =>
  HTTP.get(`/GetTemperatures?panelId=${panelId}`)

export const activatePanel = ({ PanelId }: { PanelId: string }) => {
  return HTTP.post(`/ActivatePanel`, { PanelId })
}

export const panelInfo = (panelId: string) => {
  return HTTP.get<PanelModel>('/GetPanel?panelId=' + panelId)
}

export const panelStatus = ({ panelId }: { panelId: string }): Promise<PanelStatusResponse> => {
  return HTTP.get('/GetPanelStatus?panelId=' + panelId)
}

export const getPanelOrder = (panelId: string) => {
  return HTTP.get<OrderResponse>('/GetComponents?panelId=' + panelId)
}

export const armPanel = (payload: { PanelId: string; PanelCode?: string }) =>
  HTTP.post(`/Arm`, payload)

export const disarmPanel = (payload: { PanelId: string; PanelCode?: string }) =>
  HTTP.post(`/Disarm`, payload)

export const partialArmPanel = (payload: { PanelId: string; PanelCode?: string }) =>
  HTTP.post(`/PartialArm`, payload)

export const armAnnex = (payload: { PanelId: string; PanelCode?: string }) =>
  HTTP.post(`/ArmAnnex`, payload)

export const disarmAnnex = (payload: { PanelId: string; PanelCode?: string }) =>
  HTTP.post(`/DisarmAnnex`, payload)

export const getWifiNetworks = (payload: {
  panelId: string
  WiFiCardSerial: string
  TwoFactorPin: string
}) => HTTP.post<WifiDetails[]>('/GetWifiNetworks', payload)

export const connectToWifi = async (payload: {
  panelId: string
  WiFiCardSerial: string
  Ssid: string
  password: string | undefined
  EncryptionType?: string
  TwoFactorPin: string
}) => {
  await HTTP.post('/ConnectToWifi', payload)
}

export const disconnectFromWifi = async (payload: {
  panelId: string
  WiFiCardSerial: string
  TwoFactorPin: string
}) => {
  await HTTP.post('/DisconnectFromWifi', payload)
}

export const getSmartplugsStatus = (panelId: string) =>
  HTTP.get<SmartPlug[]>(`/GetSmartplugStatus?panelId=${panelId}`)

interface SmartplugCommandPayload {
  panelId: string
  Id: string
}

export const turnOnTCSmartplug = async ({ panelId, Id }: SmartplugCommandPayload) => {
  await HTTP.post(`/TurnOnSmartplug?switchId=${Id}&panelId=${panelId}`)
  return Id
}

export const turnOffTCSmartplug = async ({ panelId, Id }: SmartplugCommandPayload) => {
  await HTTP.post(`/TurnOffSmartplug?switchId=${Id}&panelId=${panelId}`)
  return Id
}

export const turnOnSmartplug = async ({ panelId, Id }: SmartplugCommandPayload) => {
  await HTTP.post(`/TurnOnSmartplug?switchId=${Id}&panelId=${panelId}`)
  return HTTP.get<SmartPlug[]>(`/GetSmartplugStatus?panelId=${panelId}`)
}

export const turnOffSmartplug = async ({ panelId, Id }: SmartplugCommandPayload) => {
  await HTTP.post(`/TurnOffSmartplug?switchId=${Id}&panelId=${panelId}`)
  return HTTP.get<SmartPlug[]>(`/GetSmartplugStatus?panelId=${panelId}`)
}

export const takePicture = ({ panelId, deviceId }: { panelId: string; deviceId: string }) =>
  HTTP.get(`/TakePicture?panelId=${panelId}&deviceId=${deviceId}`)

export const lock = async (payload: LockCommandPayload) => {
  await HTTP.post('/Lock', payload)
  return payload
}

export const unlock = async (payload: LockCommandPayload) => {
  await HTTP.post('/Unlock', payload)
  return payload
}

export const getLockStatus = (panelId: string) => HTTP.get(`/GetLockStatus?panelId=${panelId}`)

export const getHistory = async (panelId: string) => {
  return HTTP.get<PanelEvent[]>('/GetLogs?panelId=' + panelId)
}
