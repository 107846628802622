import React, { ChangeEventHandler, FC, FormEventHandler, useContext, useState } from 'react'
import Button from '../../../../components/Button'
import LoadPage from '../../../../components/LoadPage'
import Modal from '../../../../components/Modal'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useConnectToWifi } from '../../../../services/requestHooks/wifi'
import { WifiDetails, WifiEncryptionType } from '../../../../types/WifiDetails'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'

type ModalState = {
  show: boolean
  wifi: WifiDetails
  onSubmit?: () => void
  onCancel?: () => void
}
// TODO: Find correct translation-keys
const ConfirmConnect: FC<ModalState & { WiFiCardSerial: string | undefined }> = ({
  wifi: { Name, EncryptionType },
  show,
  onSubmit,
  onCancel,
  WiFiCardSerial,
}) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const [wifiPassword, setWifiPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { run: connectToWifi, isLoading, error } = useConnectToWifi({
    onSuccess: () => {
      setWifiPassword('')
      onSubmit?.()
    },
  })

  const togglePasswordVisibility = () => {
    setShowPassword((prevValue) => !prevValue)
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setWifiPassword(event.target.value)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    if (WiFiCardSerial) {
      if (wifiPassword || EncryptionType === WifiEncryptionType.NOT_ENCRYPTED) {
        const TwoFactorPin = await promptForTwoFactor()
        if (TwoFactorPin) {
          connectToWifi({
            Ssid: Name,
            password: wifiPassword,
            panelId,
            TwoFactorPin,
            WiFiCardSerial,
            EncryptionType,
          })
        }
      }
    }
  }

  const handleCancel = () => {
    setWifiPassword('')
    onCancel?.()
  }

  if (!show) return null

  return (
    <Modal handleClose={handleCancel}>
      <LoadPage isLoading={isLoading}>
        <h1 className="text-2xl">{t('CONNECT')}</h1>
        <p>{`${t('CONNECT_TO_WIFI')} ${Name}`}</p>
        <form onSubmit={handleSubmit}>
          {EncryptionType !== WifiEncryptionType.NOT_ENCRYPTED && (
            <div className="mb-4">
              <input
                className="form__input"
                aria-label={t('Password')}
                value={wifiPassword}
                onChange={onChange}
                type={showPassword ? 'text' : 'password'}
              />
              <Button buttonType="link" type="button" onClick={togglePasswordVisibility}>
                {showPassword ? 'Hide password' : 'Show password'}
              </Button>
            </div>
          )}
          <Button
            buttonType="primary"
            disabled={!wifiPassword && EncryptionType !== WifiEncryptionType.NOT_ENCRYPTED}
          >
            {t('Confirm')}
          </Button>
          <Button buttonType="secondary" type="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        </form>
        {!!error && <FailedRequest text={error} />}
      </LoadPage>
    </Modal>
  )
}

const emptyWifi: WifiDetails = {
  Name: '',
  Connected: 'false',
  EncryptionType: WifiEncryptionType.NOT_ENCRYPTED,
  RadioLevel: '0',
  SsidHidden: 'false',
}

export const useConfirmConnect = () => {
  const [modalState, setModalState] = useState<ModalState>({
    show: false,
    wifi: emptyWifi,
  })

  const promptForConnect = (wifi: WifiDetails) => {
    return new Promise<boolean>((resolve) => {
      setModalState({
        show: true,
        wifi,
        onSubmit: () => {
          setModalState({
            show: false,
            wifi: emptyWifi,
          })
          resolve(true)
        },
        onCancel: () => {
          setModalState({
            show: false,
            wifi: emptyWifi,
          })
          resolve(false)
        },
      })
    })
  }
  return {
    promptForConnect,
    modalState: modalState,
  }
}

export default ConfirmConnect
