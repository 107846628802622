function SvgAnnex() {
  return (
    <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 25C8 24.4477 8.44772 24 9 24H19C19.5523 24 20 24.4477 20 25V31C20 31.5523 19.5523 32 19 32H9C8.44771 32 8 31.5523 8 31V25ZM10 30V26H13V30H10ZM15 30H18V26H15V30Z"
        fill="#333131"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1L3 40H1C0.447715 40 0 40.4477 0 41C0 41.5523 0.447715 42 1 42H36C36.5523 42 37 41.5523 37 41V37C37 36.4477 36.5523 36 36 36V22H36.6743C37.3386 22 37.8183 21.3641 37.6358 20.7253L36.4144 16.4506C36.1691 15.592 35.3844 15 34.4914 15H5L5 1ZM35.3485 20H5V17H34.4914L35.3485 20ZM5 40V38H35V40H5ZM32 36H34V22H5L5 36H23V25C23 24.4477 23.4477 24 24 24H31C31.5523 24 32 24.4477 32 25V36ZM25 36H30V26H25V36Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgAnnex
