import React, { FC } from 'react'
import classNames from 'classnames'
import TermsLink from './TermsLink'
import useI18n from '../i18n/useI18n'
import { brand } from '../configs'

type Props = {
  crow?: boolean
}

const Footer: FC<Props> = ({ crow = false }) => {
  const { t } = useI18n()

  return (
    <footer className={classNames('footer', crow && 'footer--crow')}>
      {new Date().getFullYear()} ©{brand === 'phonewatch' ? 'Phonewatch ' : 'Sector Alarm '}|{' '}
      <TermsLink>{t('User terms and cookies')}</TermsLink>
    </footer>
  )
}

export default Footer
