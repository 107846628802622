import React, { FC, useState } from 'react'
import styled from 'styled-components'

import Modal from '../Modal'
import { Paragraph, SubTitle } from '../Typography'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import useI18n from '../../../i18n/useI18n'
import { colors } from '../Style/theme'

interface Handlers {
  onConfirm?: () => void
  onCancel?: () => void
}

interface PromptState {
  onConfirm?: () => void
  onCancel?: () => void
}

export const useConfirmationPrompt = (): { promptState: PromptState; prompt: () => void } => {
  const [handlers, setHandlers] = useState<Handlers>({})

  const prompt = () =>
    new Promise((resolve, reject) => {
      setHandlers({
        onConfirm: () => {
          setHandlers({})
          resolve(true)
        },
        onCancel: () => {
          setHandlers({})
          reject('Rejected')
        },
      })
    })

  return {
    promptState: {
      onConfirm: handlers.onConfirm,
      onCancel: handlers.onCancel,
    },
    prompt,
  }
}

interface ConfirmButton {
  text: string
  level?: 'primary' | 'secondary'
  variant?: 'default' | 'warning' | 'error' | 'white'
}

interface Props {
  title?: string
  text: string
  promptState: PromptState
  buttonProps: ConfirmButton
}

const ConfirmationPrompt: FC<Props> = ({
  title,
  text,
  promptState: { onConfirm, onCancel },
  buttonProps: { text: btnText, level = 'primary', variant = 'default' },
}) => {
  const { t } = useI18n()

  if (!onConfirm || !onCancel) return null

  return (
    <Modal>
      {title && <SubTitle>{title}</SubTitle>}
      <CloseButton onClick={onCancel}>
        <Icon name={'Cancel'} />
      </CloseButton>
      <Text>{text}</Text>
      <ButtonRow>
        <Button level={level} size="l" variant={variant} onClick={onConfirm}>
          {btnText}
        </Button>
        <Button level="secondary" size="l" onClick={onCancel}>
          <span>{t('Cancel')}</span>
        </Button>
      </ButtonRow>
    </Modal>
  )
}

const CloseButton = styled.button`
  position: absolute;
  top: 28px;
  right: 28px;
  font-size: 24px;
  color: ${colors.blue};
`

const Text = styled(Paragraph)`
  margin-top: 2rem;
`

const ButtonRow = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
`

export default ConfirmationPrompt
