import styled from 'styled-components'
import Button from '../../../components/Crow/Button/Button'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { colors } from '../../../components/Crow/Style/theme'
import { SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { useResourceInfo } from '../../../services/requestHooks/user'
import { MountType } from '../../../types/DoorsAndWindows'
import { Section, Temperature } from '../../../types/Temperature'
import DeviceName from '../../../utils/places/DeviceName'
import { StyledIcon, StyledParagraphBatteryLow } from '../DoorAndWindowPage/DoorsAndWindowsStyle'

interface TemperatureDetailsType {
  floors: Section
}

const TemperatureDetails = ({ floors }: TemperatureDetailsType) => {
  const { t } = useI18n()
  const resources = useResourceInfo()
  return (
    <TemperatureDetailsContainer key={floors.Key}>
      {floors.Places.flatMap((room: { Components: Temperature[] }) =>
        room.Components.map((device) => (
          <TemperatureCrowCard key={device.SerialNo} as="li">
            {/* <IconContainer color={colors.black200}>
              <TemperatureIcon name="Temperature" />
            </IconContainer> */}

            {device.MountIndex ? (
              <SubTitle light className="pl-3 pr-3">
                {t(MountType[device.MountType])}&nbsp;{device.MountIndex}
              </SubTitle>
            ) : (
              <SubTitle light className="pl-3 pr-3">
                <DeviceName deviceName={device.Type} />
              </SubTitle>
            )}
            <DeviceTemperature>{device.Temperature}°C</DeviceTemperature>
            {device.LowBattery && (
              <LowBatteryContainer>
                <IconContainer className="w-8 h-8" color={colors.lightYellow}>
                  <StyledIcon name="Battery-low" color={colors.darkGray} fontSize="22px" />
                </IconContainer>
                <StyledParagraphBatteryLow color={colors.black800} large>
                  {t('BATTERY_LOW')}
                </StyledParagraphBatteryLow>
                <a
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${resources.CustomerServiceUrl}`}
                >
                  <Button variant="default" level="secondary" size="s">
                    {t('call_support')}
                  </Button>
                </a>
              </LowBatteryContainer>
            )}
          </TemperatureCrowCard>
        )),
      )}
    </TemperatureDetailsContainer>
  )
}

const LowBatteryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: ${colors.white};
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  width: 100%;
  color: ${colors.black800};

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 17px 0px 23px 0px;
  }
`

const TemperatureDetailsContainer = styled.ul`
  padding-left: 42px;
`

const TemperatureCrowCard = styled(CrowCard)`
  border-radius: 12px;
  border: 1px solid ${colors.black300};
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    width: 500px;
  }
`

const IconContainer = styled.div<{ color?: string }>`
  border-radius: 8px;
  background-color: ${({ color }) => (color ? color : colors.black100)};
  padding: 10px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DeviceTemperature = styled.span`
  margin-left: auto;
  font-size: 18px;
  color: ${colors.grayShade};
  @media (max-width: 500px) {
    margin: 0;
  }
`

export default TemperatureDetails
