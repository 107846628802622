function SvgLibrary(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 10H36V24H34V10Z" fill="#333131" />
      <path d="M34 4V8H36V4H34Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 10V1C30 0.447716 30.4477 0 31 0H39C39.5523 0 40 0.447715 40 1V33H30V25H28V31H29V33H4C2.89543 33 2 32.1046 2 31V25C2 23.8954 2.89543 23 4 23H5L5 19C3.89543 19 3 18.1046 3 17V12C3 10.8954 3.89543 10 5 10H30ZM32 2H38V31H32V2ZM30 16.75V12.25H29V16.75H30ZM26 25V31H4V25H26ZM29 23H7V19H18V22L22 20L26 22V19H29V23ZM18 17V14C18 13.4477 18.4477 13 19 13H25C25.5523 13 26 13.4477 26 14V17H27V12H5V17H18ZM22 17.7639L20 18.7639V15H24V18.7639L22 17.7639Z"
        fill="#333131"
      />
      <path
        d="M1 34C0.447715 34 0 34.4477 0 35C0 35.5523 0.447715 36 1 36H41C41.5523 36 42 35.5523 42 35C42 34.4477 41.5523 34 41 34H1Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgLibrary
