function SvgKitchen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="43" height="33" viewBox="0 0 43 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3247 2L19.0048 4.52912V16H21.0048V11H40.0048C41.1093 11 42.0048 11.8954 42.0048 13V31C42.0048 32.1046 41.1093 33 40.0048 33H5.00477C3.9002 33 3.00477 32.1046 3.00477 31V26H5.00477V15.1944L1.60605 17.7836C1.16672 18.1182 0.539263 18.0334 0.204583 17.5941C-0.130098 17.1547 -0.0452654 16.5273 0.39406 16.1926L21.3772 0.207558C21.5222 0.097085 21.6877 0.0323204 21.8564 0.0109256C21.9048 0.00372879 21.9544 0 22.0048 0H41.0048C41.5571 0 42.0048 0.447717 42.0048 1C42.0048 1.55229 41.5571 2 41.0048 2L22.3247 2ZM13.0048 9.09995L17.0048 6.05273V16H15.0048V21H13.0048V9.09995ZM7.00477 13.6708L11.0048 10.6236V21H9.00477V26H7.00477V13.6708ZM40.0048 13H23.0048V16H40.0048V13ZM40.0048 18V21H17.0048V18H40.0048ZM40.0048 23H11.0048V26H40.0048V23ZM40.0048 28V31H5.00477V28H40.0048Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgKitchen
