import * as React from 'react'

function SvgTinyArmed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.236 5.782L10.063.365a1.773 1.773 0 00-2.165.008L.756 5.806A1.97 1.97 0 000 7.366v9.054c0 .874.685 1.583 1.52 1.583h4.03c1.016 0 1.843-.866 1.843-1.937v-2.543c0-.362.267-.67.582-.67H9.82c.323 0 .59.308.59.678v2.543c0 1.063.827 1.929 1.843 1.929h4.228c.835 0 1.52-.709 1.52-1.583V7.35c0-.623-.291-1.205-.764-1.568z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgTinyArmed
