export enum InvoiceStatus {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  COLLECTION = 'COLLECTION',
  ENDED = 'ENDED',
}

export interface Invoice {
  MonthName: string
  Overdue: boolean
  amount: string
  expiration: string
  invoiceNumber: string
  month: string
  status: InvoiceStatus
  year: string
}

export type InvoicesResponse = Invoice[]
