import { ReactNode } from 'react'
import styled from 'styled-components'

import { PermanentUser } from '../../../types/PermanentUser'
import useI18n from '../../../i18n/useI18n'

const Tag = styled.span<{ color: string; children: ReactNode }>`
  border-radius: 8px;
  padding: 0 6px;
  font-family: 'Metric';
  font-size: 14px;
  line-height: 16px;
  height: 18px;
  background-color: ${({ color }) => color};
`

interface Props {
  user: PermanentUser
  className?: string
}

const CategoryTag = ({ user, className }: Props) => {
  const { t } = useI18n()

  if (user.Accesses.IsLegalOwner) {
    return (
      <Tag color="#D6D4D4" className={className}>
        {t('owner')}
      </Tag>
    )
  } else if (user.Accesses.IsAdminUser) {
    return (
      <Tag color="#D6D4D4" className={className}>
        {t('admin')}
      </Tag>
    )
  } else if (user.Accesses.IsChild) {
    return (
      <Tag color="#D9E9F5" className={className}>
        {t('child')}
      </Tag>
    )
  }
  return null
}

export default CategoryTag
