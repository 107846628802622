import React, { FC } from 'react'
import CrowCard from '../../../../components/Crow/CrowCard/CrowCard'
import { useFeatureToggle } from '../../../../components/FeatureToggle'
import { useModalPrompt } from '../../../../components/Modal/Modal'
import Switch from '../../../../components/Switch'
import TermsLink from '../../../../components/TermsLink'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo, useSetArcConsent } from '../../../../services/requestHooks/panelInfo'
import { UserFeature } from '../../../../types/UserDetails'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import SettingsSubPage from '../components/SettingsSubPage'
import RemoveVideoControlModal from './RemoveVideoControlModal'
import VideoControlModal from './VideoControlModal'
import { useHistory } from 'react-router-dom'

const ConsentPage: FC = () => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: panelInfoError,
  } = usePanelInfo()

  const {
    run: setArcConsent,
    isLoading: settingArcConsent,
    error: arcConsentError,
  } = useSetArcConsent()

  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptForValue: promptForTerms, modalState: videoControlState } = useModalPrompt<true>()
  const { promptForValue: promptForRemove, modalState: removeVideoControlState } = useModalPrompt<
    true
  >()

  const history = useHistory()
  const hasArcConsent = useFeatureToggle(UserFeature.ARC_CONSENT)

  useOnMount(() => {
    if (!hasArcConsent) {
      history.replace(`/systems/${panelId}/settings`)
    }

    if (!panelInfo && !loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
  })

  const toggleConsent = async () => {
    const ValidationCode = await promptForTwoFactor()
    if (ValidationCode) {
      let confirm: boolean | undefined = false
      if (!panelInfo?.ArcVideoConsent) {
        confirm = await promptForTerms()
      } else {
        confirm = await promptForRemove()
      }
      if (confirm) {
        const Consent = !panelInfo?.ArcVideoConsent
        setArcConsent({ Consent, PanelId: panelId, ValidationCode })
      }
    }
  }

  return (
    <SettingsSubPage
      returnUrl={`/systems/${panelId}/settings`}
      isLoading={loadingPanelInfo}
      loaderProps={{
        loadingText: { text: 'Loading' },
        errorHandling: {
          loadingFailed: !!panelInfoError,
          errorText: 'Something went wrong. Please try again',
          returnUrl: `/systems/${panelId}/settings`,
          errorHeaderText: 'System settings',
        },
      }}
    >
      <h1>{t('CONSENT_TITLE')}</h1>
      <p className="mt-2">{t('CONSENT_DESCRIPTION')}</p>
      <CrowCard className=" mt-6 flex justify-between items-center">
        <div>
          <h2>{t('CONSENT_VIDEO_TITLE')}</h2>
          <p>{t('CONSENT_VIDEO_DESCRIPTION')}</p>
        </div>
        <Switch
          testId="toggle-consent"
          onClick={toggleConsent}
          checked={!!panelInfo?.ArcVideoConsent}
          isLoading={settingArcConsent}
          hideText
        />
      </CrowCard>
      {!!arcConsentError && <FailedRequest text={arcConsentError} />}
      <CrowCard className="mt-2 flex justify-between items-center">
        <div>
          <h2>{t('CONSENT_TERMS_TITLE')}</h2>
          <p>{t('CONSENT_TERMS_DESCRIPTION')}</p>
        </div>
        <TermsLink>{t('OPEN')}</TermsLink>
      </CrowCard>
      <VideoControlModal {...videoControlState} />
      <RemoveVideoControlModal {...removeVideoControlState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </SettingsSubPage>
  )
}

export default ConsentPage
