import React, { useState } from 'react'
import useI18n from '../../../../../i18n/useI18n'
import { SmartPlugTimeTrigger } from '../../../../../types/SmartPlug'
import Button from '../../../../../components/Button'
import TimeTriggerListItem from './TimeTriggerListItem'
import TimeTriggerForm from './TimeTriggerForm'
import { useParams } from 'react-router-dom'
import { useSetPlugTimeTrigger } from '../../../../../services/requestHooks/plugs'
import { Loader } from '../../../../../components/Elements/Loaders'

interface Props {
  timeTriggers: SmartPlugTimeTrigger[]
}

export const emptyTrigger: SmartPlugTimeTrigger = {
  Minute: '00',
  Hour: '00',
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
  On: true,
}

const TimeTriggerList = ({ timeTriggers: initialTriggers }: Props) => {
  const [isDirty, setIsDirty] = useState(false)
  const [triggers, setTriggers] = useState(initialTriggers)
  const [triggerIndex, setTriggerIndex] = useState<undefined | number>(undefined)
  const [editingTrigger, setEditingTrigger] = useState<undefined | SmartPlugTimeTrigger>(undefined)
  const { t } = useI18n()
  const { panelId, smartplugSerial: serial } = useParams<{
    panelId: string
    smartplugSerial: string
  }>()
  const { run: saveTriggers, isLoading: loadingSave } = useSetPlugTimeTrigger()

  const editTrigger = (trigger: SmartPlugTimeTrigger, index: number) => {
    setEditingTrigger(trigger)
    setTriggerIndex(index)
  }
  const editNewTrigger = () => {
    setEditingTrigger(emptyTrigger)
  }
  const addNewTrigger = (newTrigger: SmartPlugTimeTrigger) => {
    setIsDirty(true)
    if (triggerIndex === undefined) {
      setTriggers([...triggers, newTrigger])
    } else {
      const updatedTriggers = Array.from(triggers)
      updatedTriggers[triggerIndex] = newTrigger
      setTriggers(updatedTriggers)
    }
    setEditingTrigger(undefined)
    setTriggerIndex(undefined)
  }
  const deleteTrigger = (deleteIndex: number) => {
    setIsDirty(true)
    setTriggers(triggers.filter((_, index) => index !== deleteIndex))
  }
  const submit = () => {
    saveTriggers({
      triggers,
      panelId,
      serial,
    })
    setIsDirty(false)
  }
  return (
    <div>
      <h1 className="text-2xl">{t('SET_TIMERS')}</h1>
      <ul className="pl-0">
        {triggers.map((trigger, index) => (
          <TimeTriggerListItem
            key={index}
            trigger={trigger}
            onDelete={() => deleteTrigger(index)}
            onClick={() => editTrigger(trigger, index)}
          />
        ))}
      </ul>
      <div className="flex justify-end flex-wrap">
        <Button onClick={editNewTrigger} buttonType="primary">
          {t('Add timer')}
        </Button>
        <Button disabled={!isDirty || loadingSave} onClick={submit} buttonType="primary">
          {t('Save')}
          {loadingSave && <Loader className="ml-2 mb-1" />}
        </Button>
      </div>
      {editingTrigger && (
        <TimeTriggerForm
          onClose={() => setEditingTrigger(undefined)}
          onSubmit={addNewTrigger}
          trigger={editingTrigger}
        />
      )}
    </div>
  )
}

export default TimeTriggerList
