import React from 'react'
import BackgroundImagePage from '../components/pages/BackgroundImagePage'
import useI18n from '../i18n/useI18n'

const UserBlockedPage = () => {
  const { t } = useI18n()
  return (
    <BackgroundImagePage>
      <h1 className="text-4xl">{t('USER_BLOCKED_ERROR')}</h1>
    </BackgroundImagePage>
  )
}

export default UserBlockedPage
