import Icon from '../../../../components/Crow/Icon/Icon'
import { colors } from '../../../../components/Crow/Style/theme'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { tFunc } from '../../../../types/i18n'
import { PanelEvent } from '../../../../types/PanelEvent'
import EmptyOrErrorEvents from '../EmptyEvents'
import { IconContainer } from '../HistoryStyle'

export const EventIcon = ({ eventType }: { eventType: string }) => {
  switch (eventType) {
    case 'armed':
      return (
        <IconContainer className="w-10 h-10" color={colors.black900}>
          <Icon name="Armed" color={colors.white} />
        </IconContainer>
      )
    case 'armedannex':
      return (
        <IconContainer className="w-10 h-10" color={colors.black900}>
          <Icon name="Armed" color={colors.white} />
        </IconContainer>
      )
    case 'partialarmed':
      return (
        <IconContainer className="w-10 h-10" color={colors.white} border={colors.black800}>
          <Icon name="Partially-armed" color={colors.black800} />
        </IconContainer>
      )
    case 'disarmed':
      return (
        <IconContainer className="w-10 h-10" color={colors.lightGray} border={colors.lightGray}>
          <Icon name="Disarmed" color={colors.black800} />
        </IconContainer>
      )
    case 'disarmedannex':
      return (
        <IconContainer className="w-10 h-10" color={colors.lightGray} border={colors.lightGray}>
          <Icon name="Disarmed" color={colors.black800} />
        </IconContainer>
      )
    case 'lock':
    case 'armed_and_lock':
      return (
        <IconContainer className="w-10 h-10" border={colors.blue}>
          <Icon name="Door-locked" color={colors.blue} />
        </IconContainer>
      )
    case 'unlock':
    case 'disarmed_and_unlock':
      return (
        <IconContainer className="w-10 h-10" border={colors.black800}>
          <Icon name="Door-unlocked" color={colors.black800} />
        </IconContainer>
      )
    case 'lock_failed':
      return (
        <IconContainer className="w-10 h-10" border={colors.red}>
          <Icon name="Door-lock-failed" color={colors.red} />
        </IconContainer>
      )
    case 'mains':
      return (
        <IconContainer className="w-10 h-10" color={colors.red} border={colors.red}>
          <Icon name="Power-off" color={colors.white} />
        </IconContainer>
      )
    case 'mains_restored':
      return (
        <IconContainer className="w-10 h-10" color={colors.green} border={colors.lightGreen}>
          <Icon name="Power-on" color={colors.white} />
        </IconContainer>
      )
    case 'Area_Disarmed_By_Doorlock_Log':
      return (
        <IconContainer className="w-6 h-6" border={colors.black800}>
          <Icon name="Door-unlocked" color={colors.black800} />
        </IconContainer>
      )
    case 'Area_Armed_By_Doorlock_Log':
    case 'Area_Stay_Armed_By_Doorlock_Log':
      return (
        <IconContainer className="w-6 h-6" border={colors.blue}>
          <Icon name="Door-locked" color={colors.blue} />
        </IconContainer>
      )
    case 'internet_lost':
      return (
        <IconContainer className="w-10 h-10" color={colors.white} border={colors.red}>
          <Icon name="Wifi-off" color={colors.red} />
        </IconContainer>
      )
    case 'internet_restored':
      return (
        <IconContainer className="w-10 h-10" color={colors.white} border={colors.green}>
          <Icon name="Wifi-on" color={colors.green} />
        </IconContainer>
      )
    case 'output_activated':
      return (
        <IconContainer
          style={{ width: '40px', height: '40px', padding: 0 }}
          color={colors.white}
          border={colors.blue}
        >
          <Icon name="Sign-out" color={colors.blue} />
        </IconContainer>
      )
    case 'output_deactivated':
      return (
        <IconContainer
          style={{ width: '40px', height: '40px', padding: 0 }}
          border={colors.black900}
        >
          <Icon name="Sign-out" color={colors.black900} />
        </IconContainer>
      )
    default:
      return (
        <IconContainer className="w-10 h-10" style={{ padding: 0 }} border={colors.black800}>
          <Icon name="House" />
        </IconContainer>
      )
  }
}

export const getEventText = (event: PanelEvent, t: (text: TranslationKey) => string) => {
  if (
    [
      'armed',
      'disarmed',
      'partialarmed',
      'armedannex',
      'disarmedannex',
      'Area_Armed_By_Doorlock_Log',
      'Area_Stay_Armed_By_Doorlock_Log',
      'Area_Disarmed_By_Doorlock_Log',
      'mains_restored',
      'mains',
    ].includes(event.EventType)
  ) {
    switch (event.User) {
      case 'guard_user':
        return `${t('by')} ${t('guard_user')}`
      case 'installer_user':
        return `${t('by')} ${t('installer_user')}`
    }

    const userName = event.UserTranslationKey ? t(event.UserTranslationKey) : event.User

    switch (event.EventType) {
      case event.User:
        return `${t('by')} ${userName}`
      case 'mains_restored':
        return `${t('mains_restored')}`
      case 'mains':
        return `${t('mains')}`
      case 'armed':
        return `${t('Area_Armed_By_Doorlock_Log')} ${userName}`
      case 'disarmed':
        return `${t('Area_Disarmed_By_Doorlock_Log')} ${userName}`
      case 'partialarmed':
        return `${t('Area_Stay_Armed_By_Doorlock_Log')} ${userName}`
      case 'armedannex':
        return `${t('by')} ${userName}`
    }
  }
}

export const getEventTitle = (event: PanelEvent, t: tFunc): string => {
  const { EventType, LockName } = event
  switch (EventType) {
    case 'mains_restored':
      return t('regain power')
    case 'mains':
      return t('lost power')
    case 'disarmed':
      return `${t('disarmed')}`
    case 'disarmed_and_unlock':
      return `${t('history_event_disarmed_and_unlock', { LockName })} `
    case 'armed':
      return `${t('armed')}`
    case 'armed_and_lock':
      return `${t('history_event_armed_and_lock', { LockName })} `
    case 'partialarmed':
      return `${t('partialarmed')}`
    case 'armedannex':
      return `Annex ${t('Fully armed')}`
    case 'disarmedannex':
      return `Annex ${t('disarmed')}`
    case 'lock':
      return `${LockName} ${t('locked')}`
    case 'unlock':
      return `${LockName} ${t('unlocked')}`
    case 'lock_failed':
      return `${LockName} ${t('lock_failed')}`
    case 'internet_restored':
      return t('internet_restored')
    case 'internet_lost':
      return t('internet_lost')
    case 'output_activated':
      return `${LockName} ${t('On')}`
    case 'output_deactivated':
      return `${LockName} ${t('Off')}`
    case 'Area_Armed_By_Doorlock_Log':
      return t('Area_Armed_By_Doorlock_Log')
    case 'Area_Disarmed_By_Doorlock_Log':
      return t('Area_Disarmed_By_Doorlock_Log')
    case 'Area_Stay_Armed_By_Doorlock_Log':
      return t('Area_Stay_Armed_By_Doorlock_Log')
    case 'battery_low':
    case 'battery_restored':
      return `${LockName} ${t(EventType as TranslationKey)}`
    default:
      return ''
    // default:
    //   return t(EventType)
  }
}

interface EventsHasErrorOrEmptyType {
  panelEvents: [string, PanelEvent[]][] | undefined
  t: (x: any) => TranslationKey
  panelEventsError: string
}

export const EventsHasErrorOrEmpty = ({
  panelEvents,
  t,
  panelEventsError,
}: EventsHasErrorOrEmptyType) => {
  if (!!panelEventsError) {
    return (
      <EmptyOrErrorEvents
        icon="Error"
        iconColor={colors.red}
        title={t('Something went wrong. Please try again')}
      />
    )
  } else if (panelEvents?.length === 0) {
    return (
      <EmptyOrErrorEvents
        icon="History"
        iconColor={colors.blue}
        title={t('Nothing here... yet!')}
      />
    )
  }
  return null
}
