import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
  getCountryCallingCode,
  getCountries,
  CountryCode,
} from 'libphonenumber-js'

export const formatPhoneNumber = formatIncompletePhoneNumber
export const parsePhoneNumber = parseIncompletePhoneNumber

export const getPanelCountryCode = (panelId: string): CountryCode => {
  panelId.slice(0, 2)
  switch (panelId.slice(0, 2)) {
    case '01':
      return 'NO'
    case '02':
      return 'SE'
    case '04':
      return 'FI'
    case '05':
      return 'IE'
    case '06':
      return 'ES'
    case '07':
      return 'FR'
    case '08':
      return 'IT'
    default:
      return 'NO'
  }
}

export const getPhoneNumberPrefix = (countryCode: CountryCode): string =>
  '+' + getCountryCallingCode(countryCode)

export type PrefixOption = {
  value: CountryCode
  label: string
}

export const getPrefixOptions = (cultureInfo: string): PrefixOption[] => {
  const localizedRegionNames = new Intl.DisplayNames(cultureInfo, { type: 'region' })
  const countryCodes: CountryCode[] = getCountries()

  return countryCodes.map((c: CountryCode) => ({
    value: c,
    label: `${localizedRegionNames.of(c)} ${getPhoneNumberPrefix(c)}`,
  }))
}

export const getDefaultSelectedPrefixOption = (
  options: PrefixOption[],
  defaultCountryCode: CountryCode,
) => options.find((o) => o.value === defaultCountryCode) || { value: 'NO', label: 'Norway +47' }
