import { ContactPerson } from '../types/ContactPerson'
import { NotificationSetting } from '../types/NotificationSetting'
import { InviteUserPayload, UnInviteUserPayload } from '../types/UserInvite'
import { TimeSlot } from '../types/TimeSlot'
import { AppUser } from '../types/AppUser'
import { add } from 'date-fns'
import { UserType } from '../pages/AlarmSystems/Settings/PanelUsers/constants/panelUserTypes'
import { LoadDirectionsPayload, SaveDirectionsPayload } from '../types/Directions'
import createHTTPClient from './createHTTPClient'
import { LockSettingsPayload, SoundLevel } from '../types/Lock'
import { SmartPlugScenarioTrigger, SmartPlugTimeTrigger } from '../types/SmartPlug'
import { PanelUserChangeOperation } from '../types/UserDetails'
import { panelInfo } from './apiPanel'

const HTTP = createHTTPClient('/settings')

export const getAvailableTimeSlots = (body: { panelId: string }) => {
  const minDate = add(new Date(), { days: 1 }).toISOString()
  return HTTP.post<TimeSlot[]>('/GetInstallationTimeSlots', { ...body, minDate })
}

export const setTimeSlot = async ({ offer, panelId }: { offer: TimeSlot; panelId: string }) => {
  await HTTP.post(`/BookInstallation?panelId=${panelId}`, offer)
}

export const setPreInstallationWizardDone = async (body: { panelId: string }) => {
  await HTTP.post('/SetPreInstallationWizardDone', body)
}

export const getAppUsers = (panelId: string) => {
  return HTTP.get<AppUser[]>(`/GetAppUsers?panelId=${panelId}`)
}

export const getCodeWordStatus = async (ValidationCode: string, PanelId: string) => {
  const response = await HTTP.post<{ IsSet: boolean }>('/GetCodewordStatus', {
    ValidationCode,
    PanelId,
  })
  return {
    hasCodeWord: response.IsSet,
  }
}

export const setSecurityQuestion = async (body: {
  PanelId: string
  alarmCenterCodeword: string
  customerCodeword: string
  Password?: string
  validationCode?: string
}) => {
  return HTTP.post('/SetSecurityQuestion', body)
}

export const getContactPersons = async ({
  PanelId,
  ValidationCode,
}: {
  PanelId: string
  ValidationCode?: string
}) => {
  const response = await HTTP.post<{ Contacts: ContactPerson[] }>('/GetContactPersons', {
    ValidationCode,
    PanelId,
  })
  return response.Contacts
}

export const setContactPersons = async ({
  ContactPersons,
  PanelId,
  ValidationCode,
  Password,
}: {
  ContactPersons: ContactPerson[]
  PanelId: string
  ValidationCode?: string
  Password?: string
}) => {
  await HTTP.post<{ Contacts: ContactPerson[] }>('/SetContactPersons', {
    ContactPersons,
    ValidationCode,
    Password,
    PanelId,
  })
  return ContactPersons
}

export const getNotificationSettings = (PanelId: string) =>
  HTTP.post<NotificationSetting[]>('/GetNotificationSettings', { PanelId })

export const toggleNotifcationSetting = async (body: {
  Channel: string
  Enabled: boolean
  EventType: string
  PanelId: string
}) => {
  await HTTP.post<''>('/ToggleNotificationSetting', body)
  return {
    Enabled: body.Enabled,
    Channel: body.Channel,
    EventType: body.EventType,
  }
}

export const inviteAppUser = async (payload: InviteUserPayload) => {
  await HTTP.post('/InviteUser', payload)
}

export const unInviteAppUser = async (payload: UnInviteUserPayload) => {
  await HTTP.post('/UnInviteUser', payload)
}

export const getPropertyContacts = async (payload: { panelId: string }) => {
  return HTTP.get<AppUser[]>(`/GetPropertyContacts?panelId=${payload.panelId}`)
}

export const setPropertyContact = async (payload: { panelId: string; AppUserId: string }) => {
  await HTTP.post('/SetPropertyContact', payload)
}

export const invitePropertyContact = async (payload: {
  PanelId: string
  FirstName: string
  LastName: string
  PhoneNumber: string
}) => {
  await HTTP.post('/InvitePropertyContact', payload)
}

export const getPanelUsers = async (panelId: string) =>
  HTTP.post(`/GetPanelUsers`, { PanelId: panelId })

export interface SavePanelUserPayload {
  PanelCode?: string
  Name: string
  Password: string
  Index?: number
  UserType: UserType
  PanelId: string
  ValidationCode: string
}

interface TempUser extends SavePanelUserPayload {
  UserType: UserType.TWENTY_FOUR_HOUR_USER
}

const addPanelUser = (payload: SavePanelUserPayload) => {
  return HTTP.post(`/AddPanelUser`, payload)
}

interface PanelSettingsPayload {
  PanelId: string
  DisplayName: string
  QuickArm: boolean
  Password: string
}
export const setPanelSettings = async (payload: PanelSettingsPayload) => {
  await HTTP.post('/SetPanelSettings', payload)
  return payload
}

const savePanelUserCode = (payload: SavePanelUserPayload) => {
  return HTTP.post(`/EditPanelUserPanelCode`, payload)
}

const savePanelUserName = (payload: SavePanelUserPayload) => {
  return HTTP.post(`/EditPanelUserName`, payload)
}

const deletePanelUser = (payload: SavePanelUserPayload) => {
  return HTTP.post(`/DeletePanelUser`, payload)
}

const deactivatePanelUser = (payload: TempUser) => {
  return HTTP.post(`/DeactivatePanelUser`, payload)
}

const activatePanelUser = (payload: TempUser) => {
  return HTTP.post(`/AddPanelUser`, payload)
}

export const getLocationDetails = (payload: LoadDirectionsPayload) => {
  return HTTP.post('/GetLocationDetails', payload)
}

export const setLocationDetails = (payload: SaveDirectionsPayload) => {
  return HTTP.post('/SetLocationDetails', payload)
}

export const panelUserChange = async ({
  payload,
  operation,
}: {
  payload: SavePanelUserPayload
  operation: PanelUserChangeOperation
}) => {
  if (operation === PanelUserChangeOperation.ADD) {
    return addPanelUser(payload)
  }
  if (operation === PanelUserChangeOperation.DELETE) {
    return deletePanelUser(payload)
  }
  if (
    operation === PanelUserChangeOperation.DEACTIVATE &&
    payload.UserType === UserType.TWENTY_FOUR_HOUR_USER
  ) {
    return deactivatePanelUser(payload as TempUser)
  }
  if (
    operation === PanelUserChangeOperation.ACTIVATE &&
    payload.UserType === UserType.TWENTY_FOUR_HOUR_USER
  ) {
    return activatePanelUser(payload as TempUser)
  }
  if (operation === PanelUserChangeOperation.CHANGE_NAME) {
    return savePanelUserName(payload)
  }
  if (operation === PanelUserChangeOperation.CHANGE_CODE) {
    return savePanelUserCode(payload)
  }
}

export const getLockLanguage = async (payload: { ValidationCode: string; PanelId: string }) => {
  return HTTP.post(`/GetLockLanguage`, payload)
}

export const setLockSettingsCrow = async (payload: LockSettingsPayload) => {
  let response = HTTP.post('/setLockSettings', payload)
  return await response.then(() => {
    return payload
  })
}

export const setLockSettings = async (payload: {
  AutoLock?: boolean
  Language?: string
  Password: string
  Label: string
  SoundLevel?: SoundLevel
  Serial?: string
  PanelId?: string
  ValidationCode?: string
}) => await HTTP.post(`/SetLockSettings`, payload)

export const changeLockName = async (payload: {
  AutoLock: boolean
  Language: string
  Password: string
  Label: string
  SoundLevel: SoundLevel
  Serial: string
  PanelId: string
  ValidationCode?: string
}) =>
  await HTTP.post(`/SetLockSettings`, payload).then(() => {
    return panelInfo(payload.PanelId)
  })

export const setSmartplugTimeTriggers = ({
  panelId,
  serial,
  triggers,
}: {
  panelId: string
  serial: string
  triggers: SmartPlugTimeTrigger[]
}) => HTTP.post(`/SetSmartplugDetails?panelId=${panelId}&serial=${serial}`, triggers)

export const setSmartplugScenarioTrigger = (payload: SmartPlugScenarioTrigger) =>
  HTTP.post(`/SetSmartplugScenarios`, payload)

export const getSmartPlug = ({ panelId, serial }: { panelId: string; serial: string }) =>
  HTTP.get(`/GetSmartplug?panelId=${panelId}&serial=${serial}`)

interface AddDevicePayload {
  PanelId: string
  ValidationCode: string
}

export const addDeviceStart = (payload: AddDevicePayload): Promise<string> =>
  HTTP.post(`/StartDeviceRegistration`, payload)

export const addDeviceContinue = async (payload: {
  PanelId: string
  Label: string
}): Promise<string> => HTTP.post(`/ContinueDeviceRegistration`, payload)

export const addDeviceFinish = (payload: AddDevicePayload): Promise<void> =>
  HTTP.post(`/FinishDeviceRegistration`, payload)

export const setSmartPlugLabel = (payload: { Label: string; Serial: string; PanelId: string }) =>
  HTTP.post(`/SetSmartPlugLabel`, payload)

export const setArcVideoConsent = async (payload: {
  Consent: boolean
  PanelId: string
  ValidationCode: string
}) => {
  await HTTP.post(`/SetArcVideoConsent`, payload)
  return payload
}
