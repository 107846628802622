import { useState } from 'react'
import styled from 'styled-components'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'

const GeneralSettings = () => {
  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'top' })

  const openDrawer = (position: 'top' | 'bottom' | 'left' | 'right') => () => {
    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }
  return (
    <div className="ml-auto">
      <button onClick={openDrawer('right')}>
        <Icon name="Settings" />
      </button>
      <S.Drawer
        isOpen={drawerState.isOpen}
        position={drawerState.position}
        handleClose={closeDrawer}
        closeButton={true}
        title={
          <S.Header>
            <S.CloseButton onClick={closeDrawer}>
              <Icon name="Cancel" />
            </S.CloseButton>
          </S.Header>
        }
      ></S.Drawer>
    </div>
  )
}

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    padding-left: 31px;
    padding-top: 40px;
    padding-right: 72px;
    width: 636px;
  `,
  Header: styled.div`
    padding-bottom: 50px;
    display: flex;
  `,
  CloseButton: styled.button`
    margin-right: 28px;
    color: ${colors.blue};
  `,
}

export default GeneralSettings
