import useI18n from '../../i18n/useI18n'

interface RoomNameType {
  roomName: string
}
const RoomName = ({ roomName }: RoomNameType) => {
  const { t } = useI18n()

  switch (roomName) {
    case 'HALLWAY_PLACE':
      return t('HALLWAY_PLACE')
    case 'KITCHEN_PLACE':
      return t('KITCHEN_PLACE')
    case 'ENTRANCE_PLACE':
      return t('ENTRANCE_PLACE')
    case 'DINING_ROOM_PLACE':
      return t('DINING_ROOM_PLACE')
    case 'MASTER_BEDROOM_PLACE':
      return t('MASTER_BEDROOM_PLACE')
    case 'BEDROOM_PLACE':
      return t('BEDROOM_PLACE')
    case 'GARAGE_PLACE':
      return t('GARAGE_PLACE')
    case 'KIDS_ROOM_PLACE':
      return t('KIDS_ROOM_PLACE')
    case 'STAIRS_PLACE':
      return t('STAIRS_PLACE')
    case 'CELLAR_PLACE':
      return t('CELLAR_PLACE')
    case 'BASEMENT_PLACE':
      return t('BASEMENT_PLACE')
    case 'TECHNICAL_ROOM_PLACE':
      return t('TECHNICAL_ROOM_PLACE')
    case 'WINE_CELLAR_PLACE':
      return t('WINE_CELLAR_PLACE')
    case 'LIVING_ROOM_PLACE':
      return t('LIVING_ROOM_PLACE')
    case 'GUEST_BEDROOM_PLACE':
      return t('GUEST_BEDROOM_PLACE')
    case 'TV_ROOM_PLACE':
      return t('TV_ROOM_PLACE')
    case 'OFFICE_PLACE':
      return t('OFFICE_PLACE')
    case 'BATHROOM_PLACE':
      return t('BATHROOM_PLACE')
    case 'TOILET_PLACE':
      return t('TOILET_PLACE')
    case 'BAR_PLACE':
      return t('BAR_PLACE')
    case 'STORAGE_ROOM_PLACE':
      return t('STORAGE_ROOM_PLACE')
    case 'HOME_THEATER_PLACE':
      return t('HOME_THEATER_PLACE')
    case 'LIBRARY_PLACE':
      return t('LIBRARY_PLACE')
    case 'GYM_PLACE':
      return t('GYM_PLACE')
    case 'LAUNDRY_ROOM_PLACE':
      return t('LAUNDRY_ROOM_PLACE')
    case 'PLAY_ROOM_PLACE':
      return t('PLAY_ROOM_PLACE')
    case 'ATTIC_PLACE':
      return t('ATTIC_PLACE')
    case 'POOL_PLACE':
      return t('POOL_PLACE')
    case 'DRESSING_ROOM_PLACE':
      return t('DRESSING_ROOM_PLACE')
    case 'FOOD_STORAGE_PLACE':
      return t('FOOD_STORAGE_PLACE')
    case 'BOILER_HOUSE_PLACE':
      return t('BOILER_HOUSE_PLACE')
    case 'GUN_SAFE_PLACE':
      return t('GUN_SAFE_PLACE')
    case 'CRAFTS_ROOM_PLACE':
      return t('CRAFTS_ROOM_PLACE')
    case 'WALK_IN_CLOSET_PLACE':
      return t('WALK_IN_CLOSET_PLACE')
    case 'BIKE_SHED_PLACE':
      return t('BIKE_SHED_PLACE')
    case 'GUEST_HOUSE_PLACE':
      return t('GUEST_HOUSE_PLACE')
    case 'RENTAL_PLACE':
      return t('RENTAL_PLACE')
    case 'UNKNOWN':
      return t('Unknown')
    default:
      return t('Unknown')
  }
}

export default RoomName
