import { useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Crow/Button/Button'
import DropDownList from '../../../components/Crow/DropDown/DropDownList'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { Hr, Paragraph } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../i18n/useI18n'
import { useSetLockSettingsCrow } from '../../../services/requestHooks/locks'
import { usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { Lock, SoundLevel } from '../../../types/Lock'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../AlarmSystems/Panel/PasswordPromt'
import LocksListItem from './LocksListItem'
import { LockListItemContainer, LockSettingsBtnContainer, StyledSubtitle } from './LockStyle'

interface LockSettingsType {
  lock: {
    Label: string
    PanelId: string
    Serial: string
    AutoLockEnabled?: boolean
    Status?: 'lock' | 'unlock'
    SoundLevel?: SoundLevel | null
    LastChanged?: string | number
  }
}

const LockSettings = (lock: LockSettingsType) => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const [lockState, setLockState] = useState<Lock>()
  const [isAutoLocked, setIsAutoLocked] = useState<boolean>(lock.lock.AutoLockEnabled!)

  const [soundLevelState, setSoundLevelState] = useState<SoundLevel>(lock.lock.SoundLevel!)

  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()

  const { run: setLockSettingsCrow, isLoading: loadingLockSettings } = useSetLockSettingsCrow({
    onSuccess: () => {
      return window.location.reload()
    },
  })

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
  } = usePanelStatus()

  useOnMount(() => {
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentValue = parseInt(e.target.value)

    switch (currentValue) {
      case SoundLevel.Disabled:
        setSoundLevelState(SoundLevel.Disabled)
        setLockState({
          Label: lock?.lock.Label!,
          PanelId: lock?.lock.PanelId!,
          Serial: lock?.lock.Serial!,
          SoundLevel: SoundLevel.Disabled,
        })
        break
      case SoundLevel.Low:
        setSoundLevelState(SoundLevel.Low)
        setLockState({
          Label: lock?.lock.Label!,
          PanelId: lock?.lock.PanelId!,
          Serial: lock?.lock.Serial!,
          SoundLevel: SoundLevel.Low,
        })
        break
      case SoundLevel.High:
        setSoundLevelState(SoundLevel.High)
        setLockState({
          Label: lock?.lock.Label!,
          PanelId: lock?.lock.PanelId!,
          Serial: lock?.lock.Serial!,
          SoundLevel: SoundLevel.High,
        })
        break
    }
  }

  const handleSubmit = async () => {
    if (lockState?.Serial !== null) {
      const ValidationCode = await promptForTwoFactor()
      if (ValidationCode) {
        const Password = await promptForPassword()
        if (Password) {
          setLockSettingsCrow({
            Label: lock?.lock.Label!,
            PanelId: lock?.lock.PanelId!,
            Serial: lock?.lock.Serial!,
            SoundLevel: soundLevelState,
            AutoLock: isAutoLocked,
            Language: '',
            Password,
            ValidationCode,
          })
        }
      }
    }
  }

  return (
    <>
      <LockListItemContainer>
        <Hr className="mt-16" />
        <StyledSubtitle pt={34} pb={18}>
          {t('Automatic lock')}
        </StyledSubtitle>
        <StyledLockListItem
          label={<Paragraph large>{t('lock close door')}</Paragraph>}
          secondaryLabel={<Paragraph grey>{t('auto lock')}</Paragraph>}
          toggleItem={
            <StyledToggle
              enabled={isAutoLocked}
              onClick={() => {
                setIsAutoLocked(!isAutoLocked)
              }}
              disabled={panelStatus?.Status === 1 ? false : true}
            />
          }
        />
        <StyledSubtitle pt={34} pb={10}>
          {t('sound settings')}
        </StyledSubtitle>
        <StyledDropDownList
          defaultValue={soundLevelState!}
          onChange={handleOnChange}
          label={`${t('lock')} ${t('sound volume')}`}
          disabled={panelStatus?.Status === 1 ? false : true}
        >
          <option value={SoundLevel.Disabled}>{t('Off')}</option>
          <option value={SoundLevel.Low}>{t('Low')}</option>
          <option value={SoundLevel.High}>{t('High')}</option>
        </StyledDropDownList>
        <LockSettingsBtnContainer>
          <LoadingOverlay isLoading={loadingLockSettings}>
            <Button
              level="secondary"
              disabled={panelStatus?.Status === 1 ? false : true}
              onClick={handleSubmit}
              size="m"
              variant="default"
            >
              {t('Save')}
            </Button>
          </LoadingOverlay>
        </LockSettingsBtnContainer>
      </LockListItemContainer>
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </>
  )
}

const StyledToggle = styled(Toggle)`
  @media (max-width: 320px) {
    margin-top: 10px;
  }
  @media (min-width: 425px) {
    margin-left: 10px;
  }
`

const StyledLockListItem = styled(LocksListItem)`
  @media (max-width: 375px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const StyledDropDownList = styled(DropDownList)`
  display: flex;
  flex-direction: column;
`

export default LockSettings
