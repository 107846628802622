import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import FullscreenPage from '../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../components/TcOrCrowContainer'
import useI18n from '../../i18n/useI18n'
import { useLoadMessages } from '../../services/requestHooks/messages'
import { usePanelInfo } from '../../services/requestHooks/panelInfo'
import { Message } from '../../types/Messages'
import { useOnMount } from '../../utils/commonHooks'
import { usePanelId } from '../AlarmSystems/hooks/usePanelId'
import FailedRequest from '../onboardingWizard/components/FailedRequest'
import MessagePage from './MessagePage'

const NoMessagesText = styled.div`
  font-size: 20px;
  text-align: center;
`

const Page = ({ hideNavbar }: { hideNavbar?: boolean }) => {
  const { run: getMessages, data: messages, isLoading, error } = useLoadMessages()
  useOnMount(() => {
    getMessages()
  })

  const { t } = useI18n()

  return (
    <MessagePage isLoading={isLoading} hideNavbar={hideNavbar}>
      {messages?.length === 0 && <NoMessagesText>{t('INBOX_EMPTY')}</NoMessagesText>}
      {error && <FailedRequest text={error} />}
      {(messages || []).map((message) => (
        <MessageListItem key={message.Id} message={message} />
      ))}
    </MessagePage>
  )
}

const MessageListPage = () => <Page />

interface MessageListItemProps {
  message: Message
}

const MessageListItem = ({ message }: MessageListItemProps) => {
  const { t } = useI18n()
  const date = new Date(message.CreatedDate)

  return (
    <Link to={`/messages/${message.Id}`} className="no-underline">
      <div className="flex fade-in status-list__item status-list__divSelector justify-between bg-white">
        <div className="flex flex-grow justify-between items-center px-4">
          <div className="flex flex-col">
            <span>{message.Subject}</span>
            <span>{format(date, 'PPpp')}</span>
          </div>
          <div>
            {!message.IsRead && <span className="badge primary">{t('NOTIFICATIONS')}</span>}
          </div>
        </div>
        <div className="status-list__toggle mr-4">
          <div className="status-list__arrow status-list__arrow--forward" />
        </div>
      </div>
    </Link>
  )
}

export const MessageListPageWithPanelId = () => {
  const panelId = usePanelId()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
  })

  const { t } = useI18n()
  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <FullscreenPage
        isLoading={loadingPanelInfo}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: !loadingPanelInfo && !!loadInfoError,
            errorHeaderText: t('messages'),
            errorText: t('Something went wrong. Please try again'),
            returnUrl: `/systems/${panelId}`,
          },
        }}
      >
        <Page hideNavbar />
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

export default MessageListPage
