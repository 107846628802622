import { ComponentType } from 'react'
import { IconName } from './components/Crow/Icon/Icon'
import {
  DoorAndWindowPage,
  LocksPage,
  PanelPage,
  PeoplePage,
  PhotoPage,
  SmartPlugsPage,
  SmokeDetectorsPage,
  TemperaturesPage,
  VideoPage,
} from './PageComponents'
import AccountPage from './pages/account/AccountPage'
import ChangePaswordPage from './pages/account/ChangePasswordPage'
import CrowAccountPage, { CrowAccountPageWithPanelId } from './pages/account/CrowAccountPage'
import ActivatePanelPage from './pages/AlarmSystems/ActivatePanelPage'
import HistoryScreen from './pages/AlarmSystems/History/HistoryScreen'
import OrderPage from './pages/AlarmSystems/Panel/Order/OrderPage'
import TriggerPicturePage from './pages/AlarmSystems/Panel/Picture/TriggerPicturePage'
import PanelListPage from './pages/AlarmSystems/PanelListPage'
import AddSmartPlugPage from './pages/AlarmSystems/Settings/AddSmartplug/AddSmartplugPage'
import AppUsersScreen from './pages/AlarmSystems/Settings/AppUsers/AppUserPage'
import ConsentPage from './pages/AlarmSystems/Settings/Consent/ConsentPage'
import ContactsPage from './pages/AlarmSystems/Settings/Contacts/ContactsPage'
import DirectionsScreen from './pages/AlarmSystems/Settings/Directions/DirectionsPage'
import LockLabelForm from './pages/AlarmSystems/Settings/Locks/LockLabelForm'
import LocksScreenSettings from './pages/AlarmSystems/Settings/Locks/LocksPage'
import NotificationPage from './pages/AlarmSystems/Settings/Notifications/NotificationsPage'
import PanelUsersScreen from './pages/AlarmSystems/Settings/PanelUsers/PanelUsersPage'
import SecurityQuestionPage from './pages/AlarmSystems/Settings/SecurityQuestion/SecurityQuestionPage'
import SettingsScreen from './pages/AlarmSystems/Settings/SettingsPage'
import PlugNameForm from './pages/AlarmSystems/Settings/SmartPlugsSettings/PlugNameForm'
import SmartPlugSettingsPage from './pages/AlarmSystems/Settings/SmartPlugsSettings/SmartPlugSettingsPage'
import SystemSettingsScreen from './pages/AlarmSystems/Settings/SystemSettings/SystemSettingsPage'
import WifiPage from './pages/AlarmSystems/Settings/Wifi/WifiPage'
import WizardPage from './pages/AlarmSystems/Settings/Wizard/WizardPage'
import ChallengeAccountPage from './pages/ChallengeAccountPage'
import Logout from './pages/crow/Logout'
import InvoicesPage from './pages/invoices/InvoicesPage'
import ForgotPassword from './pages/login/ForgotPassword'
import LoginScreen from './pages/login/LoginScreen'
import MainPage from './pages/Main/MainPage'
import MessageDetailPage, {
  MessageDetailsPageWithPanelId,
} from './pages/messages/MessageDetailPage'
import MessageListPage, { MessageListPageWithPanelId } from './pages/messages/MessageListPage'
import OnboardingWizardPage from './pages/onboardingWizard/OnboardingWizardPage'
import RegistrationScreen from './pages/registration/AccountRegistrationWizard/AccountsRegistrationWizard'
import AcceptAppInvitePage from './pages/registration/AppUserRegistration/AcceptAppInvitePage'
import SimpleAccountRegistrationWizard from './pages/registration/SimpleAccountRegistrationWizard/SimpleAccountRegistrationWizard'
import TermsPage from './pages/TermsPage'
import TermsPagePlainText from './pages/TermsPagePlainText'
import UserBlockedPage from './pages/UserBlockedPage'
import VersionPage from './pages/VersionPage'

type RouteProps = {
  path: string
  component?: ComponentType
  exact?: boolean
  icon?: IconName
  name?: string
}

export type RouteEntry = RouteProps & {
  children?: RouteEntry[]
}

export type TwoFactorRouteEntry = RouteProps & {
  cancelUrl: string
  children?: TwoFactorRouteEntry[]
}

export const routes: {
  unauthenticatedRoutes: Array<RouteEntry>
  authenticatedRoutes: Array<RouteEntry>
  twoFactorRoutes: Array<TwoFactorRouteEntry>
} = {
  unauthenticatedRoutes: [
    { path: '/', exact: true, component: MainPage },
    { path: '/login', component: LoginScreen },
    { path: '/user/terms/:nation/:culture', component: TermsPage },
    { path: '/user/termstext/:nation/:culture', component: TermsPagePlainText },
    { path: '/user/registration', component: RegistrationScreen },
    { path: '/registration/CreateCustomer', component: SimpleAccountRegistrationWizard },
    { path: '/user/ChangePassword', component: ForgotPassword },
    { path: '/user/AcceptInvite', component: AcceptAppInvitePage },
    { path: '/user/ChallengeAccountMail', component: ChallengeAccountPage },
    { path: '/blocked', exact: true, component: UserBlockedPage },
    { path: '/version', exact: true, component: VersionPage },
  ],
  authenticatedRoutes: [
    {
      path: '/account',
      exact: true,
      component: AccountPage,
    },
    { path: '/messages', exact: true, component: MessageListPage },
    { path: '/messages/:id', component: MessageDetailPage },
    {
      path: '/invoices',
      exact: true,
      component: InvoicesPage,
    },
    {
      path: '/crowAccountPage',
      exact: true,
      component: CrowAccountPage,
    },
    {
      path: '/systems',
      exact: true,
      component: PanelListPage,
      children: [
        {
          path: '/:panelId',
          exact: true,
          component: PanelPage,
          children: [
            {
              path: '/activate',
              exact: true,
              component: ActivatePanelPage,
            },
            {
              path: '/order',
              exact: true,
              component: OrderPage,
            },
            { path: '/smartplugs', exact: true, component: SmartPlugsPage },
            {
              path: '/smartplugs/:smartplugSerial',
              exact: true,
              component: SmartPlugSettingsPage,
            },
            {
              path: '/smartplugs/:smartplugSerial/editname',
              exact: true,
              component: PlugNameForm,
              cancelUrl: '/systems/:panelId',
            },
            { path: '/locks', exact: true, component: LocksPage },
            { path: '/temperatures', exact: true, component: TemperaturesPage },
            { path: '/people', exact: true, component: PeoplePage },
            { path: '/video', exact: true, component: VideoPage },
            { path: '/picture', exact: true, component: PhotoPage },
            { path: '/doors-and-windows', exact: true, component: DoorAndWindowPage },
            { path: '/smoke-detectors', exact: true, component: SmokeDetectorsPage },
            {
              path: '/picture/:cameraId',
              exact: true,
              component: TriggerPicturePage,
            },
            {
              path: '/history',
              exact: true,
              component: HistoryScreen,
            },
            { path: '/messages', exact: true, component: MessageListPageWithPanelId },
            { path: '/messages/:id', component: MessageDetailsPageWithPanelId },
            {
              path: '/crowAccountPage',
              exact: true,
              component: CrowAccountPageWithPanelId,
            },
            {
              path: '/settings',
              exact: true,
              component: SettingsScreen,
              children: [
                {
                  path: '/system-settings',
                  exact: true,
                  component: SystemSettingsScreen,
                },
                {
                  path: '/notifications',
                  exact: true,
                  component: NotificationPage,
                },
                {
                  path: '/wizard',
                  exact: true,
                  component: WizardPage,
                },
              ],
            },
          ],
        },
      ],
    },
    { path: '/getting-started/:panelId', component: OnboardingWizardPage },
  ],
  twoFactorRoutes: [
    {
      path: '/account/password',
      exact: true,
      cancelUrl: '/account',
      component: ChangePaswordPage,
    },
    {
      path: '/systems/:panelId/settings/consent',
      component: ConsentPage,
      cancelUrl: '/systems/:panelId/settings',
    },
    {
      path: '/systems/:panelId/settings/security-question',
      component: SecurityQuestionPage,
      cancelUrl: '/systems/:panelId/settings',
    },
    {
      path: '/systems/:panelId/settings/panel-users',
      exact: true,
      cancelUrl: '/systems/:panelId/settings',
      component: PanelUsersScreen,
    },
    {
      path: '/systems/:panelId/settings/contacts',
      component: ContactsPage,
      cancelUrl: '/systems/:panelId/settings',
    },
    {
      path: '/systems/:panelId/settings/app-users',
      exact: true,
      cancelUrl: '/systems/:panelId/settings',
      component: AppUsersScreen,
    },
    {
      path: '/systems/:panelId/settings/directions',
      exact: true,
      component: DirectionsScreen,
      cancelUrl: '/systems/:panelId/settings',
    },
    {
      path: '/systems/:panelId/locks/:lockSerial',
      exact: true,
      component: LocksScreenSettings,
      cancelUrl: '/systems/:panelId',
    },
    {
      path: '/systems/:panelId/locks/:lockSerial/editname',
      exact: true,
      component: LockLabelForm,
      cancelUrl: '/systems/:panelId',
    },
    {
      path: '/systems/:panelId/settings/wifi',
      exact: true,
      component: WifiPage,
      cancelUrl: '/systems/:panelId/settings',
    },
    {
      path: '/systems/:panelId/settings/smartplug',
      exact: true,
      component: AddSmartPlugPage,
      cancelUrl: '/systems/:panelId/settings',
    },
  ],
}

type TopLevelRouteNames = 'logout'

// eslint-disable-next-line import/no-unused-modules
export const topLevelRoutes: Record<TopLevelRouteNames, RouteEntry> = {
  logout: {
    path: `/logout`,
    component: Logout,
    icon: 'Settings',
  },
}
