function SvgOffice(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 3C7.55228 3 8 3.44772 8 4V5H9C9.55228 5 10 5.44772 10 6C10 6.55228 9.55228 7 9 7H7C6.44772 7 6 6.55228 6 6V4C6 3.44772 6.44772 3 7 3Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 6C13 9.31371 10.3137 12 7 12C3.68629 12 1 9.31371 1 6C1 2.68629 3.68629 0 7 0C10.3137 0 13 2.68629 13 6ZM11 6C11 8.20914 9.20914 10 7 10C4.79086 10 3 8.20914 3 6C3 3.79086 4.79086 2 7 2C9.20914 2 11 3.79086 11 6Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 22H35C36.1046 22 37 21.1046 37 20V13C37 11.8954 36.1046 11 35 11H24C22.8954 11 22 11.8954 22 13V20C22 21.1046 22.8954 22 24 22H26V24H20V18C20 16.8954 19.1046 16 18 16H8C6.89543 16 6 16.8954 6 18V24H2C0.895431 24 0 24.8954 0 26V28C0 29.1046 0.89543 30 2 30L2 41C2 41.5523 2.44772 42 3 42C3.55229 42 4 41.5523 4 41L4 30H6V34C6 35.1046 6.89543 36 8 36H11.9998V37.0003L11.9954 37.0028L6.49721 40.1322C6.01723 40.4054 5.84959 41.0159 6.12278 41.4959C6.39597 41.9759 7.00654 42.1436 7.48653 41.8704L11.9998 39.3016V41C11.9998 41.5523 12.4475 42 12.9998 42C13.5521 42 13.9998 41.5523 13.9998 41V39.3146L18.4901 41.8704C18.97 42.1436 19.5806 41.9759 19.8538 41.4959C20.127 41.0159 19.9594 40.4054 19.4794 40.1322L13.9998 37.0134V36H18C19.1046 36 20 35.1046 20 34V30H37V41C37 41.5523 37.4477 42 38 42C38.5523 42 39 41.5523 39 41V30H40C41.1046 30 42 29.1046 42 28V26C42 24.8954 41.1046 24 40 24H33V22ZM20 28H40V26H20V28ZM6 28V26H2L2 28H6ZM35 13H24V20H35V13ZM31 24H28V22H31V24ZM13.9998 30H18V18H8V30H11.9998V25C11.9998 24.4477 12.4475 24 12.9998 24C13.5521 24 13.9998 24.4477 13.9998 25V30ZM8 32V34H18V32H8Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgOffice
