function SvgKitchen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00106 5.06582C6.00036 5.04396 6 5.02202 6 5V2C6 0.89543 6.89543 0 8 0H10C11.1046 0 12 0.895431 12 2V5C12 5.02206 11.9996 5.04404 11.9989 5.06593C12.5676 5.21578 13.0515 5.6121 13.3053 6.16188L15.3448 10.5809C15.6507 11.2436 15.1667 12 14.4369 12H3.5627C2.83291 12 2.34892 11.2436 2.65474 10.5809L4.69431 6.16188C4.94812 5.61197 5.43219 5.21559 6.00106 5.06582ZM8 2H10V5H8V2ZM6.51023 7L5.12561 10H12.874L11.4894 7H6.51023Z"
        fill="#666363"
      />
      <path
        d="M29 10C29 10.5523 29.4477 11 30 11C30.5523 11 31 10.5523 31 10V8C31 7.44772 30.5523 7 30 7C29.4477 7 29 7.44772 29 8V10Z"
        fill="#666363"
      />
      <path
        d="M30 20C29.4477 20 29 19.5523 29 19V17C29 16.4477 29.4477 16 30 16C30.5523 16 31 16.4477 31 17V19C31 19.5523 30.5523 20 30 20Z"
        fill="#666363"
      />
      <path
        d="M13 24C13 24.5523 12.5523 25 12 25H5C4.44771 25 4 24.5523 4 24C4 23.4477 4.44771 23 5 23H12C12.5523 23 13 23.4477 13 24Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 28C4 27.4477 4.44772 27 5 27H12C12.5523 27 13 27.4477 13 28V31C13 31.5523 12.5523 32 12 32H5C4.44772 32 4 31.5523 4 31V28ZM6 30V29H11V30H6Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 4C26 2.89543 26.8954 2 28 2H40C41.1046 2 42 2.89543 42 4V36H2C0.895431 36 0 35.1046 0 34V21C0 19.8954 0.895431 19 2 19H26V4ZM28 4H40V12H28V4ZM28 14H40V34H28V14ZM26 21H17V34H26V21ZM2 21H15L15 34H2V21Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgKitchen
