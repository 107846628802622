import React, { FC, useMemo } from 'react'
import { SettingsIcon, HomeIcon } from '../assets/icons'
import useI18n from '../i18n/useI18n'
import { TranslationKey } from '../types/generated/TranslationKey'
import { InstallationStatus, isPanelArmed, PanelStatus } from '../types/PanelModel'
import { useDateFnsLocale } from '../utils/useDateFnsLocale'
import classNames from 'classnames'
import { useIsCrow } from './CrowToggle'
import convertAndFormatTime from '../utils/ConvertAndFormatTIme'

const panelText = (status: PanelStatus, hasOpenDoorsOrWindows: boolean): TranslationKey => {
  switch (status) {
    case PanelStatus.NOT_AVAILABLE:
      return 'Could not load alarms system status'
    case PanelStatus.FULLY_ARMED:
      return hasOpenDoorsOrWindows ? 'FULLY_ARMED_WITH_OPEN_ENTRANCES_NO_TIME' : 'Fully armed'
    case PanelStatus.TURNED_OFF:
      return 'Turned off'
    case PanelStatus.HOME_MODE:
      return hasOpenDoorsOrWindows ? 'PARTIALLY_ARMED_WITH_OPEN_ENTRANCES_NO_TIME' : 'Home mode'
    default:
      return 'Could not load alarms system status'
  }
}

const getArmedStatus = (status: PanelStatus | undefined) => {
  if (status === PanelStatus.FULLY_ARMED) return 'system__icon--armed'
  if (status === PanelStatus.HOME_MODE) return 'system__icon--shell-armed'
  return ''
}

type Props = {
  installationStatus: InstallationStatus | undefined
  panelStatus: PanelStatus | undefined
  displayName: string | undefined
  StatusTime: string | undefined
  StatusTimeUtc: string | undefined
  TimeZoneName: string | undefined
  hasOpenDoorsOrWindows: boolean
}

const PanelStatusInfo: FC<Props> = ({
  installationStatus,
  panelStatus = PanelStatus.NOT_AVAILABLE,
  displayName = '',
  StatusTimeUtc = '',
  StatusTime = '',
  TimeZoneName = '',
  hasOpenDoorsOrWindows,
}) => {
  const { t } = useI18n()
  const locale = useDateFnsLocale()

  const activityDate = useMemo(() => {
    return convertAndFormatTime(
      TimeZoneName,
      locale,
      StatusTimeUtc,
      true,
      StatusTime,
      !!panelStatus,
    )
  }, [locale, panelStatus, StatusTimeUtc, TimeZoneName, StatusTime])

  const isCrow = useIsCrow()
  return (
    <>
      {installationStatus === InstallationStatus.PLANNED ? (
        <div className={classNames('system__icon', { 'system__icon--crow': isCrow })}>
          <SettingsIcon />
        </div>
      ) : (
        <div
          className={classNames('system__icon ' + getArmedStatus(panelStatus), {
            'system__icon--crow': isCrow,
          })}
        >
          <HomeIcon />
        </div>
      )}
      <h1 className="center">
        <span>
          {displayName}
          {isPanelArmed(panelStatus) && <span> {t('is secured')}</span>}
        </span>
      </h1>
      {installationStatus === InstallationStatus.PLANNED ? (
        <div className="header__settings mt-2">
          <div className="notification notification--booking">{t('SYSTEM_WILL_BE_INSTALLED')}</div>
        </div>
      ) : (
        <span>
          {panelStatus !== undefined
            ? `${t(panelText(panelStatus, hasOpenDoorsOrWindows))} ${activityDate}`
            : ''}
        </span>
      )}
    </>
  )
}

export default PanelStatusInfo
