import React, { FC, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../Style/theme'

export interface Props {
  enabled: boolean
  onClick?: MouseEventHandler<HTMLElement>
  id?: string
  name?: any
  isLoading?: boolean
  className?: string
  disabled?: boolean
}

const Toggle: FC<Props> = ({ enabled, onClick, name, id, disabled, className }) => {
  return (
    <>
      <S.Switch
        id={id}
        enabled={enabled}
        disabled={disabled}
        onClick={onClick}
        className={className}
      >
        <S.Slider className="slider" enabled={enabled} disabled={disabled} />
        <S.Input type="checkbox" />
      </S.Switch>
    </>
  )
}

const S = {
  Switch: styled.span<{ enabled: boolean; disabled?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    width: 60px;
    height: 34px;
    background-color: ${colors.black500};
    border-radius: 26px;

    ${({ enabled }) => {
      return (
        enabled &&
        css`
          background-color: ${colors.blue};
        `
      )
    }}
    ${({ disabled }) => {
      return (
        disabled &&
        css`
          background-color: ${colors.black300};
          pointer-events: none;
        `
      )
    }}
  `,
  Slider: styled.span<{ enabled: boolean; disabled?: boolean }>`
    margin-left: 4px;
    display: block;
    height: 26px;
    width: 26px;
    border-radius: 100%;
    background-color: ${({ disabled }) => (disabled ? `${colors.black200}` : `${colors.white}`)};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    ${({ enabled }) => {
      return (
        enabled &&
        css`
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        `
      )
    }}
  `,
  Input: styled.input`
    height: 0;
    width: 0;
    opacity: 0;
  `,
}

export default Toggle
