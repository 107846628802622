/* eslint-disable import/no-unused-modules */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../../i18n/useI18n'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import CrowCard from '../CrowCard/CrowCard'
import { TranslationKeyOverride } from '../../../types/TranslationKeyOverride'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import styled from 'styled-components'
import Icon, { IconName } from '../Icon/Icon'
import { colors } from '../Style/theme'

type Props = {
  iconName: IconName
  text: TranslationKey
  to: string
}

const AppLink: FC<Props> = ({ iconName, text, to }) => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const link = `/systems/${panelId}${to}`
  return (
    <S.Link to={link}>
      <CrowCard border size="small" className={`d flex flex-col items-center`}>
        <S.Icon name={iconName} />
        <p className="text-sm">{t(text)}</p>
      </CrowCard>
    </S.Link>
  )
}

export const TemperatureApp = () => (
  <AppLink iconName="Temperature" text="Temperature" to="/temperatures" />
)

export const SmartPlugApp = () => (
  <AppLink iconName="Smart-plugs" text="smartplugs" to="/smartplugs" />
)

export const LocksApp = () => <AppLink iconName="Locked" text="Locks" to="/locks" />

export const VideoApp = () => (
  <AppLink iconName="Video" text={'video' as TranslationKeyOverride} to="/video" />
)

export const HistoryApp = () => <AppLink iconName="History" text="History" to="/history" />

export const SettingsApp = () => <AppLink iconName="Settings" text="Settings" to="/settings" />
export const PeopleApp = () => <AppLink iconName="People" text="PEOPLE" to="/people" />

export const PhotoApp = () => (
  <AppLink iconName="SectorAlarmAppAddPhoto" text="photo" to="/picture" />
)

export const DoorsAndWindowsApp = ({
  countOpenDoorOrWindows,
  displayExclamationMark,
}: {
  countOpenDoorOrWindows: number
  displayExclamationMark: boolean
}) => (
  <div className="relative">
    {displayExclamationMark && countOpenDoorOrWindows >= 1 && (
      <S.Exclamation>{countOpenDoorOrWindows}</S.Exclamation>
    )}
    <AppLink iconName="Doors" text="DOORS_AND_WINDOWS_MINIAPP_LABEL" to="/doors-and-windows" />
  </div>
)

export const SmokeDetectorsApp = () => (
  <AppLink iconName="Smoke-Detector" text="Smoke detectors" to="/smoke-detectors" />
)

const S = {
  Link: styled(Link)`
    text-decoration: none;
    position: relative;
  `,
  Icon: styled(Icon)`
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  `,
  Exclamation: styled.div`
    z-index: 1;
    position: absolute;
    margin-left: auto;
    right: -3px;
    top: -3px;
    width: 20px;
    height: 20px;
    background-color: ${colors.lightRed};
    border-radius: 50%;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
