import cx from 'classnames'
import React, {
  ChangeEventHandler,
  ElementRef,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import useI18n from '../../i18n/useI18n'
import { TranslationKey } from '../../types/generated/TranslationKey'
import ValidationPopover from './ValidationPopover'

type Props = {
  name: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  label: TranslationKey
  errorKey?: TranslationKey
  className?: string
  autoComplete?: string
  placeholder?: TranslationKey
  rows?: number
  cols?: number
  crow?: boolean
}

const TextAreaField: ForwardRefRenderFunction<ElementRef<'textarea'>, Props> = (
  {
    placeholder,
    name,
    onChange,
    onBlur,
    label,
    errorKey,
    className,
    autoComplete,
    rows,
    cols,
    crow,
  },
  ref,
) => {
  const { t } = useI18n()

  return (
    <label className={cx('form', className)}>
      <div>{t(label)}</div>
      <textarea
        className={crow ? className : 'form__input'}
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        placeholder={placeholder && t(placeholder)}
        rows={rows}
        cols={cols}
      />
      <ValidationPopover errorMessage={errorKey} />
    </label>
  )
}

export default forwardRef(TextAreaField)
