import styled from 'styled-components'
import { colors } from '../../../components/Crow/Style/theme'
import { Paragraph, SubTitle } from '../../../components/Crow/Typography'

const IconContainer = styled.span<{ color?: string; border?: string }>`
  border-radius: 10px;
  background-color: ${({ color }) => (color ? color : colors.white)};
  border: ${({ border }) => (border ? '1px solid' : null)};
  border-color: ${({ border }) => (border ? border : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-right: 16px;
`

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: ${colors.black};
`

const StyledParagraph = styled(Paragraph)`
  font-size: 14px;
  line-height: 12px;
`

const HistoryTitle = styled.h3`
  padding-bottom: 15px;
`

const FormatEventGroupeTitle = styled.div`
  padding-top: 35px;
  padding-bottom: 9px;
  display: flex;
  align-items: center;
  color: ${colors.black900};
`

const StyledSubTitle = styled(SubTitle)`
  margin: 0;
  line-height: 29px;
`
const StyledParagraphNoEvents = styled(Paragraph)`
  padding-top: 20px;
  padding-bottom: 50px;
`

const SkeletonStyle = styled.div`
  background-color: ${colors.black200};
  padding-top: 16px;
  padding-right: 12px;
  padding-bottom: 16px;
  padding-left: 12px;
  border-radius: 12px;
  height: 72px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const TimeContainer = styled.span`
  width: 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const DividerLine = styled.span`
  width: 2px;
  background-color: ${colors.black200};
  height: 32px;
`

const HistoryCard = styled.div<{ className: string }>`
  background-color: ${colors.white};
  padding-top: 16px;
  padding-right: 12px;
  padding-bottom: 16px;
  padding-left: 12px;
  width: 100%;
`

export {
  IconContainer,
  StyledSubtitle,
  StyledParagraph,
  HistoryTitle,
  FormatEventGroupeTitle,
  StyledSubTitle,
  StyledParagraphNoEvents,
  SkeletonStyle,
  TimeContainer,
  DividerLine,
  HistoryCard,
}
