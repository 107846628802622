import styled from 'styled-components'
import {
  DoorsAndWindowsApp,
  HistoryApp,
  LocksApp,
  PeopleApp,
  PhotoApp,
  SettingsApp,
  SmartPlugApp,
  SmokeDetectorsApp,
  TemperatureApp,
} from '../../../components/Crow/AppLinks/AppLinks'
import useI18n from '../../../i18n/useI18n'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { usePanelEvents } from '../../../services/requestHooks/history'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { usePostTemperatures } from '../../../services/requestHooks/temperatureList'
import { Capability, isPanelArmed } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'

const MiniAppContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin-top: 2rem;
`

const LoadingContainer = styled.div`
  margin-top: 1.25rem;
`

const CrowMiniAppList = ({ openDoorOrWindow }: { openDoorOrWindow: boolean }) => {
  const panelId = usePanelId()
  const { t } = useI18n()

  const {
    run: getTemperatures,
    isLoading: loadingTemperatures,
    data: temperatures,
  } = usePostTemperatures()

  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const {
    data: smokeDetectors,
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
  } = useSmokeDetectors()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
  } = usePanelStatus()
  // Note: For Camera mini app, need to update when we have a proper endpoint
  const { data: panelInfo, run: loadPanelInfo, isLoading: loadingPanelInfo } = usePanelInfo()

  const { run: loadPanelEvents, isLoading: loadingPanelEvents } = usePanelEvents()

  useOnMount(() => {
    if (!loadingTemperatures) {
      getTemperatures(panelId)
    }
    if (!loadingDoorsAndWindows) {
      getDoorsAndWindows(panelId)
    }

    if (!loadingSmokeDetectors) {
      getSmokeDetectors(panelId)
    }
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }

    if (!loadingPanelEvents) {
      loadPanelEvents(panelId)
    }

    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  const isLoading =
    loadingTemperatures ||
    loadingDoorsAndWindows ||
    loadingSmokeDetectors ||
    loadingPanelInfo ||
    loadingPanelEvents

  const CountOpenDoorOrWindows = doorsAndWindows?.Floors.flatMap((floors) => floors.Rooms)
    .flatMap((room) => room.Devices)
    .filter((isClosed) => !isClosed.Closed).length

  return (
    <>
      {isLoading ? (
        <LoadingContainer>{t('Loading')}</LoadingContainer>
      ) : (
        <MiniAppContainer>
          {temperatures?.Sections.length! > 0 && <TemperatureApp />}
          {doorsAndWindows?.Floors.length! > 0 && (
            <DoorsAndWindowsApp
              countOpenDoorOrWindows={CountOpenDoorOrWindows!}
              displayExclamationMark={!!panelStatus?.Status && isPanelArmed(panelStatus?.Status)}
            />
          )}
          {panelInfo?.Locks?.length! > 0 && <LocksApp />}
          {!panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE) && <PeopleApp />}
          {/* LeakageApp */}
          {smokeDetectors?.Floors.length! > 0 && <SmokeDetectorsApp />}
          {panelInfo?.Photos.length! > 0 && <PhotoApp />}
          {/* <VideoApp /> */}
          {panelInfo?.Smartplugs?.length! > 0 && <SmartPlugApp />}
          {/* Lights App */}
          <HistoryApp />
          <SettingsApp />
        </MiniAppContainer>
      )}
    </>
  )
}

export default CrowMiniAppList
