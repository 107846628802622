import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SmartPlug, SmartPlugScenarioTrigger } from '../types/SmartPlug'

type SmartPlugState = Record<
  string,
  | {
      plugs?: SmartPlug[]
      currentPlug?: {
        Id: string
        Label: string
        Events: any[]
        Scenarios: SmartPlugScenarioTrigger
      }
    }
  | undefined
>

const initialState: SmartPlugState = {
  plugs: undefined,
  currentPlug: undefined,
}

const plugSlice = createSlice({
  initialState,
  name: 'plugs',
  reducers: {
    GET_SMARTPLUGS_SUCCESS(state, action: PayloadAction<{ data: SmartPlug[]; panelId: string }>) {
      const { panelId, data } = action.payload
      state[panelId] = { ...(state[panelId] || {}), plugs: data }
    },
    TURN_ON_TC_SUCCESS(state, action: PayloadAction<{ data: string; panelId: string }>) {
      const { panelId, data: Id } = action.payload
      const newPlugs: SmartPlug[] =
        state[panelId]?.plugs?.map((plug) => {
          return plug.Id === Id ? { ...plug, Status: 'On' } : plug
        }) || []
      state[panelId] = { ...(state[panelId] || {}), plugs: newPlugs }
    },
    TURN_OFF_TC_SUCCESS(state, action: PayloadAction<{ data: string; panelId: string }>) {
      const { panelId, data: Id } = action.payload
      const newPlugs: SmartPlug[] =
        state[panelId]?.plugs?.map((plug) => {
          return plug.Id === Id ? { ...plug, Status: 'Off' } : plug
        }) || []
      state[panelId] = { ...(state[panelId] || {}), plugs: newPlugs }
    },
    TURN_ON_SUCCESS(state, action: PayloadAction<{ data: SmartPlug[]; panelId: string }>) {
      const { panelId, data } = action.payload
      state[panelId] = { ...(state[panelId] || {}), plugs: data }
    },
    TURN_OFF_SUCCESS(state, action: PayloadAction<{ data: SmartPlug[]; panelId: string }>) {
      const { panelId, data } = action.payload
      state[panelId] = { ...(state[panelId] || {}), plugs: data }
    },
    SET_SCENARIO_TRIGGERS_SUCCESS(state, action: PayloadAction<{ data: void; panelId: string }>) {},
    SET_TIME_TRIGGERS_SUCCESS(state, action: PayloadAction<{ data: void; panelId: string }>) {},
    SET_LABEL_SUCCESS(state, action: PayloadAction<{ data: void; panelId: string }>) {},
    GET_TRIGGERS_SUCCESS(
      state,
      action: PayloadAction<{
        data: any
        panelId: string
      }>,
    ) {
      const { panelId, data } = action.payload
      state[panelId] = { ...(state[panelId] || {}), currentPlug: data }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  GET_SMARTPLUGS_SUCCESS,
  TURN_OFF_SUCCESS,
  TURN_ON_SUCCESS,
  SET_SCENARIO_TRIGGERS_SUCCESS,
  SET_TIME_TRIGGERS_SUCCESS,
  GET_TRIGGERS_SUCCESS,
  SET_LABEL_SUCCESS,
  TURN_OFF_TC_SUCCESS,
  TURN_ON_TC_SUCCESS,
} = plugSlice.actions

export default plugSlice.reducer
