function SvgTv(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 13C31 13.5523 30.5523 14 30 14C29.4477 14 29 13.5523 29 13C29 12.4477 29.4477 12 30 12C30.5523 12 31 12.4477 31 13Z"
        fill="#333131"
      />
      <path
        d="M30 17C30.5523 17 31 16.5523 31 16C31 15.4477 30.5523 15 30 15C29.4477 15 29 15.4477 29 16C29 16.5523 29.4477 17 30 17Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2071 2.20711C23.5976 1.81658 23.5976 1.18342 23.2071 0.792893C22.8166 0.402369 22.1834 0.402369 21.7929 0.792893L14 8.58579L8.70711 3.29289C8.31658 2.90237 7.68342 2.90237 7.29289 3.29289C6.90237 3.68342 6.90237 4.31658 7.29289 4.70711L11.5858 9H7C5.89543 9 5 9.89543 5 11V28C5 29.1046 5.89543 30 7 30H8C8 31.1046 8.89543 32 10 32C11.1046 32 12 31.1046 12 30H27C27 31.1046 27.8954 32 29 32C30.1046 32 31 31.1046 31 30H32C33.1046 30 34 29.1046 34 28V11C34 9.89543 33.1046 9 32 9H16.4142L23.2071 2.20711ZM32 24V25H28V24H32ZM32 28V27H28V28H32ZM7 11H26V28H7L7 11ZM32 22V11H28V22H32Z"
        fill="#333131"
      />
      <path
        d="M1 33C0.447715 33 0 33.4477 0 34C0 34.5523 0.447714 35 0.999999 35H39C39.5523 35 40 34.5523 40 34C40 33.4477 39.5523 33 39 33H1Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgTv
