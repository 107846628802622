import React, { FC } from 'react'
import cx from 'classnames'
import SystemPlugs from './PanelComponents/SystemPlugsScreen'
import SystemHeader from './SystemHeader'
import SystemTemp from './PanelComponents/Temperature/SystemTempScreen'
import SystemLocksScreen from './PanelComponents/Locks/SystemLocksScreen'
import SubFooter from '../../../components/system/SubFooter'
import SystemPhotoScreen from './Picture/SystemPhotoScreen'
import BookingWizard from './bookingWizard/BookingWizard'
import { InstallationStatus, PanelAccess } from '../../../types/PanelModel'
import { useHistory } from 'react-router-dom'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../hooks/usePanelId'
import NavBarPage from '../../../components/pages/NavBarPage'
import useI18n from '../../../i18n/useI18n'
import { CardLink } from '../../../components/Elements/Card'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import WizardLink from './WizardLink'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { CLOSE_WIZARD } from '../../../reducers/panelInfoSlice'
import VideoExpandable from './VideoExpandable'

type SystemEntity = 'Locks' | 'Photos' | 'Temperatures' | 'Smartplugs'
const systemComponents: { key: SystemEntity; component: React.FC }[] = [
  {
    key: 'Locks',
    component: SystemLocksScreen,
  },
  {
    key: 'Photos',
    component: SystemPhotoScreen,
  },
  {
    key: 'Temperatures',
    component: SystemTemp,
  },
  {
    key: 'Smartplugs',
    component: SystemPlugs,
  },
]

const PanelPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const dispatch = useDispatch()
  const history = useHistory()

  const { run: loadPanelInfo, data: panelInfo, isLoading: loadingPanelInfo } = usePanelInfo()
  const { run: loadPanelStatus, isLoading: loadingPanelStatus } = usePanelStatus()

  const hasClosedWizard = useSelector((state: RootState) => state.panel.hasClosedWizard)

  const closeWizard = () => {
    dispatch(CLOSE_WIZARD({ panelId }))
  }

  useOnMount(() => {
    if (!loadingPanelInfo && panelInfo?.PanelId !== panelId) loadPanelInfo(panelId)
    if (!loadingPanelStatus) loadPanelStatus({ panelId })
  })

  const showBooking = panelInfo?.InstallationStatus === InstallationStatus.PLANNED

  const showOrder = showBooking && panelInfo?.Access.includes(PanelAccess.Components)

  if (panelInfo?.InstallationStatus === InstallationStatus.INSTALLING) {
    history.replace(`/getting-started/${panelInfo.PanelId}`)
  }
  if (panelInfo?.InstallationStatus === InstallationStatus.READY_FOR_ACTIVATION) {
    history.replace(`/systems/${panelInfo.PanelId}/activate`)
  }

  return (
    <NavBarPage isLoading={loadingPanelInfo}>
      {panelInfo?.DisplayWizard && !hasClosedWizard && <WizardLink closeWizard={closeWizard} />}
      <SystemHeader />
      <div className="background-gray grid">
        <div className="grid__half mx-4 lg:mx-0">
          {!loadingPanelInfo && !loadingPanelStatus && panelInfo?.HasVideo && <VideoExpandable />}
          {!loadingPanelStatus &&
            systemComponents
              .filter(({ key }) => (panelInfo?.[key]?.length || 0) > 0)
              .map(({ component: Component, key }, index) => (
                <div className={cx('grid', { 'mt-0': index === 0, 'mt-6': index !== 0 })} key={key}>
                  <Component />
                </div>
              ))}
          {showOrder && (
            <CardLink className="flex-1 paper--hovereffect mt-4" to={`/systems/${panelId}/order`}>
              <span className="flex-1 center py-4 bold">{t('see_your_order')}</span>
            </CardLink>
          )}
          {panelInfo && showBooking && (
            <BookingWizard
              propertyContact={panelInfo.PropertyContact}
              startDateString={panelInfo.BookedStartDate}
              endDateString={panelInfo.BookedEndDate}
              refreshPanelInfo={() => {
                loadPanelInfo(panelId)
              }}
            />
          )}
        </div>
      </div>
      <SubFooter />
    </NavBarPage>
  )
}

export default PanelPage
