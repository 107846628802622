function SvgToilet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 14C23.6569 14 25 15.3431 25 17V20H24.9381C24.446 23.9463 21.0796 27 17 27C16.4477 27 16 27.4477 16 28V31C16 32.1046 15.1046 33 14 33H9C7.89543 33 7 32.1046 7 31V29C7 27.8954 6.10457 27 5 27H4C2.89543 27 2 26.1046 2 25L2 20C0.89543 20 0 19.1046 0 18V2C0 0.895431 0.895431 0 2 0H7C8.10457 0 9 0.895431 9 2V14H22ZM7 2H2L2 18H7V6H5C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4H7V2ZM9 18H23V17C23 16.4477 22.5523 16 22 16H9V18ZM4 20V25H5C7.20914 25 9 26.7909 9 29V31H14V28C14 26.3431 15.3431 25 17 25C19.973 25 22.441 22.8377 22.917 20H4Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 9.5C38 11.433 37.1046 13 36 13V15C36 15.5523 35.5523 16 35 16H29C28.4477 16 28 15.5523 28 15V9C28 7.34315 29.3431 6 31 6H36C37.1046 6 38 7.567 38 9.5ZM34 8H31C30.4477 8 30 8.44772 30 9V14H34V8Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgToilet
