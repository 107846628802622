import React from 'react'

const RightPrimaryXL = () => {
  return (
    <svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.5081 15.0361C27.5043 20.684 28 26.3418 28 32C28 37.6582 27.5043 43.316 26.5081 48.9639C24.9369 57.8718 19.6837 62.6079 10.6327 63.4602C7.43489 63.7628 4.21216 63.9842 1 64H0V0H1C4.21216 0.0157873 7.43489 0.23715 10.6327 0.539806C19.6837 1.39209 24.9369 6.1282 26.5081 15.0361Z" />
    </svg>
  )
}

export default RightPrimaryXL
