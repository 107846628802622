import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useI18n from '../../../i18n/useI18n'
import { useGetBenefits, useLinkToPartner } from '../../../services/requestHooks/benefits'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import { Partner } from '../../../types/Partner'
import huseierneLogoUrl from '../../../assets/images/partners/huseierne.png'
import TextField from '../../../components/forms/TextField'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import FailedRequest from '../../onboardingWizard/components/FailedRequest'

const huseierneResolver = yup.object({
  member_number: yup
    .string()
    .required()
    .min(6, 'huseierne_member_number_helper'),
})

interface PartnerInfo {
  description: TranslationKey
  label: TranslationKey
  resolver: typeof huseierneResolver
  logoUrl: string
}
const partners: Record<string, PartnerInfo> = {
  ['644' as string]: {
    description: 'huseierne_desc',
    label: 'huseierne_member_number',
    resolver: huseierneResolver,
    logoUrl: huseierneLogoUrl,
  },
}

type ModalProps = {
  partner: Partner
  closeModal: () => void
}

export const PartnerModal = ({ partner, closeModal }: ModalProps) => {
  const { t } = useI18n()
  const { run: getBenefits } = useGetBenefits()
  const { description, label, resolver } = partners[partner.Id]
  const { run: linkToPartner, isLoading, error } = useLinkToPartner({
    onSuccess: () => {
      closeModal()
      getBenefits()
    },
  })

  const onSubmit = ({ member_number }: { member_number: string }) => {
    linkToPartner({ PartnerId: partner.Id, ExternalId: member_number })
  }

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<{ member_number: string }>({
    mode: 'all',
    resolver: yupResolver(resolver),
  })
  return (
    <Modal title={partner.Name} handleClose={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="mb-2 mt-2 description-text">{t(description)}</p>
        <TextField
          {...register('member_number')}
          label={label}
          errorKey={errors.member_number?.message as any}
        />
        {error && <FailedRequest />}
        <div className="flex justify-end">
          <Button buttonType="primary" disabled={!isValid || isLoading}>
            {t('ACTIVATE')}
          </Button>
          <Button buttonType="secondary" onClick={closeModal}>
            {t('CLOSE')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
