import * as React from 'react'

function SvgTinyUnarmed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.19 17.356a.611.611 0 01-.62-.604V7.084c0-.22-.1-.416-.26-.539L9.215 1.32a.54.54 0 00-.637 0L1.516 6.562a.672.672 0 00-.259.53v8.737c0 .171.117.31.259.31h3.988c.318 0 .578-.294.578-.653v-2.45c0-1.028.821-1.861 1.826-1.861h1.826c1.006 0 1.827.84 1.827 1.87v2.45c0 .358.259.652.578.652h2.513c.343 0 .62.27.62.605a.611.611 0 01-.62.604h-2.513c-1.006 0-1.827-.833-1.827-1.862v-2.45c0-.359-.259-.653-.586-.653H7.891c-.31 0-.578.294-.578.646v2.449c0 1.029-.821 1.862-1.826 1.862H1.5c-.83.008-1.5-.67-1.5-1.52V7.093c0-.58.276-1.143.746-1.494L7.808.356A1.808 1.808 0 019.952.348l7.096 5.226c.477.35.754.914.754 1.51v9.668a.6.6 0 01-.612.604z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgTinyUnarmed
