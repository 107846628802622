import React, {
  ChangeEventHandler,
  ComponentType,
  ElementRef,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import cx from 'classnames'

import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import useI18n from '../../../../../i18n/useI18n'

type Props = {
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  icon: ComponentType<{ className?: string }>
  labelKey: TranslationKey
  placeholder?: TranslationKey
  errorKey?: TranslationKey
  iconRight?: boolean
  readonly?: boolean
}

const SpeechBubbleField: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  { name, onChange, onBlur, icon: Icon, labelKey, placeholder, errorKey, iconRight, readonly },
  ref,
) => {
  const { t } = useI18n()
  const LabelElement: keyof JSX.IntrinsicElements = readonly ? 'div' : 'label'

  return (
    <div className="speech-bubble">
      <div className={cx('speech-bubble__icon', iconRight && 'speech-bubble__icon--right')}>
        <Icon className="icon icon--small" />
      </div>
      <div className={cx('speech-bubble__message', iconRight && 'speech-bubble__message--left')}>
        <LabelElement className="form">
          <span>{t(labelKey)}</span>
          {readonly ? (
            <p>••••••••••</p>
          ) : (
            <input
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              className="form__input"
              ref={ref}
              placeholder={placeholder && t(placeholder)}
            />
          )}
        </LabelElement>
        {errorKey && (
          <div className="validation">
            <div role="alert">{t(errorKey)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default forwardRef(SpeechBubbleField)
