import React from 'react'

const LeftPrimaryL = () => {
  return (
    <svg width="21" height="48" viewBox="0 0 21 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.11895 11.2771C0.371793 15.513 0 19.7564 0 24C0 28.2436 0.371793 32.487 1.11895 36.7229C2.29729 43.4039 6.23726 46.9559 13.0255 47.5951C15.4238 47.8221 17.8409 47.9882 20.25 48H21V0H20.25C17.8409 0.0118404 15.4238 0.177863 13.0255 0.404854C6.23726 1.04407 2.29729 4.59615 1.11895 11.2771Z" />
    </svg>
  )
}

export default LeftPrimaryL
