function SvgStorage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 8H8V10H13V8Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 5.55228 0.447715 6 1 6V6V15C1 16.1046 1.89543 17 3 17H17C18.1046 17 19 16.1046 19 15V6V6C19.5523 6 20 5.55228 20 5V2C20 0.895431 19.1046 0 18 0H2C0.895431 0 0 0.89543 0 2V5ZM18 2H2V4H18V2ZM3 15V6H17V15H3Z"
        fill="#333131"
      />
      <path d="M13 27H8V29H13V27Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24C0 24.5523 0.447715 25 1 25V25V34C1 35.1046 1.89543 36 3 36H17C18.1046 36 19 35.1046 19 34V25V25C19.5523 25 20 24.5523 20 24V21C20 19.8954 19.1046 19 18 19H2C0.895431 19 0 19.8954 0 21V24ZM18 21H2V23H18V21ZM3 34V25H17V34H3Z"
        fill="#333131"
      />
      <path d="M30 27H35V29H30V27Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 25V25C22.4477 25 22 24.5523 22 24V21C22 19.8954 22.8954 19 24 19H40C41.1046 19 42 19.8954 42 21V24C42 24.5523 41.5523 25 41 25V25V34C41 35.1046 40.1046 36 39 36H25C23.8954 36 23 35.1046 23 34V25ZM24 21H40V23H24V21ZM25 25V34H39V25H25Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgStorage
