// Once upon a time this file was autogenerated, you could then run "yarn update-translation-keys" to update.
// Today we manually update it :)
const keys = [
  'Customer Number',
  'armed',
  'from',
  'disarmed',
  'history_event_disarmed_and_unlock',
  'history_event_armed_and_lock',
  'partialarmed',
  'armedannex',
  'disarmedannex',
  'Add new alarm system',
  'Find new system',
  'No alarmsystems found',
  'System is scheduled for instalation',
  'Scheduled for installation',
  'System is shell armed',
  'Alarm is off',
  'Alarm is on',
  'Searching for new system',
  'No new alarmsystem is found.',
  'If you have an activation code, you can add it manually',
  'Add manually',
  'New alarmsystem is found.',
  'Register alarm system',
  'The activation code is unique for your alarm system. You received your activation code when we installed your alarm system. You can also get it through our customer service',
  'phone.: +47 03033',
  'Activation Code',
  'Name your alarm system',
  'Register',
  'View',
  'motion detectors',
  'Hide motion detectors',
  'smartplugs',
  'Hide smartplugs',
  'locks',
  'Hide locks',
  'Users and codes',
  'Identification code is incorrect or already used.',
  'Registering',
  'User terms and cookies',
  'Menu',
  'Invalid panel code',
  'Alarm systems',
  'Invoices',
  'Log out',
  'Administrator tools',
  'Direct debit',
  'Can we help you',
  'We are allways available to help you',
  'Go to customer service',
  'Hi, we are here to help you',
  'Enter alarm code',
  'Confirm',
  'Cancel',
  'Edit',
  'Temperature',
  'Failed to get temperatures',
  'Loading',
  'Could not change alarm status',
  'Off',
  'On',
  'off',
  'on',
  'Doors and windows only',
  'Fully activated',
  'Full activation',
  'Settings',
  'History',
  'Locks',
  "You don't have any",
  'Read more about Yale doorman',
  'You can not control locks while you are offline',
  'Failed to load locks status',
  'Smart plugs',
  'Read more about smart plugs',
  'Unknown status',
  'You can not control smart plugs while you are offline',
  'Failed to load smart plugs status',
  'Motion detectors',
  'You can not get tempratures while you are offline',
  'Failed to load temperatures',
  'Your alarm system is not conntected to internet, your internet connection is offline',
  'is locked',
  'is unlocked',
  'is On',
  'is Off',
  'Turned off',
  'Home mode',
  'Fully armed',
  'by',
  'Disables motion detectors so you can move around indoors',
  "Use this when no-one's home",
  'Choose alarm mode',
  'is secured',
  'Inactive',
  'lost power',
  'regain power',
  'Save new name',
  'Could not load smartplug status',
  'Something went wrong. Please try again',
  'Serial number',
  'Warning',
  "The change you're about to do will cause",
  'Smartplug on when alarm is dissarmed',
  'Smartplug on when alarm goes off',
  'Smartplug on when alarm goes off and smartplug on when alarm is dissarmed',
  'Smartplug off when alarm is dissarmed',
  'Smartplug on when alarm is armed',
  'Smartplug off when alarm is armed',
  'Smartplug on when alarm goes of and smartplug of when alarm is armed',
  'Smartplug of when alarm is armed',
  'Smartplug of when alarm goes off',
  'Smartplug of when alarm is disarmed and smartplug on when alarm is armed',
  'to be reset',
  'Turn on',
  'Turn off',
  'Do nothing',
  'Set timer for',
  'Save',
  'You can not add any more timers, remove one to add a new one',
  'Add',
  'Done',
  'On arming',
  'On disarming',
  'When alarm goes off',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Add timer',
  'Could not save doorlock name. Please try again',
  'Getting your invoices',
  'Billing frequens',
  'Could not load invoices. Please try again or contact customer services',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'Invoice',
  'Invoice date',
  'Due date',
  'Download PDF',
  'Overdue',
  'Billing method',
  'No invoices found',
  'Read more about',
  'how to change your billing method here',
  'or contact customer service.',
  'how to change your billing frequenes here',
  'Loading account',
  'Saving account',
  'The account data could not be loaded. Please try again or contact customer service',
  'Cell Phone',
  'E-mail',
  'Newsletter',
  'Yes',
  'No',
  'Language',
  'Password',
  'Set password',
  'Existing Password',
  'New Password',
  'Confirm new Password',
  'Unknown',
  'Alarm code',
  'Enter a code to easily activate and disable your alarm system on the keypad',
  'Enter new code',
  'Repeat code',
  'Alternative contact',
  'If we can t get ahold of the owner in case of an alarm, who should we contact instead?',
  'Full name',
  'Phone number',
  'Add another',
  'I don t want you to contact anyone else in case of alarm',
  'To save changes you need to enter your account password.',
  'Saving',
  'Loading contact persons',
  'Alternative contacts',
  "To further your safety, set an alternative contact when we call if you can't be reached.",
  'No contact persons, please add one!',
  'Other',
  'Delete user',
  'Edit user',
  'Mobile',
  'Home',
  'Work',
  'First name',
  'Last name',
  'Phone Mobile',
  'Phone Home',
  'Phone Work',
  'Relation',
  'Add your first contact',
  'Add another one',
  'Could not load contact persons, please try again or contact customer service.',
  'Max number of residents reached. Remove one to add another.',
  'Max number of neighbors reached. Remove one to add another.',
  'Max number of ICE contacts reached. Remove one to add another.',
  'Loading location information',
  'Location information',
  'Having the following information makes it easier to assist in case of an alarm. Remember to add information such as main entrance code and key box code and place.',
  'Add a description',
  'Save description',
  'In case of emergency, we will contact the people on this list in top-to-bottom order.',
  'The given password is not correct.',
  'Save contact persons',
  'Add in case of alarm',
  'We will contact them at any type of alarm such as fire, burglary, SOS, low battery etc.',
  'Add in case of a fire alarm',
  'We will contact them to shorten response time for immediate help.',
  'Add in case of an emergency (ICE)',
  "We will contact them when we can't reach residents in an emergency. These contacts can not be residents.",
  'Fire alarm',
  'Alarm',
  'Delete',
  'Could not load location information, please try again or contact customer service.',
  'Required',
  'Recommended',
  'Security question',
  'Location settings',
  'Contact persons',
  'Panel users',
  'Change system name and more',
  'Add and remove users',
  'In case of alarm, we will call you to verify. Create a security question, so we are able to identify you. Due to security reasons you',
  'can not edit',
  'your existing security password, but you can make a new one.',
  'Sector Alarm password',
  'Your password',
  'Set security question',
  'Set new security password',
  'Example: tomato',
  'Example: fish',
  'Is set',
  'Could not load security question status, please try again or contact customer service.',
  'System settings',
  'Arm options',
  'Arm system without code on My page and app.',
  'Loading users',
  'Users',
  'Not active',
  'Permanent user',
  '24 hour user',
  '24 hours',
  'One time user',
  'Add user',
  'Available slots execeded. Please delete a user to continue.',
  'Typically used for family members or other trustees.',
  '24-hours users are given a panel code that lasts for',
  '24-hours',
  'Panel code expires after a single use.',
  'Add one time user',
  'Add 24-hour user',
  'Add permanent user',
  'Save name',
  'Edit name',
  'User has key tag',
  'Edit panel code',
  'Save new panel code',
  'Back',
  'Name',
  'Welcome to a more secure home',
  'Sign in',
  "Already a customer, but haven't registered your account?",
  'Start using My pages and our apps today to get full control over your alarm system.',
  'Create your account',
  'Please enter your email to reset password',
  'Please check you email and click the verification link.',
  'Please enter a new password.',
  'The password must contain a upper-case letter, a lover case letter, a number and at least 8 charachters.',
  'Password has changed.',
  'Go to login and log in with the new password.',
  'We could not identify the email in our system. Please enter a valid email.',
  'Forgot password',
  'Go to login',
  'Registrer your account',
  'Invalid customer and phone number combination',
  'Your customer number can be found on your invoice.',
  'Phone',
  'Sent by sms or e-mail',
  'Next',
  'You should have received an SMS',
  'One time code',
  'Didnt you get the SMS?',
  'Resend SMS',
  'This is not a valid one time code.',
  'You should have the SMS whithin 30 seconds.',
  'Did not get it?',
  'Try again',
  'Hello',
  'E-mail (This will be your username)',
  'Repeat password',
  'I accept',
  'the licende of agreement',
  'You have to accept the agreement to register.',
  'Almost done',
  'A confirmation e-mail has been sendt to',
  'Please verify to start using you account.',
  'Done, take me to login.',
  'Check your e-mail,',
  'The customer number is already registered in the system',
  'Validation code is not valid',
  'The email is already registered',
  'You could not validate your account. Did you enter the wrong adress?',
  'account_has_been_activated',
  'Sweden',
  'Norway',
  'Finland',
  'Ireland',
  'Swedish',
  'Norwegian',
  'Finnish',
  'English',
  'Russian',
  'Danish',
  'One time code has to be 6 digits.',
  'Customer number has to be 8 digits',
  'Tell us your customer number, if you do not have it, contact support',
  'Tell us your name',
  'Names can not contain numbers',
  'Tell us your phone',
  'Phone number has to be at least 8 digits',
  'Password should be at least 8 characters long',
  'Password must contain at least one number',
  'Password must contain at least one lowercase letter',
  'Password must contain at least one uppercase letter',
  "These passwords aren't the same",
  'Tell us your email',
  'This is not a valid email',
  'Add a description about where you property is.',
  'Change your security password.',
  'Add persons to contact i case of emergency.',
  'Edit contact person',
  'The customer number has been blocked for registration throug mypage. Please contact customer service.',
  "E.g. After the church, it's the second house on your right hand side. Around the back there is a veranda entrance accessible from the stairs.",
  'Set timers',
  'Save scenarios',
  'Yesterday',
  'Today',
  'Sure you want to delete',
  'Change code',
  'New code',
  'Tag activated on user',
  'Tag not activated on user',
  'Save user',
  'Make a user name',
  'The code has to be 6 digits',
  'The code has to be 4 digits',
  "These pin codes aren't the same",
  'User name must be between 2 and 8 characters',
  'Phone number has to be at lest 8 digits',
  'Make a contact person name',
  'Contact person name must be at least 2 characters',
  'Name can not contain numbers',
  'Key tags',
  'Key tags are connected by installer during installation. To connect tag to an user, please rename users with key tags.',
  'alarm contact',
  'ICE contact',
  'fire alarm contact',
  'Type a pin code',
  'Loading sequrity question status',
  'This process may take some time',
  'Updating user',
  'Loading settings',
  'Set new password',
  'There are uncategorized contactpersons',
  'Change password',
  'Welcome to your new alarm system',
  'We just needs a few more details to activate your system.',
  'Start',
  'Congratulations',
  'You are now ready to use your system.',
  'Go to you system',
  'Directions',
  'End',
  'New system onboarding',
  'There are unsaved changes. Do you want leave this page?',
  'Could not load alarms system status',
  'Reload',
  'The system is not ready for activation. Please go back and try again when the system i ready.',
  'Activating system',
  'Loading alarm system status',
  'Uncategorized',
  'Select',
  'Click to activate system.',
  'Special characters are not allowed',
  'At least 2 characters is required',
  'The code words can not be equal',
  'Maximum length is 12 characters',
  'Loading locks settings',
  'Volume',
  'Low',
  'High (recomended)',
  'Automatic lock',
  'The door locks automaticly after 1 seconds when closed.',
  'Your lock is not FG approved when is not used with automatic lock.',
  'Read more about FG approval',
  'Want to change code on locks?',
  'Go to panel user',
  'New features',
  'Access new features.',
  'We have added new features which you have to configure. Would you like to do this now? If you decline, you can configure them later in settings.',
  'Configure now',
  'Enter valid pin',
  'This functionality is only available to the account owner. Send a SMS validation code to the registered number on this account to get access.',
  'The pincode has expired. Send a new SMS validation code to get access.',
  'The SMS could not be sendt. Please contact customer service.',
  'The code is sendt to account owner and are active for 30 minutes.',
  'Enter pin code',
  'The pin code is invalid.  If you enter an incorrect PIN multiple times, your account will be locked.',
  'Send code',
  '24-hours users are given a panel code that lasts for 24 hours.',
  'Panel code expires after a singel use or after 24 hours.',
  '24-hour visitors',
  'One time visitors',
  'PRINT',
  'Mobile phone',
  'Add 24 hour user',
  'Retry',
  'Go to alarm system list',
  'System',
  'Updating',
  'SMS Validation',
  'You do not have access to this application.',
  'Wrong email or password.',
  'Web',
  'App',
  'Deactivate',
  'Loading smart plug settings',
  'Smartplug settings',
  'The sms will be sent to the number ending with:',
  'Failed to get valid phone number. Please contact customer service.',
  'The given user name or pin code is already in use.',
  'The maximum length is 250 characters',
  'Not paid',
  'Paid',
  'The phone number is in use on another contact. A contact should only be registered once in one category.',
  'Repeat the email',
  'The emails does not match.',
  'lock_failed',
  'This number is already registred.',
  'The maximum length is 20 characters',
  'Spanish',
  'add phone number',
  'remove phone number',
  'Residents',
  'Neighbours',
  'Resident contacts',
  'Neighbour contacts',
  'Select type',
  'LOCKSETTINGS_OFF',
  'lock',
  'unlock',
  'user_blocked',
  'EMAIL',
  'ACCOUNT',
  'ANNEX',
  'ADD_USER',
  'CLOSE',
  'ICE',
  'ADD_CONTACT',
  'YOU_CAN_ONLY_ADD_FOUR',
  'VALIDATION_CODE_IS_NOT_VALID',
  'ACCESS',
  'REQUEST_CODE',
  'ACTIVE',
  'ACTIVATE',
  'SMS_SENT_TO',
  'NOTIFICATIONS',
  'NOTIFICATIONS_SETTINGS_ACCESSIBILITY_HINT',
  'NOTIFICATION_EVENT_ARM_DISARM',
  'NOTIFICATION_EVENT_LOCK',
  'NOTIFICATION_EVENT_POWER',
  'NOTIFICATION_PUSH_HEADER',
  'NOTIFICATION_EMAIL_HEADER',
  'NOTIFICATIONS_ENABLE_PUSH',
  'UNBOOKED_INSTALLATION',
  'DISCARD_UNSAVED_CHANGES',
  'resident contact',
  'neighbour contact',
  'PW_SECURITY_QUESTION',
  'PW_CONTACT_PERSONS',
  'PW_CP_INFO_IMPORTANT',
  'PW_RESIDENTS',
  'PW_RESIDENT_CONTACT_PERSON_ADD',
  'PW_NEIGHBOUR_CONTACT_PERSON_ADD',
  'PW_ICE_CONTACT_PERSON_ADD',
  'PW_LOCATION_INFO',
  'PW_SECURITY_QUESTION_INFO',
  'PW_WE_SAY',
  'PW_YOU_SAY',
  'PW_CHANGE_VERIFICATION_WORDS',
  'SMARTPLUG_SELECT_DAYS',
  'SMARTPLUG_MAX_NUMBER_OF_TIMER_EVENTS_REACHED_FOR',
  'SMARTPLUG_TIMER_EXIST_ON_TIME',
  'CAMERA_SETTINGS',
  'DEFAULT_CAMERA',
  'RESET_BY_EMAIL',
  'RESET_BY_SMS',
  'PW_NEIGHBOUR',
  'PW_ICE',
  'take_picture',
  'photo',
  'photo_only_when_fully_armed',
  'add_timer',
  'SET_TIMERS',
  'getting_picture',
  'pictures_only_when_system_on_ethernet',
  'edit_timer',
  'SECURITY_QUESTION_TEXT_MAX_30',
  'FRENCH',
  'INVITED_AS',
  'LEGAL_OWNER',
  'USER',
  'ADMINISTRATOR',
  'ADD_ADMIN_HEADER',
  'ADD_ADMIN_INFOTEXT',
  'ADD_ADMIN_INFOTEXT_2',
  'ADD_ADMINISTRATOR',
  'ADD_FROM_ADDRESS_BOOK',
  'CHANGE_PROPERTY_CONTACT_HEADER',
  'CHANGE_PROPERTY_CONTACT_INFO_TEXT',
  'CHANGE_PERSON',
  'PROPERTY_CONTACT_HEADER',
  'PROPERTY_CONTACT_INFO_TEXT',
  'INSTALLATION_DATE_HEADER',
  'INSTALLATION_DATE_INFO_TEXT',
  'ADD_TO_CALENDAR',
  'INSTALLATION_CALENDAR_TITLE',
  'INSTALLATION_CALENDAR_NOTE',
  'APP_USERS_SETTING_MENU',
  'APP_USERS_HEADER',
  'SELECT_ROLE_HEADER',
  'ADD_NORMAL_APP_USER',
  'ADD_ADMIN_APP_USER',
  'SELECT_ROLE_INFO_TEXT',
  'INSTALLATION_DATE_TITLE',
  'PROPERTY_CONTACT',
  'INVITATION_PENDING',
  'CONFIRM_USER_DELETE',
  'APP_USERS_SETTING_MENU_INFO_TEXT',
  'NOTIFICATIONS_INFO_TEXT',
  'SYSTEM_WILL_BE_INSTALLED',
  'ALREADY_INVITED',
  'accept_invitation',
  'SETTINGS_ADD_ADMINISTRATOR',
  'USER_ALREADY_EXISTS_ON_PANEL',
  'PLUG_INTO_SOCKET',
  'ABOUT_SMART_PLUGS',
  'SMART_PLUG_NAME_HELPTEXT',
  'SMART_PLUG_NAME_PLACEHOLDER',
  'CONTACTING_ALARM_SYSTEM',
  'PRESS_AND_HOLD_FIVE_SECONDS',
  'DEVICE_DETECTED_SYNCRONIZING',
  'BEFORE_READY_FOR_PRESS_INFOTEXT',
  'START_SEARCH',
  'SMART_PLUG_SUCCESSFULLY_ADDED',
  'CONFIGURE_SMART_PLUG',
  'SETTINGS_ADD_SMART_PLUG',
  'SETTINGS_ADD_SMART_PLUG_INFOTEXT',
  'resend_invite',
  'enter_accept_invite_email',
  'invite_and_accept_email_equal',
  'invite_expired_or_blocked',
  'request_code_to_register_account',
  'invite_accepted',
  'invite_accepted_existing_account_message',
  'enter_password_to_accept_with_this_account',
  'APP_USERS_HEADER_DESCRIPTION',
  'WIFI_SETTINGS',
  'CONNECT',
  'CONNECT_TO_WIFI',
  'OPEN',
  'ENCRYPTED',
  'CONNECTED',
  'AVAILABLE_NETWORKS',
  'DISCONNECT_QUESTION',
  'DISCONNECT',
  'TAKING_PHOTO',
  'PHOTO_TAKEN',
  'SENDING_PHOTO_TO_PANEL',
  'JUST_A_FEW_MORE_SECONDS',
  'ALMOST_THERE',
  'PHOTO_RECEIVED',
  'SENDING_PHOTO_TO_DEVICE',
  'WIFI_INFO_TEXT',
  'BATTERY_LOW',
  'BATTERY_RESTORED',
  'AlreadyBooked_Title',
  'Already_Booked',
  'BookDateTime_Title',
  'BookDateTime_NoAvailableTimes',
  'Already_Booked_Less_Than_24h',
  'BookDateTime_HelpText',
  'BookDateTime_HelpText_Battery',
  'PriceNotCovered',
  'PriceNotCovered_Battery',
  'AlreadyBookedDateText',
  'PriceCovered',
  'PriceCovered_Battery',
  'Success_Title',
  'FIND_MORE_TIMES',
  'SEARCH',
  'AVAILABLE_TIMESLOTS_INFO',
  'AVAILABLE_TIMESLOTS_HEADER',
  'SUCCESS',
  'SUCCESS_INFO_CHANGE_BOOKING',
  'no_time_slots_header',
  'SUCCESS_MESSAGE',
  'CHOOSE_INSTALL_DATE',
  'ENDED',
  'VIDEO_ONLY_AVAILABLE_IN_APP',
  'ACCEPT',
  'your_order',
  'your_order_review',
  'kits',
  'extras',
  'total',
  'sum_monthly_cost',
  'monthly_cost',
  'sum_installation_fee',
  'installation_fee',
  'sum_first_payment',
  'camera_activation_header',
  'camera_activation_info1',
  'camera_activation_info2',
  'camera_setup_info1',
  'camera_setup_info2',
  'get_app_from_play_store',
  'onward',
  'setup_step',
  'set_up_camera',
  'see_your_order',
  'SECTOR_ALARM',
  'your_order_details',
  'messages',
  'ACTION_NEEDED',
  'INSTALLATION_INFORMATION',
  'INSTALLATION_INFORMATION_DETAILS',
  'START_SETUP',
  'PW_WE_SAY_V2',
  'PW_YOU_SAY_V2',
  'YOU_SAY_V2',
  'WE_SAY_V2',
  'SECURITY_QUESTION_ANSWER',
  'PW_SECURITY_QUESTION_ANSWER',
  'confirm_sms_customer_registration',
  'enter_password_registration_info_text',
  'registration_almost_ready',
  'confirm_sms_info_text',
  'Benefits',
  'Account details',
  'partner title',
  'partner text',
  'Validation needed',
  'Validation failed',
  'Deactivated',
  'partner_saving_month',
  'partner_saving_products',
  'close',
  'Purchase',
  'available_products',
  'points',
  'link_your_account',
  'link_account',
  'Link',
  'link_description',
  'partner_points_to_spend',
  'use_points',
  'link_confirmation_title',
  'link_terms_required',
  'link_terms_required_title',
  'account_ready_header',
  'points_information_title',
  'points_information',
  'I have read and accept the Terms of Service',
  'Trying to perform link with partner',
  'Processing payment',
  'Linking successful',
  'Invalid format for customer number',
  'Something went wrong. Please try again later.',
  'Product have been purchased',
  'Invalid customer number',
  'This customer number belongs to a different account. Please contact customer support',
  'You have no active benefits',
  'form_link_customer_to_long',
  'form_link_customer_to_short',
  'form_link_customer_valid',
  'customer_has_membership',
  'membership_id_already_registered',
  'customer_have_different_membership_and_membership_number_register_at_someone_else',
  'membership_registered_for_customer',
  'alliance_partner_type_not_found',
  'account_number_not_found',
  'country_not_found',
  'invalid format',
  'invalid_huseierne_customer_number',
  'membership_belongs_to_someone_else',
  'No products found',
  'product_undefined',
  'link',
  'partner_info_link_title',
  'huseierne_desc',
  'Link_partner',
  'huseierne_member_number',
  'huseierne_member_number_helper',
  'product_2210100',
  'product_2004000',
  'product_40010',
  'product_2015400',
  'product_2000400',
  'product_2101900',
  'product_2010300',
  'product_2210000',
  'product_2015500',
  'product_2102700',
  'product_2015800',
  'product_2015700',
  'product_10138',
  'product_2215300',
  'benefits_not_active_description',
  'ITALIAN',
  'CAN_NOT_MOFIFY_PANELUSER_WHEN_ARMED',
  'CONNECT_TO_WIFI_NETWORK',
  'WIFI_SSID',
  'WIFI_SSID_INFO_TEXT',
  'WIFI_PASSWORD',
  'WIFI_PASSWORD_INFO_TEXT',
  'SMARTPLUG_ACTION',
  'SMARTPLUG_TIMESTAMP',
  'SMARTPLUGS_DESCRIPTION',
  'LOCKS_DESCRIPTION',
  'TEMPERATURES_DESCRIPTION',
  'CONSENT_TITLE',
  'CONSENT_DESCRIPTION',
  'CONSENT_VIDEO_TITLE',
  'CONSENT_VIDEO_DESCRIPTION',
  'VIDEO_CONTROL_TITLE',
  'VIDEO_CONTROL_SUBTITLE',
  'VIDEO_CONTROL_DESCRIPTION',
  'CONSENT_SHORT_DESCRIPTION',
  'VIDEO_CONTROL_REMOVE_DESCRIPTION',
  'CONSENT_TERMS_TITLE',
  'CONSENT_TERMS_DESCRIPTION',
  'PHOTOS_DESCRIPTION',
  'BROWSER_NOT_SUPPORTED_TITLE',
  'BROWSER_NOT_SUPPORTED_TEXT',
  'decline',
  'USER_BLOCKED_ERROR',
  'internet_lost',
  'property_settings_description',
  'PEOPLE',
  'MOBILE_REQUIRED',
  'Initials',
  'change_pin_code',
  'underage',
  'is the person a child',
  'full_access',
  'allow_use_of_mobile_app',
  'can_manage_users',
  'administration_rights',
  'person has access to the property',
  'remove_account',
  'remove_account_owner',
  'change_user_owner',
  'my account',
  'users_description',
  'permanent_users',
  'permanent_users_description',
  'DOORS_AND_WINDOWS_MINIAPP_LABEL',
  'Door&Win',
  'DOOR_WINDOWS_DESCRIPTION',
  'You can not fetch people while you are offline',
  'People module not enabled for this user',
  'ARMING_WITH_OPEN_ENTRANCES',
  'PARTIALLY_ARMING_WITH_OPEN_ENTRANCES',
  'FULLY_ARMED_WITH_OPEN_ENTRANCES_NO_TIME',
  'PARTIALLY_ARMED_WITH_OPEN_ENTRANCES_NO_TIME',
  'CLOSED',
  'open',
  'DOOR',
  'Doors',
  'Windows',
  'WINDOW',
  'Smoke detectors',
  'You can not control smoke detectors while you are offline',
  'Unreachable',
  'SMOKE_DETECTORS_DESCRIPTION',
  'call_support',
  'locked',
  'unlocked',
  'sound settings',
  'sound volume',
  'doorlock settings',
  'lock close door',
  'auto lock',
  'High',
  'display live app',
  'live app description',
  'offline',
  'internet_restored',
  'failed_to_lock_door',
  'failed_to_unlock_door',
  'Sensors',
  'output_activated',
  'output_deactivated',
  'SINCE',
  'user_privileges',
  'user_settings',
  'my_profile',
  'code_digits',
  'key_tag',
  'owns_key_tag',
  'contact_support',
  'owner',
  'child',
  'admin',
  'app_access',
  'error_first_name_at_least_2',
  'error_last_name_at_least_2',
  'error_phone_number_required',
  'error_phone_number_invalid',
  'error_phone_number_too_long',
  'error_pin_code_required',
  'error_pin_code_digits_only',
  'error_pin_code_at_least',
  'error_first_name_length',
  'error_last_name_length',
  'error_toast_generic_title',
  'error_toast_generic_content',
  '401_toast_title',
  'info_admin_underage',
  'no_permissions',
  'system_must_be_disarmed_to_add',
  'toast_generic_success_title',
  'toast_add_permanent_user_success_content',
  'toast_send_sms',
  'toast_edit_user_privileges_success_content',
  'toast_edit_user_pin_success_content',
  'toast_delete_user_success_content',
  'Account_text',
  'account_settings',
  'Contact_CP',
  'call_support',
  'Go_to_website',
  'Nothing here... yet!',
  'history no events',
  'No events occured for this day',
  'guard_user',
  'installer_user',
  'Area_Disarmed_By_Doorlock_Log',
  'Area_Armed_By_Doorlock_Log',
  'Area_Stay_Armed_By_Doorlock_Log',
  'HISTORY_EVENT_ARMED',
  'HISTORY_EVENT_PARTIAL',
  'HISTORY_EVENT_DISARMED',
  'mains_restored',
  'mains',
  'OUTDOOR_PLACE',
  'UNPLACED_PLACE',
  'BASEMENT_PLACE',
  'GROUND_FLOOR_PLACE',
  '1ST_FLOOR_PLACE',
  '2RD_FLOOR_PLACE',
  '3RD_FLOOR_PLACE',
  '4TH_FLOOR_PLACE',
  'ATTIC_PLACE',
  'HALLWAY_PLACE',
  'KITCHEN_PLACE',
  'ENTRANCE_PLACE',
  'DINING_ROOM_PLACE',
  'MASTER_BEDROOM_PLACE',
  'BEDROOM_PLACE',
  'KIDS_ROOM_PLACE',
  'STAIRS_PLACE',
  'CELLAR_PLACE',
  'BASEMENT_PLACE',
  'TECHNICAL_ROOM_PLACE',
  'WINE_CELLAR_PLACE',
  'LIVING_ROOM_PLACE',
  'GUEST_BEDROOM_PLACE',
  'TV_ROOM_PLACE',
  'OFFICE_PLACE',
  'BATHROOM_PLACE',
  'TOILET_PLACE',
  'BAR_PLACE',
  'STORAGE_ROOM_PLACE',
  'HOME_THEATER_PLACE',
  'LIBRARY_PLACE',
  'GYM_PLACE',
  'LAUNDRY_ROOM_PLACE',
  'PLAY_ROOM_PLACE',
  'POOL_PLACE',
  'DRESSING_ROOM_PLACE',
  'FOOD_STORAGE_PLACE',
  'BOILER_HOUSE_PLACE',
  'GUN_SAFE_PLACE',
  'CRAFTS_ROOM_PLACE',
  'WALK_IN_CLOSET_PLACE',
  'BIKE_SHED_PLACE',
  'GUEST_HOUSE_PLACE',
  'RENTAL_PLACE',
  'panel_access',
  'pin_code_to_panel',
  'resend_confimation',
  'registered_account',
  'registered',
  'didnt_you_get_the_email?',
  'failed_to_resend_confimation_email',
  'login_using_email',
  'login_already_registered',
  'TERMS_AND_CONDITIONS',
  'odd_arne_sas',
  'customer_support',
  'HISTORY_END',
  'history_storing',
  'or___',
  'to_login',
  'toast_smart_plug_turn_on_success',
  'toast_smart_plug_turn_off_success',
  'toast_smart_plug_turn_on_error',
  'toast_smart_plug_turn_off_error',
  'too_many_panelusers_info',
  'Must_differ',
  'invalid_pin_code',
  'user_blocked_use_pwreset',
  'email_validation_needed',
  'EMAIL_CONFIRMATION_REQUIRED',
  'email_verification_description',
  'email_verification_resent',
  'email_already_verified',
  'email_exists_but_is_not_sas',
  '401statuscode',
  '503statuscode',
  'otp_expired',
  'WRONG_PINCODE',
  'warning_toast_generic_title',
  '2fa_expired_and_refreshed',
  'DISCONNECTED',
  'GARAGE_PLACE',
  'INBOX_EMPTY',
  'your_points',
  'remaining',
  'not_enough_points',
  'temperature_sensor_default',
  'motion_detector',
  'smoke_detector',
  'flood_detector',
  'siren',
  'keypad',
  'glass_break_detector',
  'camera',
  'choose_partner',
  'partner',
  'customer_partner_id',
  'customer_identification_explanation',
  'error_connect_benefits',
  'benefit_list',
  'benefit_list_activate',
  'benefit_cannot_find',
]

export type TranslationKey = typeof keys[number]

export const isTranslationKey = (key: string): key is TranslationKey => {
  return keys.includes(key as TranslationKey)
}
