import { useState } from 'react'
import styled from 'styled-components'
import { HamburgerIcon } from '../../../../assets/icons'
import useI18n from '../../../../i18n/useI18n'
import { TranslationKeyOverride } from '../../../../types/TranslationKeyOverride'
import Drawer from '../../Drawer/Drawer'
import { colors } from '../../Style/theme'
import NavigationItem from '../NavigationItem'
import { systemRouteList, userRouteList } from '../NavigationRoutes'

const MobileMenu = () => {
  const { t } = useI18n()
  const [isOpen, setIsOpen] = useState(false)

  const openOrCloseDrawer = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <S.Button onClick={openOrCloseDrawer}>
        <S.HamburgerIcon />
      </S.Button>
      <>
        <S.Drawer isOpen={isOpen} handleClose={openOrCloseDrawer} position="right">
          <div className="flex ml-9 pb-3 pt-2 justify-end">
            {Object.values(userRouteList).map((navItem) => {
              return (
                <NavigationItem
                  key={navItem.path}
                  path={navItem.path}
                  icon={navItem.icon!}
                  name={''}
                  onClick={openOrCloseDrawer}
                />
              )
            })}
          </div>
          <div style={{ marginRight: '16px' }}>
            {Object.values(systemRouteList).map((navItem) => {
              return (
                <NavigationItem
                  key={navItem.path}
                  path={navItem.path}
                  icon={navItem.icon}
                  name={t(navItem.name! as TranslationKeyOverride)}
                  onClick={openOrCloseDrawer}
                />
              )
            })}
          </div>
        </S.Drawer>
      </>
    </>
  )
}

const S = {
  Button: styled.button`
    color: white;
  `,
  Drawer: styled(Drawer)`
    padding-bottom: 16px;
    padding-left: 16px;
    width: 275px;
    overflow-y: hidden;
    background-color: ${colors.black900};
    color: ${colors.white};
  `,
  HamburgerIcon: styled(HamburgerIcon)`
    fill: ${colors.blue};
    width: 34px;
  `,
}

export default MobileMenu
