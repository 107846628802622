import React from 'react'

const RightPrimaryL = () => {
  return (
    <svg width="21" height="48" viewBox="0 0 21 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8811 11.2771C20.6282 15.513 21 19.7564 21 24C21 28.2436 20.6282 32.487 19.8811 36.7229C18.7027 43.4039 14.7627 46.9559 7.97449 47.5951C5.57617 47.8221 3.15912 47.9882 0.75 48H0V0H0.75C3.15912 0.0118404 5.57617 0.177863 7.97449 0.404854C14.7627 1.04407 18.7027 4.59615 19.8811 11.2771Z" />
    </svg>
  )
}

export default RightPrimaryL
