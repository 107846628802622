import styled from 'styled-components'
import { colors } from '../../../components/Crow/Style/theme'
import { SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import SmartPlugsListItem from './SmartPlugsListItem'

interface SmartPlugSettingsType {
  smartPlug: {
    Label: string
  }
}

const SmartPlugsSettings = (smartPlugs: SmartPlugSettingsType) => {
  const { t } = useI18n()

  return (
    <SmartPlugsContainer>
      <StyledSubtitle>{t('Name')}</StyledSubtitle>
      <StyledSmartPlugsListItem text={smartPlugs.smartPlug.Label!} />
    </SmartPlugsContainer>
  )
}

const SmartPlugsContainer = styled.div`
  padding-left: 44px;
`

const StyledSmartPlugsListItem = styled(SmartPlugsListItem)`
  border: 1px solid ${colors.black200};
  width: 492px;
`

const StyledSubtitle = styled(SubTitle)<{ pt?: string | number; pb?: string | number }>`
  margin: 0;
  padding-top: ${({ pt }) => pt + 'px'};
  padding-bottom: ${({ pb }) => pb + 'px'};
`

export default SmartPlugsSettings
