function SvgLocked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6V8H14V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4ZM8 6V8H4.72727C3.74454 8 3 8.81273 3 9.75V20.25C3 21.1873 3.74454 22 4.72727 22H19.2727C20.2555 22 21 21.1873 21 20.25V9.75C21 8.81273 20.2555 8 19.2727 8H16V6C16 4.93913 15.5786 3.92172 14.8284 3.17157C14.0783 2.42143 13.0609 2 12 2C10.9391 2 9.92172 2.42143 9.17157 3.17157C8.42143 3.92172 8 4.93913 8 6ZM5 10V20H19V10H5ZM13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgLocked
