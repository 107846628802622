import * as React from 'react'

function SvgDoor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="48" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{'door'}</title>
      <path
        d="M15.5 10.25C15.5 10.6642 15.1642 11 14.75 11C14.3358 11 14 10.6642 14 10.25C14 9.83579 14.3358 9.5 14.75 9.5C15.1642 9.5 15.5 9.83579 15.5 10.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93934 0.93934C4.22064 0.658036 4.60217 0.5 5 0.5H17C17.3978 0.5 17.7794 0.658035 18.0607 0.93934C18.342 1.22065 18.5 1.60218 18.5 2V20H20.75C21.1642 20 21.5 20.3358 21.5 20.75C21.5 21.1642 21.1642 21.5 20.75 21.5H1.25C0.835786 21.5 0.5 21.1642 0.5 20.75C0.5 20.3358 0.835786 20 1.25 20H3.5V2C3.5 1.60217 3.65804 1.22064 3.93934 0.93934ZM5 20H17V2L5 2V20Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDoor
