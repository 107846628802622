import React, { FC } from 'react'
import useI18n from '../../../i18n/useI18n'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { pinCodeValidator } from '../../../components/forms/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '../../../components/Button'
import ValidationPopover from '../../../components/forms/ValidationPopover'
import FormButtons from '../../../components/forms/FormButtons'
import { TranslationKeyOverride } from '../../../types/TranslationKeyOverride'

const resolver = yup.object({
  code: pinCodeValidator,
})

interface Props {
  onSubmit: (values: { code: string }) => void
  onResendSms: () => void
  isLoading: boolean
}

const AcceptCodeForm: FC<Props> = ({ onSubmit, onResendSms, isLoading, children }) => {
  const { t } = useI18n()
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(resolver),
    mode: 'onChange',
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="slide-from-right" data-testid="form">
      <label className="form">
        {t('One time code')}
        <input className="form__input" {...register('code')} />
        <ValidationPopover errorMessage={errors.code?.message as TranslationKeyOverride} />
      </label>
      {children}
      <div className="bold text-lg">{t('You should have the SMS whithin 30 seconds.')}</div>
      <div>
        {t('Did not get it?')}
        <Button buttonType="link" onClick={onResendSms}>
          {t('Try again')}
        </Button>
      </div>
      <FormButtons isLoading={isLoading} isValid={isValid} submitText="Next" cancelUrl={'/'} />
    </form>
  )
}

export default AcceptCodeForm
