function SvgBedroom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="26" viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 23H40V25C40 25.5523 40.4477 26 41 26C41.5523 26 42 25.5523 42 25V13C42 11.5194 41.1956 10.2267 40 9.53513V4C40 1.79086 38.2091 0 36 0H6C3.79086 0 2 1.79086 2 4V9.53513C0.804397 10.2267 0 11.5194 0 13V25C0 25.5523 0.447715 26 1 26C1.55228 26 2 25.5523 2 25V23ZM6 2H36C37.1046 2 38 2.89543 38 4V9H36V8C36 5.79086 34.2091 4 32 4H26C23.7909 4 22 5.79086 22 8V9H20V8C20 5.79086 18.2091 4 16 4H10C7.79086 4 6 5.79086 6 8V9H4V4C4 2.89543 4.89543 2 6 2ZM24 9H34V8C34 6.89543 33.1046 6 32 6H26C24.8954 6 24 6.89543 24 8V9ZM4 11C2.89543 11 2 11.8954 2 13V21H40V13C40 11.8954 39.1046 11 38 11H4ZM18 8V9H8V8C8 6.89543 8.89543 6 10 6H16C17.1046 6 18 6.89543 18 8Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgBedroom
