import React, { FC } from 'react'
import RequestLoader from '../../components/Elements/Loaders'
import NavBarPage from '../../components/pages/NavBarPage'
import SubFooter from '../../components/system/SubFooter'
import useI18n from '../../i18n/useI18n'

interface Props {
  isLoading: boolean
  hideNavbar?: boolean
}

const Page: FC<Props> = ({ isLoading, children }) => {
  const { t } = useI18n()
  return (
    <>
      {' '}
      <div>
        <h1 className="center mb-8">{t('messages')}</h1>
        <div className="background-gray grid">
          {isLoading ? (
            <RequestLoader loadingText={{ text: 'Loading' }} />
          ) : (
            <div className="grid__half mx-8 lg:mx-0">{children}</div>
          )}
        </div>
      </div>
      <SubFooter />
    </>
  )
}

const MessagePage: FC<Props> = ({ isLoading, children, hideNavbar }) => {
  if (hideNavbar) {
    return <Page isLoading={isLoading}>{children}</Page>
  }
  return (
    <NavBarPage isLoading={false}>
      <Page isLoading={isLoading}>{children}</Page>
    </NavBarPage>
  )
}

export default MessagePage
