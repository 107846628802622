import { Locale } from 'date-fns'
import { enGB, es, fi, fr, nb, sv, it } from 'date-fns/locale'
import { useMemo } from 'react'
import { getPreferredLang } from '../i18n/i18n'
import { useAuth } from '../services/authentication/AuthContext'
import { useUserInfo } from '../services/requestHooks/user'
import { UserCultureInfo } from '../types/UserDetails'

export const useDateFnsLocale = () => {
  const User = useUserInfo()
  const { isAuthenticated } = useAuth()
  const locale = useMemo<Locale>(() => {
    const userCultureInfo = isAuthenticated ? User.UserCultureInfo : getPreferredLang()
    switch (userCultureInfo) {
      case UserCultureInfo.SWEDISH:
        return sv
      case UserCultureInfo.NORWEGIAN:
        return nb
      case UserCultureInfo.FINNISH:
        return fi
      case UserCultureInfo.FRENCH:
        return fr
      case UserCultureInfo.SPANISH:
        return es
      case UserCultureInfo.ITALIAN:
        return it
      case UserCultureInfo.ENGLISH:
      default:
        return enGB
    }
  }, [User.UserCultureInfo, isAuthenticated])
  return locale
}
