import React, { FC } from 'react'
import styled from 'styled-components'

import Icon from '../Icon/Icon'
import { colors, pickIconColor } from '../Style/theme'

interface Props {
  color?: string
  text?: string
  subText?: string
  icon?: string
  className?: string
}

const KeyBox: FC<Props> = ({ color = '#E3E1E1', text, subText, className }) => {
  return (
    <S.Container className={className}>
      <S.IconContainer color={color}>
        <Icon name="Key" color={pickIconColor(color)} />
      </S.IconContainer>
      <S.TextContainer>
        <S.Text>{text}</S.Text>
        <S.SubText>{subText}</S.SubText>
      </S.TextContainer>
    </S.Container>
  )
}

const S = {
  Container: styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    height: 62px;
    box-shadow: 0 0 0 1px ${colors.black200};
    border-radius: 12px;
    padding: 15px;
  `,
  IconContainer: styled.div<{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: ${({ color }) => color};
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Text: styled.p`
    margin: 0;
    font-family: Metric;
    font-size: 20px;
    line-height: 18px;
    color: ${colors.black800};
  `,
  SubText: styled.p`
    font-family: Metric;
    font-size: 16px;
    line-height: 18px;
    color: ${colors.black700};
  `,
}

export default KeyBox
