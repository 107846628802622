import * as React from 'react'

function SvgInvoices(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <title>{'Invoices_Sector Alarm_app_icon'}</title>
      <path d="M94 34.1H50.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8H94c.4 0 .7.3.7.7V94c0 .4-.3.7-.7.7h-2.4c-.4 0-.7-.3-.7-.7V45.8c0-2.5-2-4.5-4.5-4.5H45.7c-2.5 0-4.5 2-4.5 4.5v55.7c0 2.5 2 4.5 4.5 4.5h43c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8H46c-.4.1-.8-.2-.9-.6v-56c0-.4.4-.7.8-.6h40.7c.4 0 .7.3.7.7v48.2c0 2.5 2 4.5 4.5 4.5h2.4c2.5 0 4.5-2 4.5-4.5V38.5c-.1-2.5-2.1-4.5-4.6-4.4H94z" />
      <path d="M78.9 52.1H53c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h25.9c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zM53 73.3c-1 0-1.8.8-1.8 1.8S52 77 53 77h12.9c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8H53zm27.7-8.8c0-1-.8-1.8-1.8-1.8H53c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h25.9c1 .1 1.8-.7 1.8-1.8 0 .1 0 .1 0 0z" />
    </svg>
  )
}

export default SvgInvoices
