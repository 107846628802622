import { isToday } from 'date-fns'
import styled from 'styled-components'
import { colors } from '../components/Crow/Style/theme'
import useI18n from '../i18n/useI18n'
import { PanelStatus } from '../types/PanelModel'
import convertAndFormatTime from './ConvertAndFormatTIme'
import { useDateFnsLocale } from './useDateFnsLocale'

interface ArmingStatusTextType {
  panelStatus: {
    Status: PanelStatus | undefined
    TimeZoneName: string | undefined
    StatusTimeUtc: string | undefined
  }
  isLoading: boolean
}

const ArmingStatusText = ({ panelStatus, isLoading }: ArmingStatusTextType) => {
  const locale = useDateFnsLocale()
  const { t } = useI18n()

  const StatusText = () => {
    return (
      <StatusTextContainer>
        {isLoading ? (
          <>
            {`${(panelStatus?.Status === PanelStatus.FULLY_ARMED && t('armed')) ||
              (panelStatus?.Status === PanelStatus.HOME_MODE && t('partialarmed')) ||
              (panelStatus?.Status === PanelStatus.TURNED_OFF && t('disarmed'))}   ${
              isToday(new Date(panelStatus?.StatusTimeUtc!)) ? `${t('Today')}` : `${t('at')}`
            } `}

            {convertAndFormatTime(
              panelStatus?.TimeZoneName,
              locale,
              panelStatus?.StatusTimeUtc,
              false,
            )}
          </>
        ) : (
          <>{t('Loading alarm system status')}</>
        )}
      </StatusTextContainer>
    )
  }

  return <StatusText />
}

const StatusTextContainer = styled.span`
  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    color: ${colors.lightBlue};
  }
`

export default ArmingStatusText
