import styled from 'styled-components'

import useI18n from '../../../i18n/useI18n'
import Icon from '../../../components/Crow/Icon/Icon'
import { PermanentUser } from '../../../types/PermanentUser'
import { colors, pickIconColor } from '../../../components/Crow/Style/theme'

const hasPermissions = (user: PermanentUser) => {
  return user.Accesses.IsSystemUser || user.Accesses.IsAppUser || !!user.Tag?.Color
}

const LabelContainer = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

const LabelCircle = styled.span<{ color: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
`

const Text = styled.span`
  font-family: 'Metric';
  font-size: 14px;
  line-height: 18px;
`

const Label = ({
  color = '#E3E1E1',
  icon,
  text,
}: {
  color?: string
  icon: string
  text: string
}) => (
  <LabelContainer>
    <LabelCircle color={color}>
      <Icon name={icon} color={pickIconColor(color)} />
    </LabelCircle>
    <Text>{text}</Text>
  </LabelContainer>
)

interface props {
  user: PermanentUser
}

const Permissions = ({ user }: props) => {
  const { t } = useI18n()

  if (!hasPermissions(user))
    return (
      <S.WrapperNoPermissions>
        <Text>{t('no_permissions')}</Text>
      </S.WrapperNoPermissions>
    )
  return (
    <S.Wrapper>
      {user.Accesses.IsSystemUser && <Label icon="System" text={t('panel_access')} />}
      {user.Accesses.IsAppUser && <Label icon="App" text={t('app_access')} />}
      {user.Tag?.Color && <Label color={user.Tag.Color} icon="Key" text={t('key_tag')} />}
    </S.Wrapper>
  )
}

const S = {
  Wrapper: styled.div`
    display: flex;
    gap: 24px;
    width: 100%;
  `,
  WrapperNoPermissions: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.black500};
    width: 100%;
    height: 100%;
  `,
}

export default Permissions
