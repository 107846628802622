import { MouseEventHandler } from 'react'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import { useAuth } from '../../../services/authentication/AuthContext'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { useGetUsers } from '../../../services/requestHooks/users'
import { useOnMount } from '../../../utils/commonHooks'
import Icon, { IconName } from '../Icon/Icon'
import { colors } from '../Style/theme'
import { Paragraph } from '../Typography'

type Props = {
  path: string
  name: string
  icon: IconName
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const NavigationItem = ({ path, name, icon, onClick }: Props) => {
  const panelId = usePanelId()

  const params = useParams()
  const to = generatePath(path, params)

  const { data: panelInfo, run: loadPanelInfo, isLoading: loadingPanelInfo } = usePanelInfo()

  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const { run: getUsers, isLoading: loadingUsers, data: usersData } = useGetUsers()

  const {
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
    data: smokeDetectors,
  } = useSmokeDetectors()

  useOnMount(() => {
    if (!loadingPanelInfo && !panelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingDoorsAndWindows && !doorsAndWindows) {
      getDoorsAndWindows(panelId)
    }
    if (!loadingUsers && !usersData) {
      getUsers(panelId)
    }
    if (!loadingSmokeDetectors && !smokeDetectors) {
      getSmokeDetectors(panelId)
    }
  })

  const { logout } = useAuth()

  const renderRoutes = (icon: any) => {
    switch (icon) {
      case panelInfo?.Smartplugs?.length! <= 0 && 'smartplug':
        return null
      case panelInfo?.Temperatures.length! <= 0 && 'Temperature':
        return null
      case doorsAndWindows?.Floors.length! <= 0 && 'Doors':
        return null
      case panelInfo?.Locks.length! <= 0 && 'Unlocked':
        return null
      case usersData === undefined && 'People':
        return null
      case smokeDetectors?.Floors.length! <= 0 && 'Smoke-Detector':
        return null
      case panelInfo?.Photos.length! <= 0 && 'Camera':
        return null
      default:
        return (
          <S.Link
            to={to}
            exact
            onClick={icon === 'Sign-out' ? logout : onClick}
            activeStyle={{
              color: colors.blue,
              border: `1px solid ${colors.blue}`,
              borderRadius: '12px',
            }}
          >
            <S.Icon name={icon} />
            <Paragraph large>{name}</Paragraph>
          </S.Link>
        )
    }
  }

  return <>{renderRoutes(icon!)}</>
}

const S = {
  Link: styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    margin: 0 0.5rem;
    font-size: 25px;
    text-decoration: none;
    color: ${colors.white};
    &:hover {
      color ${colors.blue};
    }    
   @media only screen and (max-width: 768px) {
    width: 200px;

}
}
  `,
  chink: styled(NavLink)`
    padding-top: 23px;
  `,
  Icon: styled(Icon)`
    margin-right: 1rem;
  `,
  FixedSignOutIconContainer: styled.div`
    display: flex;
    padding-right: 20px;
    @media only screen and (max-width: 768px) {
      margin-left: 18px;
    }
    @media only screen and (min-width: 768px) {
      margin-left: 18px;
    }
  `,
}

export default NavigationItem
