import * as yup from 'yup'
import { validatePhoneNumberLength, isValidPhoneNumber } from 'libphonenumber-js'
import { tFunc } from '../../types/i18n'

export const phoneValidator = yup
  .string()
  .matches(/^[0-9]*$/, 'Special characters are not allowed')
  .test('length', 'Phone number has to be at lest 8 digits', (val) => !val || val.length >= 7)
  .test('already used', 'This number is already registred.', (val, context) => {
    if (val) {
      const matchingNumbers = [
        context.parent.Mobile,
        context.parent.Home,
        context.parent.Work,
      ].filter((number) => number === val)
      if (matchingNumbers.length > 1) {
        return false
      }
    }
    return true
  })

export const phoneRequiredValidator = yup
  .string()
  .required('Required')
  .matches(/^[0-9.+]*$/, 'Please provide a valid number')
  .test('length', 'Phone number has to be at least 8 digits.', (val) => !val || val.length >= 8)

export const phoneNumberValidator = (t: tFunc) =>
  yup
    .string()
    .test('length', t('error_phone_number_too_long'), (val) => {
      return !val || (!!val && validatePhoneNumberLength(val) !== 'TOO_LONG')
    })
    .test(
      'validity',
      t('error_phone_number_invalid'),
      (val) => !val || (!!val && isValidPhoneNumber(val)),
    )

export const customerNumberValidator = yup
  .string()
  .min(1, 'Tell us your customer number, if you do not have it, contact support')
  .matches(/^\d{8}$/, 'Customer number has to be 8 digits')

/* eslint-disable no-useless-escape */
export const emailValidator = yup
  .string()
  .matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'This is not a valid email',
  )

export const repeatEmailValidator = emailValidator.test(
  'different emails',
  'Emails are not the same',
  (val, ctx) => {
    return val === ctx.parent.email
  },
)

/* eslint-enable no-useless-escape */
export const passwordValidator = yup
  .string()
  .min(8, 'Password should be at least 8 characters long')
  .matches(/[0-9]/, 'Password must contain at least one number')
  .matches(/[a-zæøåäö]/, 'Password must contain at least one lowercase letter')
  .matches(/[A-ZÆØÅÄÖ]/, 'Password must contain at least one uppercase letter')

export const passwordConfirmValidator = passwordValidator.test(
  'equal',
  'Repeat password',
  (value, context) => value === context.parent.password,
)

export const pinCodeValidator = yup
  .string()
  .required('Enter valid pin')
  .matches(/^[0-9]+$/, 'This is not a valid one time code.')
  .matches(/^\d{6}$/, 'One time code has to be 6 digits.')

export const panelCodeValidator = (requiredLength: number, t: tFunc) =>
  yup
    .string()
    .matches(/^[0-9]*$/, t('error_pin_code_digits_only'))
    .test('panelCodeLength', t('error_pin_code_length', { requiredLength }), (val) =>
      val!.length > 0 ? val!.length === requiredLength : true,
    )

export const legacyPanelCodeValidator = (requiredLength: number, t: tFunc) => {
  return yup
    .string()
    .required('Enter valid pin')
    .matches(/^[0-9]+$/, 'invalid_pin_code')
    .matches(new RegExp(`^\\d{${requiredLength}}$`), t('error_pin_code_length', { requiredLength }))
}

export const baseValidator = yup
  .string()
  .required('Required')
  .min(2, 'At least 2 characters is required')
  .matches(
    /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/,
    'Special characters are not allowed',
  )

export const addPanelUserValidator = yup
  .string()
  .required('Required')
  .max(8, 'Name cant be greater than 8 characters')
  .matches(
    /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/,
    'Special characters are not allowed',
  )
