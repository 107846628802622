/* eslint-disable import/no-unused-modules */
import FocusTrap from 'focus-trap-react'
import React, { ReactNode, useState, useEffect, MouseEventHandler } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'
import { Title } from '../Typography'

export type Props = {
  className?: string
  children?: ReactNode
  isOpen: boolean
  handleClose: MouseEventHandler<HTMLButtonElement>
  position: 'top' | 'bottom' | 'left' | 'right'
  title?: ReactNode
  closeButton?: boolean
  closeIcon?: ReactNode
  pauseFocusTrap?: boolean
}

const closeTime = 0.25

const Drawer = ({
  className,
  children,
  isOpen,
  handleClose,
  position,
  title,
  closeButton,
  closeIcon,
  pauseFocusTrap,
}: Props) => {
  const rootRef = document.getElementById('root')
  const buildTagRef = document.getElementById('build-tag')
  const [shouldShow, setShouldShow] = useState(isOpen)

  useEffect(() => {
    return () => {
      // cleanup before the component unmounts
      rootRef?.style.removeProperty('overflow')
      rootRef?.style.removeProperty('position')
      rootRef?.style.removeProperty('width')
      rootRef?.style.removeProperty('min-height')
      rootRef?.style.removeProperty('top')
    }
  }, [rootRef])

  useEffect(() => {
    if (!isOpen && shouldShow) {
      const buildTagHeight = buildTagRef?.parentElement?.clientHeight || 0
      const scrollY = -(parseInt(rootRef!.style.top) || 0) + buildTagHeight
      setTimeout(() => {
        rootRef!.style.removeProperty('overflow')
        rootRef!.style.removeProperty('position')
        rootRef!.style.removeProperty('width')
        rootRef!.style.removeProperty('min-height')
        rootRef!.style.removeProperty('top')
        window.scrollTo(0, scrollY)
        setShouldShow(false)
      }, closeTime * 1000)
    }
    if (isOpen) {
      const buildTagHeight = buildTagRef?.parentElement?.clientHeight || 0
      const scrollY = window.scrollY - buildTagHeight
      rootRef!.style.overflow = 'hidden'
      rootRef!.style.position = 'fixed'
      rootRef!.style.width = '100%'
      rootRef!.style.minHeight = `calc(100vh - ${buildTagHeight}px`
      rootRef!.style.top = `${-scrollY}px`
      setShouldShow(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!shouldShow) {
    return null
  }

  return createPortal(
    <FocusTrap paused={!!pauseFocusTrap}>
      <S.Container position={position}>
        <S.Close isOpen={isOpen} onClick={handleClose} />
        <S.Drawer className={className} isOpen={isOpen} position={position}>
          {(!!title || closeButton) && (
            <S.Header className="drawer-header">
              {closeButton && (
                <S.CloseButton onClick={handleClose} className="drawer-close">
                  <span>{closeIcon}</span>
                </S.CloseButton>
              )}
              {!!title && <Title className="drawer-title mt-0">{title}</Title>}
            </S.Header>
          )}
          {children}
        </S.Drawer>
      </S.Container>
    </FocusTrap>,
    document.body,
  )
}

const positionCSS = {
  top: css`
    align-self: start;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    @keyframes open-top {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
    @keyframes close-top {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-100%);
      }
    }
  `,
  bottom: css`
    align-self: end;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    @keyframes open-bottom {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }
    @keyframes close-bottom {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(100%);
      }
    }
  `,
  left: css`
    min-height: 100vh;
    justify-self: start;
    overflow-x: auto;
    @keyframes open-left {
      from {
        transform: translateX(-100%);
        position: absolute;
      }
      to {
        transform: translateX(0);
      }
    }
    @keyframes close-left {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
  `,
  right: css`
    min-height: 100vh;
    justify-self: end;
    overflow-x: auto;
    @keyframes open-right {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
    @keyframes close-right {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(100%);
      }
    }
  `,
}

const S = {
  Container: styled.div<{ position: 'top' | 'bottom' | 'left' | 'right' }>`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    ${({ position }) =>
      position === 'top' || position === 'bottom'
        ? css`
            overflow: hidden;
          `
        : css`
            overflow-y: auto;
            overflow-x: hidden;
          `}
    display: grid;
    grid-template-areas: 'drawer';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  `,
  Close: styled.button<{ isOpen: boolean }>`
    grid-area: drawer;
    background-color: ${colors.black};

    animation-duration: ${closeTime}s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: ${({ isOpen }) => (isOpen ? 'show-button' : 'hide-button')};

    @keyframes show-button {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.55;
      }
    }
    @keyframes hide-button {
      from {
        opacity: 0.55;
      }
      to {
        opacity: 0;
      }
    }
  `,
  Drawer: styled.div<{ isOpen: boolean; position: 'top' | 'bottom' | 'left' | 'right' }>`
    grid-area: drawer;
    height: 100%;

    ${({ position }) => positionCSS[position]}
    animation-duration: ${closeTime}s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-name: ${({ isOpen, position }) => `${isOpen ? 'open' : 'close'}-${position}`};
  `,
  Header: styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'title close';
    margin: 0;
  `,
  Title: styled(Title)`
    grid-area: title;
  `,
  CloseButton: styled.button`
    grid-area: close;
  `,
}

export default Drawer
