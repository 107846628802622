function SvgGaragePlace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38 1C38 0.447716 37.5523 0 37 0H1C0.447716 0 0 0.447716 0 1V38C0 38.5523 0.447715 39 0.999999 39C1.55228 39 2 38.5523 2 38L2 10H36V38C36 38.5523 36.4477 39 37 39C37.5523 39 38 38.5523 38 38V1ZM2 4V2H36V4H2ZM2 8L2 6H36V8H2Z"
        fill="#666363"
      />
      <path
        d="M12 29C12 29.5523 11.5523 30 11 30C10.4477 30 10 29.5523 10 29C10 28.4477 10.4477 28 11 28C11.5523 28 12 28.4477 12 29Z"
        fill="#666363"
      />
      <path
        d="M27 30C27.5523 30 28 29.5523 28 29C28 28.4477 27.5523 28 27 28C26.4477 28 26 28.4477 26 29C26 29.5523 26.4477 30 27 30Z"
        fill="#666363"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.65 15H26.354C26.7418 15.0016 27.1208 15.1158 27.4448 15.3289C27.7677 15.5412 28.0221 15.8425 28.1774 16.1963L28.179 16.2L31.6496 24H33C33.5523 24 34 24.4477 34 25C34 25.5523 33.5523 26 33 26H32V36C32 36.5304 31.7893 37.0391 31.4142 37.4142C31.0391 37.7893 30.5304 38 30 38H27C26.4696 38 25.9609 37.7893 25.5858 37.4142C25.2107 37.0391 25 36.5304 25 36V34H13V36C13 36.5304 12.7893 37.0391 12.4142 37.4142C12.0391 37.7893 11.5304 38 11 38H8C7.46957 38 6.96086 37.7893 6.58579 37.4142C6.21071 37.0391 6 36.5304 6 36V26H5C4.44771 26 4 25.5523 4 25C4 24.4477 4.44771 24 5 24H6.35042L9.82266 16.1962C9.97793 15.8424 10.2324 15.5412 10.5552 15.3289C10.8792 15.1158 11.2582 15.0016 11.646 15L11.65 15ZM26.346 17L29.4605 24H8.53946L11.6512 17.0065L11.654 17L26.346 17ZM8 32V26H30V32H8ZM27 36V34H30V36H27ZM8 34H11V36H8V34Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgGaragePlace
