function SvgMasterBedroom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="26" viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 16.7271C5.44772 16.7271 5 17.1748 5 17.7271C5 18.2793 5.44772 18.7271 6 18.7271L36 18.7271C36.5523 18.7271 37 18.2793 37 17.7271C37 17.1748 36.5523 16.7271 36 16.7271L6 16.7271Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 23H2V25C2 25.5523 1.55228 26 1 26C0.447715 26 0 25.5523 0 25V13C0 11.5194 0.804397 10.2267 2 9.53513V4C2 1.79086 3.79086 0 6 0H36C38.2091 0 40 1.79086 40 4V9.53513C41.1956 10.2267 42 11.5194 42 13V25C42 25.5523 41.5523 26 41 26C40.4477 26 40 25.5523 40 25V23ZM36 2H6C4.89543 2 4 2.89543 4 4V9H6V8C6 5.79086 7.79086 4 10 4H16C18.2091 4 20 5.79086 20 8V9H22V8C22 5.79086 23.7909 4 26 4H32C34.2091 4 36 5.79086 36 8V9H38V4C38 2.89543 37.1046 2 36 2ZM34 9H24V8C24 6.89543 24.8954 6 26 6H32C33.1046 6 34 6.89543 34 8V9ZM2 13C2 11.8954 2.89543 11 4 11H38C39.1046 11 40 11.8954 40 13V21H2V13ZM18 9V8C18 6.89543 17.1046 6 16 6H10C8.89543 6 8 6.89543 8 8V9H18Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgMasterBedroom
