import React, { FC, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'
import Icon, { IconName } from '../Icon/Icon'

import { colors } from '../Style/theme'
import { SubTitle } from '../Typography'

export interface Props {
  title: string
  icon?: IconName
  className?: string
  onClick?: MouseEventHandler
}

interface ClickableProps {
  title: string
  icon?: IconName
  className?: string
  onClick?: MouseEventHandler
  rightContent?: boolean
}

export interface PillProps {
  icon?: IconName
  title: string
  onClick: MouseEventHandler
  className?: string
}

export const Pill = ({ icon, title, onClick, className }: PillProps) => {
  return (
    <S.Pill onClick={onClick} className={className}>
      <S.Inline>
        {icon && <S.Icon name={icon} />}
        <SubTitle>{title}</SubTitle>
      </S.Inline>
      <S2.Chevron />
    </S.Pill>
  )
}

export const Clickable: FC<ClickableProps> = ({
  children,
  title,
  icon,
  className,
  onClick,
  rightContent,
}) => {
  return (
    <S3.Details className={className} onClick={onClick}>
      <S3.Summary>
        <S3.Inline>
          {icon && <S.Icon name={icon} />}
          <SubTitle>{title}</SubTitle>
        </S3.Inline>
        {rightContent ? <>{children}</> : <S2.Chevron />}
      </S3.Summary>
    </S3.Details>
  )
}

const Collapsible: FC<Props> = ({ children, title, icon, className, onClick }) => {
  return (
    <S.Details className={className} onClick={onClick}>
      <S.Summary>
        <S.Inline>
          {icon && <S.Icon name={icon} />}
          <SubTitle>{title}</SubTitle>
        </S.Inline>
        <S2.Chevron />
      </S.Summary>
      {children}
    </S.Details>
  )
}

const pillCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
const pillOuter = css`
  background-color: ${colors.white};
  padding: 1rem;
  border-radius: 16px;
  flex: 1;
`

const S = {
  Pill: styled.button`
    width: 100%;
    ${pillCss}
    ${pillOuter}
  `,
  Summary: styled.summary`
    ${pillCss}
  `,
  Details: styled.details`
    ${pillOuter};

    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    max-height: 100px;
    &[open] {
      max-height: 5000px;

      > summary {
        margin-bottom: 1rem;
      }
    }

    &[open] > summary > [class*=' icon-m-'] {
      transform: rotateZ(90deg);
    }
  `,
  Inline: styled.span`
    display: flex;
    align-items: center;
  `,
  Icon: styled(Icon)`
    font-size: 20px;
    border-radius: 8px;
    padding: 0.5rem;
    margin-right: 1rem;
    background-color: ${colors.black300};
  `,
}

const S2 = {
  Chevron: styled(Icon).attrs({ name: 'Chevron-right' })`
    font-size: 26px;
    transition: transform 150ms ease-out;
  `,
}

const S3 = {
  Pill: styled.button`
    width: 100%;
    ${pillCss}
    ${pillOuter}
  `,
  Summary: styled.summary`
    ${pillCss}
  `,
  Details: styled.details`
    ${pillOuter};

    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    max-height: 100px;
  `,
  Inline: styled.span`
    display: flex;
    align-items: center;
  `,
  Icon: styled(Icon)`
    font-size: 20px;
    border-radius: 8px;
    padding: 0.5rem;
    margin-right: 1rem;
    background-color: ${colors.black300};
  `,
}

export default Collapsible
