import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TranslationKey } from '../types/generated/TranslationKey'

export type LockSettings = {
  Languages: { Language: TranslationKey; Id: string }[]
  Selected: { Id: string; Language: null | TranslationKey }
}

type LockSettingsState = Record<string, LockSettings | undefined>

const initialState: LockSettingsState = {}

const lockSettingsSlice = createSlice({
  initialState,
  name: 'lockSettings',
  reducers: {
    LOAD_GET_LOCK_LANGUAGE_SUCCESS(
      state,
      action: PayloadAction<{ data: LockSettings; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = data
    },
    SET_LOCK_SETTINGS_SUCCESS(state, action: PayloadAction<{ panelId: string }>) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  LOAD_GET_LOCK_LANGUAGE_SUCCESS,
  SET_LOCK_SETTINGS_SUCCESS,
} = lockSettingsSlice.actions

export default lockSettingsSlice.reducer
