import { colors } from '../../../../components/Crow/Style/theme'
import useI18n from '../../../../i18n/useI18n'
import {
  StyledIcon,
  StyledSelectButton,
  StyledSpan,
  VideoPrivacyNoAccessContainer,
} from './VideoPrivacyConsentStyle'

const VideoPrivacyConsentNoAccess = ({ arcVideoConsent }: { arcVideoConsent: boolean }) => {
  const { t } = useI18n()

  return (
    <VideoPrivacyNoAccessContainer>
      {!!arcVideoConsent ? (
        <>
          <StyledSelectButton>
            <StyledIcon name="Checkmark" color={colors.gray} />
            <span>{t('Yes')}</span>
          </StyledSelectButton>
          <StyledSpan>
            Sector Alarm may access my video cameras in the case of an emergency.
          </StyledSpan>
          <span className="mt-4">You don’t have permissions to change this setting</span>
        </>
      ) : (
        <>
          <StyledSelectButton>
            <StyledIcon name="Cancel" color={colors.gray} />
            <span>{t('No')}</span>
          </StyledSelectButton>
          <StyledSpan>
            Sector Alarm may access my video cameras in the case of an emergency.
          </StyledSpan>
          <span className="mt-4">You don’t have permissions to change this setting</span>
        </>
      )}
    </VideoPrivacyNoAccessContainer>
  )
}

export default VideoPrivacyConsentNoAccess
