import './notification-setting-item.scss'
import React from 'react'
import Switch from '../../../../../components/Switch'
import useI18n from '../../../../../i18n/useI18n'
import { EventSetting } from '../../../../../types/NotificationSetting'
import { usePanelId } from '../../../hooks/usePanelId'
import { useToggleNotificationSetting } from '../../../../../services/requestHooks/notifications'

type Props = EventSetting & { Channel: string }

const NotificationSettingItem = ({ EventType, TranslationKey, Enabled, Channel }: Props) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const { run: toggleSetting, isLoading } = useToggleNotificationSetting()

  const onClick = () => {
    toggleSetting({ EventType, Channel, Enabled: !Enabled, PanelId: panelId })
  }

  const id = Channel + EventType

  return (
    <div className="notification-setting-item">
      <label htmlFor={id}>{t(TranslationKey)}</label>
      <Switch id={id} checked={Enabled} onClick={onClick} isLoading={isLoading} />
    </div>
  )
}

export default NotificationSettingItem
