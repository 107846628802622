import { FC, Fragment, useState } from 'react'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import { Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import useI18n from '../../../i18n/useI18n'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { DevicesType, Floor } from '../../../types/DoorsAndWindows'
import { useOnMount } from '../../../utils/commonHooks'
import FloorName from '../../../utils/places/FloorName'
import roomIcon from '../../../utils/places/RoomIcon'
import RoomName from '../../../utils/places/RoomName'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import DoorsAndWindowDetails from './DoorsAndWindowsDetails'
import {
  FlexColumnStart,
  FlexRow,
  FooterDevices,
  RoomNameTitle,
  StyledIcon,
  StyledRoomIcon,
  StyledSubtitle,
  StyledTile,
  TileContainer,
} from './DoorsAndWindowsStyle'
import TileFooterContent from './DoorsAndWindowsTileFooter'

const CrowDoorAndWindowPage: FC = () => {
  const panelId = usePanelId()
  const { t } = useI18n()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    error: doorsAndWindowsError,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!doorsAndWindowsError || !!panelStatusError || !!loadInfoError

  const returnUrl = `/systems/${panelId}`

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingDoorsAndWindows) {
      getDoorsAndWindows(panelId)
    }
  })

  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'right' })

  const [doorsAndWindowsState, setDoorsAndWindowsState] = useState<Floor>()

  const openDrawer = (
    position: 'top' | 'bottom' | 'left' | 'right',
    floors: Floor,
    roomName: string,
    roomKey: string,
    roomDevices: DevicesType[],
  ) => () => {
    setDoorsAndWindowsState({
      Name: floors?.Name!,
      Key: floors?.Key!,
      Rooms: [{ Name: roomName, Key: roomKey, Devices: roomDevices }],
    })

    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <FullscreenPage
        isLoading={loadingDoorsAndWindows || loadingPanelStatus}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: hasError,
            errorHeaderText: t('Door&Win'),
            errorText: !panelStatus?.IsOnline
              ? 'Something went wrong. Please try again later.'
              : 'Something went wrong. Please try again',
            returnUrl,
          },
        }}
      >
        <BackButton backUrl={returnUrl} />
        <Title>{t('Door&Win')}</Title>
        <p className="pt-4 mb-10">{t('DOOR_WINDOWS_DESCRIPTION')}</p>
        <>
          {doorsAndWindows?.Floors.map((floor) => (
            <div key={floor.Key}>
              <StyledSubtitle>
                <FloorName floorName={floor.Key} />
              </StyledSubtitle>
              <TileContainer>
                {floor!.Rooms!.map((room) => (
                  <div key={room.Name}>
                    <FlexRow>
                      <StyledTile
                        borderRadius="12px"
                        onClick={openDrawer('right', floor, room.Name, room.Key!, room.Devices!)}
                        footerPadding="2px 12px 11px 12px"
                        useDynamicHeight
                        footer={
                          <>
                            <div className="flex-col ">
                              <FooterDevices>
                                {`${t('Sensors')} (${room.Devices.length})`}
                              </FooterDevices>
                              <div className="flex">
                                <TileFooterContent props={room.Devices} />
                              </div>
                            </div>
                          </>
                        }
                      >
                        <FlexColumnStart>
                          <StyledRoomIcon name={roomIcon(room.Key)} />
                          <RoomNameTitle>
                            <RoomName roomName={room.Key} />
                          </RoomNameTitle>
                        </FlexColumnStart>
                      </StyledTile>
                    </FlexRow>
                  </div>
                ))}
              </TileContainer>
              <S.Drawer
                isOpen={drawerState.isOpen}
                position={drawerState.position}
                handleClose={closeDrawer}
                closeButton={true}
                title={
                  <S.Header>
                    <S.CloseButton onClick={closeDrawer}>
                      <StyledIcon name="Cancel" color={colors.blue} fontSize="24px" />
                    </S.CloseButton>
                    {doorsAndWindowsState?.Rooms.map((room) => (
                      <Fragment key={room.Name}>
                        <span className="mr-auto">
                          <RoomName roomName={room.Key} /> (
                          {<FloorName floorName={doorsAndWindowsState.Key} />})
                        </span>
                        <StyledRoomIcon name={roomIcon(room.Key)} />
                      </Fragment>
                    ))}
                  </S.Header>
                }
              >
                <div className="pl-10">
                  <DoorsAndWindowDetails panelStatus={panelStatus} floors={doorsAndWindowsState!} />
                </div>
              </S.Drawer>
            </div>
          ))}
        </>
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    padding-right: 72px;
    padding-top: 40px;
    padding-left: 28px;
    height: 500px;
  `,
  Header: styled.div`
    padding-bottom: 50px;
    display: flex;
    align-items: center;
  `,
  CloseButton: styled.button`
    margin-right: 28px;
  `,
}

export default CrowDoorAndWindowPage
