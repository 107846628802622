function SvgDressingRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447716 2 1 2H41C41.5523 2 42 1.55228 42 1C42 0.447715 41.5523 0 41 0H1Z"
        fill="#333131"
      />
      <path
        d="M34 20C34.5523 20 35 19.5523 35 19C35 18.4477 34.5523 18 34 18C33.4477 18 33 18.4477 33 19C33 19.5523 33.4477 20 34 20Z"
        fill="#333131"
      />
      <path
        d="M37 29C37 29.5523 36.5523 30 36 30C35.4477 30 35 29.5523 35 29C35 28.4477 35.4477 28 36 28C36.5523 28 37 28.4477 37 29Z"
        fill="#333131"
      />
      <path
        d="M27 29C27.5523 29 28 28.5523 28 28C28 27.4477 27.5523 27 27 27C26.4477 27 26 27.4477 26 28C26 28.5523 26.4477 29 27 29Z"
        fill="#333131"
      />
      <path
        d="M30 25C30 25.5523 29.5523 26 29 26C28.4477 26 28 25.5523 28 25C28 24.4477 28.4477 24 29 24C29.5523 24 30 24.4477 30 25Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 31.5C33 32.8807 31.8807 34 30.5 34C29.1193 34 28 32.8807 28 31.5C28 30.1193 29.1193 29 30.5 29C31.8807 29 33 30.1193 33 31.5ZM31 31.5C31 31.7761 30.7761 32 30.5 32C30.2239 32 30 31.7761 30 31.5C30 31.2239 30.2239 31 30.5 31C30.7761 31 31 31.2239 31 31.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5 27C35.8807 27 37 25.8807 37 24.5C37 23.1193 35.8807 22 34.5 22C33.1193 22 32 23.1193 32 24.5C32 25.8807 33.1193 27 34.5 27ZM34.5 25C34.7761 25 35 24.7761 35 24.5C35 24.2239 34.7761 24 34.5 24C34.2239 24 34 24.2239 34 24.5C34 24.7761 34.2239 25 34.5 25Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 19.5C31 20.8807 29.8807 22 28.5 22C27.1193 22 26 20.8807 26 19.5C26 18.1193 27.1193 17 28.5 17C29.8807 17 31 18.1193 31 19.5ZM29 19.5C29 19.7761 28.7761 20 28.5 20C28.2239 20 28 19.7761 28 19.5C28 19.2239 28.2239 19 28.5 19C28.7761 19 29 19.2239 29 19.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.125 10H23C22.4477 10 22 9.55228 22 9V6C22 5.44771 22.4477 5 23 5L39 5C39.5523 5 40 5.44772 40 6V9C40 9.55229 39.5523 10 39 10H38.875V10.2676C39.5475 10.6134 40 11.2597 40 12V34C40 35.6569 38.489 37 36.625 37H25.375C23.511 37 22 35.6569 22 34V12C22 11.2597 22.4525 10.6134 23.125 10.2676V10ZM38 7V8L24 8V7L38 7ZM25.125 10V10.8778C25.125 11.2525 24.9155 11.5958 24.5823 11.7671L24.0396 12.0462C24.023 12.0547 24.0099 12.0632 24 12.0706V13H38V12.0706C37.9901 12.0632 37.977 12.0547 37.9604 12.0462L37.4177 11.7671C37.0845 11.5958 36.875 11.2525 36.875 10.8778V10H25.125ZM24 34V15H38V34C38 34.3373 37.6124 35 36.625 35H25.375C24.3876 35 24 34.3373 24 34Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 16C2.89543 16 2 16.8954 2 18V35C2 36.1046 2.89543 37 4 37H18C19.1046 37 20 36.1046 20 35V18C20 16.8954 19.1046 16 18 16H4ZM18 18H4V23.7192L10.9999 21.9692L18 23.7193V18ZM18 35V25.7808L10.9999 24.0308L4 25.7808V35H18Z"
        fill="#333131"
      />
      <path
        d="M0 39C0 38.4477 0.447715 38 1 38H41C41.5523 38 42 38.4477 42 39C42 39.5523 41.5523 40 41 40H1C0.447716 40 0 39.5523 0 39Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgDressingRoom
