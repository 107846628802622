import React, { FC, useState } from 'react'
import { useGetLockStatus, useLock, useUnlock } from '../../../../../services/requestHooks/locks'
import { usePanelId } from '../../../hooks/usePanelId'
import useI18n from '../../../../../i18n/useI18n'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectIsExpandedMenu, toggleExpandableMenu } from '../../../../../reducers/panelInfoSlice'
import PinCodePrompt, { usePinCodePrompt } from '../../PinCodeConfirm'
import { RootState } from '../../../../../store'
import { Lock } from '../../../../../types/Lock'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import ExpandableList, {
  ExpandableListItem,
} from '../../../../../components/system/components/ExpandableList'
import { LockIcon } from '../../../../../assets/icons'
import Switch from '../../../../../components/Switch'
import { Loader } from '../../../../../components/Elements/Loaders'

const mapError = (error: string | undefined): TranslationKey | undefined => {
  switch (error) {
    case 'invalid_panel_code':
      return 'Invalid panel code'
    case undefined:
      return undefined
    default:
      return 'Something went wrong. Please try again'
  }
}

function validStatus(obj: any): obj is Lock {
  return obj?.Status === 'lock' || obj?.Status === 'unlock'
}

const SystemLocks: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const [currentLock, setCurrentLock] = useState<string | undefined>()
  const { run: sendLock, isLoading: isLoadingLockCommand } = useLock({
    onSuccess: () => {
      getLockStatus(panelId)
    },
  })
  const { run: sendUnlock, isLoading: isLoadingUnlockCommand } = useUnlock({
    onSuccess: () => {
      getLockStatus(panelId)
    },
  })
  const {
    run: getLockStatus,
    data: locksWithStatus,
    error: statusError,
    isLoading: isLoadingLockStatus,
  } = useGetLockStatus()
  const { promptState, promptForPinCode } = usePinCodePrompt(panelId)

  const showLocks = useSelector(selectIsExpandedMenu({ panelId, menu: 'locks' }))
  const dispatch = useDispatch()
  const panelInfo = useSelector((state: RootState) => state.panel[panelId]?.Info)

  const toggleLocks = () => {
    if (!showLocks) getLockStatus(panelId)
    dispatch(toggleExpandableMenu({ panelId, menu: 'locks' }))
  }

  const toggleLock = async (lock: Lock) => {
    const command = lock.Status === 'lock' ? sendUnlock : sendLock
    const PanelCode = await promptForPinCode()
    if (!PanelCode) return
    setCurrentLock(lock.Serial)
    command({
      LockSerial: lock.Serial,
      PanelCode,
      PanelId: panelId,
      Platform: 'web',
    })
  }

  const locks =
    panelInfo?.Locks.map((lock) => {
      const lockWithStatus = locksWithStatus?.find((fullLock) => fullLock.Serial === lock.Serial)
      return {
        ...lock,
        Status: lockWithStatus?.Status,
      }
    }) || []

  const isTogglingLock = isLoadingLockCommand || isLoadingUnlockCommand

  return (
    <>
      <ExpandableList
        open={showLocks}
        onClick={toggleLocks}
        icon={<LockIcon />}
        summary={<h2>{t('Locks')}</h2>}
        error={mapError(statusError)}
      >
        {locks.map((lock) => (
          <ExpandableListItem key={lock.Serial}>
            {
              <div className="switch mr-6">
                {isLoadingLockStatus && <Loader className="ml-2 mr-16" />}
                {!isLoadingLockStatus &&
                  (validStatus(lock) ? (
                    <Switch
                      isLoading={isTogglingLock && currentLock === lock.Serial}
                      id={lock.Serial}
                      onClick={() => toggleLock(lock)}
                      checked={lock.Status === 'lock'}
                    />
                  ) : (
                    <div className="switch switch--inactive">{t('Inactive')}</div>
                  ))}
              </div>
            }
            <label htmlFor={lock.Serial} className="ng-binding">
              {lock.Label}{' '}
              {validStatus(lock) && (lock.Status === 'lock' ? t('is locked') : t('is unlocked'))}
            </label>
            <Link to={`/systems/${panelId}/locks/${lock.Serial}`} className="right">
              <span>{t('Settings')}</span>
            </Link>
          </ExpandableListItem>
        ))}
      </ExpandableList>
      <PinCodePrompt promptState={promptState} />
    </>
  )
}

export default SystemLocks
