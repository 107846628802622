import React, { useState } from 'react'
import Logo from '../assets/images/logo.jpg'
import LogoPW from '../assets/images/logoPW.png'
import { NavLink } from 'react-router-dom'
import { EmailIcon, HamburgerIcon, InvoicesIcon, SystemsIcon, UserIcon } from '../assets/icons'
import { useOnMount } from '../utils/commonHooks'
import useI18n from '../i18n/useI18n'
import { useLoadMessages } from '../services/requestHooks/messages'
import { useUserInfo } from '../services/requestHooks/user'
import { useAuth } from '../services/authentication/AuthContext'
import { UserFeature } from '../types/UserDetails'
import { useFeatureToggle } from './FeatureToggle'

const NavBar = () => {
  const { t } = useI18n()
  const { logout, isAuthenticated } = useAuth()
  const User = useUserInfo()
  const {
    run: loadMessages,
    data: messages,
    isLoading: loadingMessages,
    hasLoaded: hasLoadedMessages,
  } = useLoadMessages()
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible)
  }

  useOnMount(() => {
    if (!hasLoadedMessages && !loadingMessages) loadMessages()
  })

  const checkUnreadMessages = (): JSX.Element | null => {
    const unreadMessages = (Array.isArray(messages) ? messages : []).filter(
      (message) => !message.IsRead,
    ).length
    return unreadMessages > 0 ? <span className="badge primary">{unreadMessages}</span> : null
  }

  const hasInboxFeature = useFeatureToggle(UserFeature.INBOX)
  const isInvoiceNation = ![6, 3, 5].includes(User.NationId || -1)
  const logo = User.NationId !== 3 ? Logo : LogoPW

  return (
    <header className="navbar">
      <div className="navbar__container">
        <div className="navbar__logo">
          <NavLink activeClassName="active" to="/" aria-label="home">
            <div>
              <img src={logo} alt="logo" />
            </div>
          </NavLink>
        </div>
        <button className="mobile-menu__button" onClick={toggleMobileMenu}>
          <HamburgerIcon className="mobile-menu__icon" />
          <div className="mobile-menu__text">{t('Menu')}</div>
        </button>
        <nav className={mobileMenuVisible ? 'navbar__nav navbar__nav--visible' : 'navbar__nav'}>
          <ul className="no-padding">
            <li className="navbar__nav-item">
              <NavLink activeClassName="active" to="/systems">
                <SystemsIcon className="navbar__icon" />
                {t('Alarm systems')}
              </NavLink>
            </li>
            {isInvoiceNation && User.CustomerNo && (
              <li className="navbar__nav-item">
                <NavLink activeClassName="active" to={'/invoices'}>
                  <InvoicesIcon className="navbar__icon" />
                  {t('Invoices')}
                </NavLink>
              </li>
            )}
            {hasInboxFeature && (
              <li className="navbar__nav-item">
                <NavLink activeClassName="active" to="/messages">
                  <EmailIcon className="navbar__icon" />
                  {t('messages')}
                  {checkUnreadMessages()}
                </NavLink>
              </li>
            )}

            <li className="navbar__nav-item">
              <NavLink activeClassName="active" to="/account">
                <UserIcon className="navbar__icon" />
                {t('ACCOUNT')}
              </NavLink>
            </li>
            {isAuthenticated && (
              <li className="navbar__nav-item navbar__nav-item--news">
                <button onClick={logout}>{t('Log out')}</button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default NavBar
