import React, { FC, useState } from 'react'
import Button from '../../components/Button'
import { translations } from '../../i18n/translations'
import useI18n from '../../i18n/useI18n'
import {
  useGetUserInfo,
  useResetPassword,
  useSetCulture,
  useUserInfo,
} from '../../services/requestHooks/user'
import { useForm } from 'react-hook-form'
import { UserCultureInfo } from '../../types/UserDetails'
import CancelButton from '../../components/Button/CancelButton'
import FailedRequest from '../onboardingWizard/components/FailedRequest'
import { TranslationKeyOverride } from '../../types/TranslationKeyOverride'

const getLanguageName = (culture: string): string | undefined => {
  return Object.values(translations).find((it) => it.culture === culture)?.name
}

type FormValues = {
  userCultureInfo: UserCultureInfo
}

const EditAccountForm: FC = () => {
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false)
  const { t } = useI18n()

  const { run: resetPassword, isLoading: resetPasswordLoading } = useResetPassword({
    onSuccess: () => setResetPasswordEmailSent(true),
  })
  const { run: refreshUserInfo } = useGetUserInfo()
  const { run: setCulture, isLoading: settingCulture, error: setCultureError } = useSetCulture({
    onSuccess: () => {
      setIsEditingAccount(false)
      refreshUserInfo()
    },
  })
  const account = useUserInfo()

  const savingAccountsError = false
  const [isEditingAccount, setIsEditingAccount] = useState(false)
  const hasCRM = account.NationHasCRM

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: { userCultureInfo: account.UserCultureInfo },
    mode: 'all',
  })

  const handleResetPassword = async () => resetPassword(account.UserName)

  const onSubmit = (values: FormValues) => {
    if (values.userCultureInfo !== account.UserCultureInfo) {
      setCulture(values.userCultureInfo)
    }
  }

  return (
    <form className="grid-half" onSubmit={handleSubmit(onSubmit)}>
      {savingAccountsError && <FailedRequest text={savingAccountsError} />}
      <div>
        <dl className="margin-bottom">
          <dt>{t('E-mail')}</dt>
          <dd>{account.UserName}</dd>
          <dt>{t('Language')}</dt>
          <dd>
            {!isEditingAccount ? (
              t(getLanguageName(account.UserCultureInfo) as TranslationKeyOverride)
            ) : (
              <div className="select">
                <select {...register('userCultureInfo')}>
                  {Object.values(translations).map(({ culture, name }) => {
                    return (
                      <option key={culture} value={culture}>
                        {t(name)}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </dd>
          {hasCRM && (
            <>
              <dt>{t('Password')}</dt>
              <dd>
                {!resetPasswordEmailSent ? (
                  <>
                    <span className="mr-4">•••••••••••••</span>
                    <Button
                      disabled={resetPasswordLoading || resetPasswordEmailSent}
                      size="small"
                      buttonType="primary"
                      type="button"
                      onClick={handleResetPassword}
                    >
                      {t('RESET_BY_EMAIL')}
                      {resetPasswordLoading && <div className="ml-4 loader" />}
                    </Button>
                  </>
                ) : (
                  t('A confirmation e-mail has been sendt to') + ` ${account.UserName}`
                )}
              </dd>
            </>
          )}
        </dl>
      </div>
      <div className="flex">
        {isEditingAccount ? (
          <>
            <Button className="button button--small button--primary" disabled={settingCulture}>
              {t('Save')}
            </Button>
            <CancelButton onClick={() => setIsEditingAccount(false)} isLoading={settingCulture} />
          </>
        ) : (
          <Button
            buttonType="primary"
            type="button"
            size="small"
            onClick={() => setIsEditingAccount(true)}
          >
            {t('Edit')}
          </Button>
        )}
      </div>
      {setCultureError && <FailedRequest text={setCultureError} />}
    </form>
  )
}

export default EditAccountForm
