function SvgCellar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="35" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 33C42 34.1046 41.1046 35 40 35H1C0.447716 35 0 34.5523 0 34C0 33.4477 0.447716 33 1 33H13V10C13 4.47715 17.4772 0 23 0H32C37.5228 0 42 4.47715 42 10V33ZM23 2H32C36.4183 2 40 5.58172 40 10V17H31C30.4477 17 30 17.4477 30 18V21H27C26.4477 21 26 21.4477 26 22V25H23C22.4477 25 22 25.4477 22 26V29H19C18.4477 29 18 29.4477 18 30V33H15V10C15 5.58172 18.5817 2 23 2ZM32 21H40V19H32V21ZM28 23V25H40V23H28ZM40 27V29H24V27H40ZM20 31V33H40V31H20Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgCellar
