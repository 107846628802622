import React from 'react'

const RightSecondaryS = () => {
  return (
    <svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.4948 18.0301C7.83232 16.021 8 14.0101 8 12C8 9.98992 7.83232 7.97905 7.4948 5.96988C7.26448 4.59871 6.80209 3.75909 6.25217 3.23349C5.70791 2.71331 4.88923 2.32013 3.60059 2.19272C3.06136 2.13913 2.52732 2.09331 2 2.05984H1.91807e-06L2.09815e-06 0H0.357145C0.904364 0.00282412 1.45244 0.023189 2 0.0560362C2.60036 0.0920504 3.20009 0.14307 3.79738 0.202427C7.02988 0.522034 8.90605 2.29807 9.46717 5.63855C9.82296 7.75649 10 9.87819 10 12C10 14.1218 9.82296 16.2435 9.46717 18.3615C8.90605 21.7019 7.02987 23.478 3.79738 23.7976C3.20009 23.8569 2.60035 23.9079 2 23.944C1.45244 23.9768 0.904361 23.9972 0.357142 24H0V21.9402H2C2.52732 21.9067 3.06036 21.861 3.59959 21.8074L3.60059 21.8073C4.88922 21.6799 5.70791 21.2867 6.25217 20.7665C6.80209 20.2409 7.26448 19.4013 7.4948 18.0301Z" />
    </svg>
  )
}

export default RightSecondaryS
