import { ComponentType, FC, useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import {
  HomeIcon,
  InvoicesIcon,
  LocationIcon,
  PanelUsersIcon,
  SectorAlarmAppCustomerSupportIcon,
  TechSupportIcon,
  UsersIcon,
} from '../../../../assets/icons'
import BackButton from '../../../../components/BackButton'
import Drawer from '../../../../components/Crow/Drawer/Drawer'
import Icon from '../../../../components/Crow/Icon/Icon'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph, SubTitle, Title } from '../../../../components/Crow/Typography'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import useI18n, { useIrishCheck } from '../../../../i18n/useI18n'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import { useUserInfo } from '../../../../services/requestHooks/user'
import { useGetPanelPermissions } from '../../../../services/requestHooks/users'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { Capability, PanelAccess, PanelModel } from '../../../../types/PanelModel'
import { UserDetails, UserFeature } from '../../../../types/UserDetails'
import { useOnMount } from '../../../../utils/commonHooks'
import OnboardingWizardPage from '../../../onboardingWizard/OnboardingWizardPage'
import SecurityQuestion from '../../../onboardingWizard/SecurityQuestion'
import { usePanelId } from '../../hooks/usePanelId'
import AddSmartPlugPage from '../AddSmartplug/AddSmartplugPage'
import AppUsersPage from '../AppUsers/AppUserPage'
import VideoPrivacyConsent from '../Consent/VideoPrivacyConsent'
import LocationAndDirectons from '../Directions/LocationAndDirections'
import Notifications from '../Notifications/Notifications'
import PanelUsersPage from '../PanelUsers/PanelUsersPage'
import QuickArming from '../QuickArming/QuickArming'
import SystemNameChange from '../SystemSettings/SystemNameChange'
import DarkFullScreenSuccess from './DarkFullScreenSuccess'
import SettingMenuItem from './SettingsItem'
import SettingsPeopleInfo from './SettingsPeopleInfo'

type MenuItemMap = {
  [key: string]: {
    accessKey?: PanelAccess
    visibleCondition: (panelInfo: Partial<PanelModel>, userInfo: UserDetails) => boolean | undefined
    svg: ComponentType
    title: TranslationKey
    irishTitle?: TranslationKey
    description: TranslationKey
    requiresCRM?: boolean
  }
}

const userMenuItems: MenuItemMap = {
  'panel-users': {
    accessKey: PanelAccess.PanelUsers,
    visibleCondition: ({ SupportsPanelUsers }) => SupportsPanelUsers,
    svg: PanelUsersIcon,
    title: 'Users and codes',
    description: 'Add and remove users',
  },
  contacts: {
    accessKey: PanelAccess.ContactPersons,
    visibleCondition: (Panel) => Panel.InterviewDisplayStatus && !Panel.DisplayWizard,
    svg: UsersIcon,
    title: 'Contact persons',
    irishTitle: 'PW_CONTACT_PERSONS',
    description: 'Add persons to contact i case of emergency.',
  },
  'app-users': {
    accessKey: PanelAccess.AppUserSettings,
    visibleCondition: (Panel) => !!Panel.Access,
    svg: UsersIcon,
    title: 'APP_USERS_SETTING_MENU',
    description: 'APP_USERS_SETTING_MENU_INFO_TEXT',
  },
}
const panelMenuItems: MenuItemMap = {
  'system-settings': {
    accessKey: PanelAccess.AlarmSystemSettings,
    visibleCondition: (Panel) => !!Panel.Access,
    svg: HomeIcon,
    title: 'System',
    description: 'Change system name and more',
  },
  notifications: {
    visibleCondition: () => true,
    svg: TechSupportIcon,
    title: 'NOTIFICATIONS',
    description: 'NOTIFICATIONS_INFO_TEXT',
  },

  'security-question': {
    accessKey: PanelAccess.SecurityQuestion,
    visibleCondition: (Panel) => Panel.InterviewDisplayStatus && !Panel.DisplayWizard,
    svg: HomeIcon,
    title: 'Security question',
    description: 'Change your security password.',
  },
  wizard: {
    accessKey: PanelAccess.PreInstallationSettings,
    visibleCondition: (Panel) => Panel.DisplayWizard,
    svg: SectorAlarmAppCustomerSupportIcon,
    title: 'New features',
    description: 'Access new features.',
  },
  directions: {
    accessKey: PanelAccess.Directions,
    visibleCondition: (Panel) => Panel.InterviewDisplayStatus && !Panel.DisplayWizard,
    svg: LocationIcon,
    title: 'Location settings',
    description: 'Add a description about where you property is.',
  },
  consent: {
    visibleCondition: (Panel, user) =>
      Panel.HasVideo && user.Features.includes(UserFeature.ARC_CONSENT),
    svg: InvoicesIcon,
    title: 'CONSENT_TITLE',
    description: 'CONSENT_SHORT_DESCRIPTION',
  },
}
const deviceMenuItems: MenuItemMap = {
  smartplug: {
    accessKey: PanelAccess.AddProducts,
    visibleCondition: (Panel) => Panel.CanAddSmartplug,
    svg: SectorAlarmAppCustomerSupportIcon,
    title: 'SETTINGS_ADD_SMART_PLUG',
    description: 'SETTINGS_ADD_SMART_PLUG_INFOTEXT',
  },
  wifi: {
    accessKey: PanelAccess.Wifi,
    visibleCondition: (Panel) =>
      !!Panel.Access &&
      !!Panel.Wifi?.WifiExist &&
      !!Panel.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN),
    svg: TechSupportIcon,
    title: 'WIFI_SETTINGS',
    description: 'WIFI_INFO_TEXT',
  },
}

const SettingsMenu: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const User = useUserInfo()
  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: isLoadingPanelInfo,
    error: panelLoadError,
  } = usePanelInfo()

  const {
    run: getPanelPermissions,
    isLoading: loadingPanelPermissions,
    error: panelPermissionsError,
    data: panelPermissions,
  } = useGetPanelPermissions()

  useEffect(() => {
    getPanelPermissions()
  }, [getPanelPermissions])

  const menuCategories: { title: TranslationKey; items: MenuItemMap }[] = [
    { title: 'Users', items: userMenuItems },
    { title: 'System', items: panelMenuItems },
    { title: 'WIFI_INFO_TEXT', items: deviceMenuItems },
  ]

  const { checkIrish } = useIrishCheck()

  useOnMount(() => {
    if (!isLoadingPanelInfo && panelInfo?.PanelId !== panelId) {
      loadPanelInfo(panelId)
    }
  })

  const visibleMenuItems = menuCategories
    .filter(
      ({ title }) =>
        panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE) ||
        panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN) ||
        title !== 'Users',
    )
    .map(({ items, title }) => ({
      title,
      items: Object.entries(items)
        .filter(([_, { visibleCondition }]) => visibleCondition(panelInfo || {}, User))
        .filter(([_, { requiresCRM }]) => !requiresCRM || User.NationHasCRM)
        .filter(([_, { accessKey }]) => !accessKey || panelInfo?.Access.includes(accessKey)),
    }))
    .filter(({ items }) => Object.entries(items).length > 0)

  const returnUrl = `/systems/${panelId}`

  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'right' })

  const [SettingsMenuItemTitle, setSettingsMenuItemTitle] = useState<TranslationKey>()

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const openDrawer = (
    position: 'top' | 'bottom' | 'left' | 'right',
    title: TranslationKey,
  ) => () => {
    setSettingsMenuItemTitle(title)
    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const displaySuccessScreen = useCallback((isSuccess) => {
    setShowSuccessScreen(isSuccess)
  }, [])

  let DrawerContent = (title: TranslationKey) => {
    const baseUrl = `/systems/${panelId}/settings`

    switch (t(title)) {
      // Users
      case t('Users and codes'):
        return <PanelUsersPage />
      case t('APP_USERS_SETTING_MENU'):
        return <AppUsersPage />
      case t('Contact persons'):
        return <Redirect to={`${baseUrl}/contacts`} />
      // Alarm system
      case t('change_system_name'):
        return (
          <SystemNameChange
            displayName={panelInfo?.DisplayName!}
            quickArmEnabled={panelInfo?.QuickArmEnabled!}
            permissions={panelPermissions?.Accesses}
          />
        )
      case t('Security question'):
        return (
          <SecurityQuestion
          //TODO: Fix parameters
          // displaySuccessScreen={displaySuccessScreen}
          // permissions={panelPermissions?.Accesses}
          />
        )
      case t('New system onboarding'):
        return <OnboardingWizardPage />
      case t('QUICKARM_TITLE'):
        return (
          <QuickArming
            displayName={panelInfo?.DisplayName!}
            quickArmEnabled={panelInfo?.QuickArmEnabled!}
            permissions={panelPermissions?.Accesses}
          />
        )
      case t('NOTIFICATIONS_SETTINGS'):
        return <Notifications />
      case t('Location settings'):
        return <LocationAndDirectons permissions={panelPermissions?.Accesses!} />
      // WiFi
      case t('SETTINGS_ADD_SMART_PLUG'):
        return <AddSmartPlugPage />
      case t('WIFI_SETTINGS'):
        return <Redirect to={`${baseUrl}/wifi`} />
      case t('video_consent_modal_title'):
        return <VideoPrivacyConsent />
    }
    return null
  }

  return (
    <>
      {!showSuccessScreen ? (
        <FullscreenPage
          isLoading={isLoadingPanelInfo || loadingPanelPermissions}
          loaderProps={{
            loadingText: { text: 'Loading settings' },
            errorHandling: {
              errorHeaderText: 'Settings',
              errorText: 'Something went wrong. Please try again',
              loadingFailed: !!panelLoadError || !!panelPermissionsError,
              returnUrl: `/systems/${panelId}`,
            },
          }}
        >
          <>
            <BackButton backUrl={returnUrl} />
            <SettingsTitle>{t('Settings')}</SettingsTitle>
            <>
              {visibleMenuItems.map(({ items, title }) => {
                return (
                  <div key={title}>
                    {t(title) === t('change_system_name') ? (
                      <SettingsSubtitle light>{panelInfo?.DisplayName!}</SettingsSubtitle>
                    ) : (
                      <SettingsSubtitle light>{t(title)}</SettingsSubtitle>
                    )}
                    <ul className="settings" id={title}>
                      {items.map(([url, { svg, title, irishTitle, description }]) => (
                        <SettingMenuItem
                          svg={svg}
                          key={url}
                          url={url}
                          title={irishTitle ? checkIrish(title, irishTitle) : title}
                          description={description}
                        />
                      ))}
                    </ul>
                  </div>
                )
              })}
            </>

            <S.Drawer
              isOpen={drawerState.isOpen}
              position={drawerState.position}
              handleClose={closeDrawer}
              title={
                <S.Header>
                  <S.CloseButton onClick={closeDrawer}>
                    <S.CancelIcon name="Cancel" />
                  </S.CloseButton>
                  <S.Title className="m-0">{t(SettingsMenuItemTitle!)}</S.Title>
                </S.Header>
              }
            >
              {DrawerContent(t(SettingsMenuItemTitle!))}
            </S.Drawer>
            <SettingsPeopleInfo panelId={panelId} />
          </>
        </FullscreenPage>
      ) : (
        <StyledDarkFullScreenSuccess
          title={t('security_question_updated')}
          subtitle={<Paragraph light>{t('security_question_success_subtitle')}</Paragraph>}
          icon={<Circle>{<StyledCheckMarkIcon name="Checkmark" />}</Circle>}
          close={() => setShowSuccessScreen(!showSuccessScreen)}
        />
      )}
    </>
  )
}

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    padding-right: 72px;
    padding-top: 40px;
    padding-left: 28px;
    @media only screen and (max-width: 375px) {
      padding-left: 40px;
    }
    height: 500px;
  `,
  Header: styled.div`
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  `,
  Title: styled(Title)`
    margin-top: 4px;
  `,
  CloseButton: styled.button``,
  CancelIcon: styled(Icon)`
    color: ${colors.blue};
    font-size: 24px;
    padding-right: 28px;
    margin-top: 4px;
  `,
  DrawerContentContainer: styled.div`
    padding-left: 50px;
  `,
}

const SettingsTitle = styled(Title)`
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 42px;
`

const StyledDarkFullScreenSuccess = styled(DarkFullScreenSuccess)`
  color: ${colors.green};
  height: 100vh;
`

const StyledCheckMarkIcon = styled(Icon)`
  color: ${colors.green};
`
const Circle = styled.div`
  height: 50px;
  width: 50px;
  border: 1px solid ${colors.green};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SettingsSubtitle = styled(SubTitle)`
  margin-top: 18px;
  margin-left: 16px;
`

export default SettingsMenu
