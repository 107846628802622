import React from 'react'
import styled from 'styled-components'
import { colors } from '../Style/theme'
import { Paragraph } from '../Typography'

interface RadioButtonProps {
  value: string
  title: string
  name: string
  defaultChecked?: boolean
  id: string
  onClick: () => void
}

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-style: solid;
  border-color: #e3e1e1;
  border-width: 1px;
  border-radius: 12px;
  height: 64px;
  margin-top: 8px;

  @media (max-width: 600px) {
    width: 50%;
  }
`

const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35px;
  width: 100%;
  height: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ span {
    border: 2px solid #66a8d8;
  }

  input:checked ~ span {
    border: 2px solid #66a8d8;
  }

  input:checked ~ span:after {
    display: block;
  }
`

const Checkmark = styled.span`
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid #807c7c;
  border-radius: 50%;

  &:after {
    content: '';
    display: none;
  }

  &:after {
    justify-content: center;
    margin-top: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #66a8d8;
  }
`

const StyledLabel = styled(Paragraph)`
  color: ${colors.black700};
  margin-left: 16px;
  font-size: 20px;
`

export const CrowRadioButton = ({
  value,
  title,
  name,
  defaultChecked,
  id,
  onClick,
}: RadioButtonProps) => {
  return (
    <RadioButtonWrapper>
      <RadioButtonContainer htmlFor={id}>
        <input
          type="radio"
          name={name}
          defaultChecked={defaultChecked}
          onClick={onClick}
          id={id}
          value={value}
        />
        <Checkmark />
        <StyledLabel>{title}</StyledLabel>
      </RadioButtonContainer>
    </RadioButtonWrapper>
  )
}
