import React, { FC, useMemo } from 'react'
import { useGetBenefits, useGetPartners } from '../../../services/requestHooks/benefits'
import { useOnMount } from '../../../utils/commonHooks'
import { Loader } from '../../../components/Elements/Loaders'
import BenefitCard from './BenefitCard'
import PartnerCard from './PartnerCard'
import useI18n from '../../../i18n/useI18n'
import { Benefit } from '../../../types/Benefit'
import { Partner } from '../../../types/Partner'

interface Props {
  partners: Partner[]
  benefits: Benefit[]
}

export const PartnersList: FC<Props> = ({ partners, benefits }) => {
  const { t } = useI18n()

  const nonActivatedPartners = useMemo(() => {
    const activatedPartnerIds = benefits.map((benefit) => benefit.PartnerId)
    return partners.filter((partner) => !activatedPartnerIds.includes(partner.Id))
  }, [benefits, partners])

  const points = benefits.reduce((total, next) => total + next.EarnedPoints, 0)

  return (
    <div>
      {(benefits.length > 0 || nonActivatedPartners.length > 0) && (
        <div>
          <p className="text-2xl border-b-2 border-gray-300 mb-4">
            {t('partner_points_to_spend', { points })}
          </p>
          <div className="flex flex-wrap mb-4 justify-between">
            {benefits.map((benefit, index) => {
              return <BenefitCard key={index} benefit={benefit} />
            })}
          </div>
          {nonActivatedPartners.map((partner) => {
            return <PartnerCard key={partner.Id} partner={partner} />
          })}
        </div>
      )}
    </div>
  )
}

const PartnerListTab = () => {
  const { data: benefits = [], run: getBenefits, isLoading: loadingBenefits } = useGetBenefits()
  const { data: partners = [], run: getPartners, isLoading: loadingPartners } = useGetPartners()
  useOnMount(() => {
    getBenefits()
    getPartners()
  })
  if (loadingBenefits || loadingPartners) {
    return (
      <div className="flex justify-center">
        <Loader types={['large']} />
      </div>
    )
  }
  return <PartnersList partners={partners} benefits={benefits} />
}

export default PartnerListTab
