import React, { useState } from 'react'
import styled from 'styled-components'
import Collapsible, { Clickable } from '../../components/Crow/Collapsible/Collapsible'
import Drawer from '../../components/Crow/Drawer/Drawer'
import Icon from '../../components/Crow/Icon/Icon'
import { colors } from '../../components/Crow/Style/theme'
import { Paragraph, Title } from '../../components/Crow/Typography'
import FullscreenPage from '../../components/pages/FullscreenPage'
import NavBarPage from '../../components/pages/NavBarPage'
import { TcOrCrowContainer } from '../../components/TcOrCrowContainer'
import useI18n from '../../i18n/useI18n'
import { usePanelInfo } from '../../services/requestHooks/panelInfo'
import { useResourceInfo, useUserInfo } from '../../services/requestHooks/user'
import { UserFeature } from '../../types/UserDetails'
import { useOnMount } from '../../utils/commonHooks'
import { deriveInitials } from '../../utils/deriveInitials'
import { usePanelId } from '../AlarmSystems/hooks/usePanelId'
import { AccountDetails, Benefits, LanguageContent } from './drawerContent'
import { ChangePassword } from './drawerContent/ChangePassword'

const AccountContainer = styled.div<{ hideBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ hideBackground }) =>
    !hideBackground &&
    `
      background-color: ${colors.lightGray};
      `}
  min-height: 90vh;
  & > * {
    width: 50%;
    min-width: 300px;
    margin-top: 10px;
  }
`

const StyledCancelIcon = styled(Icon)`
  font-size: 24px;
`

const StyledTitle = styled(Title)`
  margin: 0;
  margin-left: 10px;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  & > *:first-child {
    flex: 2;
    padding-right: 15px;
  }

  & > *:last-child {
    flex: 1;
    justify-content: flex-end;
  }
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > *:first-child {
    padding-left: 15px;
  }
  & > * {
    margin-top: 10px;
  }
`

const AccountName = styled.p`
  display: flex;
  font-weight: bold;
  font-family: Metric;
  font-size: 18px;
`

const AccountEmail = styled.p`
  display: flex;
  font-weight: bold;
  font-family: Metric;
  font-size: 14px;
`

const AccountCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 50px;
  width: 50px;
  background-color: ${colors.black900};
  border-radius: 100%;

  & > * {
    font-size: 20px;
    color: white;
    text-align: center;
  }
`
const NameEmailContainer = styled.div`
  margin-top: 20px;
  display: flex;
  & > *:first-child {
    margin-right: 16px;
  }
`
const AccountPage = ({ hideBackground }: { hideBackground?: boolean }) => {
  const user = useUserInfo()
  const resources = useResourceInfo()

  const { FirstName, LastName, CustomerNo, CellPhone, Features } = user
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState(0)

  const setDrawerContent = (itemNumber: number) => {
    setOpenDrawer(true)
    setSelectedMenuItem(itemNumber)
  }
  const { t } = useI18n()
  const menuTitles = [t('Account details'), t('Language'), t('Password'), t('Benefits')]

  return (
    <>
      <AccountContainer hideBackground={hideBackground}>
        <TopContainer>
          <div>
            <Title>{t('ACCOUNT')}</Title>
            <Paragraph>{t('Account_text')}</Paragraph>
          </div>
          <NameEmailContainer>
            <div>
              <AccountName>
                {FirstName} {LastName}
              </AccountName>
              <AccountEmail>{user.UserName}</AccountEmail>
            </div>
            <AccountCircle>
              <Paragraph>{deriveInitials(FirstName, LastName)}</Paragraph>
            </AccountCircle>
          </NameEmailContainer>
        </TopContainer>
        <BottomContainer>
          <Title>{t('Account details')}</Title>
          <Clickable title={t('Account details')} onClick={() => setDrawerContent(0)} />
          <Clickable title={t('Language')} onClick={() => setDrawerContent(1)} />
          <Clickable title={t('Password')} rightContent>
            <ChangePassword />
          </Clickable>
          {Features.includes(UserFeature.BENEFITS) && (
            <Clickable title={t('Benefits')} onClick={() => setDrawerContent(3)} />
          )}
        </BottomContainer>
        <BottomContainer>
          <Title>{t('customer_support')}</Title>
          <Collapsible title={t('Contact_CP')}>
            <Paragraph>
              {resources && resources.CustomerServicePhone ? resources.CustomerServicePhone : ''}
            </Paragraph>
          </Collapsible>
        </BottomContainer>
      </AccountContainer>
      <S.Drawer
        isOpen={openDrawer}
        position="right"
        handleClose={() => setOpenDrawer(false)}
        closeButton={true}
        title={
          <S.Header>
            <S.CloseButton onClick={() => setOpenDrawer(false)}>
              <StyledCancelIcon name="Cancel" color="#66A8D8" />
            </S.CloseButton>
            <StyledTitle>{menuTitles[selectedMenuItem]}</StyledTitle>
          </S.Header>
        }
      >
        {selectedMenuItem === 0 ? (
          <AccountDetails
            firstName={FirstName}
            lastName={LastName}
            email={user.UserName}
            customerNumber={CustomerNo}
            cellPhone={CellPhone}
          />
        ) : selectedMenuItem === 1 ? (
          <LanguageContent closeDrawer={() => setOpenDrawer(false)} />
        ) : selectedMenuItem === 3 ? (
          <Benefits />
        ) : (
          <></>
        )}
      </S.Drawer>
    </>
  )
}

const CrowAccountPage = () => (
  <NavBarPage>
    <AccountPage />
  </NavBarPage>
)

export const CrowAccountPageWithPanelId = () => {
  const panelId = usePanelId()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
  })

  const { t } = useI18n()

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <FullscreenPage
        isLoading={loadingPanelInfo}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: !loadingPanelInfo && !!loadInfoError,
            errorHeaderText: t('ACCOUNT'),
            errorText: t('Something went wrong. Please try again'),
            returnUrl: `/systems/${panelId}`,
          },
        }}
      >
        <AccountPage hideBackground />
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    padding-left: 31px;
    padding-top: 40px;
    padding-right: 72px;
    width: 636px;
    height: 100%;
  `,
  Header: styled.div`
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  `,
  CloseButton: styled.button`
    margin-right: 12px;
  `,
}

export default CrowAccountPage
