import React, { FC } from 'react'
import NavBarPage from '../../components/pages/NavBarPage'
import BackgroundImagePage from '../../components/pages/BackgroundImagePage'
import useI18n from '../../i18n/useI18n'
import { useAuth } from '../../services/authentication/AuthContext'
import { isTranslationKey } from '../../types/generated/TranslationKey'
import { useGetUserInfo } from '../../services/requestHooks/user'

type Props = {
  text?: string
}

const InternalErrorPage: FC<Props> = ({ text }) => {
  const auth = useAuth()
  const { hasLoaded } = useGetUserInfo()
  const { t } = useI18n()
  if (!text || !isTranslationKey(text)) {
    text = 'Something went wrong. Please try again later.'
  }

  if (auth.isAuthenticated && hasLoaded) {
    return (
      <NavBarPage>
        <div className="background-gray flex justify-center mt-10">
          <span className="text-2xl">{t(text)}</span>
        </div>
      </NavBarPage>
    )
  }

  return (
    <BackgroundImagePage>
      <span className="text-2xl">{t(text)}</span>
    </BackgroundImagePage>
  )
}

export default InternalErrorPage
