import {
  LOAD_GET_LOCK_LANGUAGE_SUCCESS,
  SET_LOCK_SETTINGS_SUCCESS,
} from '../../reducers/lockSettingsSlice'
import { CHANGE_LOCK_NAME_SUCCESS } from '../../reducers/panelInfoSlice'
import { RootState } from '../../store'
import { changeLockName, getLockLanguage, setLockSettings } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

export const useGetLockLanguage = createUseRequest({
  apiCall: getLockLanguage,
  successActionCreator: LOAD_GET_LOCK_LANGUAGE_SUCCESS,
  selector: (state: RootState, panelId: string) => state.locksSettings[panelId],
  requirePanelId: true,
})

export const useChangeLockName = createUseRequest({
  apiCall: changeLockName,
  selector: (state: RootState, panelId: string) => state.panel[panelId]?.Info,
  successActionCreator: CHANGE_LOCK_NAME_SUCCESS,
  requirePanelId: true,
})

export const useSetLockSettings = createUseRequest({
  apiCall: setLockSettings,
  successActionCreator: SET_LOCK_SETTINGS_SUCCESS,
  requirePanelId: true,
})
