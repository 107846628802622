import {
  LINK_TO_PARTNER,
  UPDATE_BENEFITS,
  UPDATE_PARTNERS,
  GET_BONUS_PRODUCTS_SUCCESS,
  BUY_BONUS_PRODUCT_SUCCESS,
} from '../../reducers/benefitsSlice'
import { RootState } from '../../store'
import {
  buyBonusProduct,
  getBenefits,
  getBonusPointProducts,
  getPartners,
  linkToPartner,
} from '../apiAccount'
import { createUseRequest } from './createUseRequest'

const selectBenefits = (state: RootState) => state.benefits.benefits
const selectPartners = (state: RootState) => state.benefits.partners
const selectProducts = (state: RootState) => state.benefits.bonusProducts

export const useGetBenefits = createUseRequest({
  successActionCreator: UPDATE_BENEFITS,
  apiCall: getBenefits,
  selector: selectBenefits,
})

export const useGetPartners = createUseRequest({
  successActionCreator: UPDATE_PARTNERS,
  apiCall: getPartners,
  selector: selectPartners,
})

export const useLinkToPartner = createUseRequest({
  successActionCreator: LINK_TO_PARTNER,
  apiCall: linkToPartner,
})

export const useGetBonusPointProducts = createUseRequest({
  successActionCreator: GET_BONUS_PRODUCTS_SUCCESS,
  apiCall: getBonusPointProducts,
  selector: selectProducts,
})

export const useBuyBonusProduct = createUseRequest({
  successActionCreator: BUY_BONUS_PRODUCT_SUCCESS,
  apiCall: buyBonusProduct,
})
