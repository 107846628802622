function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 0C31.2058 0 30.9168 0.076121 30.6608 0.220722L22.8692 4.62294C22.6048 4.77232 22.3852 4.98936 22.2327 5.25132C22.0802 5.51324 22 5.81086 22 6.11368V14.8863C22 15.1891 22.0802 15.4868 22.2327 15.7487C22.3852 16.0106 22.6048 16.2277 22.8692 16.3771L30.6608 20.7793C30.9168 20.9239 31.2058 21 31.5 21C31.7942 21 32.0832 20.9239 32.3392 20.7793L40.1308 16.3771C40.3952 16.2277 40.6148 16.0106 40.7673 15.7487C40.9198 15.4868 41 15.1891 41 14.8863V6.11368C41 5.81086 40.9198 5.51324 40.7673 5.25132C40.6148 4.98936 40.3952 4.77232 40.1308 4.62294L32.3392 0.220722C32.0832 0.076121 31.7942 0 31.5 0ZM24.9499 5.74447L31.5 2.04374L38.142 5.79638L31.4389 9.58351L24.9499 5.74447ZM32.5 11.2812L39 7.60873V14.7188L32.5 18.3913V11.2812ZM30.5 11.3518V18.3913L24 14.7188V7.50628L30.5 11.3518Z"
        fill="#333131"
      />
      <path
        d="M11 24.5C11 23.9477 10.5523 23.5 10 23.5C9.44772 23.5 9 23.9477 9 24.5V25.5H8C7.44772 25.5 7 25.9477 7 26.5C7 27.0523 7.44772 27.5 8 27.5H9V28.5C9 29.0523 9.44772 29.5 10 29.5C10.5523 29.5 11 29.0523 11 28.5V27.5H12C12.5523 27.5 13 27.0523 13 26.5C13 25.9477 12.5523 25.5 12 25.5H11V24.5Z"
        fill="#333131"
      />
      <path
        d="M17 26.5C17 25.9477 17.4477 25.5 18 25.5H22C22.5523 25.5 23 25.9477 23 26.5C23 27.0523 22.5523 27.5 22 27.5H18C17.4477 27.5 17 27.0523 17 26.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8055 21.1642C24.3989 19.7576 22.4913 18.9675 20.5022 18.9675V19.9675L20.4992 18.9675L9.50035 19L9.49885 19C7.73612 19.0005 6.02983 19.6217 4.67936 20.7546C3.32851 21.8879 2.42001 23.4608 2.11335 25.1972C2.11164 25.2069 2.11007 25.2166 2.10864 25.2263L0.0720097 35.7008L0.0688063 35.7181C-0.0973089 36.6605 0.041351 37.6314 0.464647 38.4897C0.887943 39.348 1.57383 40.049 2.4227 40.4909C3.27156 40.9328 4.2392 41.0926 5.18508 40.9471C6.13095 40.8016 7.0058 40.3583 7.68257 39.6817C7.70072 39.6635 7.71802 39.6449 7.73449 39.6257L12.8252 33.9982L17.1815 33.9808L22.2651 39.624C22.282 39.6438 22.2998 39.663 22.3185 39.6817C22.9952 40.3583 23.8701 40.8016 24.816 40.9471C25.7618 41.0927 26.7295 40.9329 27.5784 40.491C28.4272 40.049 29.1131 39.348 29.5364 38.4897C29.9597 37.6314 30.0984 36.6605 29.9322 35.7181L29.9292 35.7015L27.8856 25.1499C27.6179 23.6502 26.8974 22.2561 25.8055 21.1642ZM26.8708 30.4287C26.5705 30.9115 26.214 31.3623 25.8055 31.7708C24.3995 33.1768 22.4927 33.9669 20.5043 33.9675H20.5022L19.8637 33.97L23.753 38.2875C24.1261 38.6521 24.6041 38.891 25.1201 38.9704C25.6456 39.0512 26.1832 38.9625 26.6548 38.7169C27.1264 38.4714 27.5075 38.082 27.7427 37.6051C27.9767 37.1306 28.0541 36.5941 27.9639 36.0729L26.8708 30.4287ZM20.5022 20.9675H20.5036C21.9618 20.9678 23.3601 21.5473 24.3912 22.5784C25.4227 23.6098 26.0022 25.0088 26.0022 26.4675C26.0022 27.9262 25.4227 29.3251 24.3912 30.3566C23.3598 31.388 21.9608 31.9675 20.5022 31.9675L20.4982 31.9675L12.3758 32C12.0946 32.0011 11.8268 32.1206 11.6382 32.3291L6.24864 38.287C5.87546 38.6518 5.39724 38.8909 4.88094 38.9704C4.35543 39.0512 3.81783 38.9624 3.34622 38.7169C2.87461 38.4714 2.49354 38.082 2.25837 37.6051C2.02438 37.1307 1.94694 36.5943 2.03706 36.0732L4.08079 25.5621C4.08463 25.5424 4.08786 25.5226 4.09049 25.5028C4.32254 24.2461 4.98501 23.1088 5.96479 22.2868C6.9555 21.4557 8.20732 21.0001 9.5005 21L9.50338 21L20.5022 20.9675Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgPlay
