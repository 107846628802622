import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../../../components/Crow/Button/Button'
import useI18n from '../../../../i18n/useI18n'
import {
  useBuyBonusProduct,
  useGetBenefits,
  useGetBonusPointProducts,
} from '../../../../services/requestHooks/benefits'
import { BonusProduct } from '../../../../types/BonusProduct'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { BenefitProduct } from './BenefitProduct'

interface ProductOverviewProps {
  partnerId: string
  handleClose: () => void
}

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin-bottom: 10px;
  }
`

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:first-child {
    margin-right: 8px;
  }
`

export const ProductOverview = ({ partnerId, handleClose }: ProductOverviewProps) => {
  const { t } = useI18n()
  const { data: benefits, run: getBenefits } = useGetBenefits()
  const { run: getProducts, data: products } = useGetBonusPointProducts()
  const { run: buyProduct, isLoading: isBuying, error: buyError } = useBuyBonusProduct({
    onSuccess: () => {
      handleClose()
      getBenefits()
    },
  })

  const [selectedProduct, setSelectedProduct] = useState<BonusProduct | undefined>()
  const totalPoints = benefits?.find((benefit) => benefit.PartnerId === partnerId)?.EarnedPoints
  const remainingPoints = (totalPoints || 0) - (selectedProduct?.Points || 0)

  useOnMount(() => {
    getProducts(partnerId)
  })

  const order = () => {
    if (!selectedProduct) return
    buyProduct({ ProductId: selectedProduct.Id, PartnerId: partnerId })
  }

  return (
    <div>
      {!selectedProduct && (
        <ProductContainer>
          {products?.map((product) => {
            return (
              <BenefitProduct
                onClick={() => setSelectedProduct(product)}
                key={product.Id}
                product={product}
                selectable={true}
              />
            )
          })}
        </ProductContainer>
      )}
      {selectedProduct && (
        <SummaryContainer>
          <div className="order-line">
            <div className="order-line__text">{'Your points'}</div>
            <div className="order-line__points">{totalPoints}</div>
          </div>
          <div className="order-line">
            <div className="order-line__text">{selectedProduct.Name}</div>
            <div className="order-line__points">{'-' + selectedProduct.Points}</div>
          </div>
          <div className="border-t border-gray-400 font-medium order-line mb-4">
            <div className="order-line__text">{'Remaining'}</div>
            <div className="order-line__points">{remainingPoints}</div>
          </div>
          {buyError && <FailedRequest />}
          <ButtonContainer>
            <Button disabled={isBuying} onClick={order} level="primary">
              {t('Purchase')}
            </Button>
            <Button onClick={handleClose} level="secondary">
              {t('Cancel')}
            </Button>
          </ButtonContainer>
        </SummaryContainer>
      )}
      <Button onClick={handleClose} level="secondary">
        {t('CLOSE')}
      </Button>
    </div>
  )
}
