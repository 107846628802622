import React, { useState } from 'react'
import useI18n from '../../../i18n/useI18n'
import { useResetPassword, useUserInfo } from '../../../services/requestHooks/user'
import Button from '../../../components/Crow/Button/Button'

const ChangePassword = () => {
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false)
  const { t } = useI18n()

  const { run: resetPassword, isLoading: resetPasswordLoading } = useResetPassword({
    onSuccess: () => setResetPasswordEmailSent(true),
  })

  const handleResetPassword = async () => resetPassword(account.UserName)

  const account = useUserInfo()
  const hasCRM = account.NationHasCRM

  return (
    <div>
      {hasCRM && (
        <>
          {!resetPasswordEmailSent ? (
            <>
              <Button
                disabled={resetPasswordLoading || resetPasswordEmailSent}
                level="secondary"
                type="button"
                onClick={handleResetPassword}
              >
                {t('RESET_BY_EMAIL')}
                {resetPasswordLoading && <div className="ml-4 loader" />}
              </Button>
            </>
          ) : (
            t('A confirmation e-mail has been sendt to') + ` ${account.UserName}`
          )}
        </>
      )}
    </div>
  )
}

export { ChangePassword }
