import * as React from 'react'

function SvgTemperature(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 187 320 320" {...props}>
      <title>{'temperature'}</title>
      <path d="M216.9 284.1h-17.3c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2h17.3c2.3 0 4.2 1.9 4.2 4.2.1 2.3-1.8 4.2-4.2 4.2zM162 429.4c-22.1 0-40-17.8-40.1-39.9 0-14.3 7.6-27.6 20-34.7 2-1.2 4.6-.5 5.8 1.5 1.2 2 .5 4.6-1.5 5.8-9.8 5.6-15.8 16-15.8 27.2 0 17.4 14.1 31.6 31.6 31.6 17.4 0 31.6-14.1 31.6-31.6 0-11.2-6-21.6-15.8-27.2-1.3-.8-2.1-2.2-2.1-3.7v-78.6c-.4-7.6-6.9-13.3-14.5-12.9-6.9.4-12.5 5.9-12.9 12.9v63.7c0 2.3-1.9 4.2-4.2 4.2s-4.2-1.9-4.2-4.2v-63.7c.6-12.2 11-21.7 23.2-21.1 11.4.5 20.6 9.7 21.1 21.1v76.4c18.4 12.3 23.3 37.1 11.1 55.5-7.4 11.1-19.9 17.7-33.3 17.7z" />
      <path d="M162 347.8c-2.3 0-4.2-1.9-4.2-4.2v-63.7c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2v63.7c0 2.3-1.9 4.2-4.2 4.2zm54.9 0h-17.3c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2h17.3c2.3-.4 4.5 1.2 4.9 3.5s-1.1 4.5-3.4 4.9h-1.5zm-6.9-42.4h-10.4c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2H210c2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.9 4.2-4.2 4.2zm0 21.1h-10.4c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2H210c2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.9 4.2-4.2 4.2z" />
    </svg>
  )
}

export default SvgTemperature
