import styled from 'styled-components'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import { SubTitle } from '../../../components/Crow/Typography'

const LockCrowCard = styled(CrowCard)<{ justifyContent: string }>`
  display: flex;

  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
  align-items: center;
  margin-top: 0px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${colors.black300};
  background-color: ${colors.white};
`

const LabelContainer = styled.div<{ position: 'row' | 'column' }>`
  font-weight: 500;
  display: flex;
  flex-direction: ${({ position }) => (position === 'column' ? 'column' : 'row')};
`
const FlexRow = styled.div<{ marginAuto?: 'auto' | 'none' }>`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ marginAuto }) => (marginAuto === 'auto' ? 'auto' : 'none')};
  height: 48px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Label = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black800};
`

const LabelWrapper = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black700};
`

const LockSettingsBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-bottom: 16px;
`

const GeneralSettingsBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 67px;
`

const LockGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    justify-items: center;
    grid-template-columns: repeat(1, 1fr);
  }
`

const StyledIcon = styled(Icon)<{ color?: string }>`
  color: ${({ color }) => (color ? color : colors.black800)};
  font-size: 24px;
`

const Unreachable = styled.span`
  color: ${colors.red};
`

const LockTileContainer = styled.div`
  width: 164px;
`

const LockListItemContainer = styled.div`
  @media (min-width: 768px) {
    padding-left: 44px;
  }
`

const StyledSubtitle = styled(SubTitle)<{ pt?: string | number; pb?: string | number }>`
  margin: 0;
  padding-top: ${({ pt }) => pt + 'px'};
  padding-bottom: ${({ pb }) => pb + 'px'};
`

export {
  LockCrowCard,
  LabelContainer,
  FlexRow,
  FlexColumn,
  Label,
  LabelWrapper,
  LockSettingsBtnContainer,
  GeneralSettingsBtnContainer,
  LockGrid,
  StyledIcon,
  Unreachable,
  LockTileContainer,
  LockListItemContainer,
  StyledSubtitle,
}
