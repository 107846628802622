import { ReactNode } from 'react'
import styled from 'styled-components'
import Icon from '../Icon/Icon'
import PropertySelect from '../PropertySelect/PropertySelect'
import { colors } from '../Style/theme'
import NavigationMenu from './NavigationMenu'
import NavigationMenuSlider from './NavigationMenuSlider'

type Props = {
  children?: ReactNode
  className?: string
  padding?: ReactNode
  transparentBackground?: boolean
}

const Desktop = ({ children, className, padding, transparentBackground }: Props) => {
  return (
    <S.Desktop className={className}>
      <S.Sidebar>
        <div className="pb-5 pl-3">
          <PropertySelect />
        </div>
        <NavigationMenuSlider />
        <NavigationMenu />
      </S.Sidebar>
      <S.Content padding={padding!} displayBackground={!transparentBackground}>
        {children}
      </S.Content>
    </S.Desktop>
  )
}

const S = {
  Desktop: styled.div`
    display: flex;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    margin-right: 50px;
    color: ${colors.black900};
  `,

  Sidebar: styled.aside`
    padding: 24px 16px;
    background-color: ${colors.black900};
    color: ${colors.white};
  `,
  Content: styled.div<{ padding: ReactNode; displayBackground: boolean }>`
    padding: ${({ padding }) => (padding ? padding : '3rem')};
    display: flex;
    flex: 1;
    justify-content: center;

    ${({ displayBackground }) =>
      displayBackground &&
      `
    background-color: ${colors.black100};
    `}
  `,
  Top: styled.div`
    display: flex;
  `,
  Switch: styled(Icon).attrs({ name: 'Switch' })`
    background-color: ${colors.blue200};
    border-radius: 5px;
  `,
  displayName: styled.div`
    display: flex;
    justify-content: center;
  `,
}

export default Desktop
