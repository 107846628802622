function SvgBathRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4811 2C32.7973 2 34.7049 3.75002 34.9536 6H34.981V25H31V24C31 23.4477 30.5523 23 30 23C29.4477 23 29 23.4477 29 24V25H27V24.1111C27 23.5588 26.5523 23.1111 26 23.1111C25.4477 23.1111 25 23.5588 25 24.1111V25H23V24C23 23.4477 22.5523 23 22 23C21.4477 23 21 23.4477 21 24V25H19C19 23.8954 18.1046 23 17 23H8C6.89543 23 6 23.8954 6 25H1C0.447715 25 0 25.4477 0 26V29C0 31.9416 1.27011 34.5865 3.29183 36.4163L1.44721 40.1056C1.20022 40.5995 1.40045 41.2002 1.89443 41.4472C2.38841 41.6942 2.98908 41.494 3.23607 41L4.92646 37.6192C6.41382 38.4966 8.14813 39 10 39H30C31.8519 39 33.5862 38.4966 35.0735 37.6192L36.7639 41C37.0109 41.494 37.6116 41.6942 38.1056 41.4472C38.5996 41.2002 38.7998 40.5995 38.5528 40.1056L36.7082 36.4163C38.7299 34.5865 40 31.9416 40 29V26C40 25.4477 39.5523 25 39 25H36.981V6H36.9621C36.7068 2.64378 33.9027 0 30.4811 0C27.4075 0 24.8322 2.1333 24.155 5H21C20.4477 5 20 5.44772 20 6C20 6.55228 20.4477 7 21 7V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V7H29V8C29 8.55228 29.4477 9 30 9C30.5523 9 31 8.55228 31 8V7C31.5523 7 32 6.55228 32 6C32 5.44772 31.5523 5 31 5H26.2371C26.8549 3.25221 28.5217 2 30.4811 2ZM17 25H8V27.7271H17V25ZM8 32V29.7271H17V32H8ZM19 27H38V29C38 33.4183 34.4183 37 30 37H10C5.58172 37 2 33.4183 2 29V27H6V34H19V27Z"
        fill="#333131"
      />
      <path
        d="M23 12C23 11.4477 22.5523 11 22 11C21.4477 11 21 11.4477 21 12V14C21 14.5523 21.4477 15 22 15C22.5523 15 23 14.5523 23 14V12Z"
        fill="#333131"
      />
      <path
        d="M22 17C22.5523 17 23 17.4477 23 18V20C23 20.5523 22.5523 21 22 21C21.4477 21 21 20.5523 21 20V18C21 17.4477 21.4477 17 22 17Z"
        fill="#333131"
      />
      <path
        d="M27 9C27 8.44772 26.5523 8 26 8C25.4477 8 25 8.44772 25 9V9.88889C25 10.4412 25.4477 10.8889 26 10.8889C26.5523 10.8889 27 10.4412 27 9.88889V9Z"
        fill="#333131"
      />
      <path
        d="M26 12.4444C26.5523 12.4444 27 12.8922 27 13.4444V15.2222C27 15.7745 26.5523 16.2222 26 16.2222C25.4477 16.2222 25 15.7745 25 15.2222V13.4444C25 12.8922 25.4477 12.4444 26 12.4444Z"
        fill="#333131"
      />
      <path
        d="M27 18.7778C27 18.2255 26.5523 17.7778 26 17.7778C25.4477 17.7778 25 18.2255 25 18.7778V20.5556C25 21.1078 25.4477 21.5556 26 21.5556C26.5523 21.5556 27 21.1078 27 20.5556V18.7778Z"
        fill="#333131"
      />
      <path
        d="M30 11C30.5523 11 31 11.4477 31 12V14C31 14.5523 30.5523 15 30 15C29.4477 15 29 14.5523 29 14V12C29 11.4477 29.4477 11 30 11Z"
        fill="#333131"
      />
      <path
        d="M31 18C31 17.4477 30.5523 17 30 17C29.4477 17 29 17.4477 29 18V20C29 20.5523 29.4477 21 30 21C30.5523 21 31 20.5523 31 20V18Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgBathRoom
