const roomIcon = (roomKey: string): string => {
  switch (roomKey) {
    case 'HALLWAY_PLACE':
      return 'Hallway'
    case 'KITCHEN_PLACE':
      return 'Kitchen'
    case 'ENTRANCE_PLACE':
      return 'Entrance'
    case 'DINING_ROOM_PLACE':
      return 'DiningRoom'
    case 'MASTER_BEDROOM_PLACE':
      return 'MasterBedroom'
    case 'BEDROOM_PLACE':
      return 'Bedroom'
    case 'KIDS_ROOM_PLACE':
      return 'KidsRoom'
    case 'STAIRS_PLACE':
      return 'Stairs'
    case 'CELLAR_PLACE':
    case 'BASEMENT_PLACE':
    case 'TECHNICAL_ROOM_PLACE':
    case 'WINE_CELLAR_PLACE':
      return 'Cellar'
    case 'LIVING_ROOM_PLACE':
      return 'LivingRoom'
    case 'GUEST_BEDROOM_PLACE':
      return 'GuestBedRoom'
    case 'TV_ROOM_PLACE':
      return 'Tv'
    case 'OFFICE_PLACE':
      return 'Office'
    case 'BATHROOM_PLACE':
      return 'BathRoom'
    case 'TOILET_PLACE':
      return 'Toilet'
    case 'BAR_PLACE':
      return 'Bar'
    case 'STORAGE_ROOM_PLACE':
      return 'Storage'
    case 'HOME_THEATER_ROOM_PLACE':
      return 'Theater'
    case 'LIBRARY_PLACE':
      return 'Library'
    case 'GYM_PLACE':
      return 'Gym'
    case 'LAUNDRY_ROOM_PLACE':
      return 'Laundry'
    case 'PLAY_ROOM_PLACE':
      return 'Play'
    case 'ATTIC_PLACE':
      return 'Attic'
    case 'POOL_PLACE':
      return 'Pool'
    case 'DRESSING_ROOM_PLACE':
      return 'Dressing'
    case 'FOOD_STORAGE_PLACE':
      return 'FoodStorage'
    case 'BOILER_HOUSE_PLACE':
      return 'Boiler'
    case 'GUN_SAFE_PLACE':
      return 'GunRoom'
    case 'CRAFTS_ROOM_PLACE':
      return 'CraftsRoom'
    case 'WALK_IN_CLOSET_PLACE':
      return 'Dressing'
    case 'BIKE_SHED_PLACE':
      return 'BikeShed'
    case 'GUEST_HOUSE_PLACE':
    case 'RENTAL_PLACE':
      return 'RentalPlace'
    case 'GARAGE_PLACE':
      return 'GaragePlace'
    case 'UNKNOWN':
      return 'Unknown'
    default:
      return 'Unknown'
  }
}

export default roomIcon
