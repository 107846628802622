import React, { useEffect, useImperativeHandle } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { NavLink } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CountryCode } from 'libphonenumber-js'

import { Panel, PermanentUser } from '../../../types/PermanentUser'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import useI18n from '../../../i18n/useI18n'
import Button from '../../Crow/Button/Button'
import { Paragraph } from '../../Crow/Typography/Typography'
import KeyBox from '../../Crow/KeyBox'
import ButtonBox from '../../Crow/ButtonBox'
import Tooltip from '../../Crow/Tooltip'
import { colors } from '../../Crow/Style/theme'
import Icon from '../../Crow/Icon/Icon'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { getPanelCountryCode } from '../../../utils/numberField'
import { useUserInfo } from '../../../services/requestHooks/user'
import { getCustomerSupportUrl } from '../../../i18n/supportUrls'
import { phoneNumberValidator, panelCodeValidator } from '../../../components/forms/validators'
import { Loader } from '../../Elements/Loaders'

import UserInformation from './UserInformation'
import UserSettings from './UserSettings'
import UserPrivileges from './UserPrivileges'

export interface PeopleEditFormRef {
  handleReSubmit?: (() => void) | undefined
}

interface Props {
  user: PermanentUser
  showEditUserInformation: boolean
  panelIsArmed: boolean
  panelPermissions: Panel
  onSubmit: SubmitHandler<any>
  onUserInfoSubmit: SubmitHandler<any>
  onUserInfoCancel: () => void
  onRemoveClick: any
  title?: TranslationKey
  className?: string
  isLoading: boolean
  cancelAction?: () => void

  outsideRef: React.MutableRefObject<PeopleEditFormRef>
}

const PeopleEditForm = ({
  user,
  showEditUserInformation,
  panelIsArmed,
  panelPermissions,
  onSubmit,
  onUserInfoSubmit,
  onUserInfoCancel,
  onRemoveClick,
  title,
  className,
  isLoading,
  cancelAction,
  outsideRef,
}: Props) => {
  const { t } = useI18n()

  const { data: panelInfo } = usePanelInfo()
  const countryCode: CountryCode = getPanelCountryCode(panelInfo?.PanelId || 'noPanelId')
  const panelCodeLength: number = panelInfo!.PanelCodeLength

  const resolver = yup.object({
    firstName: yup
      .string()
      .min(2, t('error_first_name_at_least_2'))
      .max(32, t('error_first_name_length'))
      .trim(),
    lastName: yup
      .string()
      .min(2, t('error_last_name_at_least_2'))
      .max(32, t('error_last_name_length'))
      .trim(),
    underage: yup.boolean(),
    system: yup.boolean(),
    app: yup.boolean(),
    admin: yup.boolean(),
    phoneNumber: user.Accesses.IsAppUser
      ? phoneNumberValidator(t)
      : phoneNumberValidator(t).when('app', {
          is: true,
          then: yup.string().required(t('error_phone_number_required')),
        }),
    pin: user.Accesses.IsSystemUser
      ? panelCodeValidator(panelCodeLength, t)
      : panelCodeValidator(panelCodeLength, t).when('system', {
          is: true,
          then: yup.string().required(t('error_pin_code_required')),
        }),
  })

  const defaultValues = {
    firstName: user.FirstName,
    lastName: user.LastName,
    underage: user.Accesses.IsChild,
    system: user.Accesses.IsSystemUser,
    app: user.Accesses.IsAppUser,
    admin: user.Accesses.IsAdminUser,
    phoneNumber: '',
    pin: '',
  }

  const {
    register,
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const isUnderage = watch('underage')
  const hasSystemAccess = watch('system')
  const hasAppAccess = watch('app')
  const hasAdminAccess = watch('admin')

  const userInformationIsDirty =
    dirtyFields.hasOwnProperty('firstName') || dirtyFields.hasOwnProperty('lastName')
  const formIsDirty =
    dirtyFields.hasOwnProperty('underage') ||
    dirtyFields.hasOwnProperty('system') ||
    dirtyFields.hasOwnProperty('app') ||
    dirtyFields.hasOwnProperty('admin') ||
    dirtyFields.hasOwnProperty('phoneNumber') ||
    dirtyFields.hasOwnProperty('pin')

  const { IsLegalOwner, IsAdminUser } = panelPermissions.Accesses
  const readOnly: boolean = !(IsLegalOwner || IsAdminUser)

  const shouldBeDisabled = (fieldName: 'system' | 'admin' | 'app') => {
    return (
      readOnly ||
      (panelIsArmed && fieldName === 'system') ||
      (isUnderage && fieldName === 'admin') ||
      (!hasSystemAccess && (fieldName === 'app' || fieldName === 'admin')) ||
      user.Accesses.IsLegalOwner
    )
  }

  const initiallyHasSystemAccess: boolean = defaultValues!.system
  const initiallyHasAppAccess: boolean = defaultValues!.app

  const currentUser = useUserInfo()
  const isCurrentUser: boolean = currentUser.Id === user.PersonId

  const canChangePanelPinCode: boolean = isCurrentUser || !readOnly

  const handleReSubmit = () => {
    handleSubmit(onSubmit)()
  }

  useImperativeHandle(
    outsideRef,
    () => ({
      handleReSubmit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleReSubmit, onSubmit],
  )

  const canRemoveUser: boolean =
    // you have to be the owner or an admin
    (IsLegalOwner || IsAdminUser) &&
    // you can't remove yourself
    !isCurrentUser &&
    // admins can't remove other admins
    (!user.Accesses.IsAdminUser || IsLegalOwner)

  useEffect(() => {
    if (isUnderage) {
      hasAdminAccess && setValue('admin', false)
    }
    // eslint-disable-next-line
  }, [isUnderage])

  useEffect(() => {
    if (!hasSystemAccess) {
      hasAppAccess && setValue('app', false)
      setValue('pin', '')
    }
    // eslint-disable-next-line
  }, [hasSystemAccess])

  return (
    <S.Wrapper className={className}>
      {showEditUserInformation ? (
        <form onSubmit={handleSubmit(onUserInfoSubmit)} data-testid="form">
          <S.UserContent>
            <UserInformation register={register} errors={errors} />
          </S.UserContent>
          <S.ButtonRow>
            <Button
              type="button"
              onClick={onUserInfoCancel}
              level="secondary"
              size="l"
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            {userInformationIsDirty && (
              <Button level="primary" size="l" disabled={isLoading}>
                {isLoading ? <Loader /> : t('Save')}
              </Button>
            )}
          </S.ButtonRow>
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
          {title && <h1 className="mb-6 text-2xl">{t(title)}</h1>}
          <S.UserContent>
            {isCurrentUser && (
              <S.AccountInformation>
                {IsLegalOwner ? t('change_user_owner') : t('remove_account')}
                <S.AccountLink to="/account">
                  <S.AccountIcon name="Avatar" />
                  {t('my_profile')}
                </S.AccountLink>
              </S.AccountInformation>
            )}
            <UserSettings register={register} shouldBeDisabled={shouldBeDisabled} />
            <UserPrivileges
              initiallyHasSystemAccess={initiallyHasSystemAccess}
              isUnderage={isUnderage}
              hasSystemAccess={hasSystemAccess}
              canChangePanelPinCode={canChangePanelPinCode}
              panelIsArmed={panelIsArmed}
              register={register}
              shouldBeDisabled={shouldBeDisabled}
              panelCodeLength={panelCodeLength}
              initiallyHasAppAccess={initiallyHasAppAccess}
              hasAppAccess={hasAppAccess}
              control={control}
              countryCode={countryCode}
              errors={errors}
            />
            {user.Tag?.Color && (
              <KeyBox
                color={user.Tag?.Color || undefined}
                text={t('key_tag')}
                subText={t('owns_key_tag')}
              />
            )}
          </S.UserContent>
          <S.ButtonRow>
            <Button
              type="button"
              onClick={cancelAction}
              level="secondary"
              size="l"
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            {formIsDirty && (
              <Button level="primary" size="l" disabled={isLoading}>
                {isLoading ? <Loader /> : t('Save')}
              </Button>
            )}
          </S.ButtonRow>
        </form>
      )}
      <S.Other>
        {IsLegalOwner && isCurrentUser && (
          <S.SupportInformation>
            <S.SupportText>{t('remove_account_owner')}</S.SupportText>
            <S.SupportLink
              href={getCustomerSupportUrl(currentUser.NationId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('contact_support')}
            </S.SupportLink>
          </S.SupportInformation>
        )}
        {canRemoveUser && (
          <Tooltip text={t('system_must_be_disarmed_to_remove')} active={panelIsArmed}>
            <ButtonBox
              text={'Remove user'}
              textColor={colors.red}
              btnLevel="secondary"
              btnSize="s"
              btnVariant="error"
              onClick={onRemoveClick}
              disabled={panelIsArmed}
            >
              {'Remove'}
            </ButtonBox>
          </Tooltip>
        )}
      </S.Other>
    </S.Wrapper>
  )
}

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  UserContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 48px;
  `,
  AccountInformation: styled(Paragraph)`
    margin-bottom: 32px;
    color: ${colors.black800};
  `,
  AccountLink: styled(NavLink)`
    display: inline flex;
    margin-left: 8px;
    position: relative;
    top: 2px;
    text-decoration: none;
    width: fit-content;
    color: ${colors.blue};

    &:hover {
      color: ${colors.lightBlue};
    }
  `,
  AccountIcon: styled(Icon)`
    font-size: 18px;
  `,
  UnderAgeInfo: styled.p`
    padding: 0 16px;
    font-size: 16px;
    color: ${colors.black800};
  `,
  ButtonRow: styled.div`
    margin-top: 65px;
    display: flex;
    gap: 24px;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  `,
  Other: styled.div`
    margin-top: 36px;
  `,
  SupportInformation: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  `,
  SupportText: styled(Paragraph)`
    color: ${colors.black800};
  `,
  SupportLink: styled.a`
    text-decoration: none;
    color: ${colors.red};

    &:hover {
      color: ${colors.lightRed};
    }
  `,
}

export default PeopleEditForm
