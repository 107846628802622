import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'
import ChevronUp from '../../../components/Crow/Assets/icons/ChevronUp.svg'

export interface DropDownType {
  defaultValue: string | number | undefined
  label: ReactNode
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  className?: string
  children: ReactNode
}

const DropDownList = ({
  defaultValue,
  label,
  onChange,
  disabled,
  className,
  children,
}: DropDownType) => {
  return (
    <form className={className}>
      <label htmlFor="dropdown">{label}</label>
      <Select
        id="dropdown"
        value={defaultValue}
        onBlur={onChange}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </Select>
    </form>
  )
}

const Select = styled.select<{ disabled?: boolean }>`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${colors.black300};
  background-color: ${colors.white};
  appearance: none;
  background-image: url(${ChevronUp});
  background-repeat: no-repeat;
  background-position: 96.5% 50%;
  ${({ disabled }) => {
    return (
      disabled &&
      css`
        background-color: ${colors.black300};
        pointer-events: none;
      `
    )
  }}
`

export default DropDownList
