function SvgHallway(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1.72705C1 1.17477 1.44772 0.727051 2 0.727051H7C7.55228 0.727051 8 1.17477 8 1.72705V8.72705C8 9.27934 7.55228 9.72705 7 9.72705H2C1.44772 9.72705 1 9.27934 1 8.72705V1.72705ZM3 7.72705V2.72705H6V7.72705H3Z"
        fill="#666363"
      />
      <path
        d="M33 18.7271C33 19.5555 32.3284 20.2271 31.5 20.2271C30.6716 20.2271 30 19.5555 30 18.7271C30 17.8986 30.6716 17.2271 31.5 17.2271C32.3284 17.2271 33 17.8986 33 18.7271Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.7271V19.8987L6.12117 18.0199C5.73065 17.6293 5.09748 17.6293 4.70696 18.0199C4.31643 18.4104 4.31643 19.0435 4.70696 19.4341L8 22.7271V26.7271H5C4.44772 26.7271 4 27.1748 4 27.7271C4 28.2793 4.44772 28.7271 5 28.7271H5.22226L5.77782 33.7271H1C0.447715 33.7271 0 34.1748 0 34.7271C0 35.2793 0.447715 35.7271 1 35.7271L41 35.727C41.5523 35.727 42 35.2793 42 34.727C42 34.1748 41.5523 33.727 41 33.727L38 33.7271V5.93538C38 5.40447 37.8271 4.86581 37.4775 4.4434C37.1234 4.01556 36.5981 3.72705 36 3.72705H20C19.4019 3.72705 18.8766 4.01556 18.5225 4.4434C18.1729 4.8658 18 5.40447 18 5.93538V33.7271H12.2223L12.7778 28.7271H13C13.5523 28.7271 14 28.2793 14 27.7271C14 27.1748 13.5523 26.7271 13 26.7271H10V25.7271L13.293 22.4341C13.6836 22.0435 13.6836 21.4104 13.293 21.0199C12.9025 20.6293 12.2694 20.6293 11.8788 21.0199L10 22.8987L10 16.7271C10 16.1748 9.55228 15.7271 9 15.7271C8.44771 15.7271 8 16.1748 8 16.7271ZM36 5.93538V33.7271H20V5.93538C20 5.83441 20.0318 5.76208 20.0568 5.72705H35.9432C35.9682 5.76208 36 5.83441 36 5.93538ZM10.21 33.7271L10.7655 28.7271H7.23457L7.79013 33.7271H10.21Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5.72705C10.4477 5.72705 10 6.17477 10 6.72705V12.7271C10 13.2793 10.4477 13.7271 11 13.7271H15C15.5523 13.7271 16 13.2793 16 12.7271V6.72705C16 6.17477 15.5523 5.72705 15 5.72705H11ZM12 11.7271H14V7.72705H12V11.7271Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgHallway
