import useI18n from '../../../i18n/useI18n'
import { PanelEvent } from '../../../types/PanelEvent'
import { StyledParagraph, StyledSubtitle } from './HistoryStyle'
import { EventIcon, getEventText, getEventTitle } from './util/Helpers'

const RowEvents = ({ event }: { event: PanelEvent }) => {
  const { t } = useI18n()

  return (
    <>
      <div className="flex items-center w-full">
        <EventIcon eventType={event.EventType} />
        <div className="flex flex-col justify-center h-full">
          <StyledSubtitle>{getEventTitle(event, t)}</StyledSubtitle>
          <StyledParagraph grey>{getEventText(event, t)}</StyledParagraph>
        </div>
      </div>
    </>
  )
}

export default RowEvents
