function SvgGym(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3V2C4 0.895431 4.89543 0 6 0H7C8.10457 0 9 0.895431 9 2V7H10V4C10 3.44772 10.4477 3 11 3C11.5523 3 12 3.44771 12 4V7H30V4C30 3.44772 30.4477 3 31 3C31.5523 3 32 3.44771 32 4V7H33V2C33 0.89543 33.8954 0 35 0H36C37.1046 0 38 0.895431 38 2V3H39C40.1046 3 41 3.89543 41 5V7C41.5523 7 42 7.44772 42 8C42 8.55229 41.5523 9 41 9V11C41 12.1046 40.1046 13 39 13H38V14C38 15.1046 37.1046 16 36 16H35C33.8954 16 33 15.1046 33 14V9H32V32H41C41.5523 32 42 32.4477 42 33C42 33.5523 41.5523 34 41 34H1C0.447716 34 0 33.5523 0 33C0 32.4477 0.447715 32 1 32H10L10 9H9V14C9 15.1046 8.10457 16 7 16H6C4.89543 16 4 15.1046 4 14V13H3C1.89543 13 1 12.1046 1 11L0.999996 9C0.447712 9 -2.04303e-06 8.55228 0 8C0 7.44771 0.447719 7 1 7L1 5C1 3.89543 1.89543 3 3 3H4ZM6 2H7V14H6V2ZM30 32L30 9H12L12 32H30ZM38 11V5L39 5V11H38ZM36 2V14H35V2L36 2ZM4 5H3V11H4V5Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgGym
