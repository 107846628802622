import React, { FC, MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'

import Button from '../Button/Button'
import { colors } from '../Style/theme'

interface Props {
  text: string
  onClick: MouseEventHandler<HTMLButtonElement>
  textColor?: string
  btnLevel: 'primary' | 'secondary'
  btnSize?: 'xl' | 'l' | 'm' | 's'
  btnVariant?: 'default' | 'warning' | 'error' | 'white'
  disabled?: boolean
  className?: string
  children: ReactNode
}

const ButtonBox: FC<Props> = ({
  text,
  textColor,
  btnLevel,
  btnSize,
  btnVariant,
  children,
  onClick,
  disabled,
  className,
}) => {
  return (
    <S.Container className={className}>
      <S.Text textColor={textColor} disabled={disabled}>
        {text}
      </S.Text>
      <Button
        type="button"
        level={btnLevel}
        size={btnSize}
        variant={btnVariant}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </S.Container>
  )
}

const S = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    box-shadow: 0 0 0 1px ${colors.black200};
    border-radius: 12px;
    padding: 15px;
  `,
  Text: styled.span<{ textColor?: string; disabled?: boolean }>`
    margin: 0;
    font-family: Metric;
    font-size: 20px;
    line-height: 24px;
    color: ${({ textColor, disabled }) =>
      disabled ? colors.black500 : textColor || colors.black800};
  `,
}

// eslint-disable-next-line import/no-unused-modules
export default ButtonBox
