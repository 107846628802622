import * as React from 'react'

function SvgSystems(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <title>{'Alarm system_Sector Alarm_app_icon'}</title>
      <path d="M70 81c-6.1 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11zm0-18.3c-4 0-7.3 3.3-7.3 7.3s3.3 7.3 7.3 7.3 7.3-3.3 7.3-7.3-3.3-7.3-7.3-7.3zM97.8 90c-1 0-1.8-.9-1.8-1.9 0-.4.1-.7.3-1 7.2-10.3 7.2-24 0-34.3-.6-.8-.4-2 .5-2.6s2-.4 2.6.5c8.1 11.5 8.1 26.9 0 38.5-.4.5-1 .8-1.6.8zm-13-4.2c-1 .1-1.8-.7-1.9-1.7 0-.6.2-1.2.7-1.5 6.9-5.6 8-15.8 2.3-22.7-.7-.9-1.5-1.6-2.3-2.3-.8-.6-.9-1.8-.3-2.6.6-.8 1.8-.9 2.6-.3 8.5 6.9 9.7 19.4 2.8 27.8-.8 1-1.8 2-2.8 2.8-.4.3-.7.5-1.1.5zM42.2 90c-.6 0-1.2-.3-1.5-.8-8.1-11.6-8.1-26.9 0-38.5.6-.8 1.7-1 2.5-.5.8.6 1 1.7.5 2.5-7.2 10.3-7.2 24 0 34.3.6.8.4 1.9-.4 2.5-.4.4-.8.5-1.1.5zm13-4.2c-.4 0-.7-.2-1-.5-8.5-6.9-9.7-19.4-2.8-27.8.8-1 1.8-2 2.8-2.8.8-.6 1.9-.5 2.6.3.6.8.5 1.9-.3 2.6-6.9 5.6-8 15.8-2.3 22.7.7.9 1.5 1.6 2.3 2.3.8.6 1 1.7.4 2.5-.4.5-1 .8-1.7.7z" />
    </svg>
  )
}

export default SvgSystems
