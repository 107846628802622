/* eslint-disable import/no-unused-modules */
import React, { ReactNode, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'
import LeftPrimaryL from './Parts/LeftPrimaryL'
import LeftPrimaryM from './Parts/LeftPrimaryM'
import LeftPrimaryS from './Parts/LeftPrimaryS'
import LeftPrimaryXL from './Parts/LeftPrimaryXL'
import LeftSecondaryL from './Parts/LeftSecondaryL'
import LeftSecondaryM from './Parts/LeftSecondaryM'
import LeftSecondaryS from './Parts/LeftSecondaryS'
import LeftSecondaryXL from './Parts/LeftSecondaryXL'
import RightPrimaryL from './Parts/RightPrimaryL'
import RightPrimaryM from './Parts/RightPrimaryM'
import RightPrimaryS from './Parts/RightPrimaryS'
import RightPrimaryXL from './Parts/RightPrimaryXL'
import RightSecondaryL from './Parts/RightSecondaryL'
import RightSecondaryM from './Parts/RightSecondaryM'
import RightSecondaryS from './Parts/RightSecondaryS'
import RightSecondaryXL from './Parts/RightSecondaryXL'

const variantColors = {
  default: {
    color: colors.blue,
    activeColor: colors.lightBlue,
  },
  warning: {
    color: colors.yellow,
    activeColor: colors.yellow200,
  },
  error: {
    color: colors.red,
    activeColor: colors.red200,
  },
  white: {
    color: colors.white,
    activeColor: colors.lightGray,
  },
}

const buttonRights = {
  primary: {
    xl: RightPrimaryXL,
    l: RightPrimaryL,
    m: RightPrimaryM,
    s: RightPrimaryS,
  },
  secondary: {
    xl: RightSecondaryXL,
    l: RightSecondaryL,
    m: RightSecondaryM,
    s: RightSecondaryS,
  },
}

const buttonLefts = {
  primary: {
    xl: LeftPrimaryXL,
    l: LeftPrimaryL,
    m: LeftPrimaryM,
    s: LeftPrimaryS,
  },
  secondary: {
    xl: LeftSecondaryXL,
    l: LeftSecondaryL,
    m: LeftSecondaryM,
    s: LeftSecondaryS,
  },
}

export type Props = {
  type?: 'button' | 'submit' | 'reset'
  level: 'primary' | 'secondary'
  size?: 'xl' | 'l' | 'm' | 's'
  variant?: 'default' | 'warning' | 'error' | 'white'
  className?: string
  children: ReactNode
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = ({
  type,
  level,
  size = 'm',
  variant = 'default',
  children,
  className,
  disabled = false,
  onClick,
}: Props) => {
  const colors = variantColors[variant]
  const ButtonLeft = buttonLefts[level][size]
  const ButtonRight = buttonRights[level][size]
  return (
    <S.Button
      type={type}
      level={level}
      size={size}
      {...colors}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      <ButtonLeft />
      <Content level={level} size={size} {...colors}>
        {children}
      </Content>
      <ButtonRight />
    </S.Button>
  )
}

const Content = styled.div<{
  color: string
  activeColor: string
  level: 'primary' | 'secondary'
  size: 'xl' | 'l' | 'm' | 's'
}>`
  margin: 0 -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const S = {
  Button: styled.button<{
    color: string
    activeColor: string
    level: 'primary' | 'secondary'
    size: 'xl' | 'l' | 'm' | 's'
  }>`
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: fit-content;
    height: fit-content;
    & > svg > path {
      fill: ${({ color }) => color};
    }
    & ${Content} {
      font-weight: 600;
      ${({ size }) => {
        if (size === 'xl') {
          return css`
            font-size: 22px;
            line-height: 28px;
            padding: 0 21px;
          `
        }
        if (size === 'l') {
          return css`
            font-size: 18px;
            line-height: 22px;
            padding: 0 11px;
          `
        }
        if (size === 'm') {
          return css`
            font-size: 16px;
            line-height: 22px;
            padding: 0 11px;
          `
        }
        if (size === 's') {
          return css`
            font-size: 16px;
            line-height: 20px;
            padding: 0 3px;
            text-transform: uppercase;
          `
        }
        return ''
      }}
      ${({ level, color }) =>
        level === 'primary'
          ? css`
              background-color: ${color};
              color: ${colors.white};
            `
          : css`
              border-top: 2px solid ${color};
              border-bottom: 2px solid ${color};
              background-color: transparent;
              color: ${color};
            `}
    }
    &:hover {
      & ${Content} {
        ${({ level, activeColor }) =>
          level === 'primary'
            ? css`
                background-color: ${activeColor};
              `
            : css`
                border-top: 2px solid ${activeColor};
                border-bottom: 2px solid ${activeColor};
                background-color: transparent;
                color: ${activeColor};
              `}
      }
      & > svg > path {
        ${({ level, activeColor }) =>
          level === 'primary'
            ? css`
                fill: ${activeColor};
              `
            : css`
                fill: ${activeColor};
              `}
      }
    }
    &:focus-within {
      & ${Content} {
        ${({ level, activeColor }) =>
          level === 'primary'
            ? css`
                background-color: ${activeColor};
                color: ${colors.white};
              `
            : css`
                border-top: 2px solid ${activeColor};
                border-bottom: 2px solid ${activeColor};
                background-color: transparent;
                color: ${activeColor};
              `}
      }
      & > svg > path {
        fill: ${({ activeColor }) => activeColor};
      }
    }
    &:disabled {
      pointer-events: none;
      background-color: transparent;
      & ${Content} {
        ${({ level }) =>
          level === 'primary'
            ? css`
                background-color: ${colors.black200};
                color: ${colors.black500};
              `
            : css`
                border-top: 2px solid ${colors.black500};
                border-bottom: 2px solid ${colors.black500};
                background-color: transparent;
                color: ${colors.black500};
              `}
      }
      & > svg > path {
        fill: ${({ level }) => (level === 'primary' ? colors.black200 : colors.black500)};
      }
    }
  `,
}

export default Button
