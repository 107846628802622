import createHTTPClient from './createHTTPClient'

const HTTPv2 = createHTTPClient('/v2/housecheck')
const HTTP = createHTTPClient('/housecheck')

export const housecheckDoorsAndWindows = (panelId: string) => {
  return HTTP.post(`/doorsandwindows`, { panelId })
}

export const housecheckSmokedetectors = (panelId: string) => {
  return HTTP.post(`/smokedetectors`, { panelId })
}

export const postTemperatures = (panelId: string) => {
  return HTTPv2.post(`/temperatures`, { panelId })
}
