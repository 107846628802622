import useI18n from '../../i18n/useI18n'

interface DeviceNameType {
  deviceName: string
}

const DeviceName = ({ deviceName }: DeviceNameType) => {
  const { t } = useI18n()

  switch (deviceName) {
    case 'TemperatureFloodMagnetDetector':
    case 'MagAndGate':
      return t('temperature_sensor_default')
    case 'SmokeDetectorSync':
    case 'SmokeDetector':
      return t('smoke_detector')
    case 'FloodDetector':
      return t('flood_detector')
    case 'InfraredMotionDetector':
      return t('motion_detector')
    case 'GlassBreakDetector':
      return t('glass_break_detector')
    case 'Siren':
    case 'SmartSiren':
      return t('siren')
    case 'CameraPIR':
      return t('camera')
    case 'keypad':
      return t('Keypad')
    case 'Keypad':
      return t('Keypad')
    case 'UNKNOWN':
      return t('temperature_sensor_default')
    default:
      return t('Unknown')
  }
}

export default DeviceName
