import './modal.scss'

import FocusTrap from 'focus-trap-react'
import React, { createContext, FC, MouseEventHandler, useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import CloseModalButton from './CloseModalButton'
import classNames from 'classnames'
import { useOnMount } from '../../utils/commonHooks'
import FullScreenClose from '../Elements/FullScreenClose'
import { CloseButtonFixed } from '../Button/CloseButton'
import { getPortalContainer } from '../../utils/getContainer'

type Props = {
  handleClose?: MouseEventHandler
  fullscreen?: boolean
  title?: string
}

const Modal: FC<Props> = ({ fullscreen = false, handleClose, title, children }) => {
  const { setFullscreen } = useContext(ModalContext)

  useOnMount(() => {
    setFullscreen(fullscreen)
    return () => {
      setFullscreen(false)
    }
  })

  return ReactDOM.createPortal(
    <FocusTrap>
      <div
        className={classNames('modal', fullscreen && 'fullscreen-page')}
        role="dialog"
        aria-modal="true"
      >
        {fullscreen ? (
          <>
            {!!handleClose && <FullScreenClose onCancel={handleClose} />}
            <div>{children}</div>
          </>
        ) : (
          <div className="modal__content 3xl:max-w-1/4 xl:max-w-1/2 lg:max-w-3/4 md:max-w-full">
            {!!handleClose &&
              (!title ? (
                <CloseModalButton onClick={handleClose} />
              ) : (
                <div className="flex justify-between">
                  <h1 className="text-2xl">{title}</h1>
                  <CloseButtonFixed onCancel={handleClose} />
                </div>
              ))}
            {children}
          </div>
        )}
      </div>
    </FocusTrap>,
    getPortalContainer(),
  )
}

const ModalContext = createContext<{ setFullscreen: (value: boolean) => void }>({
  setFullscreen: () => {},
})

export const ModalProvider: FC = ({ children }) => {
  const [fullscreen, setFullscreen] = useState(false)
  return (
    <ModalContext.Provider value={{ setFullscreen }}>
      <div
        className={classNames(fullscreen ? 'hidden' : 'flex flex-col flex-1')}
        aria-hidden={fullscreen}
      >
        {children}
      </div>
    </ModalContext.Provider>
  )
}

export type ModalPromptProps<SubmitValue> = {
  show: boolean
  onSubmit?: (value: SubmitValue) => void
  onCancel?: () => void
}

export function useModalPrompt<SubmitValue>() {
  const [modalState, setModalState] = useState<ModalPromptProps<SubmitValue>>({ show: false })

  const promptForValue = () => {
    return new Promise<SubmitValue | undefined>((resolve) => {
      setModalState({
        show: true,
        onSubmit: (value: SubmitValue) => {
          setModalState({ show: false })
          resolve(value)
        },
        onCancel: () => {
          setModalState({ show: false })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForValue,
    modalState,
  }
}

export default Modal
