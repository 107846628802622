import React from 'react'

const RightPrimaryM = () => {
  return (
    <svg width="14" height="32" viewBox="0 0 14 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.254 7.51806C13.7521 10.342 14 13.1709 14 16C14 18.8291 13.7521 21.658 13.254 24.4819C12.4685 28.9359 9.84183 31.304 5.31633 31.7301C3.71745 31.8814 2.10608 31.9921 0.5 32H0V0H0.5C2.10608 0.00789363 3.71745 0.118575 5.31633 0.269903C9.84183 0.696046 12.4685 3.0641 13.254 7.51806Z" />
    </svg>
  )
}

export default RightPrimaryM
