import React, {
  ComponentProps,
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
} from 'react'
import styled, { css } from 'styled-components'

import Icon from '../Icon/Icon'
import { colors } from '../Style/theme'

interface Props {
  onChange: any
  onBlur?: any
  type?: string
  error?: string
  id?: string
  name?: string
  label?: string
  disabled?: boolean
  prefix?: ReactNode
  placeholder?: string
  value?: string
  postfix?: ReactNode
  maxLength?: number
  className?: string
}

const TextField: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  {
    id,
    type = 'text',
    name,
    label,
    disabled,
    prefix,
    placeholder,
    value,
    postfix,
    maxLength,
    onChange,
    onBlur,
    error,
    className,
  },
  ref,
) => {
  return (
    <S.Wrapper className={className}>
      {label && <S.Label htmlFor={id}>{label}</S.Label>}
      <S.Container disabled={disabled} error={error}>
        {prefix && (
          <S.Prefix content={prefix} disabled={disabled}>
            {prefix}
          </S.Prefix>
        )}
        <S.Input
          ref={ref}
          type={type}
          id={id}
          name={name}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          className="textfield-input"
        />
        {postfix && (
          <S.Postfix content={postfix} disabled={disabled}>
            {postfix}
          </S.Postfix>
        )}
        {error && <S.Error disabled={disabled} />}
      </S.Container>
      {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </S.Wrapper>
  )
}

const S = {
  Wrapper: styled.div``,
  Label: styled.label`
    font-family: 'Metric';
    font-weight: bold;
    line-height: 22px;
  `,
  Container: styled.div<{ border?: boolean; disabled?: boolean; error?: string }>`
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 54px;
    border-radius: 12px;
    background-color: ${({ disabled }) => (disabled ? colors.black200 : colors.white)};
    box-shadow: 0 0 0 1px ${colors.black200};
    ${({ error }) =>
      !!error &&
      css`
        box-shadow: 0 0 0 2px ${colors.red};
      `}
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${colors.black200};
      `}

    &> input {
      height: inherit;
    }

    &:focus-within {
      box-shadow: 0 0 0 2px ${colors.blue};
    }
  `,
  Prefix: styled.span<{ content: ReactNode; disabled?: boolean }>`
    display: flex;
    align-items: center;
    margin-right: 12px;
    color: ${({ disabled }) => (disabled ? colors.black500 : colors.black700)};
    ${({ content }) =>
      typeof content === 'string'
        ? css`
            font-family: 'Metric Medium';
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
          `
        : css`
            font-size: 22px;
          `}
  `,
  Input: styled.input<Props>`
    margin: 0;
    border: none;
    border-radius: 12px;
    outline: none;
    padding: 14px 0;
    font-size: 20px;
    line-height: 22px;
    color: ${colors.black900};
    &:focus {
      color: ${colors.black700};
    }
    &:focus::placeholder {
      color: transparent;
    }
    &:disabled {
      border: none;
      background-color: ${colors.black200};
      color: ${colors.black500};
    }
  `,
  Postfix: styled.span<{ content: ReactNode; disabled?: boolean }>`
    display: flex;
    align-items: center;
    margin-left: 12px;
    color: ${({ disabled }) => (disabled ? colors.black500 : colors.black700)};
    ${({ content }) =>
      typeof content === 'string'
        ? css`
            font-family: 'Metric';
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
          `
        : css`
            font-size: 22px;
          `}
  `,
  Error: styled<(props: ComponentProps<typeof Icon> & { disabled?: boolean }) => JSX.Element>(
    Icon,
  ).attrs({
    name: 'Warning',
  })`
    color: ${colors.red};
    font-size: 22px;
    margin-left: 10px;
    ${({ disabled }) =>
      disabled &&
      css`
        color: ${colors.red200};
      `}
  `,
  ErrorMessage: styled.p`
    margin: 7px 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.lightRed};
  `,
}

export default forwardRef(TextField)
