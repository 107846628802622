import * as React from 'react'

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <title>{'Kunde_Sector Alarm_app_icon'}</title>
      <path d="M94.5 85.4C90.6 83.8 81.3 80 81.3 80c-.6-.1-1.2 0-1.6.4-2.8 2-6.2 3.1-9.7 3.2-10.6 0-19.1-10.4-19.1-23.2S59.4 37.1 70 37.1s19.1 10.4 19.1 23.2c.1 5.4-1.5 10.7-4.5 15.1-.6.8-.4 2 .4 2.6.8.6 2 .4 2.6-.4 3.5-5.1 5.4-11.1 5.3-17.3 0-14.8-10.3-27.1-22.6-27.1S47.6 45.3 47.6 60.3s10.3 27.1 22.8 27.1c3.9 0 7.8-1.2 11-3.4 2.5 1 9 3.6 12.1 4.9 6.6 2.8 8 4.5 8 10.3v3.7H38.9V99c0-5.8 1.4-7.5 8-10.3 1.7-.7 4.5-1.7 6.7-2.8.9-.4 1.3-1.5.9-2.4-.4-.9-1.5-1.3-2.4-.9-2.3 1-4.9 2-6.6 2.8-7.9 3.4-10.2 6.5-10.2 13.5v5.6c0 1 .8 1.8 1.9 1.8H103c1 0 1.8-.8 1.9-1.8V99c-.2-7.1-2.5-10.2-10.4-13.6z" />
      <path d="M70 75.1c2.4-.1 4.8-.8 6.8-2.1.8-.6 1-1.7.4-2.6-.6-.8-1.7-1-2.6-.4-2.9 2-6.7 2-9.7 0-.8-.6-2-.4-2.6.4-.6.8-.4 2 .4 2.6 2.3 1.4 4.8 2.1 7.3 2.1zm-5-14.5v-1.4c-.2-1.4-1.5-2.4-2.8-2.2-1.1.2-2 1-2.2 2.2v1.3c.2 1.4 1.4 2.4 2.8 2.2 1.2-.1 2.1-1 2.2-2.1zm15 0v-1.4c-.2-1.4-1.5-2.4-2.8-2.2-1.1.2-2 1-2.2 2.2v1.3c.2 1.4 1.4 2.4 2.8 2.2 1.2-.1 2.1-1 2.2-2.1z" />
    </svg>
  )
}

export default SvgUser
