import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useOnMount } from '../../utils/commonHooks'
import NavBarPage from '../../components/pages/NavBarPage'
import useI18n from '../../i18n/useI18n'
import cx from 'classnames'
import { InvoiceStatus } from '../../types/Invoices'
import Button from '../../components/Button'
import { useLoadInvoices, useLoadInvoicePDF } from '../../services/requestHooks/invoice'
import { Loader } from '../../components/Elements/Loaders'
import { format } from 'date-fns'
import { useDateFnsLocale } from '../../utils/useDateFnsLocale'

const InvoicesPage = () => {
  const { t } = useI18n()
  const locale = useDateFnsLocale()
  const [loadingPDFs, setLoadingPFDs] = useState<string[]>([])
  const [currentTab, setCurrentTab] = useState<string>()
  const { InvoicesYears, Invoices } = useSelector((state: RootState) => state.invoices)
  const { run: loadInvoices, isLoading, error: invoicesError } = useLoadInvoices({})
  const { run: loadInvoicePDF } = useLoadInvoicePDF({
    onSuccess: () => setLoadingPFDs([]),
    onFailure: () => setLoadingPFDs([]),
  })

  useOnMount(() => {
    if (isLoading) return
    loadInvoices()
  })

  useEffect(() => {
    if (!InvoicesYears.length || currentTab) return
    const latestYear = InvoicesYears[0]
    setCurrentTab(latestYear)
  }, [InvoicesYears, currentTab])

  const handleTabClick = (year: string) => setCurrentTab(year)

  const handleLoadPDF = (invoiceNumber: string) => {
    setLoadingPFDs([...loadingPDFs, invoiceNumber])
    loadInvoicePDF(invoiceNumber)
  }

  return (
    <NavBarPage>
      <div>
        <header className="header">
          <h1 className="center">{t('Invoices')}</h1>
        </header>
        <div className="">
          <div className="tabs px-8">
            {InvoicesYears.map((year) => (
              <button
                key={year}
                onClick={() => handleTabClick(year)}
                className={cx('tabs__tab', {
                  'tabs__tab--active': currentTab === year,
                })}
              >
                {year}
              </button>
            ))}
          </div>

          <div className="background-gray">
            {isLoading && <InvoicesLoading />}

            {!isLoading && (
              <div className="grid flex flex-wrap px-8">
                {invoicesError && (
                  <div className="grid__half center">
                    <span className="notification notification--error">
                      {t('Could not load invoices. Please try again or contact customer services')}
                    </span>
                  </div>
                )}
                {!invoicesError && Invoices.length === 0 && (
                  <div className="grid__half center">
                    <span className="notification notification--error">
                      {t('No invoices found')}
                    </span>
                  </div>
                )}
                {Invoices.filter((invoice) => invoice.year === currentTab).map((invoice) => (
                  <div className="invoice" key={invoice.invoiceNumber}>
                    <div className="invoice__inner">
                      <h1 className="text-2xl mt-0">
                        {format(
                          new Date(parseInt(invoice.year), parseInt(invoice.month)),
                          'MMMM yyyy',
                          { locale },
                        )}
                      </h1>
                      <dl>
                        <dt>{t('Invoice')}</dt>
                        <dd>{invoice.invoiceNumber}</dd>
                        <dt>{t('Due date')}</dt>
                        <dd>{invoice.expiration}</dd>
                      </dl>
                      <Button
                        buttonType="link"
                        disabled={loadingPDFs.includes(invoice.invoiceNumber)}
                        onClick={() => handleLoadPDF(invoice.invoiceNumber)}
                      >
                        {t('Download PDF')}
                      </Button>
                      {loadingPDFs.includes(invoice.invoiceNumber) && <Loader className="ml-4" />}

                      {invoice.Overdue && (
                        <div className="invoice__status invoice__status--overdue">
                          {t('Overdue')}
                        </div>
                      )}
                      {!invoice.Overdue && invoice.status === InvoiceStatus.NOT_PAID && (
                        <div className="invoice__status invoice__status--due">{t('Not paid')}</div>
                      )}
                      {!invoice.Overdue && invoice.status === InvoiceStatus.PAID && (
                        <div className="invoice__status invoice__status--paid">{t('Paid')}</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </NavBarPage>
  )
}

const InvoicesLoading = () => {
  const { t } = useI18n()
  return (
    <Fragment>
      <div className="center">
        <Loader types={['large']} />
      </div>
      <h1 className="center">
        <div>{t('Getting your invoices')}</div>
      </h1>{' '}
    </Fragment>
  )
}

export default InvoicesPage
