import styled from 'styled-components'
import { colors } from '../../../components/Crow/Style/theme'
import { Hr, Paragraph, SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'

const EndOfEvents = () => {
  const { t } = useI18n()

  return (
    <>
      <EndOfEventsContainer>
        <StyledHr color={colors.black300} />
        <StyledSubtitle>{t('HISTORY_END')}</StyledSubtitle>
        <Paragraph grey>{t('history_storing')}</Paragraph>
      </EndOfEventsContainer>
    </>
  )
}

const EndOfEventsContainer = styled.div`
  margin-top: 40px;
  padding-top: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
`

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
`

const StyledHr = styled(Hr)`
  width: 90%;
`

export default EndOfEvents
