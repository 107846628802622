import { format, formatRelative, isAfter, isValid, parseISO, subDays } from 'date-fns'
import React, { MouseEventHandler, useMemo } from 'react'
import styled from 'styled-components'
import Tile from '../../../components/Crow/Tile/Tile'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { Paragraph, SubTitle } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'
import useI18n from '../../../i18n/useI18n'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
import { PanelStatus } from '../../../types/PanelModel'
import { utcToZonedTime } from 'date-fns-tz'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'

const StyledTile = styled(Tile)`
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin: 0;
  padding: 13px 16px;

  & > *:first-child {
    height: 45%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    & > *:first-child {
      flex: 5;
      justify-content: flex-start;
    }
    & > *:last-child {
      flex: 1;
      justify-content: flex-end;
    }
  }

  & > *:last-child {
    height: 55%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
`

const StyledSmartplugTitle = styled(SubTitle)`
  font-size: 22px;
  margin: 0;
  line-height: 20px;
`

const StyledStatusText = styled(Paragraph)`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.black700};
`

const Unreachable = styled.span`
  color: ${colors.red};
`

const TileSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;

  & > *:last-child {
    margin-top: 10px;
    justify-content: flex-end;
    align-items: flex-end;
  }
`

const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
`

const TextSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
`
interface TimeDetails {
  status?: string
  formatDate?: string
  panelStatus?: PanelStatus
  TimeZoneName?: string
}
interface CrowSmartplugItemProps {
  id?: string
  text: string
  timeDetails: TimeDetails
  checked?: boolean
  onClick?: MouseEventHandler<HTMLInputElement>
  isLoading?: boolean
  disabled?: boolean
}

export const CrowSmartplugItem = ({
  id,
  text,
  timeDetails,
  checked,
  onClick,
  isLoading,
  disabled,
}: CrowSmartplugItemProps) => {
  const locale = useDateFnsLocale()
  const { t } = useI18n()

  const ActivityDate = useMemo(() => {
    if (timeDetails.panelStatus) {
      const today = new Date()
      const utcDateObject = new Date(parseISO(timeDetails.formatDate!))
      const isLessThanAWeekOld = isAfter(utcDateObject, subDays(today, 7))

      if (!isValid(utcDateObject)) {
        return null
      }

      const zonedDate = utcToZonedTime(utcDateObject, timeDetails.TimeZoneName!)
      if (isLessThanAWeekOld) {
        return formatRelative(zonedDate, today, {
          locale,
        })
      }
      return format(zonedDate, 'Pp', {
        locale,
      })
    }
  }, [timeDetails.TimeZoneName, timeDetails.formatDate, locale, timeDetails.panelStatus])

  const smartplugState = () => {
    if (timeDetails?.status === 'On') {
      return (
        t('on')
          .charAt(0)
          .toUpperCase() + t('on').slice(1)
      )
    }
    return (
      t('off')
        .charAt(0)
        .toUpperCase() + t('off').slice(1)
    )
  }

  return (
    <StyledListItem>
      <StyledTile>
        <ToggleContainer>
          <LoadingOverlay isLoading={isLoading!}>
            <Toggle key={id} onClick={onClick} enabled={checked!} disabled={disabled} />
          </LoadingOverlay>
          <StyledIcon name="SmartPlug" />
        </ToggleContainer>
        <TileSubContainer>
          <TextSubContainer style={{ flex: 3, justifyContent: 'end' }}>
            <StyledSmartplugTitle>{text}</StyledSmartplugTitle>
          </TextSubContainer>
          <TextSubContainer style={{ flex: 2, justifyContent: 'end' }}>
            {disabled ? (
              <Unreachable>{t('DISCONNECTED')}</Unreachable>
            ) : (
              <StyledStatusText>{`${smartplugState()} ${t(
                'SINCE',
              )} ${ActivityDate}`}</StyledStatusText>
            )}
          </TextSubContainer>
        </TileSubContainer>
      </StyledTile>
    </StyledListItem>
  )
}
