import { SmartPlugTimeTrigger } from '../../../../../types/SmartPlug'
import React, { FormEventHandler, MouseEventHandler, useState } from 'react'
import useI18n from '../../../../../i18n/useI18n'
import Modal from '../../../../../components/Modal'
import cx from 'classnames'
import Button from '../../../../../components/Button'
import { Day, days, selectedDay } from './common'
import TimeInput, { Timestamp } from './TimeInput'

const doNothing = () => {}

const TimeTriggerForm = ({
  trigger: initialTrigger,
  onSubmit,
  onClose,
}: {
  trigger: SmartPlugTimeTrigger
  onSubmit: (trigger: SmartPlugTimeTrigger) => void
  onClose: () => void
}) => {
  const [trigger, setTrigger] = useState(initialTrigger)

  const setTimestamp = ({ hour, minute }: Timestamp) => {
    setTrigger({
      ...trigger,
      Hour: hour,
      Minute: minute,
    })
  }

  const { t } = useI18n()

  const toggleOn: MouseEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.name === 'On'
    setTrigger({ ...trigger, On: value })
  }

  const handleToggleDay = (day: Day) => {
    setTrigger(toggleDay(trigger, day))
  }

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    onSubmit(trigger)
  }

  return (
    <Modal handleClose={onClose}>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <h1 className="text-2xl mb-6">{t('Add timer')}</h1>
        <label className="mb-4">
          <span className="font-medium">{t('SMARTPLUG_ACTION')}</span>
          <fieldset>
            <label className="radio--large radio">
              {t('On')}
              <input
                name="On"
                onChange={doNothing}
                onClick={toggleOn}
                checked={trigger.On}
                type="radio"
                className="radio"
              />
              <i className="radio__indicator" />
            </label>
            <label className="radio--large radio">
              {t('Off')}
              <input
                name="Off"
                onChange={doNothing}
                onClick={toggleOn}
                checked={!trigger.On}
                type="radio"
                className="radio"
              />
              <i className="radio__indicator" />
            </label>
          </fieldset>
        </label>
        <label className="mb-4">
          {t('SMARTPLUG_TIMESTAMP')}
          <TimeInput
            onChange={setTimestamp}
            value={{ hour: trigger.Hour, minute: trigger.Minute }}
          />
        </label>
        <label className="mb-4">
          {t('SMARTPLUG_SELECT_DAYS')}
          <div className="flex flex-wrap mt-2">
            {days.map((day) => (
              <button
                onClick={() => handleToggleDay(day)}
                type="button"
                key={day}
                className={cx('time-trigger__weekday mb-2', {
                  'time-trigger__weekday--selected': selectedDay(trigger, day),
                })}
              >
                {day.substr(0, 2)}
              </button>
            ))}
          </div>
        </label>
        <div className="grid">
          <Button buttonType="primary">{t('Save')}</Button>
          <Button buttonType="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

const toggleDay = (trigger: SmartPlugTimeTrigger, day: Day): SmartPlugTimeTrigger => {
  const currentValue = trigger[day]
  return {
    ...trigger,
    [day]: !currentValue,
  }
}
export default TimeTriggerForm
