import React from 'react'

const LeftPrimaryM = () => {
  return (
    <svg width="14" height="32" viewBox="0 0 14 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.745965 7.51806C0.247862 10.342 0 13.1709 0 16C0 18.8291 0.247862 21.658 0.745965 24.4819C1.53153 28.9359 4.15817 31.304 8.68367 31.7301C10.2826 31.8814 11.8939 31.9921 13.5 32H14V0H13.5C11.8939 0.00789363 10.2826 0.118575 8.68367 0.269903C4.15817 0.696046 1.53153 3.0641 0.745965 7.51806Z" />
    </svg>
  )
}

export default LeftPrimaryM
