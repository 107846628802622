import { useState } from 'react'
import styled from 'styled-components'

import { Panel, PermanentUser } from '../../../types/PermanentUser'
import Card from '../../../components/Crow/Card/Card'
import EditUserDrawer from './EditUserDrawer'
import CategoryTag from './CategoryTag'
import Permissions from './Permissions'
import { colors } from '../../../components/Crow/Style/theme'

interface Props {
  user: PermanentUser
  panelIsArmed: boolean
  panelPermissions: Panel
}

const Person = ({ user, panelIsArmed, panelPermissions }: Props) => {
  const [displayModal, setModalVisibility] = useState<boolean>(false)

  return (
    <>
      <S.Card onClick={() => setModalVisibility(true)}>
        <S.Content>
          <S.Top>
            <S.Name>{`${user.FirstName} ${user.LastName}`}</S.Name>
            <CategoryTag user={user} />
          </S.Top>
          <S.Bottom>
            <Permissions user={user} />
          </S.Bottom>
        </S.Content>
      </S.Card>
      <EditUserDrawer
        user={user}
        panelIsArmed={panelIsArmed}
        panelPermissions={panelPermissions}
        isOpen={displayModal}
        handleClose={() => setModalVisibility(false)}
      />
    </>
  )
}

const S = {
  Card: styled(Card)`
    height: 132px;
  `,
  Content: styled.div`
    width: 100%;
  `,
  Top: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e3e3;
    width: 100%;
  `,
  Name: styled.span`
    font-family: 'Metric';
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    color: ${colors.black900};
  `,
  Bottom: styled.div`
    padding-top: 16px;
    height: 60px;
  `,
}

export default Person
