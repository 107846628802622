import React from 'react'

const LeftSecondaryM = () => {
  return (
    <svg width="14" height="32" viewBox="0 0 14 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.71557 24.1345C3.05214 26.0429 3.74355 27.3045 4.65863 28.1374C5.57702 28.9734 6.91543 29.5547 8.87117 29.7389C9.92341 29.8385 10.9672 29.9187 12 29.9629L14 29.9629L14 32L13.5 32C13.0003 31.9975 12.5002 31.9851 12 31.9647C10.8925 31.9193 9.78513 31.8343 8.68367 31.7301C4.15817 31.304 1.53153 28.9359 0.745966 24.4819C0.247863 21.658 1.15144e-06 18.8291 1.39876e-06 16C1.64609e-06 13.1709 0.247864 10.342 0.745967 7.51806C1.53153 3.0641 4.15818 0.696044 8.68367 0.269903C9.78513 0.165655 10.8925 0.0806958 12 0.035343C12.5002 0.01486 13.0003 0.00245658 13.5 -4.37114e-08L14 0L14 2.03709L12 2.03709C10.9672 2.08128 9.92436 2.16141 8.87212 2.261C6.91638 2.44517 5.57703 3.0266 4.65863 3.86257C3.74355 4.69553 3.05214 5.95714 2.71557 7.86545C2.23753 10.5756 2 13.2883 2 16C2 18.7117 2.23753 21.4244 2.71557 24.1345Z" />
    </svg>
  )
}

export default LeftSecondaryM
