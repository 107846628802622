import { MouseEventHandler, FC, ReactNode } from 'react'
import styled from 'styled-components'

import Tile from '../../../components/Crow/Tile/Tile'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { SubTitle } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay'
import useI18n from '../../../i18n/useI18n'
import { FlexRow } from './LockStyle'

type Props = {
  text: ReactNode
  subText?: ReactNode
  onClick?: MouseEventHandler<HTMLInputElement>
  checked?: boolean
  healthy?: boolean
  isLoading?: boolean
  settingsUrl?: string
  id?: string
  icon?: ReactNode
  className?: string
  disabled?: boolean
}

const LockTileItem: FC<Props> = ({
  text,
  subText,
  onClick,
  checked,
  healthy,
  isLoading,
  id,
  icon,
  className,
  disabled,
}) => {
  const { t } = useI18n()
  return (
    <>
      <Tile className={className} disabled={disabled} borderRadius="16px">
        {healthy || isLoading ? (
          <FlexRow className="pt-3">
            <LoadingOverlay isLoading={isLoading!}>
              <Toggle key={id} onClick={onClick} enabled={checked!} disabled={disabled} />
            </LoadingOverlay>

            <span className="ml-auto"> {icon ? icon : null}</span>
          </FlexRow>
        ) : (
          <div style={{ width: '3.5rem', marginRight: '0.9rem' }}>{t('Unknown')}</div>
        )}
        <label htmlFor={id} title={text?.toString()}>
          <StyledSubtitle light>{text}</StyledSubtitle>
          {!!subText && subText}
        </label>
      </Tile>
    </>
  )
}

const StyledSubtitle = styled(SubTitle)`
  height: 75px;
  margin: 0;
  padding-top: 18px;
  line-height: 26px;
  width: 125px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default LockTileItem
