import * as React from 'react'

function SvgSaWarranty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 150 150" {...props}>
      <path
        clipPath="url(#sa-warranty_svg__sa-warranty_svg__SVGID_2_)"
        fill="#525455"
        d="M70 139.6C31.6 139.6.4 108.4.4 70S31.6.4 70 .4s69.6 31.2 69.6 69.6-31.2 69.6-69.6 69.6M70 4.2C33.6 4.2 4.2 33.8 4.2 70s29.6 65.8 65.8 65.8c36.4 0 65.8-29.6 65.8-65.8S106.4 4.2 70 4.2"
      />
      <path
        clipPath="url(#sa-warranty_svg__sa-warranty_svg__SVGID_4_)"
        fill="#525455"
        d="M85.8 102.5c-4.2 0-7.5-3.5-7.5-7.7v-4.2H38.8v4.2c0 2.2 1.8 3.9 3.7 3.9h33.1c1.1 0 1.8.9 1.8 1.8 0 1.1-.9 1.8-1.8 1.8h-33c-4.2 0-7.5-3.5-7.5-7.7v-6.1c0-1.1.9-1.8 1.8-1.8h43c.4 0 .9.2 1.3.4.4.4.4.9.4 1.3v6.1c0 2.2 1.8 3.9 3.7 3.9 2.2 0 3.7-1.8 3.7-3.9V45c0-1.5.4-2.9 1.1-3.9H52.9c-2.2 0-3.7 1.8-3.7 3.9v36.4c0 1.1-.9 1.8-1.8 1.8-1.1 0-1.8-.9-1.8-1.8V45.2c0-4.2 3.3-7.7 7.5-7.7h43.7c1.1 0 1.8.9 1.8 1.8 0 1.1-.9 1.8-1.8 1.8C94.6 41 93 42.8 93 45v49.3c.3 4.7-3 8.2-7.2 8.2"
      />
      <path
        clipPath="url(#sa-warranty_svg__sa-warranty_svg__SVGID_6_)"
        fill="#525455"
        d="M67.1 71.8c-.7 0-1.1-.2-1.5-.7l-4.8-6.4c-.7-.9-.4-2 .4-2.6.9-.7 2-.4 2.6.4l3.3 4.4L75 56.2c.7-.9 1.8-.9 2.6-.4.9.7.9 1.8.4 2.6l-9.4 12.7c-.4.4-.8.7-1.5.7"
      />
    </svg>
  )
}

export default SvgSaWarranty
