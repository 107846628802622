function SvgDiningRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C20.5523 0 21 0.447715 21 1V7C21 7.02774 20.9989 7.05522 20.9967 7.0824C23.5122 7.50295 25.4976 9.4886 25.9177 12.0042C26.0087 12.549 25.5523 13 25 13H22C22 14.1046 21.1046 15 20 15C18.8954 15 18 14.1046 18 13H15C14.4477 13 13.9913 12.549 14.0823 12.0042C14.5024 9.4886 16.4878 7.50295 19.0033 7.0824C19.0011 7.05523 19 7.02774 19 7V1C19 0.447715 19.4477 0 20 0ZM20 9C21.4806 9 22.7733 9.8044 23.4649 11H16.5351C17.2267 9.8044 18.5194 9 20 9Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.20884 23.1919C0.111059 22.6483 0.472433 22.1284 1.01599 22.0306C1.55955 21.9328 2.07946 22.2942 2.17724 22.8378L4.00534 33H8.00005V31H7C5.89543 31 5 30.1046 5 29V27C5 25.8954 5.89543 25 7 25H33C34.1046 25 35 25.8954 35 27V29C35 30.1046 34.1046 31 33 31H32V33H36.1828L38.0108 22.8378C38.1086 22.2942 38.6285 21.9328 39.1721 22.0306C39.7157 22.1284 40.077 22.6483 39.9793 23.1919L38.0262 34.0488C38.0138 34.1175 37.9947 34.1833 37.9697 34.2456C37.8602 34.6792 37.4676 35 37.0001 35V41C37.0001 41.5523 36.5523 42 36.0001 42C35.4478 42 35.0001 41.5523 35.0001 41V35H32V41C32 41.5523 31.5523 42 31 42C30.4477 42 30 41.5523 30 41V35H28.0001V41C28.0001 41.5523 27.5523 42 27.0001 42C26.4478 42 26.0001 41.5523 26.0001 41V35C25.4478 35 25.0001 34.5523 25.0001 34C25.0001 33.4477 25.4478 33 26.0001 33H30V31H10.0001V33H14.188C14.7403 33 15.188 33.4477 15.188 34C15.188 34.5523 14.7403 35 14.188 35V41C14.188 41.5523 13.7403 42 13.188 42C12.6358 42 12.188 41.5523 12.188 41V35H10.0001L10.0001 41C10.0001 41.5523 9.55234 42 9.00005 42C8.44777 42 8.00005 41.5523 8.00005 41L8.00005 35H5.18804L5.18804 41C5.18804 41.5523 4.74032 42 4.18804 42C3.63576 42 3.18804 41.5523 3.18804 41L3.18804 35C2.72051 35 2.32792 34.6792 2.21843 34.2456C2.19338 34.1833 2.17426 34.1175 2.1619 34.0488L0.20884 23.1919ZM33 29V27H7V29H33Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgDiningRoom
