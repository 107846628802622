import axios from 'axios'
import { baseUrl } from '../configs'
import { getHeaders } from './api'

const createHTTPClient = (controllerPath: string) => {
  return {
    get: async <Response = any>(path: string) => {
      const url = `${baseUrl}${controllerPath}${path}`
      const response = await axios.get<Response>(url, await getHeaders())
      return response.data
    },
    post: async <Response = any>(path: string, body?: any) => {
      const url = `${baseUrl}${controllerPath}${path}`
      const response = await axios.post<Response>(url, body, await getHeaders())
      return response.data
    },
    patch: async <Response = any>(path: string, body?: any) => {
      const url = `${baseUrl}${controllerPath}${path}`
      const response = await axios.patch<Response>(url, body, await getHeaders())
      return response.data
    },
    delete: async <Response = any>(path: string) => {
      const url = `${baseUrl}${controllerPath}${path}`
      const response = await axios.delete<Response>(url, await getHeaders())
      return response.data
    },
  }
}

export default createHTTPClient
