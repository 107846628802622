import React, { FC, useState } from 'react'
import Button from '../../../components/Button'
import { Card } from '../../../components/Elements/Card'
import useI18n from '../../../i18n/useI18n'
import { Benefit, BenefitStatus } from '../../../types/Benefit'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import huseierneLogoUrl from '../../../assets/images/partners/huseierne.png'
import huseierneBackgroundUrl from '../../../assets/images/partners/huseierne_background.jpg'
import './benefits.scss'
import BonusPointsModal from './BonusPointsModal'

type Props = {
  benefit: Benefit
}

const benefitMap: Record<
  string,
  { description: TranslationKey; logoUrl: string; backgroundUrl: string }
> = {
  '644': {
    description: 'huseierne_desc',
    logoUrl: huseierneLogoUrl,
    backgroundUrl: huseierneBackgroundUrl,
  },
}

const emptyMap = {
  description: 'huseierne_desc',
  logoUrl: huseierneLogoUrl,
  backgroundUrl: huseierneBackgroundUrl,
}

const BenefitCard: FC<Props> = ({ benefit }) => {
  const { description, backgroundUrl } = benefitMap[benefit.Id] || emptyMap
  const { t } = useI18n()
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <Card className="benefit-card mb-2">
        <TitleImg
          active={benefit.StatusName === BenefitStatus.Validated}
          title={benefit.Name}
          src={backgroundUrl}
        />
        <div className="flex flex-col p-4">
          <p>{t(description)}</p>
          <Button className="self-end" buttonType="third" onClick={openModal}>
            {t('use_points')}
          </Button>
        </div>
      </Card>
      {open && <BonusPointsModal partnerId={benefit.PartnerId} handleClose={closeModal} />}
    </>
  )
}

interface TitleImgProps {
  title: string
  src: string
  active: boolean
}
const TitleImg: FC<TitleImgProps> = ({ src, title, active }) => {
  return (
    <div className={'title-img'}>
      <img alt="logo" src={src} className={'title-img__img'} />
      <h1 className="title-img__title">{title}</h1>
      {active && <div className="title-img__active">Active</div>}
    </div>
  )
}

export default BenefitCard
