import React, { FC } from 'react'
import { useIrishCheck } from '../../../../../i18n/useI18n'
import { ContactPerson, ContactPersonType } from '../../../../../types/ContactPerson'
import ContactPersonList from './ContactPersonList'

type Props = {
  contacts?: ContactPerson[]
  actions: {
    updatePerson: (updatedPerson: ContactPerson, originalPerson?: ContactPerson) => void
    changePriority: (chosenPerson: ContactPerson, priority: 'up' | 'down') => void
    deletePerson: (chosenPerson: ContactPerson) => void
  }
}

const personType = ContactPersonType.NEIGHBOR

const Neighbors: FC<Props> = ({ contacts = [], actions }) => {
  const { checkIrish } = useIrishCheck()

  return (
    <ContactPersonList
      contacts={contacts}
      personType={personType}
      actions={actions}
      title={checkIrish('Neighbours', 'PW_NEIGHBOUR')}
      formTitle={checkIrish('neighbour contact', 'PW_NEIGHBOUR')}
      description={checkIrish(
        'We will contact them to shorten response time for immediate help.',
        'PW_NEIGHBOUR_CONTACT_PERSON_ADD',
      )}
      maxContacts={3}
      maxContactsKey={checkIrish(
        'Max number of neighbors reached. Remove one to add another.',
        'YOU_CAN_ONLY_ADD_FOUR',
      )}
    />
  )
}

export default Neighbors
