import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../components/Crow/Toast/Toast'
import { Paragraph, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import {
  useGetSmartplugsStatus,
  useTurnOffSmartplug,
  useTurnOnSmartplug,
} from '../../../services/requestHooks/plugs'
import { SmartPlug } from '../../../types/SmartPlug'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import { CrowSmartplugItem } from './CrowSmartplugItem'
import SmartPlugsSettings from './SmartPlugsSettings'

const ItemWrapper = styled.div`
  display: flex;
  border-style: solid;
  border-width: 1px;
  max-width: 164px;
  margin-right: 16px;
  margin-top: 12px;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const SmartplugList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 10px;
`

const CrowSmartPlugPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const [currentPlug, setCurrentPlug] = useState<string | undefined>()
  const [smartPlugsState, setSmartPlugsState] = useState<SmartPlug>()

  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'right' })

  const { run: turnOn, isLoading: isTurningOn, error: turnOnError } = useTurnOnSmartplug({
    onSuccess: ({ response }: any) => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_smart_plug_turn_on_success'),
      })
    },
  })
  const { run: turnOff, isLoading: isTurningOff, error: turnOffError } = useTurnOffSmartplug({
    onSuccess: ({ response }: any) => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_smart_plug_turn_off_success'),
      })
    },
  })

  const isToggling = isTurningOff || isTurningOn

  const {
    run: loadPlugs,
    data: plugsWithStatus,
    isLoading: loadingPlugs,
    error: plugsError,
  } = useGetSmartplugsStatus()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const { data: panelInfo } = usePanelInfo()

  const togglePlug = (plug: SmartPlug) => async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    const command = plug.Status === 'On' ? turnOff : turnOn
    setCurrentPlug(plug.SerialNo)
    command({
      panelId,
      Id: plug.Id,
    })
  }

  const plugs = plugsWithStatus || panelInfo?.Smartplugs || []

  useOnMount(() => {
    if (!loadingPlugs) {
      loadPlugs(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  useEffect(() => {
    if (turnOnError) {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: t('toast_smart_plug_turn_on_error'),
      })
    }
  }, [turnOnError, t])

  useEffect(() => {
    if (turnOffError) {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: t('toast_smart_plug_turn_off_error'),
      })
    }
  }, [turnOffError, t])

  const openDrawer = (
    position: 'top' | 'bottom' | 'left' | 'right',
    smartPlug: SmartPlug,
  ) => () => {
    setSmartPlugsState({
      ...smartPlug,
    })

    if (!panelStatus?.IsOnline) {
      return
    }

    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  const returnUrl = `/systems/${panelId}`

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <FullscreenPage
        isLoading={loadingPlugs}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: !!plugsError || !!panelStatusError,
            errorHeaderText: t('smartplugs'),
            errorText: t('Could not load smartplug status'),
            returnUrl,
          },
        }}
      >
        <MainContainer>
          <Toast />
          <BackButton backUrl={returnUrl} />
          <Title>{t('Smart plugs')}</Title>
          <Paragraph>{t('SMARTPLUGS_DESCRIPTION')}</Paragraph>
          <SmartplugList>
            {plugs.map((plug) => {
              const enabled = plug.Status === 'On'
              const isDisabled =
                plug.Status === 'Disconnected' ||
                plug.Status === 'Unreachable' ||
                plug.Status === 'unknown'
              return (
                <ItemWrapper
                  key={plug.SerialNo}
                  role="button"
                  tabIndex={0}
                  onKeyPress={openDrawer('right', plug)}
                  onClick={openDrawer('right', plug)}
                >
                  <CrowSmartplugItem
                    id={plug.SerialNo}
                    text={plug.Label}
                    timeDetails={{
                      status: plug.Status,
                      formatDate: plug.LastChanged,
                      panelStatus: panelStatus?.Status,
                      TimeZoneName: panelStatus?.TimeZoneName,
                    }}
                    checked={enabled}
                    onClick={togglePlug(plug)}
                    isLoading={(isToggling && currentPlug === plug.SerialNo) || loadingPlugs}
                    disabled={isDisabled}
                  />
                </ItemWrapper>
              )
            })}
            <S.Drawer
              isOpen={drawerState.isOpen}
              position={drawerState.position}
              handleClose={closeDrawer}
              closeButton={false}
              title={
                <S.Header>
                  <S.CloseButton onClick={closeDrawer}>
                    <StyledIcon color={colors.blue} name="Cancel" />
                  </S.CloseButton>
                  {smartPlugsState?.Label}
                </S.Header>
              }
            >
              <SmartPlugsSettings smartPlug={smartPlugsState!} />
            </S.Drawer>
          </SmartplugList>
        </MainContainer>
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const StyledIcon = styled(Icon)`
  color: ${colors.blue};
  font-size: 22px;
`

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    padding-left: 31px;
    @media (max-width: 320px) {
      padding-right: 50px;
    }
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 72px;
    width: 636px;
    height: 850px;
  `,
  Header: styled.div`
    padding-bottom: 50px;
  `,
  CloseButton: styled.button`
    margin-right: 28px;
  `,
  SoundSettings: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
}

export default CrowSmartPlugPage
