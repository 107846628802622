import React, { FC } from 'react'
import { WifiLowIcon, WifiOkIcon, WifiStrongIcon, WifiVeryLowIcon } from '../../../../assets/icons'
import useI18n from '../../../../i18n/useI18n'
import { WifiDetails, WifiEncryptionType } from '../../../../types/WifiDetails'

type Props = {
  title: string
}

const WifiList: FC<Props> = ({ title, children }) => {
  return (
    <>
      <h2 className="gray mt-8 mb-2">{title}</h2>
      <hr />
      <ul className="pl-0">{children}</ul>
    </>
  )
}

const RadioLevel: FC<{ level: string }> = ({ level }) => {
  const levelNumber = +level
  let Icon = WifiVeryLowIcon
  if (levelNumber >= 8) {
    Icon = WifiStrongIcon
  } else if (levelNumber >= 5) {
    Icon = WifiOkIcon
  } else if (levelNumber >= 3) {
    Icon = WifiLowIcon
  }
  return <Icon />
}

const Encryption: FC<{ EncryptionType: WifiEncryptionType }> = ({ EncryptionType }) => {
  const { t } = useI18n()
  const key = EncryptionType === WifiEncryptionType.NOT_ENCRYPTED ? 'OPEN' : 'ENCRYPTED'
  return <p>{t(key)}</p>
}

type ItemProps = WifiDetails & {
  onClick: () => void
}

export const WifiListItem: FC<ItemProps> = ({
  Name,
  RadioLevel: level,
  Connected,
  EncryptionType,
  onClick,
}) => {
  return (
    <li className="hoverable mt-0">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center text-left py-2 bottom-border"
      >
        <div className="ml-4">
          <h3>{Name}</h3>
          {Connected.toLowerCase() === 'false' && <Encryption EncryptionType={EncryptionType} />}
        </div>
        <RadioLevel level={level} />
      </button>
    </li>
  )
}

export default WifiList
