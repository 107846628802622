import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'

type Props = {
  backUrl: string
}

const BackButton: FC<Props> = ({ backUrl }) => {
  const { t } = useI18n()
  return (
    <Link className="fullscreen__back no-underline" to={backUrl}>
      {t('Back')}
    </Link>
  )
}

export default BackButton
