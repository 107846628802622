import React from 'react'
import BackgroundImagePage from '../components/pages/BackgroundImagePage'
import { version } from '../../package.json'
const VersionPage = () => {
  return (
    <BackgroundImagePage>
      <h1 className="text-4xl">{version}</h1>
    </BackgroundImagePage>
  )
}

export default VersionPage
