import React, { useState } from 'react'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import { useOnMount } from '../../../../utils/commonHooks'
import { takePicture } from '../../../../services/apiPanel'
import { useHistory, useParams } from 'react-router-dom'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import IconSectorAlarmAppAddPhoto from '../../../../assets/icons/IconSectorAlarmAppAddPhoto'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import Button from '../../../../components/Button'
import useI18n from '../../../../i18n/useI18n'
import { getImpersonationId } from '../../../../services/authentication/AuthContext'
import styled from 'styled-components'
import { Paragraph } from '../../../../components/Crow/Typography'

interface PictureResponse {
  Image: string
  Label: string
}

const ImpersonateImageText = styled(Paragraph)`
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
`

const imgPrefix = 'data:image/jpeg;base64,'

const TriggerPicturePage = () => {
  const { t } = useI18n()
  const history = useHistory()
  const { panelId, cameraId } = useParams<{ panelId: string; cameraId: string }>()
  const returnUrl = `/systems/${panelId}`

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<any>()
  const [data, setData] = useState<PictureResponse | undefined>()

  const impersonationId = getImpersonationId()
  const isImpersonating = !!impersonationId

  useOnMount(() => {
    takePicture({ panelId, deviceId: cameraId })
      .then((res) => {
        setData(res)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  })

  if (isLoading) {
    return <FullScreenLoader text={'TAKING_PHOTO'} />
  }

  return (
    <FullscreenPage returnURL={returnUrl}>
      <div className="center mb-8">
        <IconSectorAlarmAppAddPhoto className="icon" />
        <h1>{data?.Label}</h1>
      </div>
      {!isImpersonating && data?.Image && <img alt="livepicture" src={imgPrefix + data?.Image} />}
      {error && isImpersonating && (
        <ImpersonateImageText>Cannot see image while impersonating</ImpersonateImageText>
      )}
      {error && !isImpersonating && <FailedRequest text={error} />}
      <div className="flex justify-end mt-8">
        <Button onClick={() => history.replace(returnUrl)} buttonType="primary">
          {t('Done')}
        </Button>
      </div>
    </FullscreenPage>
  )
}

export default TriggerPicturePage
