function SvgKidsRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="38" height="41" viewBox="0 0 38 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0C2.44772 0 2 0.447715 2 1C2 1.55228 2.44772 2 3 2H6V5C6 5.55228 6.44772 6 7 6C7.55228 6 8 5.55228 8 5V2H13V9C13 9.55229 13.4477 10 14 10C14.5523 10 15 9.55229 15 9V2H19C19.5523 2 20 1.55228 20 1C20 0.447715 19.5523 0 19 0H3Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 14C17 15.6569 15.6569 17 14 17C12.3431 17 11 15.6569 11 14C11 12.3431 12.3431 11 14 11C15.6569 11 17 12.3431 17 14ZM15 14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10C10 11.6569 8.65685 13 7 13C5.34315 13 4 11.6569 4 10C4 8.34315 5.34315 7 7 7C8.65685 7 10 8.34315 10 10ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 19.4477 0.447715 19 1 19C1.55228 19 2 19.4477 2 20V21H36V20C36 19.4477 36.4477 19 37 19C37.5523 19 38 19.4477 38 20V40C38 40.5523 37.5523 41 37 41C36.4477 41 36 40.5523 36 40V39H2V40C2 40.5523 1.55228 41 1 41C0.447715 41 0 40.5523 0 40V20ZM36 37V23H34V37H36ZM30 23H32V37H30V23ZM28 23H26V37H28V23ZM22 23H24V37H22V23ZM20 23H18V37H20V23ZM14 23H16V37H14V23ZM12 23H10V37H12V23ZM6 23H8V37H6V23ZM4 23H2V37H4V23Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1358 2.88736C26.948 2.83018 26.7492 2.82997 26.5638 2.88422C25.6744 3.13404 24.8655 3.61143 24.2169 4.26937C23.5652 4.9305 23.098 5.75086 22.862 6.64872C22.626 7.54659 22.6294 8.49062 22.8718 9.38677C23.1143 10.2829 23.5872 11.0999 24.2437 11.7564C24.9002 12.4128 25.7172 12.8858 26.6133 13.1282C27.5095 13.3706 28.4535 13.374 29.3514 13.138C30.2492 12.902 31.0696 12.4348 31.7307 11.7831C32.3883 11.1348 32.8656 10.3263 33.1155 9.43748C33.168 9.25889 33.17 9.06763 33.1189 8.88551C32.9978 8.45348 32.6037 8.15486 32.155 8.15532C32.0564 8.15542 31.9605 8.16994 31.8696 8.19713C31.3096 8.34919 30.7195 8.35161 30.1581 8.20397C29.5905 8.05469 29.0727 7.75734 28.6577 7.34232C28.2427 6.92731 27.9453 6.40952 27.7961 5.84191C27.6483 5.28005 27.6508 4.68933 27.8033 4.12895C27.8292 4.04191 27.8435 3.9503 27.8446 3.85615C27.85 3.41161 27.5611 3.01685 27.1358 2.88736ZM25.6412 5.67344C25.6673 5.647 25.6938 5.62102 25.7207 5.59552C25.7493 5.84942 25.7964 6.10179 25.8618 6.35062C26.1012 7.2608 26.578 8.09107 27.2435 8.75654C27.909 9.42201 28.7393 9.89882 29.6494 10.1382C29.8983 10.2036 30.1506 10.2507 30.4045 10.2794C30.379 10.3063 30.353 10.3327 30.3266 10.3588C29.9143 10.7652 29.4028 11.0565 28.8429 11.2037C28.283 11.3508 27.6944 11.3487 27.1355 11.1976C26.5767 11.0464 26.0673 10.7515 25.6579 10.3421C25.2486 9.9328 24.9536 9.42334 24.8024 8.86452C24.6513 8.3057 24.6492 7.71703 24.7963 7.15714C24.9435 6.59726 25.2348 6.08571 25.6412 5.67344Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgKidsRoom
