import React, { ComponentType, FC, useEffect } from 'react'
import { Route } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'
import InternalErrorPage from '../../pages/errors/InternalErrorPage'
import { useAuth } from '../../services/authentication/AuthContext'
import { useGetUserInfo } from '../../services/requestHooks/user'
import RequestLoader from '../Elements/Loaders'

type Props = {
  component?: ComponentType
  path: string
  exact?: boolean
}

const PrivateRoute: FC<Props> = ({ children, component: Component, path, exact }) => {
  const { t } = useI18n()
  const { isLoading, isAuthenticated, login } = useAuth()
  const {
    run: loadUserInfo,
    isLoading: isLoadingUserInfo,
    hasLoaded: hasUserInfo,
    error: userInfoError,
  } = useGetUserInfo()
  useEffect(() => {
    if (isLoading) return
    if (isAuthenticated) {
      if (!isLoadingUserInfo && !hasUserInfo && !userInfoError) {
        loadUserInfo()
      }
      return
    }
    login()
  }, [
    isLoading,
    isAuthenticated,
    path,
    login,
    isLoadingUserInfo,
    hasUserInfo,
    loadUserInfo,
    userInfoError,
  ])

  const render = () => {
    if (!!userInfoError) {
      return (
        <InternalErrorPage text="The account data could not be loaded. Please try again or contact customer service" />
      )
    }
    if (isLoadingUserInfo || !hasUserInfo) {
      return <RequestLoader types={['large']} loadingText={t('Loading account')} />
    }
    if (isAuthenticated) {
      return <>{children || (Component && <Component />)}</>
    }
    return null
  }
  return <Route path={path} render={render} exact={exact} />
}

export default PrivateRoute
