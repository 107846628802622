import { ReactNode } from 'react'
import styled from 'styled-components'
import { displayNewNavigationMenu } from '../configs'
import { Capability, PanelModel } from '../types/PanelModel'
import Layout from './Crow/NavigationMenu/Layout/Layout'
import { colors } from './Crow/Style/theme'

const TcContainer = styled.div`
  background-color: ${colors.black100};

  display: flex;
  justify-content: center;
`
type Props = {
  panelInfo?: PanelModel
  children?: ReactNode
  hideWrapper?: boolean
  fullscreen?: boolean
}

export const TcOrCrowContainer = ({ children, panelInfo, hideWrapper, fullscreen }: Props) => {
  const displayOldNavigationMenu =
    panelInfo?.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN) ||
    !(displayNewNavigationMenu === 'true')

  if (panelInfo?.Capabilities)
    return (
      <>
        {displayOldNavigationMenu ? (
          hideWrapper ? (
            <>{children}</>
          ) : fullscreen ? (
            <div className="flex flex-1 fullscreen-page">
              <div className="grid py-16 px-8">
                <div className="grid__half">{children}</div>
              </div>
            </div>
          ) : (
            <TcContainer>{children}</TcContainer>
          )
        ) : (
          <Layout transparentBackground={hideWrapper}>{children}</Layout>
        )}
      </>
    )

  return null
}
