function SvgUnknown() {
  return (
    <svg width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38 17C38 17.5523 37.5523 18 37 18C36.4477 18 36 17.5523 36 17C36 16.4477 36.4477 16 37 16C37.5523 16 38 16.4477 38 17Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 35C0.447715 35 0 34.5523 0 34C0 33.4477 0.447715 33 1 33H19V1C19 0.447715 19.4477 0 20 0H40C40.5523 0 41 0.447715 41 1V34C41 34.5523 40.5523 35 40 35H1ZM21 2V33H39V2H21Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C1.44772 5 1 5.44771 1 6V15C1 15.5523 1.44772 16 2 16H14C14.5523 16 15 15.5523 15 15V6C15 5.44772 14.5523 5 14 5H9C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5H2ZM3 7V14H13V7H3Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgUnknown
