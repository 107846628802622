import { FC } from 'react'
import SimpleForm from '../../../components/forms/SimpleForm'
import * as yup from 'yup'
import { pinCodeValidator } from '../../../components/forms/validators'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import useI18n from '../../../i18n/useI18n'
import Button from '../../../components/Button'

export type OneTimeCodeFormValues = Record<keyof typeof resolver.fields, string>

interface Field {
  name: keyof typeof resolver.fields
  label: TranslationKey
  type: 'text' | 'password'
  placeholder: TranslationKey
}

const fields: Field[] = [
  { name: 'pinCode', type: 'text', label: 'One time code', placeholder: 'One time code' },
]

const resolver = yup.object({
  pinCode: pinCodeValidator,
})

interface Props {
  onSubmit: (formValues: OneTimeCodeFormValues) => void
  isLoading: boolean
}

const PinCodeForm: FC<Props> = ({ onSubmit, isLoading }) => {
  const { t } = useI18n()

  const refresh = () => {
    return window.location.reload()
  }

  return (
    <SimpleForm isLoading={isLoading} resolver={resolver} onSubmit={onSubmit} fields={fields}>
      <div className="padding-bottom">
        <p>
          <strong>{t('You should have the SMS whithin 30 seconds.')}</strong>
          <br />
          {t('Did not get it?')}
          <Button buttonType="link" onClick={refresh}>
            {t('Try again')}
          </Button>
        </p>
      </div>
    </SimpleForm>
  )
}

export default PinCodeForm
