function SvgPool(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="25" viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 0C21.3431 0 20 1.34315 20 3V21C20 21.5523 20.4477 22 21 22C21.5523 22 22 21.5523 22 21V18H32V18.5C32 19.0523 32.4477 19.5 33 19.5C33.5523 19.5 34 19.0523 34 18.5V3C34 2.44772 34.4477 2 35 2C35.5523 2 36 2.44772 36 3V10.5C36 11.0523 36.4477 11.5 37 11.5C37.5523 11.5 38 11.0523 38 10.5V3C38 1.34315 36.6568 0 35 0C33.3431 0 32 1.34315 32 3V8H22V3C22 2.44772 22.4477 2 23 2C23.5523 2 24 2.44772 24 3V5C24 5.55228 24.4477 6 25 6C25.5523 6 26 5.55228 26 5V3C26 1.34315 24.6568 0 23 0ZM32 16H22V14H32V16ZM32 12V10H22V12H32Z"
        fill="#333131"
      />
      <path
        d="M2.16437 20.9819C1.98368 20.46 1.55228 20 0.999995 20C0.44771 20 -0.010437 20.4522 0.0987213 20.9936C0.559521 23.279 2.57878 25 4.99999 25C6.6356 25 8.08777 24.2147 9 23.0005C9.91223 24.2147 11.3644 25 13 25C14.6356 25 16.0878 24.2147 17 23.0005C17.9122 24.2147 19.3644 25 21 25C22.6356 25 24.0878 24.2147 25 23.0005C25.9122 24.2147 27.3644 25 29 25C30.6356 25 32.0878 24.2147 33 23.0005C33.9122 24.2147 35.3644 25 37 25C39.4212 25 41.4405 23.279 41.9013 20.9936C42.0104 20.4522 41.5523 20 41 20C40.4477 20 40.0163 20.46 39.8356 20.9819C39.4289 22.1565 38.313 23 37 23C35.687 23 34.571 22.1565 34.1644 20.9819C33.9837 20.46 33.5523 20 33 20C32.4477 20 32.0163 20.46 31.8356 20.9819C31.4289 22.1565 30.313 23 29 23C27.687 23 26.571 22.1565 26.1644 20.9819C25.9837 20.46 25.5523 20 25 20C24.4477 20 24.0163 20.46 23.8356 20.9819C23.4289 22.1565 22.313 23 21 23C19.687 23 18.571 22.1565 18.1644 20.9819C17.9837 20.46 17.5523 20 17 20C16.4477 20 16.0163 20.46 15.8356 20.9819C15.4289 22.1565 14.313 23 13 23C11.687 23 10.571 22.1565 10.1644 20.9819C9.98368 20.46 9.55228 20 9 20C8.44771 20 8.01631 20.46 7.83562 20.9819C7.42894 22.1565 6.31297 23 4.99999 23C3.68702 23 2.57105 22.1565 2.16437 20.9819Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgPool
