import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WifiDetails } from '../types/WifiDetails'

type WifiState = Record<
  string,
  | {
      wifiList: WifiDetails[]
    }
  | undefined
>

const initialState: WifiState = {}

const wifiSlice = createSlice({
  name: 'wifi',
  initialState,
  reducers: {
    SET_WIFI_LIST(state, action: PayloadAction<{ data: WifiDetails[]; panelId: string }>) {
      const { data, panelId } = action.payload
      state[panelId] = { wifiList: data }
    },
    CONNECT_TO_WIFI(state, action: PayloadAction<{ panelId: string }>) {},
    DISCONNECT_FROM_WIFI(state, action: PayloadAction<{ panelId: string }>) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { SET_WIFI_LIST, CONNECT_TO_WIFI, DISCONNECT_FROM_WIFI } = wifiSlice.actions

export const wifiReducer = wifiSlice.reducer
