import React from 'react'

const RightSecondaryL = () => {
  return (
    <svg width="21" height="48" viewBox="0 0 21 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.719383 48L0.714497 48L0 48V46H0.712048C2.96541 45.9887 5.23499 45.8324 7.50333 45.6165L7.50381 45.6164C10.5847 45.3246 12.9163 44.379 14.6034 42.8341C16.289 41.2907 17.4276 39.061 17.961 36.0191L17.961 36.0191C18.6628 32.0166 19.0118 28.0081 19.0118 24C19.0118 19.9919 18.6628 15.9834 17.961 11.9809L18.9392 11.8073L17.961 11.9809C17.4276 8.93895 16.289 6.70923 14.6034 5.16585C12.9163 3.62097 10.5847 2.6754 7.50381 2.38357L7.50333 2.38353C5.23495 2.16755 2.96533 2.01123 0.711914 1.99999H0V-1.14441e-05H0.714497L0.719383 0C3.05537 0.0115509 5.39024 0.173401 7.69067 0.392422L7.597 1.38797L7.6902 0.39238C7.69035 0.392395 7.69051 0.392406 7.69067 0.392422C11.0765 0.713219 13.8549 1.77597 15.9418 3.68683C18.0303 5.59928 19.3297 8.27288 19.9189 11.6335L18.9399 11.8072L19.9189 11.6335C20.6407 15.7498 21 19.8745 21 24C21 28.1255 20.6407 32.2502 19.9189 36.3665L18.9399 36.1928L19.9189 36.3665C19.3297 39.7271 18.0303 42.4007 15.9418 44.3131C13.8549 46.224 11.0765 47.2868 7.69067 47.6076L7.597 46.612L7.6902 47.6076C5.38993 47.8266 3.05521 47.9884 0.719383 48Z" />
    </svg>
  )
}

export default RightSecondaryL
