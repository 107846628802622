import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Drawer from '../../../components/Crow/Drawer/Drawer'
import { addToast } from '../../../components/Crow/Toast/Toast'
import Icon from '../../../components/Crow/Icon/Icon'
import {
  Accesses,
  Panel,
  PermanentUser,
  ChangeUserPinCodeRequestBody,
  UpdateUserInformationRequestBody,
  UpdateUserPrivilegesRequestBody,
} from '../../../types/PermanentUser'
import PeopleEditForm, { PeopleEditFormRef } from '../../../components/forms/PeopleEditForm'
import useI18n from '../../../i18n/useI18n'
import {
  useUpdateUserInformation,
  useSetUserPrivileges,
  useChangeUserPinCode,
  useDeleteUserFromPanel,
} from '../../../services/requestHooks/users'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import ConfirmationPrompt, {
  useConfirmationPrompt,
} from '../../../components/Crow/ConfirmationPrompt'
import PasswordPrompt, { usePasswordPrompt } from '../../AlarmSystems/Panel/PasswordPromt'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
  clearOngoingPrompt,
} from '../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { colors } from '../../../components/Crow/Style/theme'
import CategoryTag from './CategoryTag'
import { errorCodesTriggerNew2FA } from '../../../utils/errorCodesTriggerNew2FA'
import { isTranslationKey } from '../../../types/generated/TranslationKey'

interface Props {
  user: PermanentUser
  panelIsArmed: boolean
  panelPermissions: Panel
  isOpen: boolean
  handleClose: () => void
}

const EditUserDrawer = ({ user, panelIsArmed, panelPermissions, isOpen, handleClose }: Props) => {
  const [pauseFocusTrap, setPauseFocusTrap] = useState<boolean>(false)
  const [showEditUserInformation, setShowEditUserInformation] = useState<boolean>(false)

  const { t } = useI18n()

  const ref = React.useRef<PeopleEditFormRef>({})

  const {
    run: updateUserInformation,
    isLoading: loadingUpdateUserInformation,
    hasLoaded: userInformationUpdated,
  } = useUpdateUserInformation({
    onFailure: (response: any) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(response.message)
          ? t(response.message)
          : t('error_toast_generic_content'),
      })
    },
  })

  const {
    run: setUsersPrivileges,
    isLoading: loadingSetUserPrivileges,
    error: setUserPrivilegesError,
  } = useSetUserPrivileges({
    onSuccess: ({ response }: any) => {
      setPauseFocusTrap(true)

      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_edit_user_privileges_success_content', {
          name: user.FirstName.concat(' ', user.LastName),
        }),
      })
      handleClose()
    },
  })

  const {
    run: changeUserPinCode,
    isLoading: loadingChangeUserPinCode,
    error: changeUserPinCodeError,
  } = useChangeUserPinCode({
    onSuccess: ({ response }: any) => {
      setPauseFocusTrap(true)

      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_edit_user_pin_success_content', {
          name: user.FirstName.concat(' ', user.LastName),
        }),
      })
      handleClose()
    },
  })

  const { run: deleteUser, isLoading: loadingDeleteUser } = useDeleteUserFromPanel({
    onSuccess: ({ response }: any) => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_delete_user_success_content', {
          name: user.FirstName.concat(' ', user.LastName),
        }),
      })
    },
    onFailure: ({ message }) => {
      if (message && errorCodesTriggerNew2FA.includes(message)) {
        clearOngoingPrompt()
        onRemoveClick()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: isTranslationKey(message) ? t(message) : t('error_toast_generic_content'),
        })
      }
    },
  })

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setShowEditUserInformation(false), 250) // Match Drawer's closing speed: 250 ms.
    }
  }, [isOpen])

  useEffect(() => {
    if (userInformationUpdated) {
      setShowEditUserInformation(false)
    }
  }, [userInformationUpdated])

  useEffect(() => {
    if (setUserPrivilegesError && ref?.current?.handleReSubmit) {
      setPauseFocusTrap(true)

      if (errorCodesTriggerNew2FA.includes(setUserPrivilegesError)) {
        clearOngoingPrompt()
        ref.current.handleReSubmit()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: isTranslationKey(setUserPrivilegesError)
            ? t(setUserPrivilegesError)
            : t('error_toast_generic_content'),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserPrivilegesError, ref])

  useEffect(() => {
    if (changeUserPinCodeError && ref?.current?.handleReSubmit) {
      setPauseFocusTrap(true)

      if (errorCodesTriggerNew2FA.includes(changeUserPinCodeError)) {
        clearOngoingPrompt()
        ref.current.handleReSubmit()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: isTranslationKey(changeUserPinCodeError)
            ? t(changeUserPinCodeError)
            : t('error_toast_generic_content'),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeUserPinCodeError, ref])

  const panelId = usePanelId()
  const { promptState: confirmationPromptState, prompt: promptForDelete } = useConfirmationPrompt()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()
  type EditUserFormData = {
    firstName: string
    lastName: string
    underage: boolean
    system: boolean
    app: boolean
    admin: boolean
    phoneNumber: string
    pin: string
  }

  const mapUserPropertiesForPinCode = (
    e: EditUserFormData,
    validationCode: string,
    password: string,
  ): ChangeUserPinCodeRequestBody => {
    return {
      ValidationCode: validationCode,
      Password: password,
      PanelPinCode: e.pin,
    }
  }

  const mapUserPropertiesForInformation = (
    e: EditUserFormData,
  ): UpdateUserInformationRequestBody => {
    return {
      FirstName: e.firstName,
      LastName: e.lastName,
    }
  }

  const mapUserPropertiesForPrivileges = (
    e: EditUserFormData,
    validationCode: string,
    password: string,
  ): UpdateUserPrivilegesRequestBody => {
    return {
      ValidationCode: validationCode,
      Password: password,
      IsChild: e.underage,
      IsSystemUser: e.system,
      IsAppUser: e.app,
      IsAdminUser: e.admin,
      InvitePhoneNumber: e.phoneNumber,
      PanelPinCode: e.pin,
    }
  }

  const isChangingPinCode = (isSystemUser: boolean, pin: string) => isSystemUser && pin.length > 0
  const isGivingSystemAccess = (isSystemUser: boolean, system: boolean) =>
    !isSystemUser && system === true
  const isChangingOtherThings = (accesses: Accesses, formData: EditUserFormData) => {
    const { IsChild, IsSystemUser, IsAppUser, IsAdminUser } = accesses
    const { underage, system, app, admin } = formData

    return (
      IsChild !== underage || IsSystemUser !== system || IsAppUser !== app || IsAdminUser !== admin
    )
  }

  const onSubmit = async (e: EditUserFormData) => {
    try {
      const password = await promptForPassword()
      if (password) {
        const validationCode = await promptForTwoFactor()
        if (validationCode) {
          if (isChangingPinCode(user.Accesses.IsSystemUser, e.pin)) {
            changeUserPinCode(
              panelId,
              user.PersonId,
              mapUserPropertiesForPinCode(e, validationCode, password),
            )
          }

          if (isGivingSystemAccess(user.Accesses.IsSystemUser, e.system)) {
            setUsersPrivileges(
              panelId,
              user.PersonId,
              mapUserPropertiesForPrivileges(e, validationCode, password),
            )
          } else if (isChangingOtherThings(user.Accesses, e)) {
            const payload: UpdateUserPrivilegesRequestBody = mapUserPropertiesForPrivileges(
              e,
              validationCode,
              password,
            )
            delete payload.PanelPinCode
            setUsersPrivileges(panelId, user.PersonId, payload)
          }
        }
      }
    } catch (error) {}
  }

  const onUserInfoSubmit = (e: EditUserFormData) => {
    updateUserInformation(user.PersonId, mapUserPropertiesForInformation(e))
  }

  const onRemoveClick = async () => {
    try {
      await promptForDelete()
      const validationCode = await promptForTwoFactor()
      if (validationCode) {
        const password = await promptForPassword()
        if (password) {
          deleteUser(panelId, user.PersonId, { ValidationCode: validationCode, Password: password })
        }
      }
    } catch (error) {}
  }

  const { IsLegalOwner, IsAdminUser } = panelPermissions.Accesses

  const canChangeName: boolean =
    // you have to be the owner or an admin
    (IsLegalOwner || IsAdminUser) &&
    // admins can't change other admins' names
    (!user.Accesses.IsAdminUser || IsLegalOwner) &&
    // you can only change names of non-app users
    !user.Accesses.IsAppUser

  return (
    <S.Drawer
      isOpen={isOpen}
      position="right"
      title={
        <S.Title>
          <S.NameWrapper>
            <S.Initials>{user.Initials}</S.Initials>
            {canChangeName ? (
              <S.NameEditButtonWrapper>
                <S.Name>{user.FirstName + ' ' + user.LastName}</S.Name>
                <S.EditButton onClick={() => setShowEditUserInformation(!showEditUserInformation)}>
                  <Icon name="Pencil" />
                </S.EditButton>
              </S.NameEditButtonWrapper>
            ) : (
              <S.Name>{user.FirstName + ' ' + user.LastName}</S.Name>
            )}
          </S.NameWrapper>
          <S.Tag user={user} />
        </S.Title>
      }
      handleClose={handleClose}
      closeButton={true}
      closeIcon={<Icon name={'Cancel'} />}
      pauseFocusTrap={pauseFocusTrap}
    >
      <S.Content
        user={user}
        showEditUserInformation={showEditUserInformation}
        panelIsArmed={panelIsArmed}
        panelPermissions={panelPermissions}
        isLoading={
          loadingUpdateUserInformation ||
          loadingChangeUserPinCode ||
          loadingSetUserPrivileges ||
          loadingDeleteUser
        }
        cancelAction={handleClose}
        onSubmit={onSubmit}
        onUserInfoSubmit={onUserInfoSubmit}
        onUserInfoCancel={() => setShowEditUserInformation(false)}
        onRemoveClick={onRemoveClick}
        outsideRef={ref}
      />
      <ConfirmationPrompt
        title="Are you sure you want to remove user?"
        text={`Are you sure you want to remove ${user.FirstName} ${user.LastName}? All user information will be removed, and you will no longer be able to access them.`}
        promptState={confirmationPromptState}
        buttonProps={{ text: 'Remove', level: 'primary', variant: 'error' }}
      />
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </S.Drawer>
  )
}

const S = {
  Drawer: styled(Drawer)`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    max-width: 636px;
    padding: 32px 72px 48px 72px;

    .drawer-close {
      position: absolute;
      width: 16px;
      top: 45px;
      left: 28px;
      font-size: 24px;
      color: ${colors.blue};
    }

    .drawer-title {
      margin: 0;
    }

    @media only screen and (max-width: 600px) {
      padding: 32px 24px;
    }
  `,
  Title: styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
    }
  `,
  NameWrapper: styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
    }
  `,
  Initials: styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 18px;
    line-height: 22px;

    @media only screen and (max-width: 600px) {
      width: 100px;
      height: 100px;
      font-size: 28px;
      line-height: 32px;
    }
  `,
  NameEditButtonWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  Name: styled.span`
    margin-left: 12px;

    @media only screen and (max-width: 600px) {
      margin-left: 0;
      font-size: 22px;
      line-height: 28px;
    }
  `,
  EditButton: styled.button`
    position: relative;
    top: 1px;
  `,
  Tag: styled(CategoryTag)`
    position: relative;
    top: 1px;
    margin-left: 1.5rem;
    border-radius: 20px;
    padding: 1.5px 14px;
    height: 24px;
    line-height: 20px;

    @media only screen and (max-width: 600px) {
      margin-left: 0;
    }
  `,
  Content: styled(PeopleEditForm)`
    flex: 1;
  `,
}

export default EditUserDrawer
