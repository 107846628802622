import React, { useState } from 'react'
import useI18n from '../../../i18n/useI18n'
import { useOnMount } from '../../../utils/commonHooks'
import * as uuid from 'uuid'
import { Link } from 'react-router-dom'
import EmailForm from './EmailForm'
import NewUserForm, { NewUserFormValues } from './NewUserForm'
import { FormStep } from '../../../reducers/appUserRegistrationSlice'
import ExistingUserForm from './ExistingUserForm'
import SplitPage from '../../../components/pages/SplitPage'
import RequestCodeForm from './RequestCodeForm'
import AcceptCodeForm from './AcceptCodeForm'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import FailedRequest from '../../onboardingWizard/components/FailedRequest'
import {
  useSendSMSCode,
  useCheckInviteExists,
  useAcceptInviteExistingUser,
  useAcceptInviteNewUser,
  useValidateSMSCode,
} from '../../../services/requestHooks/appUserRegistration'

const getInviteIdFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('invite')
}

const errorMapping: Record<string, TranslationKey> = {
  VALIDATION_CODE_IS_NOT_VALID: 'VALIDATION_CODE_IS_NOT_VALID',
  invite_expired_or_blocked: 'invite_expired_or_blocked',
  invite_and_accept_email_equal: 'invite_and_accept_email_equal',
  USER_ALREADY_EXISTS_ON_PANEL: 'USER_ALREADY_EXISTS_ON_PANEL',
  email_exists_but_is_not_sas: 'email_exists_but_is_not_sas',
}

const AcceptAppInvitePage = () => {
  const [currentForm, setCurrentForm] = useState(FormStep.Email)
  const [validInviteId, setValidInviteId] = useState(true)
  const { t } = useI18n()

  const [email, setEmail] = useState('')
  const [smsCode, setSMSCode] = useState('')
  const [userExists, setUserExists] = useState(true)

  const { run: sendSMSCode, error: sendSMSError, isLoading: isSendingSMSCode } = useSendSMSCode()
  const {
    run: checkIfInviteExists,
    error: inviteError,
    isLoading: isCheckingInvite,
  } = useCheckInviteExists({
    onSuccess: ({ response }) => {
      setUserExists(response.UserExists)
      if (response.UserExists) {
        setCurrentForm(FormStep.ExistingUser)
      } else {
        setCurrentForm(FormStep.RequestCode)
      }
    },
  })
  const {
    run: acceptExistingUser,
    error: acceptExistingError,
    isLoading: isAcceptingExistingUser,
  } = useAcceptInviteExistingUser({
    onSuccess: () => setCurrentForm(FormStep.Finished),
  })
  const {
    run: acceptNewUser,
    error: acceptNewError,
    isLoading: isAcceptingNewUser,
  } = useAcceptInviteNewUser({
    onSuccess: () => setCurrentForm(FormStep.Finished),
  })
  const {
    run: validateSMS,
    error: invalidSMSError,
    isLoading: isValidatingSMS,
  } = useValidateSMSCode({
    onSuccess: () => {
      setCurrentForm(FormStep.NewUser)
    },
  })

  useOnMount(() => {
    const inviteId = getInviteIdFromUrl()
    if (!inviteId || !uuid.validate(inviteId)) {
      setValidInviteId(false)
    }
  })

  const requestCode = () => {
    sendSMSCode(getInviteIdFromUrl()!!)
    setCurrentForm(FormStep.AcceptCode)
  }

  const acceptCode = ({ code }: { code: string }) => {
    setSMSCode(code)
    validateSMS({
      InviteId: getInviteIdFromUrl()!!,
      Otp: code,
    })
  }

  const gotoResendSMS = () => setCurrentForm(FormStep.RequestCode)

  const handleSubmitEmail = async ({ email }: { email: string }) => {
    const inviteId = getInviteIdFromUrl()!!
    setEmail(email)
    checkIfInviteExists({
      email,
      inviteId,
    })
  }

  const handleSubmitPassword = ({ password }: { password: string }) => {
    const inviteId = getInviteIdFromUrl()!!
    acceptExistingUser({
      Email: email,
      InviteId: inviteId,
      Password: password,
    })
  }

  const handleSubmitNewUser = async ({ firstName, lastName, password }: NewUserFormValues) => {
    acceptNewUser({
      FirstName: firstName,
      LastName: lastName,
      InviteId: getInviteIdFromUrl()!!,
      Email: email,
      Otp: smsCode,
      Password: password,
    })
  }

  const getContent = () => {
    if (!validInviteId)
      return (
        <>
          <h1 className="mb-6">{t('ACCEPT')}</h1>
          <span>{t('invite_expired_or_blocked')}</span>
        </>
      )
    if (acceptNewError || acceptExistingError)
      return (
        <>
          <h1 className="mb-6">{t('ACCEPT')}</h1>
          <span>{t('Validation failed')}</span>
        </>
      )

    switch (currentForm) {
      case FormStep.Email:
        return (
          <EmailForm isLoading={isCheckingInvite} onSubmit={handleSubmitEmail}>
            {inviteError && <FailedRequest text={errorMapping[inviteError]} />}
          </EmailForm>
        )
      case FormStep.RequestCode:
        return (
          <RequestCodeForm isLoading={isSendingSMSCode} onSubmit={requestCode}>
            {sendSMSError && <FailedRequest text={errorMapping[sendSMSError]} />}
          </RequestCodeForm>
        )
      case FormStep.AcceptCode:
        return (
          <AcceptCodeForm
            isLoading={isValidatingSMS}
            onSubmit={acceptCode}
            onResendSms={gotoResendSMS}
          >
            {invalidSMSError && <FailedRequest text={errorMapping[invalidSMSError]} />}
          </AcceptCodeForm>
        )
      case FormStep.NewUser:
        return (
          <NewUserForm isLoading={isAcceptingNewUser} onSubmit={handleSubmitNewUser}>
            {acceptNewError && <FailedRequest text={errorMapping[acceptNewError]} />}
          </NewUserForm>
        )
      case FormStep.ExistingUser:
        return (
          <ExistingUserForm isLoading={isAcceptingExistingUser} onSubmit={handleSubmitPassword}>
            {acceptExistingError && <FailedRequest text={errorMapping[acceptExistingError]} />}
          </ExistingUserForm>
        )
      case FormStep.Finished:
        return userExists ? (
          <div className="box-content">
            <h1 className="mb-6">{t('invite_accepted')}</h1>
            <h2>{t('Done')}</h2>
            <p>{t('invite_accepted_existing_account_message')}</p>
            <Link to="/">{t('Done, take me to login.')}</Link>
          </div>
        ) : (
          <div className="box-content">
            <h1 className="mb-6">{t('invite_accepted')}</h1>
            <h2>{t('Done')}</h2>
            <p>{t('email_validation_needed')}</p>
          </div>
        )
    }
  }

  return <SplitPage heading={t('accept_invitation')}>{getContent()}</SplitPage>
}

export default AcceptAppInvitePage
