import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { useOnMount } from '../../../utils/commonHooks'
import FloorName from '../../../utils/places/FloorName'
import roomIcon from '../../../utils/places/RoomIcon'
import RoomName from '../../../utils/places/RoomName'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import SmokeDetectorsTileFooter from './SmokeDetectorsTileFooter'
import { StyledSubtitle, StyledUl } from './SmokeDetectorStyle'

const CrowSmokeDetectorsPage = () => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    data: smokeDetectors,
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
    error: SmokeDetectorError,
  } = useSmokeDetectors()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!SmokeDetectorError || !!panelStatusError || !!loadInfoError

  const returnUrl = `/systems/${panelId}`

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingSmokeDetectors) {
      getSmokeDetectors(panelId)
    }
  })

  return (
    <>
      <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
        <FullscreenPage
          isLoading={loadingSmokeDetectors || loadingPanelStatus}
          loaderProps={{
            loadingText: { text: t('Loading') },
            errorHandling: {
              loadingFailed: !loadingSmokeDetectors && hasError && !panelStatus?.IsOnline,
              errorHeaderText: t('Smoke detectors'),
              errorText: !panelStatus?.IsOnline
                ? t('You can not control smoke detectors while you are offline')
                : t('Something went wrong. Please try again'),
              returnUrl,
            },
          }}
        >
          <BackButton backUrl={returnUrl} />
          <Title>{t('Smoke detectors')}</Title>
          <p className="pt-4 mb-10">{t('SMOKE_DETECTORS_DESCRIPTION')}</p>
          <>
            <StyledUl>
              {smokeDetectors?.Floors.map((floor) => (
                <li key={floor.Key}>
                  <StyledSubtitle>
                    <FloorName floorName={floor.Key} />
                  </StyledSubtitle>
                  <div className="flex flex-wrap gap-4">
                    {floor.Rooms.map((room) => (
                      <StyledTile
                        key={room.Index}
                        borderRadius="8px"
                        footerPadding="2px 12px 11px 12px"
                        useDynamicHeight
                        footer={
                          <div className="flex-col">
                            <FooterDevices>
                              {`${t('Sensors')} (${room.Devices.length})`}
                            </FooterDevices>
                            <SmokeDetectorsTileFooter props={room.Devices} />
                          </div>
                        }
                      >
                        <FlexColumnStart>
                          <StyledRoomIcon name={roomIcon(room.Key)} />
                          <RoomNameTitle>
                            <RoomName roomName={room.Key} />
                          </RoomNameTitle>
                        </FlexColumnStart>
                      </StyledTile>
                    ))}
                  </div>
                </li>
              ))}
            </StyledUl>
          </>
        </FullscreenPage>
      </TcOrCrowContainer>
    </>
  )
}

const StyledTile = styled(Tile)`
  padding-left: 15px;
  padding-right: 15px;
`

const FooterDevices = styled.span`
  font-size: 12px;
  color: ${colors.black900};
`

const FlexColumnStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 14px;
`

const RoomNameTitle = styled(Title)`
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  padding-top: 9px;
  padding-bottom: 6px;
  max-width: 138px;
  min-height: 56px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyledRoomIcon = styled(Icon)`
  font-size: 43px;
`

export default CrowSmokeDetectorsPage
