import { IconName } from '../Icon/Icon'

const systemsRoute = '/systems/:panelId'
type RouteEntry = {
  name: string
  path: string
  icon: IconName
}

type SystemRouteNames =
  | 'Dashboard'
  | 'Temperature'
  | 'Doors&windows'
  | 'Locks'
  | 'People'
  | 'Smoke_detectors'
  | 'Camera'
  | 'SmartPlug'
  | 'History'
  | 'Settings'

type UserRouteNames = 'Inbox' | 'Account' | 'LogOut'
export const userRouteList: Record<UserRouteNames, RouteEntry> = {
  Inbox: {
    name: 'Inbox',
    path: `${systemsRoute}/messages`,
    icon: 'Inbox',
  },
  Account: {
    name: 'Account',
    path: `${systemsRoute}/crowAccountPage`,
    icon: 'Avatar',
  },
  LogOut: {
    name: 'Sign-out',
    path: ``,
    icon: 'Sign-out',
  },
}

export const systemRouteList: Record<SystemRouteNames, RouteEntry> = {
  Dashboard: {
    name: 'Dashboard',
    path: `${systemsRoute}`,
    icon: 'Mini-apps',
  },
  Temperature: {
    name: 'Temperature',
    path: `${systemsRoute}/temperatures`,
    icon: 'Temperature',
  },
  'Doors&windows': {
    name: 'Doors&windows',
    path: `${systemsRoute}/doors-and-windows`,
    icon: 'Doors',
  },
  Locks: {
    name: 'Locks',
    path: `${systemsRoute}/locks`,
    icon: 'Unlocked',
  },
  People: {
    name: 'People',
    path: `${systemsRoute}/people`,
    icon: 'People',
  },
  Smoke_detectors: {
    name: 'Smoke detectors',
    path: `${systemsRoute}/smoke-detectors`,
    icon: 'Smoke-Detector',
  },
  Camera: {
    name: 'Camera',
    path: `${systemsRoute}/picture`,
    icon: 'Camera',
  },
  SmartPlug: {
    name: 'smartplugs',
    path: `${systemsRoute}/smartplugs`,
    icon: 'SmartPlug',
  },
  History: {
    name: 'History',
    path: `${systemsRoute}/history`,
    icon: 'History',
  },
  Settings: {
    name: 'Settings',
    path: `${systemsRoute}/settings`,
    icon: 'Settings',
  },
}
