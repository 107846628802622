import defaultImage from '../../../assets/images/products/default.png'
import product_10138 from '../../../assets/images/products/product_10138.png'
import product_40010 from '../../../assets/images/products/product_40010.png'
import product_2000400 from '../../../assets/images/products/product_2000400.png'
import product_2004000 from '../../../assets/images/products/product_2004000.png'
import product_2010300 from '../../../assets/images/products/product_2010300.png'
import product_2015400 from '../../../assets/images/products/product_2015400.png'
import product_2015500 from '../../../assets/images/products/product_2015500.png'
import product_2015700 from '../../../assets/images/products/product_2015700.png'
import product_2015800 from '../../../assets/images/products/product_2015800.png'
import product_2101900 from '../../../assets/images/products/product_2101900.png'
import product_2102700 from '../../../assets/images/products/product_2102700.png'
import product_2210000 from '../../../assets/images/products/product_2210000.png'
import product_2210100 from '../../../assets/images/products/product_2210100.png'
import product_2215300 from '../../../assets/images/products/product_2215300.png'

const images: Record<string, string> = {
  '10138': product_10138,
  '40010': product_40010,
  '2000400': product_2000400,
  '2004000': product_2004000,
  '2010300': product_2010300,
  '2015400': product_2015400,
  '2015500': product_2015500,
  '2015700': product_2015700,
  '2015800': product_2015800,
  '2101900': product_2101900,
  '2102700': product_2102700,
  '2210000': product_2210000,
  '2210100': product_2210100,
  '2215300': product_2215300,
}

export const getProductUrl = (productId: string) => {
  return images[productId] || defaultImage
}
