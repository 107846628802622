import React from 'react'

const LeftSecondaryXL = () => {
  return (
    <svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.46153 48.6165C4.19089 52.7518 5.73535 55.7189 7.97097 57.7539C10.2099 59.7919 13.3363 61.0718 17.5548 61.469C20.3809 61.7365 23.2013 61.9373 26 61.9883L28 61.9883L28 64L27 64C26.6668 63.9984 26.3334 63.9945 26 63.9886C23.1194 63.9374 20.2334 63.7315 17.3673 63.4602C8.31635 62.6079 3.06306 57.8718 1.49193 48.9639C0.495726 43.316 2.30288e-06 37.6582 2.79753e-06 32C3.29218e-06 26.3418 0.495728 20.684 1.49193 15.0361C3.06306 6.12819 8.31635 1.39209 17.3673 0.539805C20.2334 0.26855 23.1195 0.0625949 26 0.0114134C26.3334 0.0054892 26.6668 0.00163639 27 -8.74228e-08L28 0L28 2.01173L26 2.01173C23.2013 2.06272 20.3819 2.26343 17.5558 2.53091C13.3373 2.92814 10.2099 4.20813 7.97098 6.24612C5.73536 8.2811 4.19089 11.2482 3.46153 15.3835C2.4854 20.9176 2 26.4592 2 32C2 37.5408 2.4854 43.0824 3.46153 48.6165Z" />
    </svg>
  )
}

export default LeftSecondaryXL
