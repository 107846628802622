import * as React from 'react'

function KeyIcon(props: React.SVGProps<SVGSVGElement>) {
  const color: string = props.color || '#333131'

  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.15394 7.93925C5.76851 6.97786 5.72683 5.91287 6.03597 4.9243C6.34511 3.93573 6.9861 3.08421 7.85058 2.51368C8.71507 1.94315 9.75003 1.68861 10.7805 1.79307C11.811 1.89754 12.7739 2.3546 13.5063 3.08701C14.2387 3.81942 14.6957 4.78224 14.8002 5.81274C14.9047 6.84324 14.6501 7.8782 14.0796 8.74269C13.509 9.60717 12.6575 10.2482 11.669 10.5573C10.6804 10.8664 9.61539 10.8248 8.654 10.4393L8.65404 10.4392L7.83105 11.2622H6.33105V12.7622H4.83105V14.2622H2.33105V11.7622L6.15405 7.9392L6.15394 7.93925Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M11.5811 5.26221C11.7191 5.26221 11.8311 5.15028 11.8311 5.01221C11.8311 4.87414 11.7191 4.76221 11.5811 4.76221C11.443 4.76221 11.3311 4.87414 11.3311 5.01221C11.3311 5.15028 11.443 5.26221 11.5811 5.26221Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5811 5.76221C11.9953 5.76221 12.3311 5.42642 12.3311 5.01221C12.3311 4.59799 11.9953 4.26221 11.5811 4.26221C11.1668 4.26221 10.8311 4.59799 10.8311 5.01221C10.8311 5.42642 11.1668 5.76221 11.5811 5.76221Z"
        fill={color}
      />
    </svg>
  )
}

export default KeyIcon
