import React, { FC } from 'react'
import FocusTrap from 'focus-trap-react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

import { getPortalContainer } from '../../../utils/getContainer'
import { colors } from '../Style/theme'

const Modal: FC = ({ children }) => {
  return ReactDOM.createPortal(
    <FocusTrap>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </FocusTrap>,
    getPortalContainer(),
  )
}

const Wrapper = styled.div.attrs({
  role: 'dialog',
  'aria-modal': 'true',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: auto;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(5px);
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

const Content = styled.div`
  position: relative;
  box-shadow: -1px 2px 11px 1px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 2.5rem;
  width: 500px;
  background: ${colors.white};

  @media only screen and (max-width: 600px) {
    width: min-content;
  }
`

export default Modal
