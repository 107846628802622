import { format, isToday } from 'date-fns'
import { Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Layout from '../../../components/Crow/NavigationMenu/Layout/Layout'
import LoadPage from '../../../components/LoadPage'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { usePanelEvents } from '../../../services/requestHooks/history'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { Capability } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import convertAndFormatTime from '../../../utils/ConvertAndFormatTIme'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
import { usePanelId } from '../hooks/usePanelId'
import ColumnEvents from './ColumnEvents'
import EndOfEvents from './EndOfEvents'
import {
  DividerLine,
  FormatEventGroupeTitle,
  HistoryCard,
  HistoryTitle,
  SkeletonStyle,
  StyledParagraph,
  StyledParagraphNoEvents,
  StyledSubTitle,
  TimeContainer,
} from './HistoryStyle'
import RowEvents from './RowEvents'
import { EventsHasErrorOrEmpty } from './util/Helpers'

const HistoryScreen = () => {
  const {
    run: loadPanelEvents,
    data: panelEvents,
    isLoading: loadingPanelEvents,
    error: panelEventsError,
  } = usePanelEvents()
  const { data: panelInfo } = usePanelInfo()

  const locale = useDateFnsLocale()
  const panelId = usePanelId()

  const formatEventGroupDay = (timestamp: Date, options: { locale: Locale }) => {
    const isEventToday = isToday(new Date(timestamp))
    if (isEventToday) {
      return `${t('Today')}`
    }
    return `${format(timestamp, 'EEEE', options)} `
  }

  const formatEventGroupMonth = (timestamp: Date, options: { locale: Locale }) => {
    return ` , ${format(timestamp, 'PPP', options)}`
  }

  const { data: panelStatus } = usePanelStatus()

  const displayOldNavigationMenu =
    panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN) ||
    !(displayNewNavigationMenu === 'true')
  const { t } = useI18n()
  const returnUrl = `/systems/${panelId}`

  useOnMount(() => {
    if (!loadingPanelEvents) {
      loadPanelEvents(panelId)
    }
  })

  const TcWrapper: React.FC = ({ children }) => (
    <main className={`flex flex-1 fullscreen-page `}>
      <div className="grid py-16 px-8">
        <div className="grid__half">
          <LoadPage
            isLoading={loadingPanelEvents}
            loaderProps={{ loadingText: { text: t('Loading') } }}
          >
            {children}
          </LoadPage>
        </div>
      </div>
    </main>
  )

  const History = () => (
    <Content>
      <BackButton backUrl={returnUrl} />
      <HistoryTitle className="mt-2">{t('History')}</HistoryTitle>
      <EventsHasErrorOrEmpty
        panelEvents={panelEvents}
        t={t}
        panelEventsError={panelEventsError ? panelEventsError : ''}
      />
      {panelEvents?.map(([timestamp, events], index) => (
        <Fragment key={timestamp}>
          <FormatEventGroupeTitle>
            <StyledSubTitle>
              {formatEventGroupDay(new Date(timestamp), { locale: locale })}
            </StyledSubTitle>
            <StyledParagraph light large>
              {formatEventGroupMonth(new Date(timestamp), { locale: locale })}
            </StyledParagraph>
          </FormatEventGroupeTitle>
          {events.length === 0 && (
            <StyledParagraphNoEvents large grey>
              {t('No events occured for this day')}
            </StyledParagraphNoEvents>
          )}
          {events.map((event, index) => (
            <div key={index}>
              {events.length === 0 || loadingPanelEvents ? (
                <SkeletonStyle>
                  <Skeleton />
                </SkeletonStyle>
              ) : (
                <div className="flex items-center">
                  <TimeContainer>
                    <DividerLine />
                    {convertAndFormatTime(panelStatus?.TimeZoneName, locale, event.Time, false)}
                    <DividerLine />
                  </TimeContainer>
                  <HistoryCard className="history__item">
                    {event.EventType === 'Area_Armed_By_Doorlock_Log' ||
                    event.EventType === 'Area_Stay_Armed_By_Doorlock_Log' ||
                    event.EventType === 'Area_Disarmed_By_Doorlock_Log' ||
                    event.EventType === 'disarmed_and_unlock' ||
                    event.EventType === 'armed_and_lock' ? (
                      <ColumnEvents event={event} />
                    ) : (
                      <RowEvents event={event} />
                    )}
                  </HistoryCard>
                </div>
              )}
            </div>
          ))}
        </Fragment>
      ))}
      <EndOfEvents />
    </Content>
  )

  const Content = styled.div`
    display: flex;
    flex-direction: column;
  `

  return (
    <>
      {displayOldNavigationMenu ? (
        <TcWrapper>
          <History />
        </TcWrapper>
      ) : (
        <Layout>
          <History />
        </Layout>
      )}
    </>
  )
}

export default HistoryScreen
