import React from 'react'

const LeftPrimaryXL = () => {
  return (
    <svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.49193 15.0361C0.495723 20.684 0 26.3418 0 32C0 37.6582 0.495723 43.316 1.49193 48.9639C3.06306 57.8718 8.31635 62.6079 17.3673 63.4602C20.5651 63.7628 23.7878 63.9842 27 64H28V0H27C23.7878 0.0157873 20.5651 0.23715 17.3673 0.539806C8.31635 1.39209 3.06306 6.1282 1.49193 15.0361Z" />
    </svg>
  )
}

export default LeftPrimaryXL
