/* eslint-disable */
import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { usePanelId } from '../../../../pages/AlarmSystems/hooks/usePanelId'
import { PanelModel } from '../../../../types/PanelModel'
import PropertySelect from '../../PropertySelect/PropertySelect'
import { colors } from '../../Style/theme'
import { SubTitle } from '../../Typography'
import Desktop from '../Desktop'
import NavigationMenuSlider from '../NavigationMenuSlider'
import mediaQueries from './MediaQueries'
import Mobile from './Mobile'
import Tablet from './Tablet'
// import PropertySelect from '../../PropertySelect/PropertySelect'

type Props = {
  children?: ReactNode
  className?: string
  panelInfo?: PanelModel | undefined
  transparentBackground?: boolean
}

const Layout = ({ children, className, panelInfo, transparentBackground }: Props) => {
  const isDesktop = useMediaQuery({ query: mediaQueries.isDesktop })
  const isTablet = useMediaQuery({ query: mediaQueries.isTablet })
  const isMobile = useMediaQuery({ query: mediaQueries.isMobile })

  const panelId = usePanelId()
  const location = useLocation()

  if (isDesktop) {
    return (
      <Desktop
        transparentBackground={transparentBackground}
        className={className}
        //  displayName={panelInfo?.DisplayName}
        padding={location.pathname === `/systems/${panelId}` && '0px'}
      >
        {children}
      </Desktop>
    )
  }
  if (isTablet) {
    return (
      <Tablet
        transparentBackground={transparentBackground}
        className={className}
        displayName={panelInfo?.DisplayName}
        padding={location.pathname === `/systems/${panelId}` && '0px'}
      >
        {children}
      </Tablet>
    )
  }
  if (isMobile) {
    return (
      <Mobile className={className} transparentBackground={transparentBackground}>
        <Content>
          <PropertySelect />
          <NavigationMenuSlider />
        </Content>
        {children}
      </Mobile>
    )
  }
  return null
}

const Content = styled.div`
  width: 100vw;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  background: ${colors.black900};
  padding-left: 32px;
  padding-right: 32px;
  height: 180px;
  gap: 15px;
  margin-bottom: 50px;
  @media only screen and (max-width: 320px) {
    padding: 12px;
  }
`

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  margin-top: 24px;
  align-self: flex-start;
`
export default Layout
