import React, { FC } from 'react'
import { add } from 'date-fns'
import useI18n from '../../../i18n/useI18n'
import Button from '../../Button'
import { EXPIRES_AT } from '../constants'
import { useLastPhoneDigits } from '../../../services/requestHooks/securityQuestion'
import CancelButton from '../../Button/CancelButton'
import { useOnMount } from '../../../utils/commonHooks'
import { Loader } from '../../Elements/Loaders'
import { useCreateSMSCode } from '../../../services/requestHooks/validateSMSCode'
import FailedRequest from '../../../pages/onboardingWizard/components/FailedRequest'
import styled from 'styled-components'
import { Paragraph } from '../../Crow/Typography'
import { colors } from '../../Crow/Style/theme'

type Props = {
  openValidateCode: () => void
  onCancel: () => void
  cancelUrl?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 18rem;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  color: ${colors.grayShade};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const CreateSMSCode: FC<Props> = ({ openValidateCode, onCancel, cancelUrl }) => {
  const { t } = useI18n()

  const {
    run: getPhoneDigits,
    data: lastPhoneDigits,
    isLoading: isLoadingPhoneDigits,
    error: phoneError,
  } = useLastPhoneDigits()
  const { error: createCodeError, isLoading, run: createValidationCode } = useCreateSMSCode({
    onSuccess: () => {
      sessionStorage.setItem(EXPIRES_AT, add(new Date(), { minutes: 30 }).toUTCString())
      openValidateCode()
    },
  })

  useOnMount(() => {
    getPhoneDigits()
  })

  return (
    <Container>
      <StyledParagraph>
        {t(
          'This functionality is only available to the account owner. Send a SMS validation code to the registered number on this account to get access.',
        )}
      </StyledParagraph>

      <LoadingContainer>
        {isLoadingPhoneDigits && <Loader className="create-sms-code__loader" as="span" />}
        {lastPhoneDigits && (
          <StyledParagraph>
            {t('SMS_SENT_TO')} {lastPhoneDigits}
          </StyledParagraph>
        )}
      </LoadingContainer>

      {phoneError && t('Failed to get valid phone number. Please contact customer service.')}
      <ButtonWrapper>
        <Button buttonType="primary" disabled={isLoading} onClick={createValidationCode}>
          {t('Send code')}
        </Button>
      </ButtonWrapper>
      <CancelButton onClick={onCancel} isLoading={isLoading} />

      {!!createCodeError && (
        <FailedRequest
          align="left"
          text="The SMS could not be sendt. Please contact customer service."
        />
      )}
    </Container>
  )
}

export default CreateSMSCode
