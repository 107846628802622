import styled from 'styled-components'
import Icon from '../../../../components/Crow/Icon/Icon'
import { colors } from '../../../../components/Crow/Style/theme'

const VideoPrivacyNoAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledSelectButton = styled.button<{ toggle?: boolean }>`
  width: 145px;
  height: 90px;
  border: 2px solid ${colors.black400};
  border-radius: 12px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  padding: 21px;
  background: ${({ toggle }) => toggle && colors.black400};
`

const StyledIcon = styled(Icon)`
  font-size: 24px;
  padding-bottom: 6px;
`

const StyledSpan = styled.span`
  width: 147px;
  margin-top: 10px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 16px;
  }
  padding-left: 21px;
`

export { VideoPrivacyNoAccessContainer, StyledSelectButton, StyledIcon, StyledSpan }
