function SvgError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.7071 16.2929C34.0976 16.6834 34.0976 17.3166 33.7071 17.7071L26.4142 25L33.7071 32.2929C34.0976 32.6834 34.0976 33.3166 33.7071 33.7071C33.3166 34.0976 32.6834 34.0976 32.2929 33.7071L25 26.4142L17.7071 33.7071C17.3166 34.0976 16.6834 34.0976 16.2929 33.7071C15.9024 33.3166 15.9024 32.6834 16.2929 32.2929L23.5858 25L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L25 23.5858L32.2929 16.2929C32.6834 15.9024 33.3166 15.9024 33.7071 16.2929Z"
        fill="#E4464E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25ZM25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2Z"
        fill="#E4464E"
      />
    </svg>
  )
}

export default SvgError
