/* eslint-disable import/no-unused-modules */
import styled from 'styled-components'
import { colors } from '../Style/theme'
import Icon from './Icon'

export const SwitchIcon = styled(Icon).attrs({ name: 'Switch' })`
  cursor: pointer;
  font-size: 24px;
  border-radius: 8px;
  background-color: ${colors.blue};
`

export const CloseIcon = styled(Icon).attrs({ name: 'Cancel' })`
  font-size: 20px;
  padding: 4px;
  border-radius: 8px;
  background-color: ${colors.black800};
`
export const AlarmIcon = styled(Icon).attrs({ name: 'Alarm-System' })`
  font-size: 24px;
  color: ${colors.red};
`
