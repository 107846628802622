import './arm-slider.scss'

import React, { ComponentType, FC, MouseEventHandler } from 'react'
import useI18n from '../../../i18n/useI18n'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import classNames from 'classnames'

type OptionProps = {
  text: TranslationKey
  Icon: ComponentType<{ className?: string }>
  active: boolean
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ArmSliderOption: FC<OptionProps> = ({ onClick, text, Icon, active, disabled }) => {
  const { t } = useI18n()
  return (
    <button
      onClick={onClick}
      disabled={active || disabled}
      className={classNames('arm-slider__option', active && 'arm-slider__option--active')}
    >
      <Icon className="arm-slider__icon" />
      {t(text)}
    </button>
  )
}

type Props = {
  canPartialArm?: boolean
  className?: string
}

const ArmSlider: FC<Props> = ({ canPartialArm = false, children, className }) => {
  return (
    <div className={classNames('arm-slider', canPartialArm && 'arm-slider--home-mode', className)}>
      {children}
    </div>
  )
}

export default ArmSlider
