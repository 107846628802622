import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import useI18n from '../../../../i18n/useI18n'
import ScenarioTriggerForm from './ScenarioTriggerForm/ScenarioTriggerForm'
import SmartPlugTimeTriggerForm from './TimeTriggerForm/TimeTriggerList'
import { SmartplugIcon } from '../../../../assets/icons'
import { useGetSmartplug } from '../../../../services/requestHooks/plugs'

const SmartPlugSettingsPage = () => {
  const { t } = useI18n()
  const { panelId, smartplugSerial } = useParams<{ panelId: string; smartplugSerial: string }>()
  const { run: getSmartPlug, data: currentPlug, isLoading, error } = useGetSmartplug()

  useEffect(() => {
    getSmartPlug({ panelId, serial: smartplugSerial })
  }, [smartplugSerial, getSmartPlug, panelId])

  const returnUrl = `/systems/${panelId}`

  return (
    <FullscreenPage
      isLoading={isLoading}
      loaderProps={{
        loadingText: { text: 'Loading smart plug settings' },
        errorHandling: {
          loadingFailed: !!error,
          errorHeaderText: 'Smart plugs',
          errorText: 'Something went wrong. Please try again',
          returnUrl,
        },
      }}
      returnURL={returnUrl}
    >
      <div className="center mb-8">
        <SmartplugIcon className="icon" />
        <h1>{currentPlug?.Label}</h1>
        <p className="mt-4 flex flex-wrap justify-center">
          <span className="mr-2">{t('Serial number')}:</span>
          <span className="mr-2">{smartplugSerial}</span>
          <Link to={`/systems/${panelId}/smartplugs/${smartplugSerial}/editname`}>
            {t('Edit name')}
          </Link>
        </p>
      </div>
      {currentPlug && <ScenarioTriggerForm scenarios={currentPlug.Scenarios} />}
      <div className="border border-b-1 border-gray-300 mt-4" />
      {currentPlug && <SmartPlugTimeTriggerForm timeTriggers={currentPlug.Events} />}
    </FullscreenPage>
  )
}

export default SmartPlugSettingsPage
