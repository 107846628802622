import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { LockIcon } from '../../../../assets/icons'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import {
  useGetLockLanguage,
  useSetLockSettings,
} from '../../../../services/requestHooks/lockSettings'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import { RootState } from '../../../../store'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import SettingsSubPage from '../components/SettingsSubPage'
import LockSettingsForm, { LockSettingsFormValues } from './LockSettingsForm'

const LockSettingsPage = () => {
  const { t } = useI18n()
  const { panelId, lockSerial } = useParams<{ panelId: string; lockSerial: string }>()
  const locks = useSelector((state: RootState) => state.panel[panelId]?.Info?.Locks || [])
  const history = useHistory()

  const {
    run: getLockLanguage,
    isLoading: lockLanguageLoading,
    data: lockLanguage,
    error: lockError,
  } = useGetLockLanguage()
  const { run: getPanelInfo, isLoading: loadingPanelInfo, data: panelInfo } = usePanelInfo()
  const {
    run: setLockSettings,
    error: saveLockError,
    isLoading: saveLockLoading,
  } = useSetLockSettings()

  const { promptForTwoFactor } = useContext(TwofactorContext)

  useOnMount(() => {
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) getPanelInfo(panelId)
    if (!lockLanguageLoading) {
      promptForTwoFactor().then((ValidationCode) => {
        if (!ValidationCode) {
          history.goBack()
          return
        }
        getLockLanguage({
          PanelId: panelId,
          ValidationCode,
        })
      })
    }
  })

  const currentLock = !lockLanguageLoading
    ? locks.find((lock) => lock.Serial === lockSerial)
    : undefined

  const { promptState, promptForPassword } = usePasswordPrompt()

  const onSubmit = async (values: LockSettingsFormValues) => {
    if (currentLock) {
      const ValidationCode = await promptForTwoFactor()
      if (ValidationCode) {
        const password = await promptForPassword()
        if (!password) return
        setLockSettings({
          SoundLevel: Number.parseInt(values.soundLevel as any),
          Language: values.language,
          AutoLock: values.autoLockEnabled,
          Label: currentLock.Label,
          Password: password,
          Serial: lockSerial,
          ValidationCode,
          PanelId: panelId,
        })
      }
    }
  }

  if (saveLockLoading) {
    return <FullScreenLoader text={'Updating'} />
  }

  return (
    <>
      <SettingsSubPage
        returnUrl={`/systems/${panelId}`}
        isLoading={lockLanguageLoading || loadingPanelInfo}
      >
        <div className="mt-4">
          <div style={{ textAlign: 'center' }}>
            <LockIcon className="icon status-list__icon-svg" />
            <h1 className="status-list__title">{currentLock?.Label}</h1>
            <div>
              <p className="">
                {t('Serial number')}: {currentLock?.Serial} |
                <Link
                  className="ml-2 underline"
                  to={`/systems/${panelId}/locks/${lockSerial}/editname`}
                >
                  {t('Edit name')}
                </Link>
              </p>
            </div>
            <br />
          </div>
        </div>
        {currentLock && lockLanguage && (
          <LockSettingsForm onSubmit={onSubmit} lock={currentLock} lockLanguage={lockLanguage} />
        )}
        {saveLockError && <FailedRequest text={saveLockError} />}
        {lockError && <FailedRequest text={'Something went wrong. Please try again later.'} />}
      </SettingsSubPage>
      <PasswordPrompt promptState={promptState} />
    </>
  )
}

export default LockSettingsPage
