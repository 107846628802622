import { TranslationKey } from '../types/generated/TranslationKey'
import { NationId, UserCultureInfo } from '../types/UserDetails'

interface CultureData {
  nation: number
  culture: UserCultureInfo
  baseUrl: string
  name: TranslationKey
}

export const translations: Record<UserCultureInfo, CultureData> = {
  [UserCultureInfo.SWEDISH]: {
    nation: NationId.SWEDEN,
    culture: UserCultureInfo.SWEDISH,
    baseUrl: 'minasidor.sectoralarm.se',
    name: 'Swedish',
  },
  [UserCultureInfo.NORWEGIAN]: {
    nation: NationId.NORWAY,
    culture: UserCultureInfo.NORWEGIAN,
    baseUrl: 'minside.sectoralarm.no',
    name: 'Norwegian',
  },
  [UserCultureInfo.ENGLISH]: {
    nation: NationId.IRELAND,
    culture: UserCultureInfo.ENGLISH,
    baseUrl: 'mypages.phonewatch.ie',
    name: 'English',
  },
  [UserCultureInfo.FINNISH]: {
    nation: NationId.FINLAND,
    culture: UserCultureInfo.FINNISH,
    baseUrl: 'omatsivut.sectoralarm.fi',
    name: 'Finnish',
  },
  [UserCultureInfo.FRENCH]: {
    nation: NationId.FRANCE,
    culture: UserCultureInfo.FRENCH,
    baseUrl: 'mespages.sectoralarm.fr',
    name: 'FRENCH',
  },
  [UserCultureInfo.SPANISH]: {
    nation: NationId.SPAIN,
    culture: UserCultureInfo.SPANISH,
    baseUrl: 'mispaginas.sectoralarm.es',
    name: 'Spanish',
  },
  [UserCultureInfo.CATALAN]: {
    nation: NationId.SPAIN,
    culture: UserCultureInfo.CATALAN,
    baseUrl: 'mispaginas.sectoralarm.es',
    name: 'Catalan',
  },
  [UserCultureInfo.ITALIAN]: {
    nation: NationId.ITALY,
    culture: UserCultureInfo.ITALIAN,
    baseUrl: 'mypages.sectoralarm.it',
    name: 'ITALIAN',
  },
}

export const getCultureDataFromUrl = (): CultureData | undefined => {
  const url = window.location.href
  return Object.values(translations).find(({ baseUrl }) => url.includes(baseUrl))
}
