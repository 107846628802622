import React, { FC } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'
import BackgroundImagePage from '../../components/pages/BackgroundImagePage'
import { useAuth } from '../../services/authentication/AuthContext'
import { Paragraph } from '../../components/Crow/Typography'

interface RedirectState {
  error?: string
}

const LoginScreen: FC = () => {
  const { isLoading, isAuthenticated, login } = useAuth()
  const { t } = useI18n()
  const { state } = useLocation<RedirectState>()

  if (isLoading) {
    return null
  }

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  const displayError = (error: string) => {
    if (error === 'Error: user is blocked') {
      return t('user_blocked_use_pwreset')
    }
    return t('Something went wrong. Please try again')
  }

  return (
    <BackgroundImagePage>
      <div>
        {state && state.error && <Paragraph>{displayError(state.error.toString())}</Paragraph>}
        <h1>{t('Welcome to a more secure home')} </h1>
        <br />
        <button
          className="button button--third"
          onClick={() => {
            login()
          }}
        >
          {t('Go to login')}
        </button>
      </div>
      <br />
      <h2>{t("Already a customer, but haven't registered your account?")}</h2>
      <p>
        <span>
          {t('Start using My pages and our apps today to get full control over your alarm system.')}
        </span>
        <br />
        <Link to="/user/registration" className="button button--primary button--small">
          {t('Create your account')}
        </Link>
      </p>
    </BackgroundImagePage>
  )
}

export default LoginScreen
