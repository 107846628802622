function SvgGunRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="43" viewBox="0 0 30 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9999 6C19.9999 5.44772 19.5522 5 18.9999 5C18.4476 5 17.9999 5.44772 17.9999 6V15H15.9999C15.4476 15 14.9999 15.4477 14.9999 16V20.5774L12.5358 22C12.0575 22.2761 11.8937 22.8877 12.1698 23.366C12.4459 23.8443 13.0575 24.0082 13.5358 23.7321L14.9999 22.8868V23.8334L12.1269 25.0305C11.4349 25.3188 10.9623 25.9699 10.9025 26.7171L10.0863 36.9203C10.0397 37.5022 10.4993 38.0001 11.0831 38.0001H15.9999C16.5522 38.0001 16.9999 37.5523 16.9999 37.0001V31H18.9999C19.5522 31 19.9999 30.5523 19.9999 30L19.9999 8C20.5522 8 20.9999 7.55229 20.9999 7C20.9999 6.44772 20.5522 6 19.9999 6ZM16.9999 24.5001L16.9999 24.4988L16.9999 17H17.9999L17.9999 29H16.9999L16.9999 24.5001ZM12.8961 26.8766L14.9999 26.0001L14.9999 36.0001H12.1663L12.8961 26.8766Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6004 2C22.6453 1.98046 22.6921 1.96404 22.7405 1.95103L28.7405 0.338532C29.3758 0.167796 30 0.646427 30 1.30426V41.6958C30 42.3536 29.3758 42.8323 28.7405 42.6615L22.7405 41.049C22.692 41.036 22.6452 41.0196 22.6003 41H1C0.447716 41 0 40.5523 0 40V3C0 2.44772 0.447715 2 1 2H22.6004ZM28 40.3916L24 39.3166V3.6835L28 2.6085V40.3916ZM2 18.4649C2.58835 18.8052 3.27143 19 4 19C6.20914 19 8 17.2091 8 15C8 12.7909 6.20914 11 4 11C3.27143 11 2.58835 11.1948 2 11.5351V9.34141C2.62556 9.12031 3.29873 9 4 9C7.31371 9 10 11.6863 10 15C10 18.3137 7.31371 21 4 21C3.29873 21 2.62556 20.8797 2 20.6586V18.4649ZM2 4V7.25204C2.32636 7.16804 2.66078 7.10411 3.00187 7.06166C3.00063 7.04127 3 7.02071 3 7V6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6V7C5 7.02071 4.99937 7.04127 4.99813 7.06166C8.61835 7.51223 11.4878 10.3816 11.9383 14.0019C11.9587 14.0006 11.9793 14 12 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H12C11.9793 16 11.9587 15.9994 11.9383 15.9981C11.4878 19.6184 8.61836 22.4878 4.99813 22.9383C4.99937 22.9587 5 22.9793 5 23V24C5 24.5523 4.55228 25 4 25C3.44772 25 3 24.5523 3 24V23C3 22.9793 3.00063 22.9587 3.00187 22.9383C2.66078 22.8959 2.32636 22.832 2 22.748V39H22V4H2ZM2 15C2 13.8954 2.89543 13 4 13C5.10457 13 6 13.8954 6 15C6 16.1046 5.10457 17 4 17C2.89543 17 2 16.1046 2 15Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgGunRoom
