import { usePanelId } from '../hooks/usePanelId'
import PinCodeConfirm, { usePinCodePrompt } from './PinCodeConfirm'
import React, { FC } from 'react'
import i18n from 'i18next'
import useI18n from '../../../i18n/useI18n'
import { PanelStatus } from '../../../types/PanelModel'
import {
  usePanelStatus,
  useArmCommand,
  useDisarmCommand,
  usePartialArmCommand,
  useArmAnnex,
  useDisarmAnnex,
} from '../../../services/requestHooks/panelInfo'
import Switch from '../../../components/Switch'
import ArmModeModal, { ArmMode, useArmModeModal } from './SelectArmMode'

type Props = {
  canPartialArm: boolean | undefined
  quickArmEnabled: boolean | undefined
  hasAnnex: boolean | undefined
}

const ArmSwitch: FC<Props> = ({ canPartialArm, quickArmEnabled, hasAnnex }) => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const {
    data: panelStatus,
    isLoading: isLoadingStatus,
    error: panelStatusError,
    run: getPanelStatus,
  } = usePanelStatus()

  const isPanelArmed = panelStatus?.Status !== PanelStatus.TURNED_OFF
  const isAnnexArmed = panelStatus?.AnnexStatus === PanelStatus.FULLY_ARMED

  const { run: armPanel, error: armError, isLoading: isLoadingArm } = useArmCommand()
  const { run: disarmPanel, error: disarmError, isLoading: isLoadingDisarm } = useDisarmCommand({})
  const {
    run: partialArm,
    error: partialArmError,
    isLoading: isLoadingPartialArm,
  } = usePartialArmCommand()

  const { run: armAnnex, error: armAnnexError, isLoading: isLoadingArmAnnex } = useArmAnnex({})
  const {
    run: disarmAnnex,
    error: disarmAnnexError,
    isLoading: isLoadingDisarmAnnex,
  } = useDisarmAnnex()

  const { modalState: armPromptState, promptForArmMode } = useArmModeModal()
  const { promptState: pincodePromptState, promptForPinCode } = usePinCodePrompt(panelId)

  const arm = async () => {
    let armMode: ArmMode | undefined
    if (canPartialArm) {
      armMode = await promptForArmMode()
      if (!armMode) return
    } else {
      armMode = 'full'
    }
    let PanelCode: string | undefined
    if (!quickArmEnabled || isPanelArmed) {
      PanelCode = await promptForPinCode()
      if (!PanelCode) return
    }
    if (armMode === 'full') {
      armPanel({ PanelCode, PanelId: panelId })
    } else if (armMode === 'partial') {
      partialArm({ PanelCode, PanelId: panelId })
    }
  }

  const disarm = async () => {
    const PanelCode = await promptForPinCode()
    if (!PanelCode) return
    disarmPanel({ PanelCode, PanelId: panelId })
  }

  const handleArmButtonClick = () => {
    if (isPanelArmed) {
      disarm()
    } else {
      arm()
    }
  }

  const handleArmAnnex = async () => {
    let PanelCode: string | undefined
    if (!quickArmEnabled || isAnnexArmed) {
      PanelCode = await promptForPinCode()
      if (!PanelCode) return
    }
    if (isAnnexArmed) {
      disarmAnnex({ PanelCode, PanelId: panelId })
    } else {
      armAnnex({ PanelCode, PanelId: panelId })
    }
  }

  const error = armError || disarmError || partialArmError || disarmAnnexError || armAnnexError

  const clickReloadHandler = () => getPanelStatus({ panelId })

  if (isLoadingStatus) {
    return null
  }

  return (
    <>
      <Switch
        name="armAlarm"
        size="large"
        position="center"
        checked={isPanelArmed}
        onClick={handleArmButtonClick}
        isLoading={isLoadingArm || isLoadingPartialArm || isLoadingDisarm}
        className="mt-4"
      />
      {!!hasAnnex && (
        <>
          <span>{`${t('ANNEX')} ${isAnnexArmed ? t('is secured') : ''}`}</span>
          <Switch
            name="armAnnex"
            position="center"
            onClick={handleArmAnnex}
            checked={isAnnexArmed}
            isLoading={isLoadingArmAnnex || isLoadingDisarmAnnex}
          />
        </>
      )}

      {error && (
        <div className="notification notification--error" /*timeout*/>
          {t('Could not change alarm status')}
        </div>
      )}

      {panelStatusError && (
        <div>
          <div className="notification notification--error">
            {i18n.t('Could not load alarms system status')}
          </div>
          <button onClick={clickReloadHandler} className="button button--primary button--small">
            {i18n.t('Reload')}
          </button>
        </div>
      )}
      <PinCodeConfirm promptState={pincodePromptState} />
      <ArmModeModal {...armPromptState} />
    </>
  )
}

export default ArmSwitch
