import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'

export interface Props {
  col?: boolean
  onClick?: any
  className?: string
}

const Card: FC<Props> = ({ children, col, onClick, className }) => {
  return (
    <S.Card col={col} onClick={onClick} className={className}>
      {children}
    </S.Card>
  )
}

const S = {
  Card: styled.div<{ col?: boolean; onClick?: any }>`
    display: flex;
    ${({ col }) => {
      return (
        col &&
        css`
          flex-direction: column;
        `
      )
    }}
    background-color: ${colors.white};
    padding: 16px;
    border-radius: 12px;

    cursor: ${({ onClick }) => onClick && 'pointer'};
  `,
}

export default Card
