import React, { FC, useState } from 'react'

import Modal from '../../../../../components/Modal'
import { ContactPerson } from '../../../../../types/ContactPerson'
import ContactForm from './ContactForm'

type FormValues = Omit<ContactPerson, 'Type'>

type Props = {
  title: string
  helpText?: string
  initialValues?: FormValues
  onSubmit?: (contact: FormValues) => void
  onCancel?: () => void
  onlyMobile?: boolean
}

const ContactFormPrompt: FC<Props> = ({ title, ...props }) => {
  if (!props.onSubmit || !props.onCancel) return null

  return (
    <Modal>
      <h1>{title}</h1>
      <ContactForm {...props} allowReuseOfPhonenumber />
    </Modal>
  )
}

export default ContactFormPrompt

export const useContactFormPrompt = () => {
  const [handlers, setHandlers] = useState<{
    onSubmit?: (values: FormValues) => void
    onCancel?: () => void
  }>({})
  const [initialValues, setInitialValues] = useState<FormValues>()
  const promptForContact = (existingPerson?: FormValues): Promise<FormValues | undefined> =>
    new Promise((resolve) => {
      setInitialValues(existingPerson)

      setHandlers({
        onSubmit: (values: FormValues) => {
          setInitialValues(undefined)
          resolve(values)
          setHandlers({})
        },
        onCancel: () => {
          setInitialValues(undefined)
          setHandlers({})
          resolve(undefined)
        },
      })
    })
  return {
    promptForContact,
    promptState: {
      initialValues,
      onSubmit: handlers?.onSubmit,
      onCancel: handlers?.onCancel,
    },
  }
}
