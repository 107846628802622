import React from 'react'

const RightPrimaryS = () => {
  return (
    <svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.46717 18.3615C9.82296 16.2435 10 14.1218 10 12C10 9.87819 9.82296 7.75649 9.46717 5.63855C8.90605 2.29807 7.02988 0.522034 3.79738 0.202427C2.65532 0.0889323 1.50434 0.00592054 0.357145 3.12224e-08L2.09815e-06 0L0 24H0.357142C1.50434 23.9941 2.65532 23.9111 3.79738 23.7976C7.02987 23.478 8.90605 21.7019 9.46717 18.3615Z" />
    </svg>
  )
}

export default RightPrimaryS
