import * as React from 'react'

function SvgPencil(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8107 3.15187L9.81332 3.14924L9.81596 3.14661L12.4213 0.541267C12.7679 0.1947 13.2379 0 13.728 0C14.2182 0 14.6882 0.1947 15.0348 0.541267L19.4587 4.96522C19.8053 5.31179 20 5.78184 20 6.27196C20 6.76208 19.8053 7.23213 19.4587 7.5787L7.34673 19.6907C7.14869 19.8887 6.88009 20 6.60002 20H1.84801C1.35789 20 0.887838 19.8053 0.541268 19.4587C0.1947 19.1122 0 18.6421 0 18.152V13.728C0 13.4854 0.0478001 13.245 0.140671 13.0208C0.233541 12.7966 0.369664 12.5929 0.541269 12.4213L9.8107 3.15187ZM12.0534 3.89595L13.728 2.22136L17.7786 6.27196L16.104 7.94655L12.0534 3.89595ZM14.6106 9.43997L6.16261 17.888H2.11201V13.8374L10.56 5.38936L14.6106 9.43997Z"
        fill="#66A8D8"
      />
    </svg>
  )
}

export default SvgPencil
