import React, { FC, useState } from 'react'
import {
  useBuyBonusProduct,
  useGetBenefits,
  useGetBonusPointProducts,
} from '../../../services/requestHooks/benefits'
import { useOnMount } from '../../../utils/commonHooks'
import { BonusProduct } from '../../../types/BonusProduct'
import Button from '../../../components/Button'
import useI18n from '../../../i18n/useI18n'
import './benefits.scss'
import FailedRequest from '../../onboardingWizard/components/FailedRequest'
import { Product } from './Product'
import Modal from '../../../components/Modal'

interface Props {
  partnerId: string
  handleClose: () => void
}

const BonusPointsModal: FC<Props> = ({ handleClose, partnerId }) => {
  const { t } = useI18n()
  const { data: benefits, run: getBenefits } = useGetBenefits()
  const { run: getProducts, data: products } = useGetBonusPointProducts()
  const { run: buyProduct, isLoading: isBuying, error: buyError } = useBuyBonusProduct({
    onSuccess: () => {
      handleClose()
      getBenefits()
    },
  })

  const [selectedProduct, setSelectedProduct] = useState<BonusProduct | undefined>()
  const totalPoints = benefits?.find((benefit) => benefit.PartnerId === partnerId)?.EarnedPoints
  const remainingPoints = (totalPoints || 0) - (selectedProduct?.Points || 0)

  useOnMount(() => {
    getProducts(partnerId)
  })

  const order = () => {
    if (!selectedProduct) return
    buyProduct({ ProductId: selectedProduct.Id, PartnerId: partnerId })
  }

  return (
    <Modal
      title={selectedProduct ? t('your_order') : t('available_products')}
      handleClose={handleClose}
    >
      <div className="bonus-modal">
        {!selectedProduct && (
          <div className="flex flex-wrap mt-4 ">
            {products?.map((product) => {
              return (
                <Product
                  onClick={() => setSelectedProduct(product)}
                  key={product.Id}
                  product={product}
                  selectable={true}
                />
              )
            })}
          </div>
        )}
        {selectedProduct && (
          <div className="flex flex-col mt-8">
            <div className="order-line">
              <div className="order-line__text">{t('Your points')}</div>
              <div className="order-line__points">{totalPoints}</div>
            </div>
            <div className="order-line">
              <div className="order-line__text">{selectedProduct.Name}</div>
              <div className="order-line__points">{'-' + selectedProduct.Points}</div>
            </div>
            <div className="border-t border-gray-400 font-medium order-line mb-4">
              {remainingPoints > 0 && (
                <>
                  <div className="order-line__text">{t('remaining')}</div>
                  <div className="order-line__points">{remainingPoints}</div>
                </>
              )}
              {remainingPoints < 0 && (
                <>
                  <div className="order-line__text text-red-700">{t('not_enough_points')}</div>
                  <div className="order-line__points">{Math.abs(remainingPoints)}</div>
                </>
              )}
            </div>
            {buyError && <FailedRequest text={buyError} />}
            <div className="flex justify-end">
              <Button
                size="small"
                disabled={isBuying || remainingPoints < 0}
                onClick={order}
                buttonType="primary"
              >
                {t('Purchase')}
              </Button>
              <Button size="small" onClick={handleClose} buttonType="secondary">
                {t('Cancel')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default BonusPointsModal
