import React from 'react'
import styled from 'styled-components'

import useI18n from '../../../i18n/useI18n'
import Toggle from '../../Crow/Toogle/ToggleForForm'
import { GroupTitle } from './Shared'

const UserSettings = ({ register, shouldBeDisabled }: any) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <GroupTitle>{t('user_settings')}</GroupTitle>
      <Settings>
        <Toggle
          text={t('underage')}
          subText={t('is the person a child')}
          icon="Smiley"
          disabled={shouldBeDisabled('underage')}
          {...register('underage')}
        />
      </Settings>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 16px;
`

const Settings = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default UserSettings
