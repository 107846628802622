function SvgAttic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 20C26 22.7614 23.7614 25 21 25C18.2386 25 16 22.7614 16 20C16 17.2386 18.2386 15 21 15C23.7614 15 26 17.2386 26 20ZM18.1707 19H20V17.1707C19.1476 17.472 18.472 18.1476 18.1707 19ZM22 17.1707V19H23.8293C23.528 18.1476 22.8524 17.472 22 17.1707ZM18.1707 21C18.472 21.8524 19.1476 22.528 20 22.8293V21H18.1707ZM22 22.8293C22.8524 22.528 23.528 21.8524 23.8293 21H22V22.8293Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6641 1.49607C21.8725 0.308603 20.1275 0.308602 19.3359 1.49607L2.33328 27H1C0.447715 27 0 27.4477 0 28C0 28.5523 0.447715 29 1 29H41C41.5523 29 42 28.5523 42 28C42 27.4477 41.5523 27 41 27H39.6667L22.6641 1.49607ZM37.263 26.9999L27.9297 13H14.0703L4.73703 26.9999H37.263ZM22 4.10547L26.5964 11H22V4.10547ZM15.4036 11L20 4.10547V11H15.4036Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgAttic
