import React from 'react'

const LeftPrimaryS = () => {
  return (
    <svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.532832 5.63855C0.177044 7.75649 0 9.87818 0 12C0 14.1218 0.177044 16.2435 0.532832 18.3615C1.09395 21.7019 2.97012 23.478 6.20262 23.7976C7.34468 23.9111 8.49566 23.9941 9.64286 24H10V0H9.64286C8.49566 0.00592022 7.34468 0.0889313 6.20262 0.202427C2.97012 0.522034 1.09395 2.29807 0.532832 5.63855Z" />
    </svg>
  )
}

export default LeftPrimaryS
