import React from 'react'
import CrowToggle from './components/CrowToggle'
import DomoPanelPage from './pages/AlarmSystems/Panel/PanelPage'
import CrowLocksPage from './pages/crow/Locks/CrowLocksPage'
import CrowPhotoPage from './pages/crow/CrowPhotoPage'
import CrowSmartPlugsPage from './pages/crow/SmartPlugsPage/CrowSmartPlugsPage'
import CrowVideoPage from './pages/crow/CrowVideoPage'
import CrowPanelPage from './pages/crow/PanelPage/CrowPanelPage'
import CrowPeoplePage from './pages/crow/PeoplePage/CrowPeoplePage'
import CrowTemperaturePage from './pages/crow/TemperaturePage/CrowTemperaturePage'
import CrowDoorAndWindowPage from './pages/crow/DoorAndWindowPage/CrowDoorsAndWindowsPage'
import CrowSmokeDetectorsPage from './pages/crow/SmokeDetectors/CrowSmokeDetectors'

export const PanelPage = () => {
  return <CrowToggle CrowComponent={CrowPanelPage} DomoComponent={DomoPanelPage} />
}

export const LocksPage = () => {
  return <CrowToggle CrowComponent={CrowLocksPage} />
}

export const SmartPlugsPage = () => {
  return <CrowToggle CrowComponent={CrowSmartPlugsPage} />
}

export const TemperaturesPage = () => {
  return <CrowToggle CrowComponent={CrowTemperaturePage} />
}

export const PeoplePage = () => {
  return <CrowToggle CrowComponent={CrowPeoplePage} />
}

export const VideoPage = () => {
  return <CrowToggle CrowComponent={CrowVideoPage} />
}

export const PhotoPage = () => {
  return <CrowToggle CrowComponent={CrowPhotoPage} />
}

export const DoorAndWindowPage = () => {
  return <CrowToggle CrowComponent={CrowDoorAndWindowPage} />
}

export const SmokeDetectorsPage = () => {
  return <CrowToggle CrowComponent={CrowSmokeDetectorsPage} />
}
