import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import { useWifiList } from '../../../../services/requestHooks/wifi'
import { Capability } from '../../../../types/PanelModel'
import { WifiDetails } from '../../../../types/WifiDetails'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import SettingsSubPage from '../components/SettingsSubPage'
import ConfirmConnect, { useConfirmConnect } from './ConfirmConnect'
import ConfirmDisconnect, { useConfirmDisconnect } from './ConfirmDisconnect'
import ConnectForm from './ConnectForm'
import WifiList, { WifiListItem } from './WifiList'

const WifiPage: FC = () => {
  const history = useHistory()
  const panelId = usePanelId()
  const { t } = useI18n()
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { modalState: connectState, promptForConnect } = useConfirmConnect()
  const { modalState: disconnectState, promptForDisconnect } = useConfirmDisconnect()

  const {
    run: getWifiList,
    data: wifiList = [],
    isLoading: loadingWifiList,
    error: loadWifiError,
  } = useWifiList()
  const {
    run: getPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadPanelInfoError,
  } = usePanelInfo({
    onSuccess: async ({ response: { Wifi } }) => {
      if (Wifi?.WifiExist) {
        const TwoFactorPin = await promptForTwoFactor(returnUrl)
        if (TwoFactorPin) {
          getWifiList({ panelId, TwoFactorPin, WiFiCardSerial: Wifi.Serial })
        }
      } else {
        history.replace(returnUrl)
      }
    },
  })

  const returnUrl = useMemo(() => `/systems/${panelId}/settings`, [panelId])

  const loadWifiList = useCallback(async () => {
    if (panelInfo && !!panelInfo.Wifi) {
      const TwoFactorPin = await promptForTwoFactor(returnUrl)
      if (TwoFactorPin) {
        getWifiList({ panelId, TwoFactorPin, WiFiCardSerial: panelInfo.Wifi.Serial })
      }
    }
  }, [getWifiList, panelId, panelInfo, promptForTwoFactor, returnUrl])

  const onConnectClick = (wifi: WifiDetails) => async () => {
    const connectedToWifi = await promptForConnect(wifi)
    if (connectedToWifi) {
      loadWifiList()
    }
  }

  const onDisconnectClick = (wifi: WifiDetails) => async () => {
    const disconnected = await promptForDisconnect(wifi)
    if (disconnected) {
      loadWifiList()
    }
  }

  const connectedWifis = wifiList.filter((wifi) => wifi.Connected.toLowerCase() === 'true')
  const otherWifis = wifiList.filter(
    (wifi) => wifi.Connected.toLowerCase() === 'false' && wifi.SsidHidden !== '1',
  )

  const requiresSSid = !panelInfo?.Capabilities.includes(Capability.CAN_SCAN_FOR_WIFI)

  useOnMount(() => {
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      getPanelInfo(panelId)
    } else {
      loadWifiList()
    }
  })

  return (
    <>
      <SettingsSubPage
        returnUrl={returnUrl}
        isLoading={loadingPanelInfo || loadingWifiList}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed: !!loadWifiError || !!loadPanelInfoError,
            errorText: 'Something went wrong. Please try again',
            returnUrl: `/systems/${panelId}/settings`,
            errorHeaderText: 'WIFI_SETTINGS',
          },
        }}
      >
        <h1>{t('WIFI_SETTINGS')}</h1>
        <p>{t('WIFI_INFO_TEXT')}</p>
        {connectedWifis.length > 0 && (
          <WifiList title={t('CONNECTED')}>
            {connectedWifis.map((wifi, index) => (
              <WifiListItem key={index} {...wifi} onClick={onDisconnectClick(wifi)} />
            ))}
          </WifiList>
        )}
        {otherWifis.length > 0 && (
          <WifiList title={t('AVAILABLE_NETWORKS')}>
            {otherWifis.map((wifi, index) => (
              <WifiListItem key={index} {...wifi} onClick={onConnectClick(wifi)} />
            ))}
          </WifiList>
        )}
        {requiresSSid && (
          <>
            <ConnectForm loadWifiList={loadWifiList} WiFiCardSerial={panelInfo?.Wifi?.Serial} />
            <hr />
          </>
        )}
      </SettingsSubPage>
      <ConfirmConnect {...connectState} WiFiCardSerial={panelInfo?.Wifi?.Serial} />
      <ConfirmDisconnect {...disconnectState} WiFiCardSerial={panelInfo?.Wifi?.Serial} />
    </>
  )
}

export default WifiPage
