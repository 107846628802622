import Button from '../../../components/Crow/Button/Button'
import { colors } from '../../../components/Crow/Style/theme'
import useI18n from '../../../i18n/useI18n'
import { useResourceInfo } from '../../../services/requestHooks/user'
import { DevicesType, MountType } from '../../../types/DoorsAndWindows'

import {
  DeviceName,
  DeviceStatusContainer,
  DoorsCrowCard,
  FlexColumn,
  IconContainer,
  LowBatteryContainer,
  StyledParagraphBatteryLow,
  StyledStrong,
  StyledSubHeadline,
  WindowDeviceNameStatusContainer,
  WindowsCrowCard,
  StyledIcon,
} from './DoorsAndWindowsStyle'
import DeviceListType from './types/DoorAndWindowsType'

const DeviceList = ({ floors, panelStatus, type }: DeviceListType) => {
  const { t } = useI18n()

  const resources = useResourceInfo()

  const devices = floors.Rooms.map((device) => device.Devices)

  return (
    <>
      {devices.map((device: DevicesType[]) =>
        device
          .filter((deviceType) => deviceType.MountType === type)
          .map((device) => (
            <div key={device.Serial}>
              {/*TODO: Replace panelStatus?.IsOnline with device online/offline status when supported */}
              {panelStatus?.IsOnline ? (
                <WindowsCrowCard border key={device.Serial}>
                  <WindowDeviceNameStatusContainer>
                    {!device.Closed && device.MountType === MountType.DOOR ? (
                      <IconContainer className="w-8 h-8 mr-4" color={colors.lightRed}>
                        <StyledIcon name="Warning" color={colors.white} fontSize="22px" />
                      </IconContainer>
                    ) : null}
                    <DeviceName large>
                      {t(MountType[device.MountType])}&nbsp;{device.MountIndex}
                    </DeviceName>
                    <DeviceStatusContainer color={device.Closed ? 1 : 0}>
                      <StyledStrong color={device.Closed ? 1 : 0}>
                        {device.Closed ? t('CLOSED') : t('open')}
                      </StyledStrong>
                    </DeviceStatusContainer>
                  </WindowDeviceNameStatusContainer>
                  {device.LowBattery && (
                    <LowBatteryContainer>
                      <IconContainer className="w-8 h-8" color={colors.lightYellow}>
                        <StyledIcon name="Battery-low" color={colors.white} fontSize="22px" />
                      </IconContainer>
                      <StyledParagraphBatteryLow large>
                        {t('BATTERY_LOW')}
                      </StyledParagraphBatteryLow>
                      {resources && resources.CustomerServicePhone && (
                        <a className="no-underline" href={`tel:${resources.CustomerServicePhone}`}>
                          <Button variant="default" level="secondary" size="s">
                            {t('call_support')}
                          </Button>
                        </a>
                      )}
                    </LowBatteryContainer>
                  )}
                </WindowsCrowCard>
              ) : (
                <DoorsCrowCard border key={device.Serial}>
                  <FlexColumn>
                    <DeviceName large>{device.Name}</DeviceName>
                    <StyledSubHeadline color={colors.black}>Can’t connect</StyledSubHeadline>
                  </FlexColumn>
                  <DeviceStatusContainer color={colors.yellow}>
                    <StyledStrong color={colors.black900}>{t('Unknown')}</StyledStrong>
                  </DeviceStatusContainer>
                </DoorsCrowCard>
              )}
            </div>
          )),
      )}
    </>
  )
}

export default DeviceList
