function SvgTheater(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="44" height="31" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 11.6273C27.1667 12.0152 27.1667 12.9848 26.5 13.3727L20.5 16.8635C19.8333 17.2514 19 16.7665 19 15.9908V9.00921C19 8.23348 19.8333 7.74864 20.5 8.13651L26.5 11.6273ZM21 10.7546L24 12.5L21 14.2454V10.7546Z"
        fill="#333131"
      />
      <path
        d="M5 23C5.55228 23 6 22.5523 6 22C6 21.4477 5.55228 21 5 21C4.44772 21 4 21.4477 4 22C4 22.5523 4.44772 23 5 23Z"
        fill="#333131"
      />
      <path
        d="M5 28C6.10457 28 7 27.1046 7 26C7 24.8954 6.10457 24 5 24C3.89543 24 3 24.8954 3 26C3 27.1046 3.89543 28 5 28Z"
        fill="#333131"
      />
      <path
        d="M40 22C40 22.5523 39.5523 23 39 23C38.4477 23 38 22.5523 38 22C38 21.4477 38.4477 21 39 21C39.5523 21 40 21.4477 40 22Z"
        fill="#333131"
      />
      <path
        d="M39 28C40.1046 28 41 27.1046 41 26C41 24.8954 40.1046 24 39 24C37.8954 24 37 24.8954 37 26C37 27.1046 37.8954 28 39 28Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 10H40V2C40 0.895431 39.1046 0 38 0H6C4.89543 0 4 0.89543 4 2V10H2C0.895431 10 0 10.8954 0 12V29C0 30.1046 0.895431 31 2 31H8C9.10457 31 10 30.1046 10 29V25H34V29C34 30.1046 34.8954 31 36 31H42C43.1046 31 44 30.1046 44 29V12C44 10.8954 43.1046 10 42 10ZM6 2H38V10H36C34.8954 10 34 10.8954 34 12V23H10V12C10 10.8954 9.10457 10 8 10H6V2ZM8 12H2V29H8V12ZM36 12H42V29H36V12Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgTheater
