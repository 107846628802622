import React from 'react'
import styled from 'styled-components'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import { Paragraph, SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { TranslationKey } from '../../../types/generated/TranslationKey'

interface EmptyEventsType {
  icon: string
  iconColor: string
  title: TranslationKey
}

const EmptyOrErrorEvents = ({ icon, iconColor, title }: EmptyEventsType) => {
  const { t } = useI18n()
  return (
    <EmptyEventsContainer>
      <StyledIcon name={icon} color={iconColor} />
      <StyledSubtitle>{title}</StyledSubtitle>
      <Paragraph>{t('history no events')}</Paragraph>
    </EmptyEventsContainer>
  )
}

const EmptyEventsContainer = styled.div`
  max-width: 610px;
  max-height: 240px;
  border-radius: 12px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 32px;
`

const StyledIcon = styled(Icon)`
  font-size: 50px;
`

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  padding-top: 23px;
  padding-bottom: 16px;
`

export default EmptyOrErrorEvents
