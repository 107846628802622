import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../../../components/Button'
import Icon from '../../../../components/Crow/Icon/Icon'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph } from '../../../../components/Crow/Typography'
import useI18n from '../../../../i18n/useI18n'

const SettingsPeopleInfo = ({ panelId }: { panelId: string }) => {
  const { t } = useI18n()
  let history = useHistory()
  return (
    <>
      <PeopleInfo>
        <Paragraph light>
          {t('You can access user information, emergency contacts and access codes via')}
        </Paragraph>
        <>
          <StyledButton
            buttonType="link"
            type="button"
            onClick={() => history.push(`/systems/${panelId}/people`)}
          >
            <StyledPeopleIcon name="People" className="pl-1 pr-1" />
            {t('PEOPLE')} App
          </StyledButton>
        </>
      </PeopleInfo>
    </>
  )
}

const PeopleInfo = styled.div`
  height: 225px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
`

const StyledPeopleIcon = styled(Icon)`
  font-size: 15px;
`

const StyledButton = styled(Button)`
  display: flex;
  height: 20px;
  align-items: center;
  color: ${colors.blue};
  text-decoration: none;

  @media only screen and (max-width: 630px) {
    margin-top: 32px;
    align-self: flex-start;
  }
`

/* eslint-disable @typescript-eslint/no-unused-vars */
export default SettingsPeopleInfo
