import React, { FormEventHandler, MouseEventHandler, useState } from 'react'
import Button from '../../../components/Button'
import useI18n from '../../../i18n/useI18n'
import Modal from '../../../components/Modal'
import CancelButton from '../../../components/Button/CancelButton'
import { Loader } from '../../../components/Elements/Loaders'

interface Props {
  promptState: {
    onSubmit?: (password: string) => void
    onCancel?: MouseEventHandler
  }
}

const PasswordPrompt = ({ promptState: { onSubmit, onCancel } }: Props) => {
  const { t } = useI18n()
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit: FormEventHandler = (event) => {
    // Don't trigger any forms beneath the modal
    event.stopPropagation()
    event.preventDefault()
    if (!onSubmit) throw Error('Prompt was not disabled when onSubmit was missing')
    onSubmit(password)
    setIsLoading(false)
    setPassword('')
  }
  if (!onSubmit || !onCancel) return null
  return (
    <Modal fullscreen handleClose={onCancel}>
      <div className="password__area">
        <label htmlFor="password" className="margin-bottom text-3xl">
          {t('Password')}
        </label>
        <p>{t('To save changes you need to enter your account password.')}</p>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <input
            style={{ display: 'none' }}
            aria-hidden
            autoComplete="username"
            type="text"
            name="username"
          />
          <label className="password__label">
            <input
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              autoComplete="current-password"
              className="form__input"
              placeholder={t('Password')}
            />
          </label>
          <div className="flex">
            <Button type="submit" buttonType="primary" disabled={!password}>
              {t('Confirm')}
            </Button>
            {isLoading && <Loader className="mt-6 mr-4" />}
            <CancelButton onClick={onCancel} />
          </div>
        </form>
      </div>
    </Modal>
  )
}

interface PromtHandlers {
  onSubmit: (password: string) => void
  onCancel: () => void
}

export const usePasswordPrompt = (): {
  promptForPassword: () => Promise<string | undefined>
} & Props => {
  const [handlers, setHandlers] = useState<PromtHandlers | undefined>()
  const promptForPassword = () => {
    return new Promise<string | undefined>((resolve) => {
      setHandlers({
        onSubmit: (password) => {
          setHandlers(undefined)
          resolve(password)
        },
        onCancel: () => {
          setHandlers(undefined)
          resolve(undefined)
        },
      })
    })
  }

  return {
    promptForPassword,
    promptState: {
      onSubmit: handlers?.onSubmit,
      onCancel: handlers?.onCancel,
    },
  }
}

export default PasswordPrompt
