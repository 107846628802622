import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import useI18n from '../../../i18n/useI18n'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import { PanelEvent } from '../../../types/PanelEvent'

import { IconContainer, StyledParagraph, StyledSubtitle } from './HistoryStyle'
import { EventIcon, getEventText, getEventTitle } from './util/Helpers'

const ColumnEvents = ({ event }: { event: PanelEvent }) => {
  const { t } = useI18n()

  const SubEventIcon = (param: string) => {
    switch (param) {
      case 'Area_Armed_By_Doorlock_Log':
        return (
          <IconContainer className="w-6 h-6" color={colors.black}>
            <Icon name="Armed" color={colors.white} />
          </IconContainer>
        )
      case 'Area_Stay_Armed_By_Doorlock_Log':
        return (
          <IconContainer className="w-6 h-6" color={colors.white} border={colors.black900}>
            <Icon name="Partially-armed" color={colors.black900} />
          </IconContainer>
        )
      case 'Area_Disarmed_By_Doorlock_Log':
        return (
          <IconContainer className="w-6 h-6" color={colors.lightGray} border={colors.lightGray}>
            <Icon name="Disarmed" color={colors.black900} />
          </IconContainer>
        )
      case 'disarmed_and_unlock':
        return (
          <IconContainer className="w-10 h-10" color={colors.lightGray} border={colors.lightGray}>
            <Icon name="Disarmed" color={colors.black800} />
          </IconContainer>
        )
      case 'armed_and_lock':
        return (
          <IconContainer className="w-10 h-10" color={colors.black900}>
            <Icon name="Armed" color={colors.white} />
          </IconContainer>
        )
    }
  }

  const getColumnEventTitle = (event: PanelEvent, t: (text: TranslationKey) => string) => {
    const { EventType, LockName } = event

    switch (EventType) {
      case 'Area_Armed_By_Doorlock_Log':
        return `${LockName} ${t('locked')}`
      case 'Area_Stay_Armed_By_Doorlock_Log':
        return `${LockName} ${t('locked')}`
      case 'Area_Disarmed_By_Doorlock_Log':
        return `${LockName} ${t('unlocked')}`
      case 'armed_and_lock':
        return `${LockName} ${t('locked')}`
      case 'disarmed_and_unlock':
        return `${LockName} ${t('unlocked')}`
    }
    return ''
  }
  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="flex items-center pb-3">
          <EventIcon eventType={event.EventType} />
          <StyledSubtitle>{getColumnEventTitle(event, t)}</StyledSubtitle>
        </div>
        <div className="flex items-center">
          {SubEventIcon(event.EventType)}
          <div className="flex flex-col">
            <StyledSubtitle>{getEventTitle(event, t)}</StyledSubtitle>
            <StyledParagraph grey>{getEventText(event, t)}</StyledParagraph>
          </div>
        </div>
      </div>
    </>
  )
}

export default ColumnEvents
