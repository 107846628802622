import registration from './registrationSlice'
import appUserRegistration from './appUserRegistrationSlice'
import directions from '../pages/AlarmSystems/Settings/Directions/state/directionsReducer'
import plugs from './plugsReducer'
import locks from './locksReducer'
import locksSettings from './lockSettingsSlice'
import panelUsers from '../pages/AlarmSystems/Settings/PanelUsers/state/panelUsersSlice'
import appUsers from './appUserSlice'
import invoices from './invoiceSlice'
import messages from './messagesSlice'
import { systemListReducer } from './systemListSlice'
import { requestReducer } from './requestSlice'
import { temperatureHousecheckReducer, temperatureListReducer } from './temperatureListSlice'
import { contactsReducer } from './contactsSlice'
import { securityQuestionReducer } from './securityQuestionSlice'
import { notificationSettingsReducer } from './notificationSlice'
import { userReducer } from './userSlice'
import { bookingReducer } from './bookingSlice'
import { propertyContactsReducer } from './propertyContactSlice'
import order from './orderSlice'
import panel from './panelInfoSlice'
import { wifiReducer } from './wifiSlice'
import { historyReducer } from './historySlice'
import { simpleRegistrationReducer } from './simpleRegistrationSlice'
import addDeviceSlice from './addDeviceSlice'
import termsSlice from './termsSlice'
import benefitsSlice from './benefitsSlice'
import { doorsandwindowsListReducer } from './doorsandwindowsSlice'
import usersReducer from './usersSlice'
import { smokeDetectorsReducer } from './smokeDetectorsSlice'

const combinedReducers = {
  registration,
  user: userReducer,
  directions,
  plugs,
  locks,
  locksSettings,
  panelUsers,
  history: historyReducer,
  invoices: invoices,
  messages,
  appUsers,
  systemList: systemListReducer,
  request: requestReducer,
  temperatureList: temperatureListReducer,
  temperatureHousecheckReducer: temperatureHousecheckReducer,
  contacts: contactsReducer,
  securityQuestion: securityQuestionReducer,
  notificationSettings: notificationSettingsReducer,
  appUserRegistration,
  booking: bookingReducer,
  propertyContacts: propertyContactsReducer,
  order,
  panel,
  wifi: wifiReducer,
  simpleRegistration: simpleRegistrationReducer,
  addDevice: addDeviceSlice,
  terms: termsSlice,
  benefits: benefitsSlice,
  doorsAndWindowsList: doorsandwindowsListReducer,
  users: usersReducer,
  smokeDetectors: smokeDetectorsReducer,
}

export default combinedReducers
