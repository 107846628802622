import React from 'react'
import styled from 'styled-components'
import { HomeIcon, SmartplugIcon } from '../../../assets/icons'
import {
  Annex,
  Attic,
  Bar,
  BathRoom,
  BikeShed,
  Boiler,
  Cellar,
  CraftsRoom,
  DiningRoom,
  Dressing,
  Entrance,
  Error,
  FoodStorage,
  GuestBedRoom,
  GunRoom,
  Gym,
  Hallway,
  KidsRoom,
  Kitchen,
  Laundry,
  Library,
  LivingRoom,
  Locked,
  MasterBedroom,
  Office,
  Pencil,
  Play,
  Plus,
  Pool,
  SmartPlug,
  Smiley,
  Stairs,
  Star,
  Storage,
  Theater,
  Toilet,
  Tv,
  Unknown,
} from '../../../assets/icons/crow'
import BedRoom from '../../../assets/icons/crow/DoorsAndWindows/BedRoom'
import GaragePlace from '../../../assets/icons/crow/DoorsAndWindows/GaragePlace'
import SignOut from '../../../assets/icons/crow/SmartPlugs/SignOut'
import SvgIconSectorAlarmAppAddPhoto from '../../../assets/icons/IconSectorAlarmAppAddPhoto'
import KeyIcon from '../../../assets/icons/KeyIcon'
import MobileIcon from '../../../assets/icons/MobileIcon'
import SystemIcon from '../../../assets/icons/SystemIcon'
import { colors } from '../Style/theme'

export const iconNames = [
  'Avatar',
  'Switch',
  'Chevron-right',
  'Chevron-left',
  'Home',
  'Shop',
  'Inbox',
  'Armed',
  'Disarmed',
  'Partially-armed',
  'Doors',
  'Windows',
  'Video',
  'Camera',
  'Temperature',
  'Locks',
  'History',
  'Places',
  'Lights',
  'Leakage',
  'Mini-apps',
  'Alarm-System',
  'People',
  'Smoke-Detector',
  'Smart-plugs',
  'Settings',
  'Warning',
  'Warning-inverted',
  'Checkmark',
  'Cancel',
  'Info',
  'GSM-',
  'GSM-Off',
  'leaving-app',
  'Protected',
  'API',
  'Phone',
  'Biometric',
  'Document',
  'Document-lost',
  'Door-locked',
  'Door-unlocked',
  'Door-lock-failed',
  'Door-battery-low',
  'Door-Battery-power-restored',
  'Battery-low',
  'Unlocked',
  'Visible',
  'Hidden',
  'Volume-low',
  'Volume-high',
  'Adjust',
  'Wifi-off',
  'Wifi-on',
  'Power-on',
  'Power-off',
  'Signal',
  'Time',
  'Notifications',
  'Download',
  'Share',
  'Fullscreen',
  'Zoom-in',
  'Zoom-out',
  'Location',
  'Timer',
  'Edit',
  'Flag',
  'Contact',
  'SectorAlarmAppAddPhoto',
  'SmartPlug',
]

export type IconName = typeof iconNames[number]

export type Props = {
  name: IconName | undefined
  className?: string
  color?: string
} & React.InputHTMLAttributes<HTMLSpanElement>
const Icon = ({ name, className = '', ...props }: Props) => {
  const activeColor = props.color || colors.black900
  const color = props.disabled ? colors.black500 : activeColor

  if (name === 'Smart-plugs') {
    //Quickfix for smart-plugs icon until we understand whats going on with this font icon...
    return <SmartplugIcon className={className} />
  } else if (name === 'SmartPlug') {
    return <SmartPlug className={className} />
  } else if (name === 'SectorAlarmAppAddPhoto') {
    //This icon was missing in the icon set
    return <SvgIconSectorAlarmAppAddPhoto className={className} />
  } else if (name === 'Star') {
    return <Star fill={color} />
  } else if (name === 'System') {
    return <SystemIcon stroke={color} />
  } else if (name === 'App') {
    return <MobileIcon fill={color} />
  } else if (name === 'Smiley') {
    return <Smiley fill={color} />
  } else if (name === 'Plus') {
    return <Plus />
  } else if (name === 'Key') {
    return <KeyIcon color={color} />
  } else if (name === 'Entrance') {
    return <Entrance />
  } else if (name === 'Kitchen') {
    return <Kitchen />
  } else if (name === 'LivingRoom') {
    return <LivingRoom />
  } else if (name === 'KidsRoom') {
    return <KidsRoom />
  } else if (name === 'Hallway') {
    return <Hallway />
  } else if (name === 'MasterBedroom') {
    return <MasterBedroom />
  } else if (name === 'Bedroom') {
    return <BedRoom />
  } else if (name === 'GuestBedRoom') {
    return <GuestBedRoom />
  } else if (name === 'DiningRoom') {
    return <DiningRoom />
  } else if (name === 'Stairs') {
    return <Stairs />
  } else if (name === 'Cellar') {
    return <Cellar />
  } else if (name === 'Tv') {
    return <Tv />
  } else if (name === 'Office') {
    return <Office />
  } else if (name === 'BathRoom') {
    return <BathRoom />
  } else if (name === 'Toilet') {
    return <Toilet />
  } else if (name === 'Bar') {
    return <Bar />
  } else if (name === 'Storage') {
    return <Storage />
  } else if (name === 'Theater') {
    return <Theater />
  } else if (name === 'Library') {
    return <Library />
  } else if (name === 'Gym') {
    return <Gym />
  } else if (name === 'Laundry') {
    return <Laundry />
  } else if (name === 'Play') {
    return <Play />
  } else if (name === 'Attic') {
    return <Attic />
  } else if (name === 'Pool') {
    return <Pool />
  } else if (name === 'Dressing') {
    return <Dressing />
  } else if (name === 'FoodStorage') {
    return <FoodStorage />
  } else if (name === 'Boiler') {
    return <Boiler />
  } else if (name === 'GunRoom') {
    return <GunRoom />
  } else if (name === 'Boiler') {
    return <Boiler />
  } else if (name === 'CraftsRoom') {
    return <CraftsRoom />
  } else if (name === 'RentalPlace') {
    return <Annex />
  } else if (name === 'BikeShed') {
    return <BikeShed />
  } else if (name === 'Unknown') {
    return <Unknown />
  } else if (name === 'Locked') {
    return <Locked />
  } else if (name === 'system') {
    return <SystemIcon />
  } else if (name === 'app') {
    return <MobileIcon />
  } else if (name === 'Sign-out') {
    return <SignOut className={className} />
  } else if (name === 'House') {
    return <HomeIcon />
  } else if (name === 'GaragePlace') {
    return <GaragePlace />
  } else if (name === 'Pencil') {
    return <Pencil />
  } else if (name === 'Error') {
    return <Error />
  }

  return <S.Icon {...props} className={`icon-m-${name} ${className}`} />
}

const S = {
  Icon: styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ color }) => (color ? color : null)};
  `,
}

export default Icon
