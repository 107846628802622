import React from 'react'

const RightSecondaryM = () => {
  return (
    <svg width="14" height="32" viewBox="0 0 14 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2844 7.86545C10.9479 5.95714 10.2564 4.69553 9.34137 3.86258C8.42298 3.02661 7.08457 2.44526 5.12883 2.26109C4.07659 2.1615 3.03283 2.08128 2 2.03709H0V0H0.5C0.999667 0.00245578 1.49985 0.0148603 2 0.0353424C3.10749 0.0806959 4.21487 0.165655 5.31633 0.269903C9.84183 0.696046 12.4685 3.0641 13.254 7.51806C13.7521 10.342 14 13.1709 14 16C14 18.8291 13.7521 21.658 13.254 24.4819C12.4685 28.9359 9.84183 31.304 5.31633 31.7301C4.21487 31.8343 3.10749 31.9193 2 31.9647C1.49985 31.9851 0.999667 31.9975 0.5 32H0V29.9629H2C3.03283 29.9187 4.07564 29.8386 5.12788 29.739C7.08363 29.5548 8.42298 28.9734 9.34137 28.1374C10.2564 27.3045 10.9479 26.0429 11.2844 24.1345C11.7625 21.4244 12 18.7117 12 16C12 13.2883 11.7625 10.5756 11.2844 7.86545Z" />
    </svg>
  )
}

export default RightSecondaryM
