export enum BenefitStatus {
  NotSet = 'NotSet',
  NoValidation = 'NoValidation',
  Validated = 'Validated',
  ValidationFailed = 'ValidationFailed',
  Deactivated = 'Deactivated',
}

export type Benefit = {
  Id: string
  Name: string
  HardwareSaving: number
  SubscriptionSaving: number
  Status: number
  StatusName: BenefitStatus
  PartnerId: string
  EarnedPoints: number
}
