import { yupResolver } from '@hookform/resolvers/yup'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Button from '../../../../components/Button'
import { Loader } from '../../../../components/Elements/Loaders'
import ExpandableSection, {
  ExpandableSectionSummary,
} from '../../../../components/ExpandableSection/ExpandableSection'
import TextField from '../../../../components/forms/TextField'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useConnectToWifi } from '../../../../services/requestHooks/wifi'
import { TranslationKeyOverride } from '../../../../types/TranslationKeyOverride'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'

type FormValues = {
  ssid: string
  password: string
}

const resolver = yup.object({
  ssid: yup.string().required('Required'),
  password: yup.string().required('Required'),
})

type Props = {
  WiFiCardSerial: string | undefined
  loadWifiList: () => void
}

const ConnectForm = ({ WiFiCardSerial, loadWifiList }: Props): JSX.Element => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const { run: connectToWifi, isLoading, error } = useConnectToWifi({
    onSuccess: () => {
      loadWifiList()
    },
  })
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const onSubmit = async ({ ssid, password }: FormValues) => {
    if (!WiFiCardSerial) return
    const TwoFactorPin = await promptForTwoFactor()
    if (!TwoFactorPin) return
    connectToWifi({
      Ssid: ssid,
      password,
      TwoFactorPin,
      WiFiCardSerial,
      panelId,
    })
  }

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(resolver),
  })

  return (
    <ExpandableSection className="mt-8 mb-2">
      <ExpandableSectionSummary>
        <h2 className="gray">{t('CONNECT_TO_WIFI_NETWORK')}</h2>
      </ExpandableSectionSummary>
      {isLoading ? (
        <div className="flex justify-center">
          <Loader types={['large']} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className="mt-6"
            {...register('ssid')}
            label="WIFI_SSID"
            helpText="WIFI_SSID_INFO_TEXT"
            errorKey={errors.ssid?.message as TranslationKeyOverride}
          />
          <TextField
            className="mt-6"
            {...register('password')}
            label="WIFI_PASSWORD"
            helpText="WIFI_PASSWORD_INFO_TEXT"
            type="password"
            errorKey={errors.password?.message as TranslationKeyOverride}
          />
          <Button buttonType="primary" disabled={!isValid || isLoading}>
            {t('CONNECT')}
          </Button>
          {!!error && <FailedRequest text={error} />}
        </form>
      )}
    </ExpandableSection>
  )
}

export default ConnectForm
