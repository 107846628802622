import * as React from 'react'

function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 114.2" {...props}>
      <path
        fill="#505354"
        d="M130.2 45.6c-.7-1.2-1.9-2.3-3.3-2.6l-7.2-2.1c-2.8-.9-5.9.7-6.8 3.7l-10 32.5L6.5 47.6 18.4 7.5l98.1 30.4c1.7.5 3.5-.3 4-2.1.5-1.7-.3-3.5-2.1-4l-99-30.6c-1.4-.3-2.8-.3-4.2.3-1.2.7-2.3 1.9-2.6 3.3L.2 46.7c-.9 2.8.7 5.9 3.7 6.8L102 83.6c.5.2 1 .2 1.6.2 2.3 0 4.5-1.6 5.2-3.8l1.6-4.9 6.3 1.9c2.8.9 5.9-.7 6.8-3.7l7.2-23.4c.2-1.5.2-3-.5-4.3m-12.6 24.9l-5.4-1.7 6.6-21.5 5.4 1.6-6.6 21.6zm-54.2 4c-1.7-.5-3.5.3-4 2.1l-4.2 14H8.1V77.5c0-1.7-1.4-3.1-3.1-3.1s-3.2 1.3-3.2 3.1v32.2c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1V96.9h47.2c2.6 0 5.2-1.9 5.9-4.4l4.4-14c.5-1.7-.4-3.5-2.1-4"
      />
    </svg>
  )
}

export default SvgCamera
