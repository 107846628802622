import * as React from 'react'

function SvgHistory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <title>{'History_Sector Alarm_app_icon'}</title>
      <path d="M84.3 71.8H70c-1 0-1.8-.8-1.8-1.8V55.7c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v12.5h12.4c1 0 1.8.8 1.8 1.8s-.7 1.8-1.7 1.8z" />
      <path d="M70 37.5c-7.6 0-15 2.7-20.9 7.7v-5.1c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v9.3c0 1 .8 1.8 1.8 1.8h10c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8h-5.4c12.4-10 30.6-8 40.6 4.4s8 30.6-4.4 40.6-30.6 8-40.6-4.4c-4.1-5.1-6.4-11.4-6.4-18 0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8c0 18 14.6 32.5 32.6 32.4 18 0 32.5-14.6 32.4-32.6 0-18-14.6-32.5-32.5-32.5z" />
    </svg>
  )
}

export default SvgHistory
