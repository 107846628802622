export const deriveInitials = (firstName: string, lastName: string) => {
  const firstNames = firstName.split(' ')
  const lastNames = lastName.split(' ')

  return firstNames
    .splice(0, 1)
    .concat(lastNames.slice(-1))
    .map((s) => s[0])
    .join('')
}
