import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { UserIcon } from '../../../../../assets/icons'
import { AccessGroupType } from '../../../../../types/AppUser'
import ExpandableSection, {
  ExpandableSectionSummary,
} from '../../../../../components/ExpandableSection/ExpandableSection'
import useI18n from '../../../../../i18n/useI18n'
import Button from '../../../../../components/Button'
import '../appUser.scss'

interface Props {
  onDelete: () => void
  onSelectUser: () => void
  onUnSelectUser: () => void
  isSelected: boolean
  user: {
    AppUserId: string
    AccessGroup: AccessGroupType
    PhoneNumber: string
    LastName: string
    FirstName: string
    IsInvite: boolean
  }
}

const noOp = () => {}

const AppUserListItem = ({ user, isSelected, onDelete, onSelectUser, onUnSelectUser }: Props) => {
  const { t } = useI18n()
  const { AccessGroup, IsInvite, FirstName, LastName, PhoneNumber } = user

  const handleUnselect: MouseEventHandler = (e) => {
    e.stopPropagation()
    onUnSelectUser()
  }

  return (
    <div
      className={classNames({
        'user-active': isSelected,
      })}
    >
      <ExpandableSection open={isSelected} onClick={isSelected ? noOp : onSelectUser} hideIndicator>
        <ExpandableSectionSummary
          className={'list-item status-list__divSelector flex flex-col px-4'}
        >
          <div className="flex flex-col flex-grow">
            <div className="flex flex-wrap pb-2 flex-grow">
              <UserIcon
                className={classNames('icon icon--tiny self-center mr-4 mt-4', {
                  'icon--user-permanent':
                    AccessGroup === AccessGroupType.LEGAL_OWNER ||
                    AccessGroup === AccessGroupType.ADMINISTRATOR,
                  'icon--user-temp':
                    AccessGroup !== AccessGroupType.LEGAL_OWNER &&
                    AccessGroup !== AccessGroupType.ADMINISTRATOR,
                })}
              />
              <div className="">
                <h1 className="text-2xl">
                  {FirstName} {LastName}
                </h1>
                <div>
                  <div className="gray">
                    {AccessGroup === AccessGroupType.LEGAL_OWNER && t('LEGAL_OWNER')}
                    {AccessGroup === AccessGroupType.ADMINISTRATOR && t('ADMINISTRATOR')}
                    {user.AccessGroup === AccessGroupType.USER && t('USER')}
                  </div>
                  {IsInvite && (
                    <div className="gray">
                      <span>{t('INVITATION_PENDING')}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-4 justify-self-end flex-grow justify-end flex">{PhoneNumber}</div>
            </div>

            {isSelected && (
              <div className="flex self-end justify-end">
                {AccessGroup !== AccessGroupType.LEGAL_OWNER && (
                  <Button
                    size="small"
                    buttonType="primary"
                    onClick={onDelete}
                    className="button button--primary button--small"
                  >
                    {t('Delete')}
                  </Button>
                )}
                <Button size="small" buttonType="secondary" onClick={handleUnselect}>
                  {t('Cancel')}
                </Button>
              </div>
            )}
          </div>
        </ExpandableSectionSummary>
      </ExpandableSection>
    </div>
  )
}

export default AppUserListItem
