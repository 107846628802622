import { isToday } from 'date-fns'
import { FC, useState } from 'react'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../components/Crow/Toast/Toast'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { SubHeadline, Title } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import useI18n from '../../../i18n/useI18n'
import { useGetLockStatus, useLock, useUnlock } from '../../../services/requestHooks/locks'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { Lock } from '../../../types/Lock'
import { useOnMount } from '../../../utils/commonHooks'
import convertAndFormatTime from '../../../utils/ConvertAndFormatTIme'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import PinCodePrompt, { usePinCodePrompt } from '../../AlarmSystems/Panel/PinCodeConfirm'
import LockSettings from './LockSettings'
import LockTileItem from './LocksTileItem'
import { FlexColumn, LockGrid, LockTileContainer, StyledIcon, Unreachable } from './LockStyle'

const CrowLocksPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const locale = useDateFnsLocale()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const { run: sendLock, isLoading: isLoadingLockCommand } = useLock({
    onSuccess: () => {
      setCurrentLock(undefined)
    },
    onFailure: () => {
      addToast({
        title: t('failed_to_lock_door'),
        type: 'error',
      })
      return
    },
  })

  const { run: sendUnlock, isLoading: isLoadingUnlockCommand } = useUnlock({
    onSuccess: () => {
      setCurrentLock(undefined)
    },
    onFailure: () => {
      addToast({
        title: t('failed_to_unlock_door'),
        type: 'error',
      })
      return
    },
  })

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: panelInfoError,
  } = usePanelInfo()

  const {
    run: getLockStatus,
    data: lockStatus,
    isLoading: loadingLockStatus,
    error: lockStatusError,
  } = useGetLockStatus()

  useOnMount(() => {
    if (!loadingLockStatus) {
      getLockStatus(panelId)
    }
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'right' })

  const [lockState, setLockState] = useState<Lock>()
  const [currentLock, setCurrentLock] = useState<string | undefined>()

  const handleLockOrUnlock = (lock: Lock) => async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    event.stopPropagation()

    if (!panelStatus?.IsOnline) {
      addToast({
        title: 'Lost connection',
        content: 'It seems like you have lost connection. Connect to the internet and try again.',
        type: 'warning',
        time: 4000,
      })
      return
    }

    const command = lock.Status === 'lock' ? sendUnlock : sendLock

    const pinCode = await promptForPinCode()
    if (!pinCode) return
    setCurrentLock(lock.Serial)

    command({
      LockSerial: lock.Serial!,
      PanelCode: pinCode,
      PanelId: panelId,
      Platform: 'web',
    })
  }

  const openDrawer = (position: 'top' | 'bottom' | 'left' | 'right', lock: Lock) => () => {
    const findById = lockStatus?.find(({ Id }) => Id === lock.Id)

    setLockState({
      Status: findById?.Status,
      PanelId: findById?.PanelId!,
      Serial: findById?.Serial!,
      Label: findById?.Label!,
      SoundLevel: findById?.SoundLevel!,
      LastChanged: findById?.LastChanged!,
      AutoLockEnabled: findById?.AutoLockEnabled!,
    })

    if (!panelStatus?.IsOnline) {
      return
    }
    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  const isTogglingLock = isLoadingLockCommand || isLoadingUnlockCommand

  const returnUrl = `/systems/${panelId}`

  const { promptState, promptForPinCode } = usePinCodePrompt(panelId)

  const FormatLastChanged = ({
    LastChanged,
    Status,
    color,
  }: {
    LastChanged: string | undefined
    Status: 'lock' | 'unlock'
    color: string
  }) => {
    return (
      <>
        {Status === 'lock' ? (
          <StyledSubHeadline color={color}>
            {LastChanged !== null && (
              <>
                {`${t('locked')} ${
                  !isToday(new Date(LastChanged!)) ? t('SINCE') : ''
                } ${convertAndFormatTime(panelStatus?.TimeZoneName, locale, LastChanged)} ${
                  isToday(new Date(LastChanged!)) ? t('Today') : ''
                }`}
              </>
            )}
          </StyledSubHeadline>
        ) : (
          <>
            {LastChanged !== null && (
              <StyledSubHeadline color={color}>
                {`${t('unlocked')} ${
                  !isToday(new Date(LastChanged!)) ? t('SINCE') : ''
                } ${convertAndFormatTime(panelStatus?.TimeZoneName, locale, LastChanged)} ${
                  isToday(new Date(LastChanged!)) ? t('Today') : ''
                }`}
              </StyledSubHeadline>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
        <FullscreenPage
          isLoading={loadingLockStatus || loadingPanelInfo}
          loaderProps={{
            loadingText: { text: t('Loading') },
            errorHandling: {
              loadingFailed: !!lockStatusError || !!panelInfoError || !!panelStatusError,
              errorHeaderText: t('Locks'),
              errorText: t('Something went wrong. Please try again'),
              returnUrl,
            },
          }}
        >
          <BackButton backUrl={returnUrl} />
          <div className="flex flex-row justify-center items-center">
            <Title className="m-0 mr-auto">{t('Locks')}</Title>
          </div>
          <p className={`mt-4 mb-0 ${colors.black600}`}>{t('LOCKS_DESCRIPTION')}</p>
          <LockGrid className="pl-0 mt-10">
            {lockStatus?.map((lock) => {
              const locked = lock.Status === 'lock'
              const healthy = lock.Status === 'lock' || lock.Status === 'unlock'

              return (
                <LockTileContainer
                  key={lock.Serial}
                  onKeyPress={openDrawer('right', lock)}
                  onClick={openDrawer('right', lock)}
                >
                  <LockTileItem
                    key={lock.Id}
                    text={lock.Label}
                    subText={
                      panelStatus?.IsOnline ? (
                        <FormatLastChanged
                          LastChanged={lock.LastChanged}
                          Status={lock.Status!}
                          color={colors.black700}
                        />
                      ) : (
                        <Unreachable>{t('Unreachable')}</Unreachable>
                      )
                    }
                    checked={locked}
                    healthy={healthy}
                    onClick={handleLockOrUnlock(lock!)}
                    isLoading={(isTogglingLock && currentLock === lock.Serial) || loadingLockStatus}
                    icon={
                      locked ? (
                        <StyledIcon color={colors.black700} name="Locked" />
                      ) : (
                        <StyledIcon color={colors.black700} name="Unlocked" />
                      )
                    }
                    disabled={panelStatus?.IsOnline ? false : true}
                  />
                </LockTileContainer>
              )
            })}
            <S.Drawer
              isOpen={drawerState.isOpen}
              position={drawerState.position}
              handleClose={closeDrawer}
              closeButton={true}
              title={
                <S.Header>
                  <S.CloseButton onClick={closeDrawer}>
                    <StyledIcon color={colors.blue} name="Cancel" />
                  </S.CloseButton>
                  {lockState?.Label}
                </S.Header>
              }
            >
              <FlexColumn>
                <LoadingOverlay
                  isLoading={
                    (isTogglingLock && currentLock === lockState?.Serial) || loadingLockStatus
                  }
                >
                  {lockStatus?.map((lock) => (
                    <div key={lock.Id}>
                      {lock.Serial === lockState?.Serial! ? (
                        <FlexColumn>
                          <Toggle
                            enabled={lock?.Status === 'lock'}
                            onClick={handleLockOrUnlock(lock!)}
                          />
                          <div className="pt-3">
                            <FormatLastChanged
                              LastChanged={lock.LastChanged}
                              Status={lock.Status!}
                              color={colors.blue}
                            />
                          </div>
                        </FlexColumn>
                      ) : null}
                    </div>
                  ))}
                </LoadingOverlay>
                <LockSettings lock={lockState!} />
              </FlexColumn>
              {/*  Note: To ble implemented in future version.
             <StyledCollapsible title={`${lockState?.Label} Doorlock events`}>
              <FormatDayMonth statusTime={panelStatus?.StatusTime} />
            </StyledCollapsible> */}
            </S.Drawer>
          </LockGrid>
        </FullscreenPage>
      </TcOrCrowContainer>
      <Toast />
      <PinCodePrompt promptState={promptState} />
    </>
  )
}

// const StyledCollapsible = styled(Collapsible)`
//   padding-left: 50px;
// `

const StyledSubHeadline = styled(SubHeadline)<{ color?: string }>`
  margin-top: 6px;
  color: ${({ color }) => (color ? color : colors.black700)};
`

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    @media (max-width: 425px) {
      padding-right: 28px;
    }
    padding-right: 72px;
    padding-top: 40px;
    padding-left: 28px;
    height: 500px;
  `,
  Header: styled.div`
    padding-bottom: 50px;
  `,
  CloseButton: styled.button`
    margin-right: 28px;
  `,
  SoundSettings: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
}

export default CrowLocksPage
