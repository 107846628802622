import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import Button from '../../../../components/Crow/Button/Button'
import { CrowRadioButton } from '../../../../components/Crow/CrowRadioButton/CrowRadioButton'
import { ErrorMessage } from '../../../../components/Crow/ErrorMessage/ErrorMessage'
import TextFieldForForm from '../../../../components/Crow/Input/TextFieldForForm'
import { Loader } from '../../../../components/Elements/Loaders'
import useI18n from '../../../../i18n/useI18n'
import { useGetBenefits, useLinkToPartner } from '../../../../services/requestHooks/benefits'
import { Partner } from '../../../../types/Partner'
import { Description, Subtitle, Title } from './StyledComponents'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const AddNewBenefitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;

  & > * {
    width: 45%;
    margin: 5px 5px 5px 0px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;

  & > *:first-child {
    margin-right: 8px;
  }
`

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-top: 15px;
  }
`

const PartnerTextContainer = styled.div`
  & > *:last-child {
    margin-top: 15px;
  }
`

type FormValues = {
  selectedPartner: Partner
  member_number: string
}

const benefitResolver = yup.object({
  // selectedPartner: yup.required(),
  member_number: yup
    .string()
    .required()
    .min(6, 'member_number'),
})

interface AddNewBenefitsProps {
  partners: Partner[]
  handleClose: () => void
}

export const AddNewBenefit = ({ partners, handleClose }: AddNewBenefitsProps) => {
  const [linkRequestFailed, setLinkRequestFailed] = useState(false)
  const { run: getBenefits } = useGetBenefits()
  const { run: linkToPartner, isLoading: loadingLink } = useLinkToPartner({
    onSuccess: () => {
      getBenefits()
    },
    onFailure: () => {
      setLinkRequestFailed(true)
    },
  })

  const { t } = useI18n()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: { member_number: '', selectedPartner: {} },
    resolver: yupResolver(benefitResolver),
  })

  const onSubmit = (values: FormValues) => {
    linkToPartner({ PartnerId: values.selectedPartner.Id, ExternalId: values.member_number })
  }

  return (
    <Container>
      <form className="slide-from-right" onSubmit={handleSubmit(onSubmit)}>
        <PartnerTextContainer>
          <Title>{t('partner')}</Title>
          <Description>{t('choose_partner')}</Description>
        </PartnerTextContainer>
        <AddNewBenefitContainer>
          {partners.map((partner) => (
            <CrowRadioButton
              {...register('selectedPartner')}
              title={partner.Name}
              key={partner.Id}
              value={partner.Name}
              name="benefitsRadio"
              id={partner.Id}
              onClick={() => setValue('selectedPartner', partner)}
            />
          ))}
        </AddNewBenefitContainer>
        <TextFieldContainer>
          <Title>{t('customer_partner_id')}</Title>
          <Description>{t('customer_identification_explanation')}</Description>
          <Subtitle>{t('Customer Number')}</Subtitle>
          <TextFieldForForm
            {...register('member_number')}
            className={'mt-4'}
            error={errors.member_number?.message}
          />
        </TextFieldContainer>

        <ButtonContainer>
          <Button level="primary" type="submit" disabled={!isValid}>
            {t('Add')}
          </Button>
          <Button type="button" level="secondary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </ButtonContainer>
        {linkRequestFailed && <ErrorMessage text={t('error_connect_benefits')} />}
        {loadingLink && <Loader />}
      </form>
    </Container>
  )
}
