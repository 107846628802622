import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import _ from 'lodash'
import { PanelEvent } from '../types/PanelEvent'

type HistoryState = Record<
  string,
  {
    panelEvents: [string, PanelEvent[]][]
  }
>

const initialState: HistoryState = {}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    GET_HISTORY(
      state,
      { payload: { panelId, data } }: PayloadAction<{ panelId: string; data: PanelEvent[] }>,
    ) {
      const groupedEvents = Object.entries(
        _.groupBy(data, (day) => format(new Date(day.Time), 'yyyy-MM-dd')),
      )
      state[panelId] = { panelEvents: groupedEvents }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { GET_HISTORY } = historySlice.actions

export const historyReducer = historySlice.reducer
