import { getImpersonationId, getToken } from './authentication/AuthContext'

const IMPERSONATION_HEADER = 'x-impersonating-userid'

export const getHeaders = async () => {
  const token = await getToken()
  const headers: Record<string, string> = {
    Authorization: token,
  }
  const impersonationId = getImpersonationId()
  if (!!impersonationId) {
    headers[IMPERSONATION_HEADER] = impersonationId
  }
  return {
    headers,
  }
}
