import {
  RESEND_EMAIL_VERIFICATION,
  GET_USERS_SUCCESS,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_PRIVILEGES_SUCCESS,
  CHANGE_USER_PIN_CODE_SUCCESS,
  CREATE_NEW_USER_FOR_PANEL_SUCCESS,
  DELETE_USER_FOR_PANEL_SUCCESS,
  GET_PANEL_PERMISSIONS_SUCCESS,
} from '../../reducers/usersSlice'
import { RootState } from '../../store'
import {
  resendEmailVerification,
  getPermanentUsers,
  updateUserInformation,
  updateUserPrivileges,
  createNewUserForPanel,
  deleteUserFromPanel,
  getMyPanels,
  changeUserPinCode,
} from '../apiUsers'
import { createUseRequest } from './createUseRequest'

export const useResendEmailVerification = createUseRequest({
  successActionCreator: RESEND_EMAIL_VERIFICATION,
  apiCall: resendEmailVerification,
})

export const useGetUsers = createUseRequest({
  successActionCreator: GET_USERS_SUCCESS,
  apiCall: getPermanentUsers,
  selector: (state: RootState, panelId: string) => state.users[panelId]?.users,
  requirePanelId: true,
})

export const useUpdateUserInformation = createUseRequest({
  successActionCreator: UPDATE_USER_INFORMATION_SUCCESS,
  apiCall: updateUserInformation,
  requirePanelId: true,
})

export const useSetUserPrivileges = createUseRequest({
  successActionCreator: UPDATE_USER_PRIVILEGES_SUCCESS,
  apiCall: updateUserPrivileges,
  requirePanelId: true,
})

export const useChangeUserPinCode = createUseRequest({
  successActionCreator: CHANGE_USER_PIN_CODE_SUCCESS,
  apiCall: changeUserPinCode,
})

export const useCreateNewUserForPanel = createUseRequest({
  successActionCreator: CREATE_NEW_USER_FOR_PANEL_SUCCESS,
  apiCall: createNewUserForPanel,
  requirePanelId: true,
})

export const useDeleteUserFromPanel = createUseRequest({
  successActionCreator: DELETE_USER_FOR_PANEL_SUCCESS,
  apiCall: deleteUserFromPanel,
  requirePanelId: true,
})

export const useGetPanelPermissions = createUseRequest({
  successActionCreator: GET_PANEL_PERMISSIONS_SUCCESS,
  apiCall: getMyPanels,
  selector: (state: RootState, panelId: string) => state.users[panelId]?.info,
  requirePanelId: true,
})
