import React, { FC, useEffect, useState } from 'react'
import { TinyArmed, TinyHomeMode, TinyUnarmed } from '../../../assets/icons/crow'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import PinCodePrompt, { usePinCodePrompt } from '../../../pages/AlarmSystems/Panel/PinCodeConfirm'
import {
  useArmCommand,
  useDisarmCommand,
  usePartialArmCommand,
} from '../../../services/requestHooks/panelInfo'
import { PanelStatus } from '../../../types/PanelModel'
import FailedRequest from '../../../pages/onboardingWizard/components/FailedRequest'
import ArmSlider, { ArmSliderOption } from './ArmSlider'
import LoadingOverlay from '../../LoadingOverlay'
import useI18n from '../../../i18n/useI18n'
import { LoadingText } from '../../Elements/Loaders'
import { colors } from '../Style/theme'

type Props = {
  canPartialArm: boolean | undefined
  quickArmEnabled: boolean | undefined
  panelStatus: PanelStatus | undefined
  hasOpenDoorsOrWindows: boolean
}

const ArmPanelSlider: FC<Props> = ({
  quickArmEnabled = false,
  canPartialArm = false,
  panelStatus = PanelStatus.NOT_AVAILABLE,
  hasOpenDoorsOrWindows,
}) => {
  const panelId = usePanelId()
  const [loadingText, setLoadingText] = useState<LoadingText>()
  const { t } = useI18n()
  const { run: armPanel, error: armError, isLoading: isLoadingArm } = useArmCommand()
  const { run: disarmPanel, error: disarmError, isLoading: isLoadingDisarm } = useDisarmCommand()
  const {
    run: partialArmPanel,
    error: partialArmError,
    isLoading: isLoadingPartialArm,
  } = usePartialArmCommand()

  const { promptState: pincodePromptState, promptForPinCode } = usePinCodePrompt(panelId)

  const arm = async () => {
    let PanelCode: string | undefined
    if (!quickArmEnabled) {
      PanelCode = await promptForPinCode()
      if (!PanelCode) return
    }
    armPanel({ PanelCode, PanelId: panelId })
  }

  const partialArm = async () => {
    let PanelCode: string | undefined
    if (!quickArmEnabled) {
      PanelCode = await promptForPinCode()
      if (!PanelCode) return
    }
    partialArmPanel({ PanelCode, PanelId: panelId })
  }
  const disarm = async () => {
    const PanelCode = await promptForPinCode()
    if (!PanelCode) return
    disarmPanel({ PanelCode, PanelId: panelId })
  }

  const error = armError || disarmError || partialArmError
  const isChangingStatus = isLoadingArm || isLoadingDisarm || isLoadingPartialArm

  useEffect(() => {
    if (hasOpenDoorsOrWindows && isLoadingArm) {
      setLoadingText({ text: t('ARMING_WITH_OPEN_ENTRANCES'), color: colors.lightRed })
    } else if (hasOpenDoorsOrWindows && isLoadingPartialArm) {
      setLoadingText({ text: t('PARTIALLY_ARMING_WITH_OPEN_ENTRANCES'), color: colors.lightRed })
    } else {
      setLoadingText({})
    }
  }, [isLoadingArm, isLoadingPartialArm, hasOpenDoorsOrWindows, t])

  return (
    <>
      <LoadingOverlay isLoading={isChangingStatus} className="mt-4" loadingText={loadingText}>
        <ArmSlider canPartialArm={canPartialArm}>
          <ArmSliderOption
            onClick={arm}
            text="Fully armed"
            Icon={TinyArmed}
            active={panelStatus === PanelStatus.FULLY_ARMED}
            disabled={panelStatus !== PanelStatus.TURNED_OFF}
          />
          <ArmSliderOption
            onClick={disarm}
            text="disarmed"
            Icon={TinyUnarmed}
            active={panelStatus === PanelStatus.TURNED_OFF}
          />
          {canPartialArm && (
            <ArmSliderOption
              onClick={partialArm}
              text="Home mode"
              Icon={TinyHomeMode}
              active={panelStatus === PanelStatus.HOME_MODE}
              disabled={panelStatus !== PanelStatus.TURNED_OFF}
            />
          )}
        </ArmSlider>
      </LoadingOverlay>
      {!!error && <FailedRequest text={error} />}
      <PinCodePrompt promptState={pincodePromptState} />
    </>
  )
}

export default ArmPanelSlider
