import { FC, Fragment, useState } from 'react'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { SubTitle, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { usePostTemperatures } from '../../../services/requestHooks/temperatureList'
import { Section, Temperature } from '../../../types/Temperature'
import { useOnMount } from '../../../utils/commonHooks'
import FloorName from '../../../utils/places/FloorName'
import roomIcon from '../../../utils/places/RoomIcon'
import RoomName from '../../../utils/places/RoomName'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import GeneralSettings from './GeneralSettings'
import TemperatureDetails from './TemperatureDetails'
import TemperatureTileFooter from './TemperatureTileFooter'

const CrowTemperaturePage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'top' })
  const [temperatureState, setTemperatureState] = useState<Section>()

  const {
    data: panelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    run: getTemperatures,
    isLoading: loadingTemperatures,
    error: temperatureError,
    data: temperatures,
  } = usePostTemperatures()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!temperatureError || !!panelStatusError || !!loadInfoError

  const returnUrl = `/systems/${panelId}`

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingTemperatures) {
      getTemperatures(panelId)
    }
  })

  const openDrawer = (
    position: 'top' | 'bottom' | 'left' | 'right',
    floors: Section,
    roomName: string,
    roomKey: string,
    roomDevices: Temperature[],
    averageTemp?: number,
  ) => () => {
    setDrawerState({
      position,
      isOpen: true,
    })
    setTemperatureState({
      Name: floors?.Name!,
      Key: floors?.Key!,
      Places: [
        { Name: roomName, Key: roomKey, Components: roomDevices, AverageTemperature: averageTemp! },
      ],
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <FullscreenPage
        isLoading={loadingTemperatures || loadingPanelStatus}
        loaderProps={{
          loadingText: t('Loading'),
          errorHandling: {
            loadingFailed: !loadingTemperatures && hasError && !panelStatus?.IsOnline,
            errorHeaderText: t('Temperature'),
            errorText: !panelStatus?.IsOnline
              ? t('You can not get tempratures while you are offline')
              : t('Something went wrong. Please try again'),
            returnUrl,
          },
        }}
      >
        <BackButton backUrl={returnUrl} />
        <div className="flex flex-row justify-center items-center">
          <Title>{t('Temperature')}</Title>
          {/*Note: To be implemented in future version */}
          <div className="ml-auto invisible">
            <GeneralSettings />
          </div>
        </div>
        <p className="pt-4 mb-5">{t('TEMPERATURES_DESCRIPTION')}</p>
        <>
          {temperatures?.Sections.map((floor) => (
            <div key={floor.Key} className="pt-6">
              <StyledSubtitle>
                <FloorName floorName={floor.Key} />
              </StyledSubtitle>
              <RoomFlexContainer>
                {floor.Places.map((room, index) => (
                  /* Use room.Index as key instead when data is correct*/
                  <StyledTile
                    key={index}
                    borderRadius="8px"
                    onClick={openDrawer('right', floor, room.Name, room.Key, room.Components)}
                    footerPadding="2px 12px 11px 12px"
                    useDynamicHeight
                    footer={
                      <>
                        <FooterDevices>{`${t('Sensors')} (${
                          room.Components.length
                        })`}</FooterDevices>
                        <TemperatureTileFooter props={room.Components}></TemperatureTileFooter>
                      </>
                    }
                  >
                    <div role="list" aria-label="tile-content">
                      <AverageTemperature>
                        {Math.round(room.AverageTemperature)}°
                      </AverageTemperature>
                      <RoomNameTitle>
                        <RoomName roomName={room.Key} />
                      </RoomNameTitle>
                    </div>
                  </StyledTile>
                ))}
              </RoomFlexContainer>
            </div>
          ))}

          <S.Drawer
            isOpen={drawerState.isOpen}
            position={drawerState.position}
            handleClose={closeDrawer}
            closeButton={true}
            title={
              <S.Header>
                <S.CloseButton onClick={closeDrawer}>
                  <StyledCancelIcon color={colors.blue} name="Cancel" />
                </S.CloseButton>
                {temperatureState?.Places.map((room) => (
                  <Fragment key={room.Name}>
                    <span className="mr-auto">
                      <RoomName roomName={room.Key} /> (
                      {<FloorName floorName={temperatureState.Key} />})
                    </span>
                    <StyledRoomIcon name={roomIcon(room.Key)} />
                  </Fragment>
                ))}
              </S.Header>
            }
          >
            <TemperatureDetails floors={temperatureState!} />
          </S.Drawer>
        </>
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  padding-bottom: 10px;
  padding-left: 10px;
`

const RoomFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`

const StyledTile = styled(Tile)`
  padding-left: 12px;
  padding-right: 0px;
`

const AverageTemperature = styled.p`
  color: ${colors.black700};
  font-size: 64px;
  line-height: 75px;
  font-weight: lighter;
  margin: 0;
  height: 60px;
`

const RoomNameTitle = styled(Title)`
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  padding-top: 6px;
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const FooterDevices = styled.span`
  font-size: 12px;
  color: ${colors.black900};
  padding-bottom: 4px;
`

const S = {
  Drawer: styled(Drawer)`
    background-color: ${colors.white};
    padding-left: 30px;
    padding-top: 40px;
    padding-right: 72px;
  `,
  Header: styled.div`
    padding-bottom: 32px;
    display: flex;
    align-items: center;
  `,
  CloseButton: styled.button`
    margin-right: 34px;
    color: ${colors.blue};
  `,
}

const StyledCancelIcon = styled(Icon)`
  font-size: 24px;
`

const StyledRoomIcon = styled(Icon)`
  font-size: 43px;
`

export default CrowTemperaturePage
