function SvgBoilerRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 13C19.4477 13 19 13.4477 19 14V16H21V14C21 13.4477 20.5523 13 20 13Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 20C22.7614 20 25 17.7614 25 15C25 12.2386 22.7614 10 20 10C17.2386 10 15 12.2386 15 15C15 17.7614 17.2386 20 20 20ZM20 18C21.6569 18 23 16.6569 23 15C23 13.3431 21.6569 12 20 12C18.3431 12 17 13.3431 17 15C17 16.6569 18.3431 18 20 18Z"
        fill="#333131"
      />
      <path
        d="M22 24C22 25.1046 21.1046 26 20 26C18.8954 26 18 25.1046 18 24C18 22.8954 18.8954 22 20 22C21.1046 22 22 22.8954 22 24Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4958 33.1182C30.7414 32.8782 31 32.5017 31 32V4C31 3.10097 30.397 2.44523 29.7927 2.0166C29.1624 1.56956 28.3152 1.2079 27.3584 0.920853C25.432 0.342941 22.8307 0 20 0C17.1693 0 14.568 0.342941 12.6416 0.920853C11.6848 1.2079 10.8376 1.56956 10.2073 2.0166C9.60301 2.44523 9 3.10097 9 4V32C9 32.5017 9.25858 32.8782 9.50419 33.1182C9.74814 33.3566 10.0543 33.5387 10.3584 33.6825C10.9717 33.9725 11.7952 34.2073 12.7328 34.3948C13.4088 34.53 14.1712 34.6458 15 34.7389V36.5C15 37.6046 14.1046 38.5 13 38.5H1C0.447715 38.5 0 38.9477 0 39.5C0 40.0523 0.447715 40.5 1 40.5H13C15.2091 40.5 17 38.7091 17 36.5V34.9104C17.9524 34.9688 18.9599 35 20 35C21.0401 35 22.0476 34.9688 23 34.9104V36.5C23 38.7091 24.7909 40.5 27 40.5H39C39.5523 40.5 40 40.0523 40 39.5C40 38.9477 39.5523 38.5 39 38.5H27C25.8954 38.5 25 37.6046 25 36.5V34.7389C25.8288 34.6458 26.5912 34.53 27.2672 34.3948C28.2048 34.2073 29.0283 33.9725 29.6416 33.6825C29.9457 33.5387 30.2519 33.3566 30.4958 33.1182ZM28.6356 3.64792C28.8982 3.83419 28.9737 3.95585 28.9942 4H11.0058C11.0263 3.95585 11.1018 3.83419 11.3644 3.64792C11.7462 3.37714 12.3635 3.09235 13.2163 2.83651C14.9092 2.32863 17.3079 2 20 2C22.6921 2 25.0908 2.32863 26.7837 2.83651C27.6365 3.09235 28.2538 3.37714 28.6356 3.64792ZM11.5123 32C11.906 32.1485 12.4453 32.2977 13.125 32.4336C14.8527 32.7792 17.2839 33 20 33C22.7161 33 25.1473 32.7792 26.875 32.4336C27.5547 32.2977 28.094 32.1485 28.4877 32H11.5123ZM11 6V30H29V6H11Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgBoilerRoom
