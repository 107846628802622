import React, { ChangeEventHandler, FormEventHandler, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/Button'
import { Loader } from '../../../../components/Elements/Loaders'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo, useSetPanelSettings } from '../../../../services/requestHooks/panelInfo'
import { RootState } from '../../../../store'
import { Capability } from '../../../../types/PanelModel'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import SettingsSubPage from '../components/SettingsSubPage'

const LoadingWrapper = () => {
  const panelId = usePanelId()
  const { data, run: getPanelInfo, isLoading, error: panelInfoError } = usePanelInfo()

  useOnMount(() => {
    if (!isLoading && panelId !== data?.PanelId) {
      getPanelInfo(panelId)
    }
  })

  return (
    <SettingsSubPage
      isLoading={isLoading}
      loaderProps={{
        loadingText: { text: 'Loading' },
        errorHandling: {
          loadingFailed: !!panelInfoError,
          errorText: 'Something went wrong. Please try again',
          returnUrl: `/systems/${panelId}/settings`,
          errorHeaderText: 'System settings',
        },
      }}
    >
      {data && (
        <SystemSettingsScreen
          displayName={data.DisplayName}
          quickArmEnabled={data.QuickArmEnabled}
        />
      )}
    </SettingsSubPage>
  )
}

interface Props {
  quickArmEnabled: boolean
  displayName: string
}

const SystemSettingsScreen = ({
  displayName,
  quickArmEnabled: quickArmCurrentlyEnabled,
}: Props) => {
  const { t } = useI18n()
  const history = useHistory()
  const panelId = usePanelId()
  const panel = useSelector((state: RootState) => state.panel[panelId]?.Info)
  const [name, setName] = useState(displayName)
  const [quickArmEnabled, setQuickArmEnabled] = useState(quickArmCurrentlyEnabled)
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [didChangeQuickarm, setDidChangeQuickarm] = useState(false)
  // const [changeNameError, setChangeNameError] = useState(false)

  const { run: save, isLoading, error: panelSettingsError } = useSetPanelSettings({
    onSuccess: () => {
      history.push(`/systems/${panelId}/settings`)
    },
  })
  const { promptState, promptForPassword } = usePasswordPrompt()

  const handleTxtChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (displayName !== e.target.value) {
      setSubmitEnabled(true)
    } else {
      setSubmitEnabled(false)
    }
    return setName(e.target.value)
  }
  const handleToggleQuickarm = () => setQuickArmEnabled(!quickArmEnabled)
  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    const Password = await promptForPassword()
    if (!Password) return
    save({
      DisplayName: name,
      QuickArm: quickArmEnabled,
      Password,
      PanelId: panelId,
    })
  }

  const hasNoLocks = !panel?.Locks || panel?.Locks.length === 0

  const isDomoPanel = panel && panel.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN)

  const enableQuickarm = (hasNoLocks && isDomoPanel) || !isDomoPanel

  return (
    <form onSubmit={handleSubmit}>
      <h1>{t('System settings')}</h1>
      <label className="form mt-6">
        {t('Name')}
        <input className="form__input" maxLength={20} onChange={handleTxtChange} value={name} />
      </label>
      {enableQuickarm && (
        <>
          <div className="mt-6">{t('Arm options')}</div>
          <label className="form checkbox mb-6">
            <input
              id="quickarm"
              type="checkbox"
              className="form__checkbox"
              checked={quickArmEnabled}
              onChange={() => {
                handleToggleQuickarm()
                setDidChangeQuickarm(true)
              }}
            />
            <i className="checkbox__indicator box-content" />
            {t('Arm system without code on My page and app.')}
          </label>
        </>
      )}

      {panelSettingsError && (
        <FailedRequest text={t('Something went wrong. Please try again later.')} />
      )}
      <Button
        disabled={name.length <= 0 || isLoading || (!submitEnabled && !didChangeQuickarm)}
        buttonType="primary"
      >
        {t('Save')}
      </Button>
      {isLoading && <Loader as="span" />}
      <PasswordPrompt promptState={promptState} />
    </form>
  )
}
export default LoadingWrapper
