import * as React from 'react'

function SvgWifiLow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={31} height={31} {...props}>
      <g fill="none">
        <path
          fill="#FFF"
          d="M15.5 30.999c-8.56 0-15.499-6.94-15.499-15.5C.001 6.94 6.941 0 15.501 0 24.06 0 31 6.94 31 15.5c-.01 8.556-6.944 15.49-15.5 15.499z"
        />
        <path
          fill="#D5D5D5"
          d="M6.875 13.145a.74.74 0 01.217-.523c4.647-4.636 12.17-4.636 16.816 0a.74.74 0 01-1.046 1.046c-4.069-4.058-10.655-4.059-14.724 0a.74.74 0 01-1.263-.523z"
        />
        <path
          fill="#D5D5D5"
          d="M9.338 15.605a.74.74 0 01.217-.523 8.416 8.416 0 0111.89 0 .74.74 0 11-1.046 1.046 6.937 6.937 0 00-9.799 0 .74.74 0 01-1.262-.523z"
        />
        <path
          fill="#58595B"
          d="M18.695 18.66a.665.665 0 01-.473-.195 3.854 3.854 0 00-5.444 0 .669.669 0 01-.946-.945 5.193 5.193 0 017.336 0 .668.668 0 01-.473 1.14z"
        />
        <circle cx={15.5} cy={20.503} r={1.351} fill="#58595B" />
      </g>
    </svg>
  )
}

export default SvgWifiLow
