function SvgEntrance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 15C12 14.4477 11.5523 14 11 14C10.4477 14 10 14.4477 10 15L10 18C10 18.5523 10.4477 19 11 19C11.5523 19 12 18.5523 12 18L12 15Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 24H40C41.1046 24 42 24.8954 42 26V35C42 35.5523 41.5523 36 41 36C40.4477 36 40 35.5523 40 35V33H2L2 35C2 35.5523 1.55229 36 1 36C0.447716 36 0 35.5523 0 35V2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V24ZM2 2H14V31H2V2ZM16 31V26H27V31H16ZM29 31V26H40V31H29Z"
        fill="#666363"
      />
      <path
        d="M25.2929 9.29289C24.9024 9.68342 24.9024 10.3166 25.2929 10.7071C25.6834 11.0976 26.3166 11.0976 26.7071 10.7071L30.7071 6.70711C31.0977 6.31658 31.0977 5.68342 30.7071 5.29289C30.3166 4.90237 29.6834 4.90237 29.2929 5.29289L25.2929 9.29289Z"
        fill="#666363"
      />
      <path
        d="M25.2929 13.2929L29.2929 9.29289C29.6834 8.90237 30.3166 8.90237 30.7071 9.29289C31.0977 9.68342 31.0977 10.3166 30.7071 10.7071L26.7071 14.7071C26.3166 15.0976 25.6834 15.0976 25.2929 14.7071C24.9024 14.3166 24.9024 13.6834 25.2929 13.2929Z"
        fill="#666363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5C22 3.34315 23.3431 2 25 2H31C32.6569 2 34 3.34315 34 5V17C34 18.6569 32.6569 20 31 20H25C23.3431 20 22 18.6569 22 17V5ZM25 4H31C31.5523 4 32 4.44772 32 5V17C32 17.5523 31.5523 18 31 18H25C24.4477 18 24 17.5523 24 17V5C24 4.44772 24.4477 4 25 4Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgEntrance
