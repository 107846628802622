import React, { useState } from 'react'
import classNames from 'classnames'
import NavBarPage from '../../components/pages/NavBarPage'
import PartnerListTab from './Benefits/PartnersList'
import EditAccountForm from './EditAccountForm'
import SubFooter from '../../components/system/SubFooter'
import { useUserInfo } from '../../services/requestHooks/user'
import useI18n from '../../i18n/useI18n'
import { UserFeature } from '../../types/UserDetails'

const AccountPage = () => {
  const { t } = useI18n()
  const User = useUserInfo()
  const [tabSelection, setTabSelection] = useState<'AccountDetails' | 'Benefits'>('AccountDetails')

  const { FirstName, LastName, CustomerNo, NationHasCRM, Features } = User

  return (
    <NavBarPage>
      <header className="header center">
        <h1>{`${FirstName} - ${LastName}`}</h1>
        {NationHasCRM && <p className="small">{`${t('Customer Number')}: ${CustomerNo}`}</p>}
      </header>
      <div className="grid mx-8 lg:mx-0">
        <div className="grid__two-third">
          <button
            className={classNames('tab-group__tab', {
              'tab-group__tab--active': tabSelection === 'AccountDetails',
            })}
            onClick={() => setTabSelection('AccountDetails')}
          >
            {t('Account details')}
          </button>
          {Features.includes(UserFeature.BENEFITS) && (
            <button
              onClick={() => setTabSelection('Benefits')}
              className={classNames('tab-group__tab', {
                'tab-group__tab--active': tabSelection === 'Benefits',
              })}
            >
              {t('Benefits')}
            </button>
          )}
        </div>
      </div>
      <div className="grid background-gray">
        <div className="grid__two-third flex-col px-8">
          {tabSelection === 'Benefits' && <PartnerListTab />}
          {tabSelection === 'AccountDetails' && <EditAccountForm />}
        </div>
      </div>
      <SubFooter />
    </NavBarPage>
  )
}
// eslint-disable-next-line import/no-unused-modules
export default AccountPage
