import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CountryCode } from 'libphonenumber-js'
import { Controller } from 'react-hook-form'

import useI18n from '../../../i18n/useI18n'
import Tooltip from '../../Crow/Tooltip'
import Toggle from '../../Crow/Toogle/ToggleForForm'
import { GroupTitle } from './Shared'
import TextField from '../../Crow/Input/TextFieldForForm'
import NumberField from '../../Crow/Input/NumberField'
import { colors } from '../../Crow/Style/theme'

interface UserPrivilegesProps {
  initiallyHasSystemAccess: boolean
  isUnderage: boolean
  hasSystemAccess: boolean
  canChangePanelPinCode: boolean
  panelIsArmed: boolean
  register: any
  shouldBeDisabled: any
  panelCodeLength: number
  initiallyHasAppAccess: boolean
  hasAppAccess: boolean
  control: any
  countryCode: CountryCode
  errors: any
}

const UserPrivileges = ({
  initiallyHasSystemAccess,
  isUnderage,
  hasSystemAccess,
  canChangePanelPinCode,
  panelIsArmed,
  register,
  shouldBeDisabled,
  panelCodeLength,
  initiallyHasAppAccess,
  hasAppAccess,
  control,
  countryCode,
  errors,
}: UserPrivilegesProps) => {
  const [isChangingPINCode, setIsChangingPINCode] = useState<boolean>(false)

  const { t } = useI18n()

  useEffect(() => {
    isChangingPINCode && document.getElementById('change-user-pin')?.focus()
  }, [isChangingPINCode])

  return (
    <Wrapper>
      <GroupTitle>{t('user_privileges')}</GroupTitle>
      <Privileges>
        <Tooltip text={'Disarm your system to edit panel access'} active={panelIsArmed}>
          <Toggle
            key="system"
            text={t('panel_access')}
            subText={t('pin_code_to_panel')}
            icon="System"
            disabled={shouldBeDisabled('system')}
            {...register('system')}
          />
        </Tooltip>
        {initiallyHasSystemAccess && hasSystemAccess && canChangePanelPinCode && (
          <Tooltip text={'Disarm your system to edit PIN'} active={panelIsArmed}>
            <PanelCodeWrapper>
              <TextField
                key="change-user-pin"
                id="change-user-pin"
                maxLength={panelCodeLength}
                prefix={'PIN'}
                placeholder={'•'.repeat(panelCodeLength)}
                disabled={!isChangingPINCode}
                {...register('pin')}
                error={errors.pin?.message}
              />
              {!panelIsArmed && (
                <ChangePINButtonWrapper>
                  <ChangePINButton
                    type="button"
                    style={isChangingPINCode ? { color: colors.black600 } : {}}
                    onClick={() => setIsChangingPINCode(!isChangingPINCode)}
                  >
                    {t('change_pin_code')}
                  </ChangePINButton>
                </ChangePINButtonWrapper>
              )}
            </PanelCodeWrapper>
          </Tooltip>
        )}
        {!initiallyHasSystemAccess && hasSystemAccess && (
          <TextField
            key="add-user-pin"
            id="add-user-pin"
            maxLength={panelCodeLength}
            placeholder={t('code_digits', { panelCodeLength })}
            {...register('pin')}
            error={errors.pin?.message}
          />
        )}
        <>
          <Toggle
            key="app"
            text={t('App')}
            subText={t('allow_use_of_mobile_app')}
            icon="App"
            disabled={shouldBeDisabled('app')}
            {...register('app')}
          />
          {!initiallyHasAppAccess && hasAppAccess && (
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <NumberField
                  {...field}
                  value={field.value || ''}
                  defaultCountryCode={countryCode}
                  error={errors.phoneNumber?.message}
                />
              )}
            />
          )}
        </>
        <>
          <Toggle
            key="admin"
            text={t('administration_rights')}
            subText={t('can_manage_users')}
            icon="Star"
            disabled={shouldBeDisabled('admin')}
            {...register('admin')}
          />
          {hasSystemAccess && isUnderage && <UnderAgeInfo>{t('info_admin_underage')}</UnderAgeInfo>}
        </>
      </Privileges>
    </Wrapper>
  )
}

const Wrapper = styled.section``

const Privileges = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const PanelCodeWrapper = styled.div``

const ChangePINButtonWrapper = styled.div`
  margin: 9px 0 13px 0;
  display: flex;
  justify-content: end;
  padding-right: 15px;
`

const ChangePINButton = styled.button`
  width: fit-content;
  font-family: 'Metric';
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: ${colors.blue};

  &:hover {
    color: ${colors.lightBlue};
  }
`

const UnderAgeInfo = styled.p`
  padding: 0 16px;
  font-size: 16px;
  color: ${colors.black800};
`

export default UserPrivileges
