import React from 'react'
import useI18n from '../../i18n/useI18n'
import { getCustomerSupportUrl } from '../../i18n/supportUrls'
import { useUserInfo } from '../../services/requestHooks/user'

const SubFooter = () => {
  const { t } = useI18n()
  const { NationId } = useUserInfo()

  return (
    <div className="grid">
      <div className="grid__half center">
        <br />
        <h2>{t('Can we help you')}</h2>
        <p>{t('We are allways available to help you')}</p>
        <a
          className="button button--secondary button--small"
          href={getCustomerSupportUrl(NationId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('Go to customer service')}
        </a>
      </div>
    </div>
  )
}

export default SubFooter
