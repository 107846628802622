import React from 'react'

const LeftSecondaryL = () => {
  return (
    <svg width="21" height="48" viewBox="0 0 21 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2806 2.41399e-05L20.2855 0L21 1.20401e-05V2.00001H20.288C18.0346 2.01126 15.765 2.16758 13.4967 2.38355L13.4962 2.38359C10.4153 2.67543 8.08373 3.62099 6.39656 5.16588C4.71105 6.70925 3.57238 8.93897 3.03904 11.9809L3.03903 11.9809C2.33722 15.9834 1.98817 19.9919 1.98817 24C1.98817 28.0081 2.33722 32.0166 3.03903 36.0191L2.06077 36.1927L3.03904 36.0191C3.57238 39.0611 4.71105 41.2908 6.39656 42.8341C8.08373 44.379 10.4153 45.3246 13.4962 45.6164L13.4967 45.6165C15.7651 45.8324 18.0347 45.9888 20.2881 46H21V48H20.2855L20.2806 48C17.9446 47.9884 15.6098 47.8266 13.3093 47.6076L13.403 46.612L13.3098 47.6076C13.3096 47.6076 13.3095 47.6076 13.3093 47.6076C9.9235 47.2868 7.14508 46.224 5.05824 44.3132C2.96966 42.4007 1.67031 39.7271 1.08109 36.3665L2.06006 36.1928L1.08109 36.3665C0.359329 32.2502 0 28.1255 0 24C0 19.8745 0.359329 15.7498 1.08109 11.6335L2.06006 11.8072L1.08109 11.6335C1.67031 8.27291 2.96966 5.5993 5.05824 3.68685C7.14508 1.776 9.9235 0.713245 13.3093 0.392446L13.403 1.388L13.3098 0.392402C15.6101 0.173403 17.9448 0.0115727 20.2806 2.41399e-05Z" />
    </svg>
  )
}

export default LeftSecondaryL
