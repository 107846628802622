import React from 'react'

const RightSecondaryXL = () => {
  return (
    <svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5385 15.3835C23.8091 11.2482 22.2646 8.2811 20.029 6.24613C17.7901 4.20813 14.6637 2.92823 10.4452 2.531C7.61906 2.26352 4.79873 2.06272 2 2.01173H0V0H1C1.33323 0.00163779 1.66658 0.00548802 2 0.0114119C4.88055 0.062591 7.76663 0.268548 10.6327 0.539806C19.6837 1.39209 24.9369 6.1282 26.5081 15.0361C27.5043 20.684 28 26.3418 28 32C28 37.6582 27.5043 43.316 26.5081 48.9639C24.9369 57.8718 19.6837 62.6079 10.6327 63.4602C7.76663 63.7314 4.88055 63.9374 2 63.9886C1.66658 63.9945 1.33323 63.9984 1 64H0V61.9883H2C4.79873 61.9373 7.61811 61.7366 10.4442 61.4691C14.6627 61.0719 17.7901 59.7919 20.029 57.7539C22.2646 55.7189 23.8091 52.7518 24.5385 48.6165C25.5146 43.0824 26 37.5408 26 32C26 26.4592 25.5146 20.9176 24.5385 15.3835Z"
        fill="#66A8D8"
      />
    </svg>
  )
}

export default RightSecondaryXL
