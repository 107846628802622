function SvgGuestBedRoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C1.55228 0 2 0.447715 2 1V4H8C10.419 4 12.4367 5.71776 12.9 8H33C35.7614 8 38 10.2386 38 13V22C38 22.5523 37.5523 23 37 23C36.4477 23 36 22.5523 36 22V20H2V22C2 22.5523 1.55228 23 1 23C0.447715 23 0 22.5523 0 22V1C0 0.447715 0.447715 0 1 0ZM36 18V13C36 11.3431 34.6569 10 33 10H2V18H36ZM2 8V6H8C9.30622 6 10.4175 6.83481 10.8293 8H2Z"
        fill="#666363"
      />
    </svg>
  )
}

export default SvgGuestBedRoom
