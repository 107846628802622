/* eslint-disable import/no-unused-modules */
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'

export interface TitleProps {
  large?: boolean
}

export const Title = styled.h1<TitleProps>`
  font-family: 'Metric';
  font-weight: 600;
  font-size: ${({ large }) => (large ? '32px' : '28px')};
  line-height: ${({ large }) => (large ? '40px' : '32px')};
  letter-spacing: 0;
`

export interface SubTitleProps {
  light?: boolean
}

export const SubTitle = styled.h2<SubTitleProps>`
  font-family: 'Metric';
  font-weight: ${({ light }) => (light ? 400 : 600)};
  line-height: 24px;
  font-size: 20px;
  margin: 0.5rem 0;
  color: ${colors.black800};
`

export interface ParagraphProps {
  large?: boolean
  light?: boolean
  grey?: boolean
}

export const Paragraph = styled.p<ParagraphProps>`
  font-family: 'Metric';
  font-weight: ${({ light }) => (light ? 400 : 500)};
  font-size: ${({ large }) => (large ? '20px' : '16px')};
  line-height: ${({ large }) => (large ? '24px' : '22px')};
  ${({ grey }) =>
    grey &&
    css`
      color: ${colors.black600};
    `}
  margin: 0;
`

export const SubHeadline = styled.p`
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
`

export interface HrProps {
  width?: number | string
  color?: string
}

export const Hr = styled.hr<HrProps>`
  width: ${({ width }) => width};
  border-color: ${({ color }) => (color ? color : colors.black200)};
  height: 1px;
`
