import React from 'react'
import useI18n from '../../i18n/useI18n'
import { TranslationKey } from '../../types/generated/TranslationKey'

const ValidationPopover = ({ errorMessage }: { errorMessage?: TranslationKey }) => {
  const { t } = useI18n()
  if (!errorMessage) return null
  return (
    <div className="validation">
      <div role="alert">{t(errorMessage)}</div>
    </div>
  )
}

export default ValidationPopover
