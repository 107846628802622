import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HistoryIcon, SettingsIcon } from '../../../assets/icons'
import { InstallationStatus as InstallationStatusEnum } from '../../../types/PanelModel'
import { usePanelId } from '../hooks/usePanelId'
import useI18n from '../../../i18n/useI18n'
import { useOnMount } from '../../../utils/commonHooks'
import ArmSwitch from './ArmSwitch'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import PanelStatusInfo from '../../../components/PanelStatusInfo'
import { Loader } from '../../../components/Elements/Loaders'
import FailedRequest from '../../onboardingWizard/components/FailedRequest'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { isDoorOrWindow } from '../../../types/DoorsAndWindows'

const SystemHeader = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const {
    run: getPanelInfo,
    data: panelInfo,
    error: panelError,
    isLoading: loadingPanel,
  } = usePanelInfo()
  const {
    run: getPanelStatus,
    data: panelStatus,
    isLoading: panelStatusLoading,
    hasLoaded: hadLoadedPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()
  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    error: doorsAndWindowsError,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const [hasOpenDoorOrWindow, setHasOpenDoorOrWindow] = useState(false)

  useEffect(() => {
    if (
      doorsAndWindows &&
      doorsAndWindows.Floors.flatMap((e) => e.Rooms)
        .flatMap((e) => e.Devices)
        .find((e) => !e.Closed && isDoorOrWindow(e))
    ) {
      setHasOpenDoorOrWindow(true)
    }
  }, [doorsAndWindows])

  const { InstallationStatus, DisplayName } = panelInfo || {}
  const isLoading = loadingPanel || panelStatusLoading || loadingDoorsAndWindows
  const isStatusReady =
    !isLoading && !panelStatusError && !!panelStatus?.Status && !doorsAndWindowsError
  const shouldShowArmingSwitch =
    !panelError && InstallationStatus === InstallationStatusEnum.ACTIVE && isStatusReady

  useOnMount(() => {
    if (!loadingPanel && panelId !== panelInfo?.PanelId) {
      getPanelInfo(panelId)
    }
    if (!hadLoadedPanelStatus && !panelStatusLoading) {
      getPanelStatus({ panelId })
      getDoorsAndWindows(panelId)
    }
  })

  return (
    <div className="header">
      <div className="center container--horizontal-center">
        {isLoading ? (
          <Loader types={['large']} />
        ) : (
          <PanelStatusInfo
            installationStatus={panelInfo?.InstallationStatus}
            panelStatus={panelStatus?.Status}
            displayName={DisplayName}
            StatusTimeUtc={panelStatus?.StatusTimeUtc}
            StatusTime={panelStatus?.StatusTime}
            TimeZoneName={panelStatus?.TimeZoneName}
            hasOpenDoorsOrWindows={hasOpenDoorOrWindow}
          />
        )}
        {panelStatusError && <FailedRequest text={panelStatusError} />}
        {shouldShowArmingSwitch && (
          <ArmSwitch
            canPartialArm={panelInfo?.CanPartialArm}
            quickArmEnabled={panelInfo?.QuickArmEnabled}
            hasAnnex={panelInfo?.HasAnnex}
          />
        )}
        <div className="header__settings self-stretch justify-center">
          {InstallationStatus === InstallationStatusEnum.ACTIVE && (
            <Link to={`/systems/${panelId}/history`}>
              <HistoryIcon className="header__settings-icon" />
              <span>{t('History')}</span>
            </Link>
          )}
          <Link className="flex" to={`/systems/${panelId}/settings`}>
            <SettingsIcon className="header__settings-icon" />
            <span>{t('Settings')}</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SystemHeader
