import { GET_HISTORY } from '../../reducers/historySlice'
import { RootState } from '../../store'
import { getHistory } from '../apiPanel'
import { createUseRequest } from './createUseRequest'

const selectPanelEvents = (state: RootState, panelId: string) =>
  state.history[panelId]?.panelEvents || []

export const usePanelEvents = createUseRequest({
  successActionCreator: GET_HISTORY,
  apiCall: getHistory,
  selector: selectPanelEvents,
  requirePanelId: true,
})
