import styled, { css } from 'styled-components'
import RadioButton from '../RadioButton/RadioButton'
import Icon from '../Icon/Icon'
import { colors } from '../Style/theme'
import { Link } from 'react-router-dom'
import { PanelStatus } from '../../../types/PanelModel'

// import convertAndFormatTime from '../../../utils/ConvertAndFormatTIme'
// import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
// import { TranslationKey } from '../../../types/generated/TranslationKey'
// import useI18n from '../../../i18n/useI18n'
// import { isToday } from 'date-fns'

interface PropertyOptionProps {
  name: string
  selected: boolean
  onClick: (name: string) => void
  panelId: string
  panelTime: string
  panelStatus?: PanelStatus
}
const PropertyOption = ({
  name,
  selected,
  onClick,
  panelId,
  panelTime,
  panelStatus,
}: PropertyOptionProps) => {
  // * NOTE: NOTE: Comment inn after /GetPanelList returns correct data https://jira.sectoralarm.net/browse/SKA-2026

  // const locale = useDateFnsLocale()
  // const { t } = useI18n()

  // const panelStatusText = (panel: PanelStatus): TranslationKey | undefined => {
  //   if (panel === PanelStatus.NOT_AVAILABLE) {
  //     return 'Unknown'
  //   }
  //   if (panel === PanelStatus.TURNED_OFF) {
  //     return 'disarmed'
  //   }
  //   if (panel === PanelStatus.HOME_MODE) {
  //     return 'partialarmed'
  //   }
  //   if (panel === PanelStatus.FULLY_ARMED) {
  //     return 'armed'
  //   }
  //   return undefined
  // }

  return (
    <Link
      className="no-underline"
      to={'/'}
      onClick={() => (window.location.href = `/systems/${panelId}`)}
    >
      <S.PropertyOption onClick={() => onClick(name)} selected={selected}>
        <S.OptionLeft>
          <RadioButton checked={selected} />
          <S.OptionText>
            <S.PropertyTitle>{name}</S.PropertyTitle>
            {/* 
            <S.PropertyDesc>
              {`${panelStatusText(panelStatus!)} ${
                !isToday(new Date(panelTime!)) ? t('SINCE') : ''
              } ${convertAndFormatTime('', locale, panelTime, false)}  ${
                isToday(new Date(panelTime!)) ? t('Today') : ''
              }`}
            </S.PropertyDesc> */}
          </S.OptionText>
        </S.OptionLeft>
        <Icon name="Disarmed" color={colors.white} />
      </S.PropertyOption>
    </Link>
  )
}

const S = {
  PropertyOption: styled.div<{ selected: boolean }>`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    i {
      font-size: 30px;
    }

    ${({ selected }) => {
      return (
        selected &&
        css`
          border-radius: 8px;
          background-color: ${colors.black700};
        `
      )
    }}
  `,
  OptionLeft: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  PropertyTitle: styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${colors.white};
  `,
  PropertyDesc: styled.div`
    color: ${colors.black500};
  `,
  OptionText: styled.div`
    margin-left: 1rem;
  `,
}

export default PropertyOption
