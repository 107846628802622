import * as React from 'react'

function SvgTechSupport(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 150 150" {...props}>
      <path
        clipPath="url(#tech-support_svg__tech-support_svg__SVGID_4_)"
        fill="#525455"
        d="M89.5 106.6c-3.3 0-6.6-1.3-9-3.7-2.9-2.9-4.2-7-3.5-11l-5.5-5.5c-.7-.7-.7-1.8 0-2.6s1.8-.7 2.6 0l6.1 6.1c.4.4.7 1.1.4 1.8-.9 3.1 0 6.4 2.4 8.6 2 2 4.6 2.9 7.2 2.6l-3.3-3.3c-.4-.4-.4-.9-.4-1.3s.2-.9.4-1.3l5.5-5.5c.7-.7 1.8-.7 2.6 0l3.3 3.3c.2-2.6-.7-5.3-2.6-7.2-2.2-2.2-5.5-3.1-8.6-2.4-.7.2-1.3 0-1.8-.4l-9.2-9.2-26.6 26.8c-2.9 2.9-7.5 2.9-10.3 0-1.3-1.3-2.2-3.3-2.2-5.3s.7-3.7 2.2-5.3l26.3-26.3-9.2-9.2c-.4-.4-.7-1.1-.4-1.8.9-3.1 0-6.4-2.4-8.6-2-2-4.6-2.9-7.2-2.6l3.3 3.3c.7.7.7 1.8 0 2.6l-5.5 5.5c-.7.7-1.8.7-2.6 0l-3.3-3.3c-.2 2.6.7 5.3 2.6 7.2 2.2 2.2 5.5 3.1 8.6 2.4.7-.2 1.3 0 1.8.4l6.1 6.1c.7.7.7 1.8 0 2.6-.7.7-1.8.7-2.6 0l-5.5-5.5c-3.9.7-8.1-.7-11-3.5-3.7-3.7-4.6-9.2-2.6-14 .2-.4.7-.9 1.3-1.1.7 0 1.1 0 1.5.4l4.2 4.2 2.9-2.9-4.2-4.2c-.4-.4-.7-1.1-.4-1.5 0-.7.4-1.1 1.1-1.3 4.8-2.2 10.3-1.1 14 2.6 2.9 2.9 4.2 7 3.5 11l9.9 9.9c.7.7.7 1.8 0 2.6L41.9 94.4c-.7.7-1.1 1.5-1.1 2.6 0 .9.4 2 1.1 2.6 1.5 1.5 3.7 1.5 5.3 0L74.8 72c.4-.4.9-.4 1.3-.4s.9.2 1.3.4l9.9 9.9c3.9-.7 8.1.7 11 3.5 3.7 3.7 4.6 9.2 2.6 14-.2.4-.7.9-1.3 1.1-.7 0-1.1 0-1.5-.4l-4.2-4.2-2.9 2.9 4.2 4.2c.4.4.7 1.1.4 1.5 0 .7-.4 1.1-1.1 1.3-1.7.4-3.4.8-5 .8"
      />
      <path
        clipPath="url(#tech-support_svg__tech-support_svg__SVGID_6_)"
        fill="#525455"
        d="M80.3 70.9c-.4 0-.9-.2-1.3-.4-.7-.7-.7-1.8 0-2.6l10.1-10.1c.7-.7 2-.7 2.6 0l6.6 6.4c1.5-2 2.2-4.4 2.2-7 0-3.1-1.3-6.1-3.5-8.3L85.8 37.5 79 44.8l5 5c.4.4.4.9.4 1.3s-.1.9-.4 1.3L73.9 62.5c-.7.7-1.8.7-2.6 0s-.7-1.8 0-2.6l8.8-8.8-5-5c-.4-.4-.4-.9-.4-1.3s.2-.9.4-1.3l9.7-9.7c.7-.7 2-.7 2.6 0l12.5 12.5c2.9 2.9 4.6 6.8 4.6 11s-1.5 7.9-4.6 11c-.7.7-1.8.7-2.6 0l-6.6-6.6-8.8 8.8c-.7.2-1.1.4-1.6.4"
      />
    </svg>
  )
}

export default SvgTechSupport
