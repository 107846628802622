import React from 'react'
import Button from '../../../../components/Crow/Button/Button'
import useI18n from '../../../../i18n/useI18n'
import { Benefit } from '../../../../types/Benefit'
import imgTemplate from './imgTemplate.png'
import huseierneLogoUrl from '../../../../assets/images/partners/huseierne.png'
import { BenefitsItemContainer, SubContainer, Image, Title } from './StyledComponents'

interface BenefitItemProps {
  benefit: Benefit
  onClick: () => void
}

const BenefitsItem = ({ benefit, onClick }: BenefitItemProps) => {
  const { t } = useI18n()

  return (
    <BenefitsItemContainer>
      <SubContainer>
        {benefit.Name === 'Huseierne' ? (
          <Image src={huseierneLogoUrl} alt="Huseierne" />
        ) : (
          <Image src={imgTemplate} alt="Temporary" />
        )}
      </SubContainer>
      <SubContainer>
        <Title>{benefit.Name}</Title>
      </SubContainer>
      <SubContainer>
        <Button level="secondary" type="button" onClick={onClick}>
          {t('use_points')}
        </Button>
      </SubContainer>
    </BenefitsItemContainer>
  )
}

export { BenefitsItem }
