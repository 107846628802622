import React, { useEffect, useImperativeHandle } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { phoneNumberValidator, panelCodeValidator } from '../../components/forms/validators'
import useI18n from '../../i18n/useI18n'
import { usePanelInfo } from '../../services/requestHooks/panelInfo'
import { getPanelCountryCode } from '../../utils/numberField'
import Button from '../Crow/Button/Button'
import TextField from '../Crow/Input/TextFieldForForm'
import NumberField from '../Crow/Input/NumberField'
import Toggle from '../Crow/Toogle/ToggleForForm'
import styled from 'styled-components'
import { colors } from '../Crow/Style/theme'
import { Loader } from '../Elements/Loaders'

const UserInformation = ({ register, errors }: any) => {
  const { t } = useI18n()

  return (
    <S.UserInformation>
      <TextField
        id="firstName"
        label={t('First name')}
        {...register('firstName')}
        error={errors.firstName?.message}
      />
      <TextField
        id="lastName"
        label={t('Last name')}
        {...register('lastName')}
        error={errors.lastName?.message}
      />
    </S.UserInformation>
  )
}

const UserSettings = ({ register }: any) => {
  const { t } = useI18n()

  return (
    <S.UserSettings>
      <S.GroupTitle>{t('user_settings')}</S.GroupTitle>
      <S.Settings>
        <Toggle
          text={t('underage')}
          subText={t('is the person a child')}
          icon="Smiley"
          {...register('underage')}
        />
      </S.Settings>
    </S.UserSettings>
  )
}

const UserPrivileges = ({
  register,
  shouldBeDisabled,
  hasSystemAccess,
  panelCodeLength,
  hasAppAccess,
  control,
  countryCode,
  isUnderage,
  errors,
}: any) => {
  const { t } = useI18n()

  return (
    <S.UserPrivileges>
      <S.GroupTitle>{t('user_privileges')}</S.GroupTitle>
      <S.Privileges>
        <>
          <Toggle
            key="system"
            text={t('panel_access')}
            subText={t('pin_code_to_panel')}
            icon="System"
            disabled={shouldBeDisabled('system')}
            {...register('system')}
          />
          {hasSystemAccess && (
            <TextField
              key="add-user-pin"
              id="add-user-pin"
              maxLength={panelCodeLength}
              placeholder={t('code_digits', { panelCodeLength })}
              {...register('pin')}
              error={errors.pin?.message}
            />
          )}
        </>
        <>
          <Toggle
            key="app"
            text={t('App')}
            subText={t('allow_use_of_mobile_app')}
            icon="App"
            disabled={shouldBeDisabled('app')}
            {...register('app')}
          />
          {hasAppAccess && (
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <NumberField
                  {...field}
                  value={field.value || ''}
                  defaultCountryCode={countryCode}
                  error={errors.phoneNumber?.message}
                />
              )}
            />
          )}
        </>
        <>
          <Toggle
            key="admin"
            text={t('administration_rights')}
            subText={t('can_manage_users')}
            icon="Star"
            disabled={shouldBeDisabled('admin')}
            {...register('admin')}
          />
          {hasSystemAccess && isUnderage && (
            <S.UnderAgeInfo>{t('info_admin_underage')}</S.UnderAgeInfo>
          )}
        </>
      </S.Privileges>
    </S.UserPrivileges>
  )
}

export interface PeopleFormRef {
  handleReSubmit?: (() => void) | undefined
}

interface Props {
  onSubmit: SubmitHandler<any>
  cancelAction?: () => void
  isLoading?: boolean
  outsideRef: React.MutableRefObject<PeopleFormRef>
}

const PeopleForm = ({ onSubmit, cancelAction, isLoading, outsideRef }: Props) => {
  const { t } = useI18n()

  const { data: panelInfo } = usePanelInfo()
  const countryCode: string = getPanelCountryCode(panelInfo?.PanelId || 'noPanelId')
  const panelCodeLength: number = panelInfo!.PanelCodeLength

  const resolver = yup.object({
    firstName: yup.string().min(2, t('error_first_name_at_least_2')),
    lastName: yup.string().min(2, t('error_last_name_at_least_2')),
    underage: yup.boolean(),
    system: yup.boolean(),
    app: yup.boolean(),
    admin: yup.boolean(),
    phoneNumber: phoneNumberValidator(t).when('app', {
      is: true,
      then: yup.string().required(t('error_phone_number_required')),
    }),
    pin: panelCodeValidator(panelCodeLength, t).when('system', {
      is: true,
      then: yup.string().required(t('error_pin_code_required')),
    }),
  })

  const defaultValues = {
    firstName: '',
    lastName: '',
    underage: false,
    system: false,
    app: false,
    admin: false,
    phoneNumber: '',
    pin: '',
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(resolver),
  })
  const isUnderage = watch('underage')
  const hasSystemAccess = watch('system')
  const hasAppAccess = watch('app')
  const hasAdminAccess = watch('admin')

  const shouldBeDisabled = (fieldName: 'admin' | 'app') => {
    return (
      (isUnderage && fieldName === 'admin') ||
      (!hasSystemAccess && (fieldName === 'app' || fieldName === 'admin'))
    )
  }

  const handleReSubmit = () => {
    handleSubmit(onSubmit)()
  }

  useImperativeHandle(
    outsideRef,
    () => ({
      handleReSubmit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleReSubmit, onSubmit],
  )

  useEffect(() => {
    if (isUnderage) {
      hasAdminAccess && setValue('admin', false)
    }
    // eslint-disable-next-line
  }, [isUnderage])

  useEffect(() => {
    if (!hasSystemAccess) {
      hasAppAccess && setValue('app', false)
      hasAdminAccess && setValue('admin', false)
      setValue('pin', '')
    }
    // eslint-disable-next-line
  }, [hasSystemAccess])

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
      <S.Content>
        <div>
          <UserInformation
            register={register}
            control={control}
            countryCode={countryCode}
            isUnderage={isUnderage}
            errors={errors}
          />
          <UserSettings register={register} />
        </div>
        <UserPrivileges
          register={register}
          shouldBeDisabled={shouldBeDisabled}
          hasSystemAccess={hasSystemAccess}
          panelCodeLength={panelCodeLength}
          hasAppAccess={hasAppAccess}
          control={control}
          countryCode={countryCode}
          isUnderage={isUnderage}
          errors={errors}
        />
      </S.Content>
      <S.ButtonRow>
        <Button
          type="button"
          onClick={cancelAction}
          level="secondary"
          size="l"
          disabled={isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button level="primary" size="l" disabled={isLoading}>
          {isLoading ? <Loader /> : t('Add')}
        </Button>
      </S.ButtonRow>
    </form>
  )
}

const S = {
  Content: styled.div`
    margin-top: 48px;
    display: grid;
    column-gap: 78px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  `,
  UserInformation: styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  UserSettings: styled.section`
    margin-top: 26px;
  `,
  Settings: styled.div`
    margin-top: 8px;
  `,
  UserPrivileges: styled.section`
    @media only screen and (max-width: 600px) {
      margin-top: 26px;
    }
  `,
  GroupTitle: styled.h2`
    font-family: 'Metric';
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
  `,
  Privileges: styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  UnderAgeInfo: styled.p`
    padding: 0 16px;
    font-size: 16px;
    color: ${colors.black800};
  `,
  ButtonRow: styled.div`
    margin-top: 107px;
    display: flex;
    gap: 24px;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  `,
}

export default PeopleForm
