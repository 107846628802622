function SvgLaundry(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 3C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5H7C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3H4Z"
        fill="#333131"
      />
      <path
        d="M21 5C21.5523 5 22 4.55228 22 4C22 3.44772 21.5523 3 21 3C20.4477 3 20 3.44772 20 4C20 4.55228 20.4477 5 21 5Z"
        fill="#333131"
      />
      <path
        d="M24 5C24.5523 5 25 4.55228 25 4C25 3.44772 24.5523 3 24 3C23.4477 3 23 3.44772 23 4C23 4.55228 23.4477 5 24 5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 16C20 19.3137 17.3137 22 14 22C10.6863 22 8 19.3137 8 16C8 12.6863 10.6863 10 14 10C17.3137 10 20 12.6863 20 16ZM11.1372 16.3416C11.6404 16.1739 12.1896 16.2129 12.6641 16.4502L14.4412 17.3387C15.39 17.8131 16.4885 17.8912 17.4949 17.5557L17.7165 17.4819C17.1277 18.9574 15.6856 20 14 20C12.0298 20 10.3923 18.5756 10.0611 16.7003L11.1372 16.3416ZM10.2835 14.518C10.8724 13.0426 12.3144 12 14 12C15.9702 12 17.6077 13.4244 17.9389 15.2996L16.8625 15.6584C16.3593 15.8261 15.81 15.7871 15.3356 15.5498L13.5585 14.6613C12.6096 14.1869 11.5111 14.1088 10.5047 14.4443L10.2835 14.518Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.895431 0 2 0H26C27.1046 0 28 0.895431 28 2V28C28 29.1046 27.1046 30 26 30H2C0.895431 30 0 29.1046 0 28V2ZM2 2H26V6H2V2ZM2 8V24H26V8H2ZM2 28V26H26V28H2Z"
        fill="#333131"
      />
    </svg>
  )
}

export default SvgLaundry
