import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { UsersIcon } from '../../../assets/icons'
import BackButton from '../../../components/BackButton'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import Toast from '../../../components/Crow/Toast/Toast'
import Tooltip from '../../../components/Crow/Tooltip'
import { Paragraph, SubTitle, Title } from '../../../components/Crow/Typography/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useGetPanelPermissions, useGetUsers } from '../../../services/requestHooks/users'
import { Capability } from '../../../types/PanelModel'
import { PermanentUser } from '../../../types/PermanentUser'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import SettingsMenuItem from '../../AlarmSystems/Settings/components/SettingsItem'
import AddUserDrawer from './AddUserDrawer'
import Person from './Person'

const CrowPeoplePage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const [displayModal, setModalVisibility] = useState<boolean>(false)
  const [peopleModuleNotEnabled, setPeopleModuleNotEnabled] = useState(false)

  const {
    run: getPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadPanelInfoError,
  } = usePanelInfo()

  const {
    run: getPanelStatus,
    data: panelStatus,
    isLoading: loadingPanelStatus,
    error: loadingPanelStatusError,
  } = usePanelStatus()
  const panelIsArmed: boolean = panelStatus?.Status !== 1

  const {
    run: getUsers,
    isLoading: loadingUsers,
    error: usersError,
    data: usersData,
  } = useGetUsers()

  const {
    run: getPanelPermissions,
    isLoading: loadingPanelPermissions,
    error: panelPermissionsError,
    data: panelPermissions,
  } = useGetPanelPermissions()

  const users: PermanentUser[] = usersData || []

  const isLoading = loadingPanelStatus || loadingUsers || loadingPanelPermissions
  const hasError = usersError || panelPermissionsError
  const canAddUsers =
    panelPermissions?.Accesses.IsLegalOwner || panelPermissions?.Accesses.IsAdminUser

  const returnUrl = `/systems/${panelId}`

  useOnMount(() => {
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      getPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      getPanelStatus({ panelId })
    }
    if (!isLoading && !panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE)) {
      getUsers(panelId)
      getPanelPermissions()
    }
    if (panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE)) {
      setPeopleModuleNotEnabled(true)
    }
  })

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <S.Page
        isLoading={isLoading}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed:
              (!isLoading && !!hasError) ||
              (!loadingPanelInfo && !!loadPanelInfoError) ||
              (!loadingPanelStatus && !!loadingPanelStatusError) ||
              peopleModuleNotEnabled,
            errorHeaderText: t('PEOPLE'),
            errorText: peopleModuleNotEnabled
              ? 'People module not enabled for this user'
              : 'Something went wrong. Please try again',
            returnUrl,
          },
        }}
      >
        <BackButton backUrl={returnUrl} />
        <S.Title>{t('PEOPLE')}</S.Title>
        <S.Description>{t('users_description')} </S.Description>
        <S.PermanentUsers>
          <S.SubTitle>{t('permanent_users') + ` (${users.length})`}</S.SubTitle>
          <S.ContentDescription> {t('permanent_users_description')} </S.ContentDescription>
          {canAddUsers && (
            <S.AddButtonTooltip
              text={t('system_must_be_disarmed_to_add')}
              position="right"
              active={panelIsArmed}
              shouldGrow={false}
            >
              <S.Button onClick={() => setModalVisibility(true)} disabled={panelIsArmed}>
                <Icon name="Plus" />
              </S.Button>
            </S.AddButtonTooltip>
          )}
          <S.UserList>
            {panelPermissions &&
              users.map((user) => (
                <Person
                  key={user.PersonId}
                  user={user}
                  panelIsArmed={panelIsArmed}
                  panelPermissions={panelPermissions}
                />
              ))}
          </S.UserList>
          <AddUserDrawer isOpen={displayModal} handleClose={() => setModalVisibility(false)} />
        </S.PermanentUsers>
        <S.ContactPersons>
          <S.SubTitle>{t('Contact persons')}</S.SubTitle>
          <SettingsMenuItem
            url="contacts"
            title="Contact persons"
            description="Add persons to contact i case of emergency."
            svg={UsersIcon}
          />
        </S.ContactPersons>
        <Toast />
      </S.Page>
    </TcOrCrowContainer>
  )
}

const S = {
  Page: styled(FullscreenPage)`
    & .grid__half {
      flex: 1;
    }

    & .fullscreen__back:hover {
      color: ${colors.lightBlue};
    }
    & .fullscreen__back::before {
      border-color: ${colors.blue};
    }
  `,
  Title: styled(Title)`
    font-size: 2.25rem;
    color: ${colors.black900};
  `,
  Description: styled(Paragraph)`
    margin-top: 16px;
    color: ${colors.black800};
  `,
  PermanentUsers: styled.div`
    margin-top: 60px;
  `,
  SubTitle: styled(SubTitle)`
    margin: 0;
    color: ${colors.black900};
  `,
  ContentDescription: styled(Paragraph)`
    color: ${colors.black800};
  `,
  AddButtonTooltip: styled(Tooltip)`
    margin-top: 16px;
  `,
  Button: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${colors.blue};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.lightBlue};
    }

    &:disabled {
      cursor: default;
      background-color: ${colors.black200};
      color: ${colors.white};
    }

    &:disabled:hover {
      background-color: ${colors.black200};
      color: ${colors.white};
    }
  `,
  UserList: styled.ul`
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
    padding: 0;

    @media only screen and (max-width: 600px) {
      grid-template-columns: none;
    }
  `,
  ContactPersons: styled.div`
    margin-top: 80px;

    & .settings__item {
      margin-top: 16px;
      border-radius: 12px;
      padding: 10px 2px;
      text-decoration: none;
    }
    & .settings__item:hover {
      color: ${colors.black800};
    }
  `,
}

export default CrowPeoplePage
