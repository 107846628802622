import { MouseEventHandler, ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { colors } from '../../../components/Crow/Style/theme'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { Paragraph } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay'

type SmartPlugsListItemType = {
  id?: string
  text: string
  subText?: ReactElement
  checked?: boolean
  healthy?: boolean
  onClick?: MouseEventHandler<HTMLInputElement>
  isLoading?: boolean
  icon?: ReactNode
  disabled?: boolean
  className?: string
}

const SmartPlugsListItem = ({
  id,
  text,
  subText,
  checked,
  healthy,
  onClick,
  isLoading,
  icon,
  disabled,
  className,
}: SmartPlugsListItemType) => {
  return (
    <StyledCrowCard as="li" className={className}>
      <StyledLabel htmlFor={id}>
        <Paragraph large>{text}</Paragraph>
        {!!subText && <StyledParagraph>{subText}</StyledParagraph>}
      </StyledLabel>
      {healthy || isLoading ? (
        <LoadingOverlay isLoading={isLoading!}>
          <Toggle key={id} onClick={onClick} enabled={checked!} disabled={disabled} />
        </LoadingOverlay>
      ) : null}
      {icon ? icon : null}
    </StyledCrowCard>
  )
}

const StyledCrowCard = styled(CrowCard)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  padding: 10px 16px 13px 16px;
  border-radius: 12px;
  width: 610px;
  height: 64px;
  margin-top: 8px;
  &:first-child {
    margin-top: 0px;
  }
`

const StyledLabel = styled.label`
  line-height: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  margin-right: auto;
`

const StyledParagraph = styled(Paragraph)`
  color: ${colors.black700};
  line-height: 16px;
`

export default SmartPlugsListItem
