import * as React from 'react'

function SvgTinyHomeMode(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.212 5.78L10.048.366a1.788 1.788 0 00-2.167 0L.749 5.796A2.008 2.008 0 000 7.36v9.05c0 .873.678 1.581 1.521 1.581h4.027c1.017 0 1.844-.865 1.844-1.928v-2.541c0-.362.268-.67.583-.67H9.82c.323 0 .59.308.59.678v2.541c0 1.062.828 1.928 1.845 1.928h4.224c.835 0 1.521-.708 1.521-1.582V7.346c-.024-.622-.307-1.204-.788-1.566zm-.489 10.63c0 .174-.118.323-.26.323H12.24c-.323 0-.583-.299-.583-.677v-2.541c0-1.063-.828-1.936-1.844-1.936H8.96V1.263a.54.54 0 01.338.11l7.164 5.414a.69.69 0 01.26.559v9.064z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgTinyHomeMode
