import React, { FC, useState } from 'react'
import * as yup from 'yup'
import Button from '../../../components/Button'
import { Card } from '../../../components/Elements/Card'
import useI18n from '../../../i18n/useI18n'
import { Partner } from '../../../types/Partner'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import huseierneLogoUrl from '../../../assets/images/partners/huseierne.png'
import './benefits.scss'
import { PartnerModal } from './PartnerModal'

type Props = {
  partner: Partner
}

const huseierneResolver = yup.object({
  member_number: yup
    .string()
    .required()
    .min(6, 'huseierne_member_number_helper'),
})
interface PartnerInfo {
  description: TranslationKey
  label: TranslationKey
  resolver: typeof huseierneResolver
  logoUrl: string
}
const partners: Record<string, PartnerInfo> = {
  ['644' as string]: {
    description: 'huseierne_desc',
    label: 'huseierne_member_number',
    resolver: huseierneResolver,
    logoUrl: huseierneLogoUrl,
  },
}

const PartnerCard: FC<Props> = ({ partner }) => {
  const { t } = useI18n()
  const [open, setOpen] = useState(false)

  const { description, logoUrl } = partners[partner.Id]
  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Card>
        <div className="flex items-center">
          <img style={{ maxWidth: '200px' }} src={logoUrl} alt="logo" />
          <div>
            <h3>{partner.Name}</h3>
            <p>{t(description)}</p>
          </div>
        </div>
        <div className="flex justify-end">
          <Button size="small" buttonType="third" onClick={openModal}>
            {t('ACTIVATE')}
          </Button>
        </div>
      </Card>
      {open && <PartnerModal partner={partner} closeModal={closeModal} />}
    </>
  )
}

export default PartnerCard
