import styled from 'styled-components'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Paragraph, SubHeadline, SubTitle, Title } from '../../../components/Crow/Typography'
import Icon from '../../../components/Crow/Icon/Icon'

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  padding-bottom: 10px;
  padding-left: 10px;
`

const TileContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const FlexRow = styled.div`
  max-width: 710px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 26px;
`

const FlexColumnStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 14px;
`

const StyledRoomIcon = styled(Icon)`
  font-size: 43px;
`

const RoomNameTitle = styled(Title)`
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  padding-top: 9px;
  padding-bottom: 6px;
  max-width: 138px;
  min-height: 71px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyledTile = styled(Tile)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 15px;
`

const FooterDevices = styled.span`
  font-size: 12px;
  color: ${colors.black900};
`

const IconContainer = styled.span<{ color?: string }>`
  border-radius: 8px;
  background-color: ${({ color }) => (color ? color : colors.black100)};
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: 425px) {
    margin-right: 0;
  }
`

const StyledIcon = styled(Icon)<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
`

const DoorsCrowCard = styled(CrowCard)`
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 17px 16px 23px 16px;
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`

const DeviceName = styled(Paragraph)`
  margin-right: auto;
  @media (max-width: 425px) {
    margin-right: 0px;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
  }
`

const WindowsCrowCard = styled(CrowCard)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 12px;
  padding: 0px;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    width: 500px;
  }
`

const WindowDeviceNameStatusContainer = styled.div`
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const StyledParagraphBatteryLow = styled(Paragraph)<{ color?: string }>`
  margin-right: auto;
  padding-left: 16px;
  @media (max-width: 425px) {
    margin-right: 0px;
    padding-left: 0px;
  }
  color: ${({ color }) => (color ? color : colors.black)};
`

const DeviceStatusContainer = styled.div<{ color?: any }>`
  border-radius: 20px;
  background-color: ${({ color }) => (color ? colors.black300 : colors.red)};
  height: 23px;
`

const StyledStrong = styled.strong<{ color?: any }>`
  color: ${({ color }) => (color ? colors.black900 : colors.white)};
  text-transform: uppercase;
  padding-left: 12px;
  padding-right: 12px;
`

const LowBatteryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 16px 16px 16px;
  border-top: 1px solid ${colors.black200};
  background-color: ${colors.white};
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  width: 100%;
  color: ${colors.black800};

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 17px 0px 23px 0px;
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSubHeadline = styled(SubHeadline)<{ color?: string }>`
  color: ${({ color }) => (color ? color : colors.black700)};
  line-height: 16px;
  padding-bottom: 6px;
`

export {
  StyledSubtitle,
  TileContainer,
  StyledTile,
  FlexRow,
  FlexColumnStart,
  StyledRoomIcon,
  RoomNameTitle,
  FooterDevices,
  IconContainer,
  DoorsCrowCard,
  StyledIcon,
  DeviceName,
  WindowsCrowCard,
  WindowDeviceNameStatusContainer,
  DeviceStatusContainer,
  StyledStrong,
  LowBatteryContainer,
  StyledParagraphBatteryLow,
  FlexColumn,
  StyledSubHeadline,
}
