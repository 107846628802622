import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useI18n from '../../../../i18n/useI18n'
import { useOnMount } from '../../../../utils/commonHooks'
import FormButtons from '../../../../components/forms/FormButtons'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import SettingsSubPage from '../components/SettingsSubPage'
import { SmartplugIcon } from '../../../../assets/icons'
import { useGetSmartplug, useSetSmartplugLabel } from '../../../../services/requestHooks/plugs'
import { useForm } from 'react-hook-form'

const PlugNameForm = ({ name, returnUrl }: { name: string; returnUrl: string }) => {
  const { t } = useI18n()
  const history = useHistory()
  const { panelId, smartplugSerial } = useParams<{ panelId: string; smartplugSerial: string }>()
  const { run: setLabel, error: saveError, isLoading: isSaving } = useSetSmartplugLabel({
    onSuccess: () => {
      history.replace(`/systems/${panelId}/smartplugs/${smartplugSerial}`)
    },
  })

  const { register, handleSubmit } = useForm({
    defaultValues: { name },
  })

  const onSubmit = async ({ name }: { name: string }) => {
    setLabel({
      PanelId: panelId,
      Label: name,
      Serial: smartplugSerial,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col center">
        <SmartplugIcon className="icon status-list__icon-svg self-center mb-6" />
        <h1 className="text-2xl">{t('Edit name')}</h1>
        <label className="form">
          {t('Name')}
          <input {...register('name')} className="form__input" type="text" />
        </label>
        <div>
          <FormButtons isLoading={isSaving} submitText={'Save new name'} cancelUrl={returnUrl} />
        </div>
      </form>
      {saveError && <FailedRequest text={saveError} />}
    </>
  )
}

const PlugNameFormWrapper = () => {
  const { panelId, smartplugSerial } = useParams<{ panelId: string; smartplugSerial: string }>()
  const { run: getPlug, isLoading, data: plug } = useGetSmartplug()
  const returnUrl = `/systems/${panelId}/smartplugs/${smartplugSerial}`
  const name = plug?.Label
  useOnMount(() => {
    if (!isLoading && !plug) getPlug({ panelId, serial: smartplugSerial })
  })
  return (
    <SettingsSubPage returnUrl={returnUrl} isLoading={isLoading}>
      {name && <PlugNameForm name={name} returnUrl={returnUrl} />}
    </SettingsSubPage>
  )
}

export default PlugNameFormWrapper
