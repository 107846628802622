import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ExpandableList, {
  ExpandableListItem,
} from '../../../../components/system/components/ExpandableList'
import { RootState } from '../../../../store'
import useI18n from '../../../../i18n/useI18n'
import { PanelStatus, Photo } from '../../../../types/PanelModel'
import IconSectorAlarmAppAddPhoto from '../../../../assets/icons/IconSectorAlarmAppAddPhoto'
import { usePanelId } from '../../hooks/usePanelId'
import { selectIsExpandedMenu, toggleExpandableMenu } from '../../../../reducers/panelInfoSlice'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { useUserInfo } from '../../../../services/requestHooks/user'

const SystemPhotoScreen = () => {
  const { t } = useI18n()

  const panelId = usePanelId()
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsExpandedMenu({ panelId, menu: 'photo' }))

  const [infoMessage, setInfoMessage] = useState<TranslationKey | undefined>()
  const { Impersonation: isImpersonating } = useUserInfo()
  const panel = useSelector((state: RootState) => state.panel[panelId])
  const PanelId = usePanelId()

  const getTriggerURL = (photo: Photo) => {
    return '/systems/' + PanelId + '/picture/' + photo.Id
  }
  const canTakePicture =
    panel?.Status?.IsOnline && panel?.Status.Status === PanelStatus.FULLY_ARMED && !isImpersonating

  useEffect(() => {
    if (!panel?.Status?.IsOnline) {
      setInfoMessage(t('pictures_only_when_system_on_ethernet'))
      return
    }
    if (panel?.Status?.IsOnline && panel?.Status.Status !== PanelStatus.FULLY_ARMED) {
      setInfoMessage(t('photo_only_when_fully_armed'))
      return
    }
    setInfoMessage(undefined)
  }, [isImpersonating, t, panel?.Status?.IsOnline, panel?.Status?.Status])

  const handleToggle = useCallback(
    () => dispatch(toggleExpandableMenu({ panelId, menu: 'photo' })),
    [panelId, dispatch],
  )

  return (
    <ExpandableList
      open={isOpen}
      isLoading={false}
      onClick={handleToggle}
      summary={<h2>{t('photo')}</h2>}
      icon={<IconSectorAlarmAppAddPhoto />}
    >
      {panel?.Info?.Photos.map((photo, index) => {
        return (
          <ExpandableListItem key={photo.Id}>
            <span id={index.toString()} key={index}>
              {photo.Label}
            </span>
            {canTakePicture && (
              <Link to={getTriggerURL(photo)} className="right">
                {t('take_picture')}
              </Link>
            )}
          </ExpandableListItem>
        )
      })}
      {infoMessage && <p className="mt-4">{t(infoMessage)}</p>}
    </ExpandableList>
  )
}

export default SystemPhotoScreen
