import * as React from 'react'

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.350098 8.90359C0.350098 8.37873 0.775585 7.95324 1.30045 7.95324H16.5061C17.0309 7.95324 17.4564 8.37873 17.4564 8.90359C17.4564 9.42846 17.0309 9.85394 16.5061 9.85394H1.30045C0.775585 9.85394 0.350098 9.42846 0.350098 8.90359Z"
        fill="white"
      />
      <path
        d="M8.90319 17.4567C9.42806 17.4567 9.85354 17.0312 9.85354 16.5063V1.30069C9.85354 0.775829 9.42806 0.350342 8.90319 0.350342C8.37833 0.350342 7.95284 0.775828 7.95284 1.30069V16.5063C7.95284 17.0312 8.37833 17.4567 8.90319 17.4567Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgPlus
