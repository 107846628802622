import styled from 'styled-components'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import { Temperature } from '../../../types/Temperature'

const TemperatureTileFooter = (props: { props: Temperature[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    return (
      <>
        {devices.length > 4 ? (
          <span>{`+${devices.length - devices.slice(0, 4).length}`}</span>
        ) : null}
      </>
    )
  }

  const SortedDevices = () => {
    const sortedDevices = ([] as Array<Temperature>)
      .concat(devices)
      .sort((a, b) => Number(a.Temperature) - Number(b.Temperature))
      .slice(0, 4)

    const renderItems = (property?: keyof Temperature) => {
      return (
        sortedDevices
          // .filter((device) => device.Temprature) // TODO: filter by device statuses
          .map((device) => (
            <IconContainer
              key={device.Id}
              color={device.LowBattery ? 1 : 0}
              role="listitem"
              className="w-6 h-6 mx-1"
            >
              <StyledTemperatureIcon name="Temperature" />
            </IconContainer>
          ))
      )
    }

    return (
      <SortedDevicesUl role="list" aria-label="devices">
        {renderItems()}
      </SortedDevicesUl>
    )
  }

  return (
    <TileFooterContainer>
      <SortedDevices />
      <CountRemainderDevices />
    </TileFooterContainer>
  )
}

const SortedDevicesUl = styled.ul`
  display: flex;
  padding-top: 4px;
  padding-left: 0;
  margin-bottom: 0;
`

const IconContainer = styled.li<{ color?: any; border?: string }>`
  margin-top: 0;
  border-radius: 8px;
  background-color: ${({ color }) => (color ? colors.lightYellow : colors.black100)};
  border: ${({ border }) => (border ? border : border)};
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-left: 0;
  }
`

const StyledTemperatureIcon = styled(Icon)`
  font-size: 16px;
`

const TileFooterContainer = styled.div`
  display: flex;
  align-items: center;
`

export default TemperatureTileFooter
