import { ReactNode } from 'react'
import { LockCrowCard, Label, LabelContainer, LabelWrapper } from './LockStyle'

type LockListItemType = {
  label?: ReactNode
  secondaryLabel?: ReactNode
  onClick?: () => void
  className?: string
  toggleItem?: ReactNode
  children?: ReactNode
  position?: 'row' | 'column'
  justifyContent?: 'flex-start' | 'center' | 'space-between'
}

const LocksListItem = ({
  label,
  secondaryLabel,
  onClick,
  className,
  children,
  toggleItem,
  position = 'column',
  justifyContent,
}: LockListItemType) => {
  return (
    <LockCrowCard className={className} onClick={onClick} as="li" justifyContent={justifyContent!}>
      <LabelContainer position={position}>
        <Label>{label}</Label>
        <LabelWrapper>{secondaryLabel}</LabelWrapper>
      </LabelContainer>
      {children}
      {toggleItem ? <>{toggleItem}</> : null}
    </LockCrowCard>
  )
}

export default LocksListItem
