import styled from 'styled-components'
import Icon from '../../../components/Crow/Icon/Icon'
import { colors } from '../../../components/Crow/Style/theme'
import { Device } from '../../../types/SmokeDetectors'

const SmokeDetectorsTileFooter = (props: { props: Device[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    return (
      <>
        {devices.length > 4 ? (
          <span>{`+${devices.length - devices.slice(0, 4).length}`}</span>
        ) : null}
      </>
    )
  }

  const SortedDevices = () => {
    const sortedDevices = ([] as Array<Device>)
      .concat(devices)
      .sort((a, b) =>
        b.Alarm === a.Alarm
          ? Number(a.LowBattery) - Number(b.LowBattery)
          : Number(b.Alarm) - Number(a.Alarm),
      )
      .slice(0, 4)

    // TODO: More cases for: "outlined red" = verification failure (no backend)
    const renderItems = (property?: keyof Device) => {
      if (property === 'Alarm') {
        return sortedDevices
          .filter((device) => device.Alarm)
          .map((device) => (
            <IconContainer
              key={device.Id}
              role="listitem"
              className="w-6 h-6 mx-1"
              color={colors.lightRed}
            >
              <StyledSmokeDetectorIcon name="Smoke-Detector" color={colors.white} />
            </IconContainer>
          ))
      } else if (property === 'LowBattery') {
        return sortedDevices
          .filter((device) => device.LowBattery)
          .map((device) => (
            <IconContainer
              key={device.Id}
              role="listitem"
              className="w-6 h-6 mx-1"
              color={colors.yellow}
            >
              <StyledSmokeDetectorIcon name="Smoke-Detector" />
            </IconContainer>
          ))
      } else {
        return sortedDevices
          .filter((device) => !device.Alarm && !device.LowBattery)
          .map((device) => (
            <IconContainer key={device.Id} role="listitem" className="w-6 h-6 mx-1">
              <StyledSmokeDetectorIcon name="Smoke-Detector" />
            </IconContainer>
          ))
      }
    }

    return (
      <SortedDevicesUl role="list" aria-label="devices">
        {renderItems('Alarm')}
        {renderItems('LowBattery')}
        {renderItems()}
      </SortedDevicesUl>
    )
  }

  return (
    <TileFooterContainer>
      <SortedDevices />
      <CountRemainderDevices />
    </TileFooterContainer>
  )
}

const SortedDevicesUl = styled.ul`
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
`

const IconContainer = styled.li<{ color?: string; border?: string }>`
  margin-top: 0;
  border-radius: 8px;
  background-color: ${({ color }) => (color ? color : colors.black100)};
  border: ${({ border }) => (border ? border : border)};
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-left: 0;
  }
`

const StyledSmokeDetectorIcon = styled(Icon)`
  font-size: 16px;
`

const TileFooterContainer = styled.div`
  display: flex;
  align-items: center;
`

export default SmokeDetectorsTileFooter
