export enum NationId {
  SWEDEN = 1,
  NORWAY = 2,
  IRELAND = 3,
  FINLAND = 4,
  FRANCE = 5,
  SPAIN = 6,
  ITALY = 7,
}

export enum UserCultureInfo {
  ENGLISH = 'en-US',
  SWEDISH = 'sv-SE',
  NORWEGIAN = 'nb-NO',
  FINNISH = 'fi-FI',
  FRENCH = 'fr-FR',
  SPANISH = 'es-ES',
  CATALAN = 'ca-ES',
  ITALIAN = 'it-IT',
}

export enum UserFeature {
  INBOX = 'Inbox',
  ARC_CONSENT = 'ArcVideoConsent',
  BOOKING = 'Booking',
  BENEFITS = 'Benefits',
}
export interface Resource {
  CustomerServicePhone: string
  TermsUrl: string
  CustomerServiceUrl: string
}

export type UserDetails = {
  Id: string
  Brand: number
  UserId: number
  UserName: string
  FirstName: string
  LastName: string
  NationId: NationId
  Roles: string[]
  Features: UserFeature[]
  Impersonation: boolean
  ImpersonatingUserName?: string
  ImpersonatingUserId?: number
  UserCultureInfo: UserCultureInfo
  CustomerNo: string
  IsEnterpriseCustomer: boolean
  NationHasCRM: boolean
  NationUseIRCam: boolean
  NationCanAddSmartPlug: boolean
  UpdatedTermsRequired: boolean
  CellPhone: string
}
// eslint-disable-next-line import/no-unused-modules
/* export interface GetUserResponse {
  AuthorizaitionToken: string
  DefaultPanelId: string
  Resources: Record<string, any>
  User: UserDetails
} */

export enum PanelUserChangeOperation {
  ADD,
  DELETE,
  CHANGE_NAME,
  CHANGE_CODE,
  DEACTIVATE,
  ACTIVATE,
}
