import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Drawer from '../../../components/Crow/Drawer/Drawer'
import { addToast } from '../../../components/Crow/Toast/Toast'
import Icon from '../../../components/Crow/Icon/Icon'
import { AddUserRequestBody } from '../../../types/PermanentUser'
import PeopleForm, { PeopleFormRef } from '../../../components/forms/PeopleForm'
import useI18n from '../../../i18n/useI18n'
import { useCreateNewUserForPanel } from '../../../services/requestHooks/users'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../AlarmSystems/Panel/PasswordPromt'
import TwoFactorPrompt, {
  clearOngoingPrompt,
  useTwoFactorPrompt,
} from '../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { colors } from '../../../components/Crow/Style/theme'
import { deriveInitials } from '../../../utils/deriveInitials'
import { errorCodesTriggerNew2FA } from '../../../utils/errorCodesTriggerNew2FA'
import { isTranslationKey } from '../../../types/generated/TranslationKey'

const AddUserDrawer = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
  const [pauseFocusTrap, setPauseFocusTrap] = useState<boolean>(false)
  const { t } = useI18n()

  const ref = React.useRef<PeopleFormRef>({})

  const {
    run: createNewUser,
    isLoading: newUserIsLoading,
    error: newUserError,
  } = useCreateNewUserForPanel({
    onSuccess: ({ response }: any) => {
      setPauseFocusTrap(true)
      const willSendSMS = response.Accesses.IsSystemUser && response.Accesses.IsAppUser

      const mainContent = t('toast_add_permanent_user_success_content', {
        name: response.FirstName.concat(' ', response.LastName),
      })
      const content = willSendSMS ? mainContent.concat(' ', t('toast_send_sms')) : mainContent

      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content,
      })
      handleClose()
    },
  })

  useEffect(() => {
    if (newUserError && ref?.current?.handleReSubmit) {
      setPauseFocusTrap(true)

      if (errorCodesTriggerNew2FA.includes(newUserError)) {
        clearOngoingPrompt()
        ref.current.handleReSubmit()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title:
            newUserError === '401statuscode'
              ? t('401_toast_title')
              : t('error_toast_generic_title'),
          content: isTranslationKey(newUserError)
            ? t(newUserError)
            : t('error_toast_generic_content'),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserError, ref])

  const panelId = usePanelId()

  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()

  type AddUserFormData = {
    firstName: string
    lastName: string
    phoneNumber: string
    underage: boolean
    system: boolean
    app: boolean
    admin: boolean
    pin: string
  }

  const mapUserProperties = (
    e: AddUserFormData,
    validationCode: string,
    password: string,
  ): AddUserRequestBody => {
    return {
      ValidationCode: validationCode,
      Password: password,
      FirstName: e.firstName,
      LastName: e.lastName,
      Initials: deriveInitials(e.firstName, e.lastName),
      PhoneNumber: e.phoneNumber,
      IsChild: e.underage,
      IsLegalOwner: false,
      IsSystemUser: e.system,
      IsAppUser: e.app,
      IsAdminUser: e.admin,
      PanelPinCode: e.pin,
    }
  }

  const onSubmit = async (e: AddUserFormData) => {
    try {
      const password = await promptForPassword()
      if (password) {
        const validationCode = await promptForTwoFactor()
        if (validationCode) {
          createNewUser(panelId, mapUserProperties(e, validationCode, password))
        }
      }
    } catch (error) {}
  }

  return (
    <S.Drawer
      isOpen={isOpen}
      position="right"
      title={t('Add permanent user')}
      handleClose={handleClose}
      closeButton={true}
      closeIcon={<Icon name={'Cancel'} />}
      pauseFocusTrap={pauseFocusTrap}
    >
      <PeopleForm
        onSubmit={onSubmit}
        cancelAction={handleClose}
        isLoading={newUserIsLoading}
        outsideRef={ref}
      />
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </S.Drawer>
  )
}

const S = {
  Drawer: styled(Drawer)`
    background-color: white;
    width: 100%;
    max-width: 950px;
    padding: 40px 72px;

    .drawer-close {
      position: absolute;
      width: 16px;
      top: 45px;
      left: 28px;
      font-size: 24px;
      color: ${colors.blue};
    }

    .drawer-title {
      margin: 0;
    }

    @media only screen and (max-width: 600px) {
      padding: 40px 24px;

      .drawer-title {
        margin-left: 48px;
      }
    }
  `,
  Icon: styled(Icon)`
    background-color: red;
  `,
}

export default AddUserDrawer
