import React from 'react'
import useI18n from '../../../i18n/useI18n'
import { BonusProduct } from '../../../types/BonusProduct'
import { getProductUrl } from './productImages'
import Button from '../../../components/Button'

interface ProductProps {
  product: BonusProduct
  selectable: boolean
  onClick: () => void
}

export const Product = ({ product, onClick, selectable }: ProductProps) => {
  const { t } = useI18n()
  return (
    <div className="m-4 product">
      <div>
        <img className="product__img" alt="product__img" src={getProductUrl(product.Id)} />
        <h1 className="text-xl mt-0">{product.Name}</h1>
        <div>{`${product.Points} ${t('points')}`}</div>
      </div>
      <Button size="small" onClick={onClick} buttonType="primary">
        {t('Purchase')}
      </Button>
    </div>
  )
}
