import React, { FC, useContext, useState } from 'react'
import Button from '../../../../components/Button'
import LoadPage from '../../../../components/LoadPage'
import Modal from '../../../../components/Modal'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useDisconnectFromWifi } from '../../../../services/requestHooks/wifi'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import { WifiDetails } from '../../../../types/WifiDetails'

type ModalState = {
  show: boolean
  onSubmit?: () => void
  onCancel?: () => void
  wifi?: WifiDetails
}
// TODO: Find correct translation-keys
const ConfirmDisconnect: FC<ModalState & { WiFiCardSerial: string | undefined }> = ({
  show,
  onSubmit,
  onCancel,
  WiFiCardSerial,
  wifi,
}) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { run: disconnectFromWifi, isLoading, error } = useDisconnectFromWifi({
    onSuccess: () => {
      onSubmit?.()
    },
  })

  const onDisconnectClick = async () => {
    if (WiFiCardSerial) {
      const TwoFactorPin = await promptForTwoFactor()
      if (TwoFactorPin) {
        disconnectFromWifi({ panelId, TwoFactorPin, WiFiCardSerial })
      }
    }
  }

  if (!show || !wifi) return null
  const { Name } = wifi

  return (
    <Modal handleClose={onCancel}>
      <LoadPage isLoading={isLoading}>
        <h1 className="text-2xl">{t('DISCONNECT')}</h1>
        <p>{`${t('DISCONNECT_QUESTION')} ${Name}`}</p>
        <Button buttonType="primary" onClick={onDisconnectClick}>
          {t('Confirm')}
        </Button>
        <Button buttonType="secondary" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        {!!error && <FailedRequest text={error} />}
      </LoadPage>
    </Modal>
  )
}

export const useConfirmDisconnect = () => {
  const [modalState, setModalState] = useState<ModalState>({ show: false, wifi: undefined })

  const promptForDisconnect = (wifi: WifiDetails) => {
    return new Promise<true | undefined>((resolve) => {
      setModalState({
        show: true,
        wifi,
        onSubmit: () => {
          setModalState({
            wifi: undefined,
            show: false,
          })
          resolve(true)
        },
        onCancel: () => {
          setModalState({ show: false, wifi: undefined })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForDisconnect,
    modalState: modalState,
  }
}

export default ConfirmDisconnect
