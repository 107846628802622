import React, { FC, ReactElement, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'
import { boxShadows } from '../Style/utilities'

export interface TileProps {
  footer?: ReactElement | ReactElement[]
  onClick?: () => void
  className?: string
  disabled?: boolean
  borderRadius?: string
  footerPadding?: string
  useDynamicHeight?: ReactNode
}

const Tile: FC<TileProps> = ({
  children,
  footer,
  onClick,
  disabled,
  useDynamicHeight,
  borderRadius,
  footerPadding,
  className,
}) => {
  return (
    <S.Tile
      role="tile"
      onClick={onClick}
      disabled={disabled!}
      useDynamicHeight={useDynamicHeight!}
      borderRadius={borderRadius!}
    >
      <S.Content className={className}>{children}</S.Content>
      {footer && (
        <S.CardFooter footerPadding={footerPadding!} className={className}>
          {footer}
        </S.CardFooter>
      )}
    </S.Tile>
  )
}

const S = {
  Tile: styled.div<{ disabled: boolean; borderRadius: string; useDynamicHeight: ReactNode }>`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    width: 164px;
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      ${boxShadows.tileHover}
    }
    height: ${({ useDynamicHeight }) => (useDynamicHeight ? useDynamicHeight : '164px')};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '24px')};
    ${boxShadows.tileStandard}
    ${({ disabled }) => {
      return (
        disabled &&
        css`
          background-color: ${colors.black100};
        `
      )
    }}
  `,
  MockCards: styled.div`
    padding: 32px;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, 164px);
    gap: 16px;
  `,
  Content: styled.div`
    flex-grow: 1;
    padding: 0 1.25rem;
  `,
  CardFooter: styled.div<{ footerPadding: string }>`
    padding: ${({ footerPadding }) => (footerPadding ? footerPadding : '0.25rem 5.25rem')};
    justify-self: flex-end;
    border-top: 1px solid ${colors.black100};
  `,
}

export default Tile
