import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import CrowCard from '../../components/Crow/CrowCard/CrowCard'
import FullscreenPage from '../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../components/TcOrCrowContainer'
import useI18n from '../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../services/requestHooks/panelInfo'
import { PanelStatus, Photo } from '../../types/PanelModel'
import { useOnMount } from '../../utils/commonHooks'
import { usePanelId } from '../AlarmSystems/hooks/usePanelId'

const CrowPhotoPage: FC = () => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const {
    data: panelInfo,
    run: loadPanelInfo,
    isLoading: loadingPanelInfo,
    error: panelInfoError,
  } = usePanelInfo()
  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  useOnMount(() => {
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  const getTriggerURL = (photo: Photo) => {
    return '/systems/' + panelId + '/picture/' + photo.Id
  }

  const canTakePicture = panelStatus?.IsOnline && panelStatus?.Status === PanelStatus.FULLY_ARMED

  const returnUrl = `/systems/${panelId}`

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <FullscreenPage
        isLoading={loadingPanelInfo || loadingPanelStatus}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed: !!panelInfoError || !!panelStatusError,
            errorHeaderText: 'photo',
            errorText: 'Something went wrong. Please try again',
            returnUrl,
          },
        }}
      >
        <BackButton backUrl={returnUrl} />
        <h1>{t('photo')}</h1>
        <p>{t('PHOTOS_DESCRIPTION')}</p>
        <ul className="pl-0">
          {panelInfo?.Photos.map((photo) => (
            <CrowCard key={photo.Id} as="li">
              <span>{photo.Label}</span>
              {canTakePicture && (
                <Link to={getTriggerURL(photo)} className="right">
                  {t('take_picture')}
                </Link>
              )}
            </CrowCard>
          ))}
        </ul>
        {!loadingPanelStatus && !panelStatus?.IsOnline && (
          <p>{t('pictures_only_when_system_on_ethernet')}</p>
        )}
        {panelStatus?.IsOnline && panelStatus?.Status !== PanelStatus.FULLY_ARMED && (
          <p>{t('photo_only_when_fully_armed')}</p>
        )}
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

export default CrowPhotoPage
